import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ContentFields from 'components/molecules/ContentFields';
import UserToProfileList from 'components/molecules/UserToProfileList';
import ScheduleCardList from 'components/molecules/ScheduleCardList';
import ROUTES from 'constants/routes';

const modalCss = css`
  @media (max-width: 599px) {
    top: 0;
  }

  .ant-modal-body {
    position: relative;
  }

  .CourseDetail-link {
    display: block;
    text-align: center;
    padding: 16px 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ac171c;
    color: #ffffff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const CourseModal = ({
  visible,
  onCancel,
  data,
  course,
  lecturers,
  locale,
  getMsg,
  otherSchedules,
}) => (
  <Modal
    className={modalCss}
    visible={visible}
    onCancel={e => {
      e.preventDefault();
      e.stopPropagation();
      onCancel();
    }}
    footer={null}
  >
    <Box
      className="course-detail-wrapper"
      bgColor={'#ffffff'}
      pr={16}
      pt={16}
      pb={16}
      borderRadius={4}
    >
      <ContentFields
        fields={[
          {
            label: 'Capacity',
            value: data ? data.capacity : '',
          },
          {
            label: 'Type',
            value: course.type,
          },
          {
            label: 'Credits',
            value: course.credit,
          },
        ]}
      />
    </Box>
    {!isEmpty(otherSchedules) && (
      <Box mb={16}>
        <Text.Paragraph color={'text.hint'} fontWeight={'bold'}>
          {getMsg('otherSection')}
        </Text.Paragraph>
        <ScheduleCardList
          code={course.code}
          name={course.name}
          schedules={otherSchedules}
        />
      </Box>
    )}
    <Text.Paragraph mb={8} color={'text.hint'} fontWeight={'bold'}>
      {getMsg('lecturers')}
    </Text.Paragraph>
    <UserToProfileList
      users={lecturers}
      to={ROUTES.lecturerDetail.get}
      getMsg={getMsg}
      locale={locale}
    />
    <Box height={32} mt={8} />
    <Link
      className={'CourseDetail-link'}
      to={ROUTES.courseDetail.get(course.id, course.name)}
    >
      {getMsg('fullCourseDetail')}
    </Link>
  </Modal>
);

CourseModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  data: PropTypes.shape({}),
  course: PropTypes.shape({}),
  otherSchedules: PropTypes.arrayOf(PropTypes.shape({})),
  lecturers: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string,
  getMsg: PropTypes.func,
};

CourseModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  data: {},
  course: {},
  lecturers: [],
  otherSchedules: [],
  locale: 'en',
  getMsg: () => '',
};

export default CourseModal;
