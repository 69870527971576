import uuid from 'uuid';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import matchesProperty from 'lodash/matchesProperty';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import isPlainObject from 'lodash/isPlainObject';
import some from 'lodash/some';
import { LEVELS, COURSE_TYPES } from 'constants/temporary';
import { replaceUndefined } from 'helpers/functions';

const ScheduleFactory = data => {
  // eslint-disable-next-line no-underscore-dangle
  const _schedule = data;

  const schema = {
    course: '',
    day: '',
    end: 0,
    lecturers: [],
    room: '',
    section: '',
    start: 0,
    capacity: '',
  };

  const getPart = item => {
    if (item.end < 13) {
      return 'morning';
    }
    if (item.start >= 13) {
      return 'evening';
    }
    return 'unknown';
  };

  const separatePart = array => ({
    morning: array.filter(item => getPart(item) === 'morning'),
    evening: array.filter(item => getPart(item) === 'evening'),
    unknown: array.filter(item => item.day === 'unknown'),
  });

  const toObject = (resources = {}) => {
    if (!_schedule || isEmpty(_schedule)) return _schedule;
    if (!isArray) {
      throw new Error('data must be an array');
    }
    const { courses, rooms, users } = resources;
    const { morning, evening, unknown } = separatePart(
      _schedule.map(({ course, room, lecturers, ...rest }) => ({
        ...rest,
        course: courses
          ? courses.find(matchesProperty('id', course)) || {}
          : {},
        room: rooms ? rooms.find(matchesProperty('id', room)) || {} : {},
        lecturers: users
          ? lecturers.map(id => users.find(matchesProperty('id', id))) || []
          : [],
      })),
    );
    return {
      morning: groupBy(morning, 'day'),
      evening: groupBy(evening, 'day'),
      unknown,
    };
  };

  const toArray = () => {
    if (!_schedule) return _schedule;
    if (!isPlainObject(_schedule)) {
      throw new Error('data must be an object');
    }
    const mapDaysToArray = days => {
      if (!days) {
        return [];
      }
      return reduce(days, (result, val) => [...result, ...val], []);
    };
    return [
      ...mapDaysToArray(_schedule.morning || {}),
      ...mapDaysToArray(_schedule.evening || {}),
    ];
  };

  const getFilters = () => [
    ...LEVELS.filter(matchesProperty('scheduleFilterIncluded', true)).map(
      ({ shortName, ...rest }) => ({
        ...rest,
        name: shortName,
        validate: ({ code }) =>
          Number(code) >= rest.min && Number(code) <= rest.max,
      }),
    ),
    ...COURSE_TYPES.map(item => ({
      ...item,
      validate: ({ type }) => type === item.id,
    })),
  ];

  const toApi = values => replaceUndefined(schema, values);

  const handleCreate = (value, schedule) => ({
    ...schedule,
    data: [...schedule.data, toApi({ ...value, id: uuid() })],
  });

  const handleUpdate = (value, selectedItem, schedule) => ({
    ...schedule,
    data: schedule.data.map(item => {
      if (item.id === selectedItem.id) {
        return toApi({ id: selectedItem.id, ...value });
      }
      return item;
    }),
  });

  const handleDelete = (selectedItem, schedule) => ({
    ...schedule,
    data: schedule.data.filter(item => item.id !== selectedItem.id),
  });

  return {
    toApi: values => replaceUndefined(schema, values),
    handleCreate,
    handleUpdate,
    handleDelete,
    toObject,
    toArray,
    getFilters,
    getOptions: (locale = 'en') =>
      getFilters().map(item => ({
        ...item,
        label: item.name[locale],
        value: item.id,
      })),
    filterBy: (courses, filterValues) => {
      if (isEmpty(filterValues)) return courses;
      const FILTERS = getFilters();
      return courses.filter(item =>
        some(filterValues, value =>
          FILTERS.find(matchesProperty('id', value)).validate(item.course),
        ),
      );
    },
  };
};

export const scheduleService = ScheduleFactory();

export default ScheduleFactory;
