import React from 'react';
import get from 'lodash/get';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';

const SeniorProject = () => {
  const dbAssets = useConsumer();
  return (
    <>
      <Text.Title level={3}>Senior Project</Text.Title>
      <Text.Paragraph fontSize={16} mb={24} color={'text.secondary'}>
        ภาคการศึกษาปลาย
      </Text.Paragraph>
      <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
        กำหนดการฉบับย่อ
      </Text.Paragraph>
      <MonthlyTimeline
        events={[
          {
            title: 'ปลายมกราคม',
            subtitle: 'ประชุมครั้งแรก (กำหนดการ-หลักการเขียนโครงงาน)',
            current: isInRange([18, 1], [23, 1]),
          },
          {
            title: 'ต้นเมษายน',
            subtitle: 'ส่งแบบร่างรายงานวิชาการให้อาจารย์ที่ปรึกษา',
            current: isInRange([24, 1], [5, 4]),
          },
          {
            title: 'ต้นพฤษภาคม (ส่งงานครั้งที่ 1)',
            subtitle: 'ส่งรายงานวิชาการ (5%) ที่ห้องธุรการภาควิชา ก่อน 15.00น',
            list: ['จำนวน 3 ชุด (อาจารย์ที่ปรึกษาและกรรมการ 2 ท่าน)'],
            links: [
              {
                label: 'รูปแบบรายงาน',
                href: get(
                  dbAssets,
                  'bachelorProject.seniorProjectReportTemplate',
                ),
              },
            ],
            current: isInRange([6, 4], [7, 5]),
          },
          {
            title: 'ปลายพฤษภาคม',
            subtitle: 'สอบนำเสนอหัวข้อโครงงาน',
            list: [
              // eslint-disable-next-line max-len
              'นําเสนอ (รวม VDO 10 นาที) 20 นาที ถาม-ตอบ 10 นาที (กรุณารักษาเวลา)',
              'ใช้ Powerpoint Template ของคณะฯ',
              'เตรียมเอกสารประกอบการนําเสนอให้กรรมการทุกท่าน',
              'เตรียมแบบฟอร์มให้คะแนนพร้อมกรอกรายละเอียด',
            ],
            links: [
              {
                label: 'Powerpoint Template',
                href: get(
                  dbAssets,
                  'bachelorProject.seniorProjectPowerpointTemplate',
                ),
              },
              {
                label: 'แบบฟอร์มให้คะแนน (อ.ที่ปรึกษา 1 ชุด)',
                href: get(dbAssets, 'bachelorProject.assessmentFormX1'),
              },
              {
                label: 'แบบฟอร์มให้คะแนน (กรรมการ+อ.ที่ปรึกษา 3 ชุด)',
                href: get(dbAssets, 'bachelorProject.assessmentFormX3'),
              },
            ],
            current: isInRange([20, 5], [25, 5]),
          },
          {
            title: 'ปลายพฤษภาคม (ส่งงานครั้งที่ 2)',
            subtitle:
              // eslint-disable-next-line max-len
              'ส่ง รายงานฉบับสมบูรณ์ และไฟล์เอกสารต่างๆ (5%) ที่ห้องธุรการภาควิชา ก่อน 15.00น',
            list: [
              'รายงานฉบับสมบูรณ์ (Hard Copy)',
              'แผ่น DVD (แยกเป็น Folder ตามรายละเอียดที่ท้ายตาราง)',
              'แบบประเมิน (Hard Copy)',
              // eslint-disable-next-line max-len
              'นําไฟล์ใน DVD ท้ังหมดมาลงที่เครื่อง คอมฯ ที่ห้องธุรการ (Private folder)',
              'แยกไฟล์ VDO + Article มาลงที่เครื่อง คอมฯธุรการ (Public folder)',
            ],
            current: isInRange([26, 5], [31, 5]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'Senior project ฉบับเต็ม'}
        href={get(dbAssets, 'bachelorProject.seniorProjectDetail')}
      />
      <br />
      <br />
      <br />
    </>
  );
};

SeniorProject.propTypes = {};
SeniorProject.defaultProps = {};

export default SeniorProject;
