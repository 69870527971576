import { useEffect, useState } from 'react';
import useApi from './useApi';

export default (fetchApi, initialState = null, deps = []) => {
  const [data, setData] = useState(initialState);
  const { api, status, reset } = useApi(fetchApi);
  useEffect(() => {
    let ignore = false;
    api().then(response => {
      if (!ignore) {
        setData(response);
      }
    });
    return () => {
      ignore = true;
    };
  }, deps);
  return { data, setData, status, reset };
};
