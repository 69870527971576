/* eslint-disable no-console */
const STAGE = process.env.REACT_APP_STAGE;

export default (promise, domain = '') => (...args) =>
  promise(...args).catch(err => {
    if (STAGE !== 'production') {
      console.log(
        `%c ${domain.toUpperCase()} : Error`,
        'background: #ff0000; color: #ffffff',
        { err },
      );
    }
    throw err;
  });
