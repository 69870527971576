import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Row, Col, Tag } from 'antd';
import CustomCard from 'components/molecules/CustomCard';
import TagList from 'components/molecules/TagList';
import Lecturer from 'factories/User/Lecturer';
import { CATEGORY_BY_ID } from 'constants/temporary';
import ROUTES from 'constants/routes';

const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
`;

const cardCss = css`
  .ant-card-cover {
    > .Box {
      border-top: none;
    }
  }
`;

const ProfessorCard = ({ className, data, loading, CardProps }) => (
  <CustomCard
    className={`${cardCss} ${className}`}
    {...CardProps}
    image={data.avatarUrl}
    loading={loading}
    actions={null}
  >
    <CustomCard.Meta title={data.fullName || data.name} />
    <br />
    {data.badge && <Tag color={'#ac171c'}>{data.badge}</Tag>}
    <TagList
      tags={data.categories}
      mapById={CATEGORY_BY_ID}
      getPath={() => ['name']}
    />
  </CustomCard>
);

ProfessorCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  CardProps: PropTypes.shape({}),
};
ProfessorCard.defaultProps = {
  className: '',
  data: {},
  loading: false,
  CardProps: {},
};
// eslint-disable-next-line react/prop-types
ProfessorCard.Group = ({ professors, loading }) => (
  <Row type={'flex'} gutter={16}>
    {professors.map(data => (
      <Col key={data.id} xs={24} sm={12}>
        <StyledLink
          to={ROUTES.lecturerDetail.get(data.id, Lecturer().getName(data))}
        >
          <ProfessorCard data={data} loading={loading} />
        </StyledLink>
      </Col>
    ))}
  </Row>
);

export default ProfessorCard;
