import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { space, width, borders } from 'styled-system';

const Paper = styled('div')(
  ({ theme, pad, padX, padY, shadow, shape }) => ({
    backgroundColor: '#ffffff',
    margin: 'auto',
    ...(pad === 'normal' &&
      theme.createMediaQueries({
        xs: {
          padding: 16,
        },
        sm: {
          padding: 24,
        },
        md: {
          padding: 36,
        },
      })),
    ...(pad === 'none' && {
      padding: '0!important',
    }),
    ...(padX === 'small' &&
      theme.createMediaQueries({
        xs: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        sm: {
          paddingLeft: 12,
          paddingRight: 12,
        },
        md: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      })),
    ...(padX === 'none' && {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    }),
    ...(padY === 'small' &&
      theme.createMediaQueries({
        xs: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        sm: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        md: {
          paddingTop: 16,
          paddingBottom: 16,
        },
      })),
    ...(padY === 'none' && {
      paddingTop: '0!important',
      paddingBottom: '0!important',
    }),
    ...(shadow === 'normal' && {
      boxShadow: '0px 0px 8px -2px rgba(0,0,0,0.12)',
    }),
    ...(shadow === 'none' && {
      boxShadow: 'none',
    }),
    ...(shape === 'circular' && {
      borderRadius: 100,
    }),
    ...(shape === 'round' && {
      borderRadius: 4,
    }),
    ...(shape === 'square' && {
      borderRadius: 0,
    }),
  }),
  space,
  width,
  borders,
);

Paper.propTypes = {
  pad: PropTypes.oneOf(['normal', 'none']),
  padY: PropTypes.oneOf([undefined, 'none']),
  padX: PropTypes.oneOf([undefined, 'none']),
  shadow: PropTypes.oneOf(['normal', 'none']),
  shape: PropTypes.oneOf(['circular', 'round', 'square']),
};
Paper.defaultProps = {
  pad: 'normal',
  padY: undefined,
  padX: undefined,
  shadow: 'normal',
  shape: 'round',
};

export default Paper;
