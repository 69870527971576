import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Avatar, List } from 'antd';
import Text from 'components/atoms/Text';

const UserToProfileList = ({
  users,
  locale,
  getMsg,
  to,
  concise,
  avatarProps,
}) => {
  const renderLink = item => (
    <Link to={to(item.id, item.name[locale])}>{getMsg('viewProfile')}</Link>
  );
  return (
    <List
      itemLayout="horizontal"
      dataSource={users}
      renderItem={item => (
        <List.Item actions={concise ? null : [renderLink(item)]}>
          <List.Item.Meta
            avatar={<Avatar {...avatarProps} src={get(item, ['avatarUrl'])} />}
            title={<Text fontWeight="bold">{get(item, ['name', locale])}</Text>}
            description={concise ? renderLink(item) : ''}
          />
        </List.Item>
      )}
    />
  );
};

UserToProfileList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string,
  getMsg: PropTypes.func,
  to: PropTypes.func.isRequired,
  concise: PropTypes.bool,
  avatarProps: PropTypes.shape({}),
};
UserToProfileList.defaultProps = {
  users: [],
  locale: 'en',
  getMsg: () => '',
  concise: false,
  avatarProps: {},
};

export default UserToProfileList;
