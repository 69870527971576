import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';
import { Transition, animated } from 'react-spring/renderprops';
import SwipeableViews from 'react-swipeable-views';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ShortcutWidget from 'components/atoms/ShortcutWidget';
import Character from 'components/atoms/Character';
import Arrow from 'components/atoms/Arrow';
import Dot from 'components/atoms/Dot';
import Container from 'components/layouts/Container';
import Hidden from 'components/responsive/Hidden';
import { withIntl } from 'contexts/intl';
import { COURSE_TYPE_BY_ID, LEVEL_BY_ID } from 'constants/temporary';
import ROUTES from 'constants/routes';
import { breakpoints } from 'theme';

import bachelorStudent from 'assets/bachelorStudent.png';
import masterStudent from 'assets/masterStudent.png';

const characters = [
  {
    id: 1,
    name: {
      en: 'Bachelor Student',
      th: 'นิสิตปริญญาตรี',
    },
    first: {
      en: 'Bachelor',
      th: 'นิสิต',
    },
    last: {
      en: 'Student',
      th: 'ปริญญาตรี',
    },
    image: bachelorStudent,
    shortcuts: [
      {
        id: 11,
        title: {
          en: 'Courses',
          th: 'รายวิชา ป.ตรี',
        },
        icon: 'read',
        href: `/courses?level=${LEVEL_BY_ID.senior.id}&type=${
          COURSE_TYPE_BY_ID.approvedElective.id
        }`,
      },
      {
        id: 12,
        title: {
          en: 'Schedule',
          th: 'ตารางสอน ป.ตรี',
        },
        icon: 'calendar',
        href: `/schedule?level=${LEVEL_BY_ID.senior.id}`,
      },
      {
        id: 13,
        title: {
          en: 'Internship',
          th: 'ฝึกงาน',
        },
        icon: 'far fa-user-tie',
        href: ROUTES.internship.get(),
      },
      {
        id: 14,
        title: {
          en: 'Senior Project',
          th: 'โปรเจคจบการศึกษา',
        },
        icon: 'far fa-file-chart-pie',
        href: ROUTES.seniorBachelor.get(),
      },
    ],
  },
  {
    id: 2,
    name: {
      en: 'Master Student',
      th: 'นิสิตปริญญาโท',
    },
    first: {
      en: 'Master',
      th: 'นิสิต',
    },
    last: {
      en: 'Student',
      th: 'ปริญญาโท',
    },
    image: masterStudent,
    shortcuts: [
      {
        id: 21,
        title: {
          en: 'Courses',
          th: 'รายวิชา ป.โท',
        },
        icon: 'read',
        href: `/courses?level=${LEVEL_BY_ID.master.id}`,
      },
      {
        id: 22,
        title: {
          en: 'Schedule',
          th: 'ตารางสอน ป.โท',
        },
        icon: 'calendar',
        href: `/schedule?level=${LEVEL_BY_ID.master.id}`,
      },
      {
        id: 23,
        title: {
          en: 'Professor',
          th: 'อาจารย์',
        },
        icon: 'team',
        href: '/lecturers',
      },
      {
        id: 24,
        title: {
          en: 'Thesis',
          th: 'วิทยานิพนธ์',
        },
        icon: 'far fa-file-certificate',
        href: ROUTES.thesis.get(),
      },
    ],
  },
];

const Root = styled('div')`
  .Arrow-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .Arrow-right {
    right: 0;
    ${breakpoints.up('sm')} {
      right: 8%;
    }
    ${breakpoints.up('md')} {
      right: 12%;
    }
    ${breakpoints.up('lg')} {
      right: 16%;
    }
  }
  .Arrow-left {
    left: 0;
    ${breakpoints.up('md')} {
      left: 4%;
    }
    ${breakpoints.up('lg')} {
      left: 10%;
    }
  }

  .ShortcutWidget-root {
    ${breakpoints.up('sm')} {
      min-height: 120px;
    }
    ${breakpoints.up('lg')} {
      min-height: 144px;
    }
  }
`;

const StyledViews = styled(SwipeableViews)`
  .Character-root {
    padding-top: 88px;
    margin-bottom: 40px;

    ${breakpoints.up('sm')} {
      margin-right: 32px;
      img {
        min-height: 192px;
        transform: translateY(-4px);
      }
      .shade-1 {
        width: 100px;
        height: 44px;
      }
      .shade-2 {
        width: 144px;
        height: 64px;
      }
      .shade-3 {
        width: 184px;
        height: 92px;
      }
    }

    ${breakpoints.up('md')} {
      img {
        min-height: 208px;
        transform: translateY(-4px);
      }
    }

    ${breakpoints.up('lg')} {
      img {
        min-height: 240px;
        transform: translateY(-4px);
      }
    }
  }

  .Name-first,
  .Name-last {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    color: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.38)`};
    ${breakpoints.up('sm')} {
      transform: translateX(-58%);
    }
  }

  .Name-first {
    ${breakpoints.up('md')} {
      font-size: 56px;
      top: -12px;
    }
  }

  .Name-last {
    top: 48px;
    color: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.2)`};
    ${breakpoints.up('md')} {
      font-size: 48px;
      top: 40px;
    }
  }
`;

const NewsBox = styled(Box)`
  .news-title {
    letter-spacing: 1px;
    color: ${({ theme }) => theme.primary.main};
  }
  .news-title-dash {
    height: 4px;
    width: 24px;
    background: ${({ theme }) => theme.primary.main};
    ${breakpoints.up('sm')} {
      height: 6px;
      width: 32px;
    }
  }

  ${breakpoints.up('sm')} {
    text-align: right;
    .news-title-dash {
      margin-left: auto;
    }
  }
`;

const calculateMargin = (selfIndex, slideIndex, degree = 1) => {
  const diff = selfIndex - slideIndex;
  if (Math.abs(diff) > 1) return 0;
  return `${diff * degree}%`;
};

const GroupShortcut = ({ displayText, getMsg }) => {
  const [index, setIndex] = useState(0);
  const [fineIndex, setFineIndex] = useState(index);

  const onChangeIndex = i => {
    setIndex(i);
    setFineIndex(i);
  };
  const similarTextProps = {
    fontSize: 40,
    fontWeight: 900,
    my: '0!important',
    whiteSpace: 'nowrap',
  };
  return (
    <Container>
      <Root>
        <NewsBox mb={24}>
          <Text.Title
            className="news-title"
            level={2}
            fontSize={{ xs: 24, sm: 28, md: 32 }}
            mb={'0.4em'}
            fontWeight={800}
          >
            {getMsg('shortcuts')}
          </Text.Title>
          <div className="news-title-dash" />
        </NewsBox>
        <Row type={'flex'}>
          <Col xs={24} sm={14}>
            <Box position={'relative'}>
              <StyledViews
                resistance
                springConfig={{
                  duration: '0.6s',
                  easeFunction: '',
                  delay: '0s',
                }}
                enableMouseEvents
                index={index}
                onChangeIndex={onChangeIndex}
                onSwitching={i => {
                  setFineIndex(i);
                }}
              >
                {characters.map(({ id, name, first, last, image }, i) => (
                  <Box key={id} position={'relative'} overflow={'hidden'}>
                    <Text.Title
                      {...similarTextProps}
                      fontSize={48}
                      className={'Name-first'}
                      style={{
                        marginLeft: calculateMargin(i, fineIndex, 60),
                        transition: fineIndex === index ? '0.8s' : 'none',
                      }}
                    >
                      {displayText(first)}
                    </Text.Title>
                    <Text.Title
                      {...similarTextProps}
                      className={'Name-last'}
                      style={{
                        marginLeft: calculateMargin(i, fineIndex, 40),
                        transition: fineIndex === index ? '0.8s' : 'none',
                      }}
                    >
                      {displayText(last)}
                    </Text.Title>
                    <Character glowed={index === i} image={image} key={name} />
                  </Box>
                ))}
              </StyledViews>
              <Arrow.Left
                disabled={index === 0}
                onClick={() => onChangeIndex(index - 1)}
              />
              <Arrow.Right
                disabled={index === characters.length - 1}
                onClick={() => onChangeIndex(index + 1)}
              />
            </Box>
            <Hidden smUp>
              <StyledViews
                index={index}
                disabled
                springConfig={{
                  duration: '0.4s',
                  easeFunction: '',
                  delay: '0s',
                }}
                style={{ margin: '-8px -8px 0' }}
                slideStyle={{ padding: '16px 8px' }}
              >
                {characters.map(({ id, shortcuts: items }) => (
                  <Box key={id} display={'flex'}>
                    {items.map((item, i) => (
                      <Box
                        key={item.id}
                        pr={i === items.length - 1 ? 0 : 16}
                        flex={1}
                        height={'100%'}
                      >
                        <ShortcutWidget
                          {...item}
                          title={displayText(item.title)}
                        />
                      </Box>
                    ))}
                  </Box>
                ))}
              </StyledViews>
            </Hidden>
            <Dot.Group>
              {characters.map(({ id }, i) => (
                <Dot
                  key={id}
                  active={i === index}
                  onClick={() => onChangeIndex(i)}
                />
              ))}
            </Dot.Group>
          </Col>
          <Hidden only={'xs'}>
            <Col span={10}>
              <Transition
                native
                items={index}
                from={{ opacity: 0, transform: 'translate3d(0,40px,0)' }}
                enter={{
                  opacity: 1,
                  transform: 'translate3d(0,0,0)',
                  position: 'absolute',
                }}
                leave={{
                  display: 'none',
                  transform: 'translate3d(0,0,0)',
                }}
              >
                {i => style => (
                  <animated.div
                    style={{ ...style, height: '100%', width: '100%' }}
                  >
                    <Row
                      gutter={16}
                      type={'flex'}
                      align={'middle'}
                      style={{ height: '100%' }}
                    >
                      {characters[i].shortcuts.map(item => (
                        <Col key={item.id} xs={12} sm={8} xl={6}>
                          <ShortcutWidget
                            {...item}
                            title={displayText(item.title)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </animated.div>
                )}
              </Transition>
            </Col>
          </Hidden>
        </Row>
      </Root>
    </Container>
  );
};

GroupShortcut.propTypes = {
  displayText: PropTypes.func,
  getMsg: PropTypes.func,
};
GroupShortcut.defaultProps = {
  displayText: () => '',
  getMsg: () => '',
};

export default withIntl(GroupShortcut);
