import React, { useContext } from 'react';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import isString from 'lodash/isString';
import format from 'date-fns/format';
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en';
import messages from 'constants/messages';
import toHOC from './toHOC';

const LOCALES = {
  en,
  th,
};

const initialState = {
  languages: [
    { id: 'en', label: '🇺🇸 English' },
    { id: 'th', label: '🇹🇭 ไทย' },
  ],
  locale: 'en',
  messages,
  switchLocale: () => {},
  getMsg: () => '',
};
export const IntlContext = React.createContext(initialState);

export default class IntlProvider extends React.Component {
  constructor() {
    super();
    const cachedLocale = localStorage.getItem('locale');
    this.switchLocale = locale => {
      this.setState({ locale }, () => {
        localStorage.setItem('locale', locale);
      });
    };
    this.state = {
      ...initialState,
      locale: cachedLocale || initialState.locale,
      switchLocale: this.switchLocale,
    };
  }

  getMsg = key => {
    const { messages: msg } = this.state;
    return this.displayText(get(msg, key, ''));
  };

  displayText = text => {
    if (!text) return '';
    const { locale } = this.state;
    if (isString(text)) return text;
    return text[locale] || this.findValidText(text);
  };

  findValidText = text => {
    const validKey = findKey(text, value => !!value);
    return text[validKey];
  };

  mapLocale = (data, keys) => {
    const { locale } = this.state;
    return data.map(item => ({
      ...item,
      ...keys.reduce(
        (result, key) => ({
          [key]: item[key][locale],
        }),
        {},
      ),
    }));
  };

  formatDate = (date, pattern = 'D MMM YYYY') => {
    const { locale } = this.state;
    const options = {
      locale: LOCALES[locale],
    };
    if (typeof date === 'string') {
      return format(new Date(date), pattern, options);
    }
    return format(date.toDate ? date.toDate() : date, pattern, options);
  };

  render() {
    return (
      <IntlContext.Provider
        {...this.props}
        value={{
          ...this.state,
          getMsg: this.getMsg,
          mapLocale: this.mapLocale,
          displayText: this.displayText,
          formatDate: this.formatDate,
        }}
      />
    );
  }
}

export const useIntl = () => useContext(IntlContext);
export const IntlConsumer = IntlContext.Consumer;
export const withIntl = toHOC(IntlConsumer);
