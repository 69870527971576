/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import Text from 'components/atoms/Text';
import { Row, Col } from 'antd';
import HrefButton from 'components/atoms/HrefButton';
import useScrollToElement from 'hooks/useScrollToElement';
import Query from 'factories/query';
import get from 'lodash/get';
import Bulletin from 'components/molecules/Bulletin';
import { useConsumer } from 'contexts/db';

const AdmissionSasin = ({ location }) => {
  const query = Query().toObject(location.search);
  useScrollToElement(query.topic);
  const dbAssets = useConsumer();
  return (
    <div>
      <Element name={'sce-program'}>
        <Text.Heading>Sasin Chula Engineering</Text.Heading>
      </Element>
      <Text.SubHeading mb={24}>
        Also known as Sasin Chula Engineering (SCE)
      </Text.SubHeading>

      {/*<Bulletin*/}
      {/*  data={get(dbAssets, 'admission.sasin')}*/}
      {/*  defaultLabel={'Sasin Chula Engineering Bulletin'}*/}
      {/*/>*/}
      <img
        src={
          'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/sasin%2Bie.png'
        }
        alt={'sasin'}
        style={{ display: 'block', maxWidth: '100%' }}
      />

      <br />
      <br />
      <Text.Body>
        When you graduate from this program you will receive:
      </Text.Body>

      <Row type={'flex'} gutter={16}>
        <Col xs={6} sm={4}>
          <img
            src={
              'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/sasin_logo.png'
            }
            alt={'chula-eng'}
            style={{ display: 'block', maxWidth: '100%' }}
          />
        </Col>
        <Col xs={18} sm={20}>
          <Text.Body>
            an <b>MBA from Sasin</b>, the first internationally accredited
            business program in Thailand
          </Text.Body>
        </Col>
        <Col xs={6} sm={4}>
          <img
            src={
              'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/chula_eng_logo.png'
            }
            alt={'chula-eng'}
            style={{ display: 'block', maxWidth: '100%' }}
          />
        </Col>
        <Col xs={18} sm={20}>
          <Text.Body>
            a{' '}
            <b>
              Master’s in Industrial Engineering from Chulalongkorn University
            </b>
            , one of the longest established and leading engineering programs in
            the country.
          </Text.Body>
        </Col>
      </Row>

      <br />
      <Text.Body fontWeight={'bold'}>What sets this program apart?</Text.Body>
      <Text.Body>
        <ul>
          <li>Two degrees from two world-class programs,</li>
          <li>
            MBA from Sasin and a Master’s in Industrial Engineering from the
            Faculty of Engineering, Chulalongkorn University,
          </li>
          <li>
            an integrated curriculum focusing on business management, and
            analytical and technical skills,
          </li>
          <li>
            practical experience through internships combined with a Master’s
            Thesis,
          </li>
          <li>
            access to two extensive alumni and corporate partner networks,
          </li>
          <li>extensive career opportunities with SCE Corporate Partners,</li>
          <li>
            opportunities to receive generous scholarships and fellowships.
          </li>
        </ul>
      </Text.Body>

      <br />
      <br />

      <Text.Body fontWeight={'bold'}>MIT/Sasin Collaboration</Text.Body>
      <Text.Body>
        Sasin-MIT Action Learning Lab is a joint initiative of the MIT Sloan
        School of Management and Sasin Graduate Institute of Business
        Administration of Chulalongkorn University. This program engages joint
        teams of MBA students from these two schools to work collaboratively on
        key management challenges facing host companies. Each Action Learning
        project team has six students, three from MIT Sloan and three from
        Sasin. Project teams will work full time from mid-June to the end of
        July at the host company. Upon completion, project teams will report
        their results to the companies’ top executives. Action Learning projects
        cover the range of issues that leadership teams face. Subject matter can
        involve strategy, operations, business development, finance, marketing,
        and human resources, as well as other management challenges. Most issues
        fall into two categories: problems that need to be solved, and
        opportunities that need to be exploited.
      </Text.Body>
      <Text.Body mb={8}>
        <b>Sasin Chula Engineering Dual MBA & M.Eng</b> students can apply to
        participate in this program, which is a 6-credit course that will
        satisfy the MBA “action learning” degree requirement. The students will
        also receive a certificate of completion from MIT.
      </Text.Body>
      <HrefButton
        href={
          'https://www.sasin.edu/what-we-offer/teaching-learning/dual-mba-master-engineering/'
        }
        label={'More detail'}
        icon={'fas fa-info-circle'}
      />
      <br />
      <br />
      <br />
      <Element name={'sce-how-to-apply'}>
        <Text.Topic>How to apply</Text.Topic>
      </Element>
      <Text.Body mb={8}>
        Follow this link to learn more about the program and <b>register</b>
      </Text.Body>
      <HrefButton
        href={'https://apply.sasin.edu/'}
        label={'Apply'}
        icon={'fas fa-edit'}
      />
      <br />
      <br />
      <br />
      <Element name={'sce-fees'}>
        <Text.Topic>Fees & Funding</Text.Topic>
      </Element>
      <Text.Body mb={4}>
        Please contact Sasin official website directly
      </Text.Body>
      <a href={'mailto:marketing@sasin.edu'} rel={'noopener'} target={'_blank'}>
        marketing@sasin.edu
      </a>
      <br />
      <br />
      <br />
    </div>
  );
};

AdmissionSasin.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
AdmissionSasin.defaultProps = {};

export default withRouter(AdmissionSasin);
