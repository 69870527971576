import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const DrmisTH = () => (
  <div>
    <Box mx={{ xs: -16, sm: -32 }} mb={24}>
      <img
        style={{ display: 'block', maxWidth: '100%' }}
        alt={'cover'}
        src={
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FDRMIS_onBlack.jpg?alt=media&token=c52de49d-c085-4a5a-a57b-d8cb8412453e'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      หน่วยปฏิบัติการวิจัยระบบสารสนเทศการจัดการภัยพิบัติและความเสี่ยง (DRMIS)
    </Text.Heading>
    <Text.Topic>งานวิจัยที่เกี่ยวข้อง</Text.Topic>
    <Text.Body>
      <ul>
        <li>การจัดการภัยพิบัติ</li>
        <li>การบริหารความเสี่ยง</li>
        <li>การบริหารความต่อเนื่องทางธุรกิจ</li>
        <li>การจัดการระบบสารสนเทศ</li>
        <li>บิ๊กดาต้าและการวิเคราะห์ข้อมูล</li>
      </ul>
    </Text.Body>

    <Text.Topic>บุคลากร</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get('/9RMKKUm8yie7AT8Ryiqd/Natt%20Leelawat')}
          >
            ผศ. ดร.ณัฏฐ์ ลีละวัฒน์
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/aN5GJGNaJBHFm0TEr4YM/Prasert%20Akkharaprathomphong',
            )}
          >
            ผศ. ประเสริฐ อัครประถมพงศ์
          </Link>
        </li>
        <li>ผศ. ดร.สุภัทรา วิเศษศรี (ภาควิชาวิศวกรรมแหล่งน้ำ)</li>
        <li>ผศ. ดร.ฉัตรพันธ์ จินตนาภักดี (ภาควิชาวิศวกรรมโยธา)</li>
        <li>อาจารย์ ดร.จิง ถาง (สำนักบริหารหลักสูตรวิศวกรรมนานาชาติ)</li>
      </ul>
    </Text.Body>

    <Text.Topic>สถานที่</Text.Topic>
    <Text.Body>ห้อง 511 ชั้น 5 อาคารเจริญวิศวกรรม (ตึก 4)</Text.Body>
  </div>
);

DrmisTH.propTypes = {};
DrmisTH.defaultProps = {};

export default DrmisTH;
