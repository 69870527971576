import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  space,
  width,
  flex,
  minHeight,
  position,
  overflow,
} from 'styled-system';

const Container = styled('div')(
  ({ theme, contracted, paper }) => ({
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: contracted ? 768 : 960,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: contracted ? 960 : 1152,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: contracted ? 1152 : 1344,
    },
    ...(paper && {
      background: '#ffffff',
      borderRadius: 4,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12)',
    }),
  }),
  space,
  width,
  flex,
  minHeight,
  position,
  overflow,
);

Container.propTypes = {
  contracted: PropTypes.bool,
  paper: PropTypes.bool,
};
Container.defaultProps = {
  className: 'CustomContainer',
  width: '100%',
  contracted: false,
  paper: false,
  px: { xs: 16, sm: 24, md: 32 },
  py: { xs: 16, sm: 32, md: 40 },
};

export default Container;
