import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animateScroll } from 'react-scroll';
import { Button } from 'antd';
import withSizes from 'hocs/withSizes';

const Wrapper = styled('div')`
  position: fixed;
  bottom: 16px;
  left: 50%;
  z-index: 900;
  ${({ theme }) => `
    ${[theme.breakpoints.up('sm')]} {
      bottom: 24px;
    }
  `}
  transition: ${({ theme }) => theme.transitions.create()};
  transform: ${({ show }) =>
    `translateX(-50%) ${show ? 'translateY(0)' : 'translateY(100px)'}`};
`;

const scrollConfig = {
  duration: 400,
  smooth: true,
  delay: 0,
};

class GotoTop extends React.Component {
  constructor() {
    super();

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('scroll', this.showScroller);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('scroll', this.showScroller);
  }

  showScroller = () => {
    const { scrollY } = window;
    if (this.mounted) {
      this.setState({ show: scrollY > 500 });
    }
  };

  render() {
    const { show } = this.state;
    const { isNotMobile } = this.props;
    return (
      <Wrapper show={show}>
        <Button
          htmlType={'button'}
          type={'primary'}
          shape={'round'}
          icon={'up-circle'}
          size={isNotMobile ? 'large' : 'default'}
          onClick={() => animateScroll.scrollToTop(scrollConfig)}
        >
          Top
        </Button>
      </Wrapper>
    );
  }
}

GotoTop.propTypes = {
  isNotMobile: PropTypes.bool,
};
GotoTop.defaultProps = {
  isNotMobile: false,
};
GotoTop.config = scrollConfig;

export default withSizes(withSizes.mapSizes)(GotoTop);
