import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConsumer } from 'contexts/scholarship';
import FireApi from 'firebase/fireApi';
import useIntl from 'hooks/useIntl';
import useApi from 'hooks/useApi';
import { Affix, Col, Row, Tabs, Empty, Divider, Tooltip } from 'antd';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import Container from 'components/layouts/Container';
import CustomCard from 'components/molecules/CustomCard';
import ScholarshipCard from 'components/molecules/ScholarshipCard';
import {
  SCHOLARSHIP_CATEGORIES,
  SCHOLARSHIP_SOURCES,
} from 'constants/temporary';
import ROUTES from 'constants/routes';

const loadingArray = Array(6)
  .fill(0)
  .map((i, index) => ({ id: i + index }));

const ScholarshipsPage = ({
  match: {
    params: { category },
  },
  history,
}) => {
  const [cachedItems, setCachedItems] = useState([]);
  const { scholarships, concatScholarships } = useConsumer();
  const { api, status } = useApi(FireApi.fetchScholarships);
  const filteredScholarships = scholarships.filter(({ categories: catId }) => {
    if (category === 'all') return true;
    return catId.includes(category);
  });
  useEffect(() => {
    setCachedItems([...cachedItems, category]);
    if (!cachedItems.includes(category)) {
      api({ categoryId: category }).then(result => {
        concatScholarships(result);
      });
    }
  }, [category]);
  const { getMsg, displayText } = useIntl();

  const isLoading = status.isRequest;
  const renderCard = item => (
    <Col key={item.id} xs={24} sm={12} lg={8} style={{ display: 'flex' }}>
      <ScholarshipCard
        loading={isLoading}
        title={displayText(item.title)}
        subtitle={displayText(item.subtitle)}
        logo={item.logo}
        sponsor={item.sponsor}
        url={item.sponsorLink}
        categories={item.categories}
        detail={item.detail}
      />
    </Col>
  );
  return (
    <Container style={{ flex: 'auto' }}>
      <Text.Title level={2} letterSpacing={0.5}>
        {getMsg('scholarship')}
      </Text.Title>
      <Affix offsetTop={0}>
        <Box bgColor={'#f7f7f7'} pb={8}>
          <Box mx={{ xs: -16, sm: 0 }} {...Box.justifyBetween}>
            <Tabs
              activeKey={category}
              onChange={key => {
                history.replace(ROUTES.scholarships.get(`/${key}`));
              }}
              style={{ width: '100%' }}
            >
              <Tabs.TabPane key={'all'} tab={getMsg('all')} />
              {SCHOLARSHIP_CATEGORIES.map(({ label, id }) => (
                <Tabs.TabPane key={id} tab={displayText(label)} />
              ))}
            </Tabs>
          </Box>
        </Box>
      </Affix>
      <Row gutter={16} type={'flex'}>
        {(isLoading ? loadingArray : filteredScholarships).map(renderCard)}
      </Row>
      {!isLoading && !filteredScholarships.length && (
        <Box py={40}>
          <Empty />
        </Box>
      )}
      <Divider />
      <Text.Title level={2} letterSpacing={0.5}>
        {getMsg('scholarshipLink')}
      </Text.Title>
      <Row gutter={16} type={'flex'}>
        {SCHOLARSHIP_SOURCES.map(source => (
          <Col xs={24} sm={8} key={source.id}>
            <Tooltip title={displayText(source.label)} placement="top">
              <a
                href={source.href}
                target={'_blank'}
                rel={'noopener'}
                style={{ display: 'block' }}
              >
                <CustomCard
                  hoverable
                  image={source.image}
                  component={'a'}
                  href={source.href}
                  target={'_blank'}
                  rel={'noopener'}
                  size={'small'}
                  bodyStyle={{ padding: 0 }}
                />
              </a>
            </Tooltip>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

ScholarshipsPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
};
ScholarshipsPage.defaultProps = {};

export default ScholarshipsPage;
