/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from '@emotion/styled';
import { Tabs, Collapse, Tag } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import Social from 'components/atoms/Social';
import DownloadUI from 'components/molecules/DownloadUI';
import Hidden from 'components/responsive/Hidden';
import CourseItem from 'components/molecules/CourseItem';
import Timeline from 'components/molecules/Timeline';
import ListSkeleton from 'components/molecules/ListSkeleton';
import FireApi from 'firebase/fireApi';
import LecturerDetailLayout from 'components/layouts/LecturerDetailLayout';
import ContactInfo from 'components/molecules/ContactInfo';
import { useConsumer } from 'contexts/lecturer';
import useIntl from 'hooks/useIntl';
import UserFactory from 'factories/User';

import { CATEGORY_BY_ID } from 'constants/temporary';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-top: ${({ theme }) => `1px solid ${theme.grey[100]}`};
    border-bottom: none;
    .ant-collapse-header {
      padding-left: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.grey[700]};
    }
  }
  .ant-collapse-item {
    .ant-collapse-header .anticon {
      height: 40px;
      left: initial;
      right: 16px;
      transform: translateY(-50%) rotateZ(90deg);
    }
    &.ant-collapse-item-active {
      .ant-collapse-header .anticon {
        transform: translateY(-50%) rotateZ(-180deg);
      }
    }
  }
`;

const LecturerDetail = ({ match: { params } }) => {
  const { getOneLecturer } = useConsumer();
  const { locale, getMsg } = useIntl();
  const [loading, setLoading] = useState(true);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [data, setData] = useState(null);
  const [courses, setCourses] = useState(null);
  const preData = getOneLecturer(params.lecturerId);
  const lecturerService = UserFactory(data);
  const social = get(data, 'social', []);
  useEffect(() => {
    if (preData) {
      setData(preData);
      setLoading(false);
    } else {
      FireApi.fetchLecturerDetail(params.lecturerId)
        .then(setData)
        .then(() => setLoading(false));
    }
  }, []);
  useEffect(() => {
    if (data && data.courses) {
      Promise.all(data.courses.map(FireApi.fetchCourseDetail))
        .then(setCourses)
        .then(() => setCoursesLoading(false));
    }
  }, [data]);
  const renderResume = () => (
    <DownloadUI
      getMsg={getMsg}
      link={data.resumeUrl}
      icon={'fal fa-file-user'}
      iconProps={{ fontSize: 40 }}
      label={'Resume'}
    />
  );
  const renderContact = () => (
    <ContactInfo
      getData={id => get(data, ['contact', id])}
      menus={[
        { id: 'office', icon: 'fal fa-map-marker-alt' },
        { id: 'phone', icon: 'phone', type: 'phone' },
        { id: 'email', icon: 'mail', type: 'email' },
        { id: 'website', icon: 'fal fa-browser', type: 'url' },
      ]}
    />
  );
  return (
    <LecturerDetailLayout
      loading={loading}
      renderInfo={() => (
        <React.Fragment>
          <Box p={{ xs: 16, sm: 24, md: 36 }} display={'flex'}>
            <Image
              avatar
              src={data.avatarUrl}
              icon={'user'}
              alignSelf={'flex-start'}
              {...Image.createAntAvatar({ xs: 72, sm: 100, md: 116 })}
            />
            <Box flex={1} ml={{ xs: 16, sm: 24, md: 36 }} mt={{ md: 8 }}>
              <Text.Title
                copyable
                level={3}
                fontSize={{ xs: 16, sm: 20, md: 24 }}
              >
                {lecturerService.getName(data, 'en')}
              </Text.Title>
              <Text.Paragraph
                copyable
                fontSize={{ xs: 16, sm: 20, md: 24 }}
                mb={0}
              >
                {lecturerService.getName(data, 'th')}
              </Text.Paragraph>
              <Hidden smUp>
                <Box mt={16}>{renderResume()}</Box>
              </Hidden>
            </Box>
            <Hidden only={'xs'}>
              <Box>{renderResume()}</Box>
            </Hidden>
          </Box>
          <Hidden smUp>
            <StyledCollapse defaultActiveKey={['1']} bordered={false}>
              <Panel header={getMsg('contact')} key="1">
                {renderContact()}
                {social.some(({ url }) => !!url) && (
                  <>
                    <Text mt={20} mb={12} strong>
                      {getMsg('social')}
                    </Text>
                    <Social.Group data={social} />
                  </>
                )}
              </Panel>
            </StyledCollapse>
          </Hidden>
        </React.Fragment>
      )}
      renderProfile={() => (
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab={getMsg('overview')} key="1">
            <Box p={{ xs: 16, sm: 24 }} pt={{ xs: 0, sm: 0 }}>
              <Text style={{ textIndent: 24 }} lineHeight={2}>
                {get(data, ['description', locale]) ||
                  get(data, ['description', 'en']) ||
                  '-'}
              </Text>
              <Text.Title level={4} fontSize={18}>
                {getMsg('expertise')}
              </Text.Title>
              <Text>
                {data.categories &&
                  data.categories.map(id => (
                    <Tag key={id}>{CATEGORY_BY_ID[id].name}</Tag>
                  ))}
              </Text>
              <Text.Title level={4} fontSize={18} mb={16}>
                {getMsg('education')}
              </Text.Title>
              {data.education && <Timeline events={data.education} />}
            </Box>
          </TabPane>
          <TabPane tab={getMsg('courses')} key="2">
            {coursesLoading ? (
              <Box p={{ xs: 16, sm: 24 }} pt={{ xs: 0, sm: 0 }}>
                {data.courses && <ListSkeleton size={data.courses.length} />}
              </Box>
            ) : (
              <CourseItem.Group
                locale={locale}
                getMsg={getMsg}
                courses={courses}
                lecturerHidden
              />
            )}
          </TabPane>
          <TabPane tab={getMsg('publication')} key="3">
            <Box p={{ xs: 16, sm: 24 }}>
              {data.publication && <Timeline events={data.publication} />}
            </Box>
          </TabPane>
        </Tabs>
      )}
      renderContact={() => (
        <React.Fragment>
          <Text mb={12} strong>
            {getMsg('contact')}
          </Text>
          {renderContact()}
          <Text mt={20} mb={12} strong>
            {getMsg('social')}
          </Text>
          <Social.Group data={get(data, 'social', [])} />
        </React.Fragment>
      )}
    />
  );
};

LecturerDetail.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
LecturerDetail.defaultProps = {};

export default LecturerDetail;
