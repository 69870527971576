import React from 'react';
import Text from 'components/atoms/Text';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Row, Col, Skeleton } from 'antd';

const FieldsWrapper = styled.div`
  .field-row {
    margin-bottom: 16px;
    ${({ theme }) => ({
      [theme.breakpoints.only('xs')]: {
        marginBottom: 8,
      },
    })}
    .ant-skeleton-title {
      margin: 0;
    }
  }
`;

const CourseFields = ({ fields, loading }) => {
  // eslint-disable-next-line react/prop-types
  const renderValue = ({ value, textProps, url }) => {
    if (url)
      return (
        <Text
          component={'a'}
          href={url}
          target={'_blank'}
          rel={'noopener'}
          {...textProps}
        >
          {value || '-'}
        </Text>
      );
    return <Text {...textProps}>{value || '-'}</Text>;
  };
  return (
    <FieldsWrapper>
      {fields.map((field, index) => (
        <Row key={field.label} className="field-row">
          <Col xs={8} sm={6}>
            <Text fontWeight="bold">{field.label}</Text>
          </Col>
          <Col xs={16} sm={18}>
            {loading ? (
              <Skeleton
                active
                title={{ width: index % 2 === 0 ? 200 : 150 }}
                paragraph={false}
              />
            ) : (
              renderValue(field)
            )}
          </Col>
        </Row>
      ))}
    </FieldsWrapper>
  );
};

CourseFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  loading: PropTypes.bool,
};
CourseFields.defaultProps = {
  fields: [],
  loading: false,
};

export default CourseFields;
