import 'firebase/storage';
import './fireConfig';
import * as firebase from 'firebase/app';

export const storage = firebase.storage();

export default {
  uploadFile: (prefixRef, file, onProgress) => {
    const uploadTask = storage
      .ref(
        typeof prefixRef === 'string'
          ? `${prefixRef}/${file.name}`
          : prefixRef(file),
      )
      .put(file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) {
            onProgress({ percent: progress });
          }
        },
        error => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          reject(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            resolve(downloadURL);
          });
        },
      );
    });
  },
};
