import { replaceUndefined } from 'helpers/functions';
import { LEVEL_BY_ID } from 'constants/temporary';

const CourseFactory = () => {
  const schema = {
    coverImage: '',
    categories: [],
    code: '',
    credit: 0,
    description: { en: '', th: '' },
    evaluation: [],
    images: [],
    lecturers: [],
    level: '',
    methods: [],
    prerequisites: [],
    name: '',
    syllabusLink: '',
    type: '',
  };
  return {
    toApi: data => replaceUndefined(schema, data),
    getLevelQuery: levelId => {
      const level = LEVEL_BY_ID[levelId];
      return level
        ? [['code', '>=', level.min], ['code', '<=', level.max]]
        : [];
    },
  };
};

export const courseService = CourseFactory();

export default CourseFactory;
