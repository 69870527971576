import React from 'react';
import cx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { textAlign } from 'styled-system';
import { Layout, Avatar, Icon, Row, Col, Divider } from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Hidden from 'components/responsive/Hidden';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import useIntl from 'hooks/useIntl';
import MENU from 'constants/header3';

const MOCK_CONTACTS = [
  {
    name: 'facebook',
    icon: 'facebook',
    href: 'https://www.facebook.com/iechulalongkorn/',
  },
  {
    name: 'call',
    icon: 'phone',
    href: 'tel:+6622186814-6', // -6
  },
  {
    name: 'linkedin',
    icon: 'linkedin',
    href: 'https://www.linkedin.com/school/cu-industrial-engineering/',
  },
  {
    name: 'email',
    icon: 'mail',
    href: 'mailto:ie.info@eng.chula.ac.th',
  },
];

const StyledFooter = styled(Layout.Footer)`
  background: #333333;
  position: relative;
  z-index: 10;
  .contact-wrapper {
    .ant-avatar {
      background: rgba(255, 255, 255, 0.12);
    }
    a {
      :hover {
        span {
          background: ${({ theme }) => theme.primary.main};
        }
      }
      margin: 4px;
    }
  }
  ${({ theme }) => `
    min-height: 240px;
    padding: 24px 16px;
    ${[theme.breakpoints.up('sm')]} {
      padding: 32px 50px;
    }
    ${[theme.breakpoints.up('md')]} {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  `}
`;

const CategoryHeading = styled('p')`
  padding-left: 16px;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.64);
  font-weight: 200;
  ${({ theme }) => `
    ${[theme.breakpoints.up('sm')]} {
      padding-left: 0;
      font-size: 12px;
    }
  `}
  ${textAlign};
`;

const Link = styled('a')`
  padding-left: 16px;
  font-size: 13px;
  line-height: 36px;
  display: block;
  color: #ffffff;
  :hover {
    color: ${({ theme }) => theme.primary.light};
  }
  ${({ theme }) => `
    ${[theme.breakpoints.up('sm')]} {
      padding-left: 0;
      font-size: 14px;
    }
  `}
  ${textAlign};
`;

const Footer = ({ dark, contacts, ...props }) => {
  const { locale } = useIntl();
  const getProps = ({ external, href }) =>
    external
      ? {
          href,
          target: '_blank',
          rel: 'noopener',
        }
      : { as: RouterLink, to: href };
  const renderContactUs = () => (
    <>
      <CategoryHeading textAlign={{ xs: 'center', sm: 'left' }}>
        {MENU.contactUs.name[locale]}
      </CategoryHeading>
      {MENU.contactUs.subMenus.map(({ id, name, ...rest }) => (
        <Link
          key={id}
          textAlign={{ xs: 'center', sm: 'left' }}
          {...getProps(rest)}
        >
          {name[locale]}
        </Link>
      ))}
    </>
  );
  return (
    <StyledFooter className={cx(dark && 'iecu-dark')} {...props}>
      <Row gutter={16} type={'flex'}>
        <Col xs={24} md={12} lg={6}>
          <Box
            className={'contact-wrapper'}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {contacts.map(contact => (
              <a
                key={`contact-${contact.name}`}
                href={contact.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Avatar>
                  <Icon type={contact.icon} theme="filled" />
                </Avatar>
              </a>
            ))}
          </Box>
          <br />
          <Text.Paragraph
            className={'copy-right'}
            color={'#a5a5a5'}
            textAlign={{ xs: 'center', md: 'left' }}
            letterSpacing={1}
            textTransform={'uppercase'}
            fontSize={12}
            mb={{ sm: '0 !important' }}
          >
            © 2019 Faculty of Engineering
          </Text.Paragraph>
          <br />
          <Text.Paragraph
            color={'#a5a5a5'}
            textAlign={{ xs: 'center', md: 'left' }}
          >
            Chulalongkorn University, Industrial Engineering uses Simio{' '}
            <a
              href="https://www.simio.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              simulation software
            </a>{' '}
            under a grant from Simio LLC (
            <a
              href="https://www.simio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.simio.com
            </a>
            ).
          </Text.Paragraph>
          <br />
          <br />
          <Hidden mdDown>{renderContactUs()}</Hidden>
        </Col>
        <Hidden mdUp>
          <Col xs={24} md={12}>
            {renderContactUs()}
            <Hidden smUp>
              <Divider style={{ background: '#535353' }} />
            </Hidden>
          </Col>
        </Hidden>
        <Hidden except={'sm'}>
          <br />
          <br />
          <br />
          <Divider style={{ background: '#535353', margin: '32px 0' }} />
          <br />
          <br />
          <br />
        </Hidden>
        <Col xs={24} lg={18}>
          <Row type={'flex'} gutter={16}>
            {MENU.footerMenus.map(menu => (
              <Col xs={12} md={8} lg={6} key={menu.id}>
                <CategoryHeading>{menu.name[locale]}</CategoryHeading>
                {menu.subMenus.map(({ id, name, ...rest }) => (
                  <Link key={id} {...getProps(rest)}>
                    {name[locale]}
                  </Link>
                ))}
                <br />
                <br />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </StyledFooter>
  );
};

Footer.propTypes = {
  dark: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
};

Footer.defaultProps = {
  dark: false,
  contacts: MOCK_CONTACTS,
};

export default Footer;
