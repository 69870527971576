import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Timeline } from 'antd';
import Text from 'components/atoms/Text';
import PostList from 'components/molecules/PostList';
import { sortYearly, sortMonthly, parsedCreatedAt } from 'helpers/dateAndTime';

const StyledTimeline = styled(Timeline)`
  margin-left: 8px;

  .ant-timeline-item-head-blue {
    color: #1890ff;
    border-color: #1890ff;
  }

  .ant-timeline-item-content {
    margin-left: 24px;
  }
`;

const yearCss = css`
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
`;

const PostTimeline = ({ posts, locale }) => (
  <div>
    {sortYearly(posts, locale).map(({ name: year, items: yearItems }) => (
      <React.Fragment key={year}>
        <Text.Title
          className={yearCss}
          py={8}
          mb={8}
          level={4}
          fontWeight={'bold'}
          plainFont
        >
          {year}
        </Text.Title>
        <StyledTimeline>
          {sortMonthly(yearItems, locale).map(
            ({ name: month, items: monthItems }) => (
              <Timeline.Item key={month}>
                <Text.Title level={4} fontSize={17} mb={8}>
                  {month}
                </Text.Title>
                <PostList posts={parsedCreatedAt(monthItems, locale)} />
              </Timeline.Item>
            ),
          )}
        </StyledTimeline>
      </React.Fragment>
    ))}
  </div>
);

PostTimeline.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string,
};
PostTimeline.defaultProps = {
  posts: [],
  locale: 'en',
};

export default PostTimeline;
