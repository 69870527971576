/* eslint-disable max-len */
export default {
  // STATIC PAGES
  'thesis-process': {
    en: 'Thesis',
    th: 'วิทยานิพนธ์',
  },
  'preparing-proposal': {
    en: 'Before draft presentation',
    th: 'ก่อนสอบโครงร่างวิทยานิพนธ์',
  },
  'submitting-proposal': {
    en: 'After draft presentation',
    th: 'หลังสอบโครงร่างวิทยานิพนธ์',
  },
  'preparing-defense': {
    en: 'Before final presentation',
    th: 'ก่อนสอบวิทยานิพนธ์',
  },
  'submitting-thesis': {
    en: 'After final presentation',
    th: 'หลังสอบวิทยานิพนธ์',
  },
  beginning: {
    en: 'Beginning of the year',
    th: 'ภาคการศึกษาต้น',
  },
  end: {
    en: 'End of the year',
    th: 'ภาคการศึกษาปลาย',
  },
  'round-1': {
    en: 'Round 1',
    th: 'รอบที่ 1',
  },
  'round-2': {
    en: 'Round 2',
    th: 'รอบที่ 2',
  },
  'round-3': {
    en: 'Round 3',
    th: 'รอบที่ 3',
  },
  'round-4': {
    en: 'Round 4',
    th: 'รอบที่ 4',
  },
  'round-5': {
    en: 'Round 5',
    th: 'รอบที่ 5',
  },
  'admission-calendar': {
    en: 'Admission calendar',
    th: 'ปฏิทินการเข้าศึกษา',
  },
  'academic-calendar': {
    en: 'Academic Calendar',
    th: 'ปฏิทินการศึกษา',
  },
  internship: {
    en: 'Internship',
    th: 'การฝึกงาน',
  },
  'bachelor-project': {
    en: 'Bachelor project',
    th: 'โปรเจคนิสิตปี 4',
  },
  'pre-project': {
    en: 'Pre-project',
    th: 'พรีโปรเจค (ต้น)',
  },
  'senior-project': {
    en: 'Senior project',
    th: 'โปรเจคจบ (ปลาย)',
  },
  'co-operative-program': {
    en: 'Co-operative program',
    th: 'สหกิจศึกษา',
  },
  intern_info: {
    en: 'What you need to know',
    th: 'สิ่งที่จำเป็นต้องรู้',
  },
  intern_files: {
    en: 'Essential files & forms',
    th: 'เอกสารที่เกี่ยวข้อง',
  },
  '1st-semester': {
    en: '1st Semester',
    th: 'ภาคการศึกษาต้น',
  },
  '2nd-semester': {
    en: '2nd Semester',
    th: 'ภาคการศึกษาปลาย',
  },
  summer: {
    en: 'Summer',
    th: 'ภาคฤดูร้อน',
  },
  bachelor: {
    en: 'Bachelor degree',
    th: 'ปริญญาตรี',
  },
  master: {
    en: 'Master degree',
    th: 'ปริญญาโท',
  },
  'm-eng': {
    en: 'M.Eng (IE)',
    th: 'วศ.ม. (อุตสาหการ)',
  },
  sce: {
    en: 'SCE (Sasin Chula Engineering)',
    th: 'ศศินทร์ วิศวกรรมศาสตร์ จุฬาฯ',
  },
  program: {
    en: 'Program',
    th: 'หลักสูตร',
  },
  'how-to-apply': {
    en: 'How to apply',
    th: 'วิธีการสมัคร',
  },
  fees: {
    en: 'Fees',
    th: 'ค่าใช้จ่าย',
  },
  doctorate: {
    en: 'Doctorate degree',
    th: 'ปริญญาเอก',
  },
  // ABOUT US
  line1Route: {
    en: '',
    th:
      'ศาลาพระเกี้ยว รัฐศาสตร์ สาธิตปทุมวัน สัตวแพทย์ แยกเฉลิมเผ่า ลิโด้ เภสัชศาสตร์ สถาปัตยกรรมศาสตร์ อักษรศาสตร์ วิศวกรรมศาสตร์',
  },
  line2Route: {
    en: '',
    th:
      'ศาลาพระเกี้ยว เศรษฐศาสตร์ อาคารมหามกุฎ วิทยาศาสตร์ ครุศาสตร์ จามจุรี 9 \n' +
      'ศูนย์กีฬาและนันทนาการ ธรรมสถาน วิทยพัฒนา สหเวชศาสตร์ BTSสนามกีฬา จุฬาพัฒน์ 13 \n' +
      'หอพักนิสิต สำนักงานมหาวิทยาลัย สถาปัตยกรรมศาสตร์ อักษรศาสตร์ วิศวกรรมศาสตร์',
  },
  line3Route: {
    en: '',
    th:
      'ศาลาพระเกี้ยว รัฐศาสตร์ สาธิตปทุมวัน สัตวแพทย์ แยกเฉลิมเผ่า ลิโด้ เภสัชศาสตร์ \n' +
      'ครุศาสตร์ จามจุรี ๙ หอพักU-Center นิติศาสตร์ สถาปัตยกรรมศาสตร์ อักษรศาสตร์ \n' +
      'วิศวกรรมศาสตร์',
  },
  cuPopBusDescription: {
    en: '',
    th:
      'มหาวิทยาลัยเปิดให้บริการ “รถโดยสารปรับอากาศขนาดเล็ก” (Shuttle Bus) 4 สายสำหรับนิสิต คณาจารย์ และบุคลากรจุฬาฯ โดยไม่เสียค่าใช้จ่าย ให้บริการตั้งแต่วันจันทร์ – เสาร์ เวลา 7.00-19.00 น. (วันเสาร์ให้บริการเฉพาะสาย 1 และ 2)',
  },
  cuPopBusApp: {
    en: '',
    th: 'สามารถดาวน์โหลดแอพ CU Pop Bus เพื่อติดตามรถบัสได้แบบเรียลไทม์',
  },
  mrtStep1: {
    en: '',
    th: '1. ใช้บริการสถานีที่ใกล้ที่สุด',
  },
  mrtStep2: {
    en: '',
    th: '2. ลงที่สถานีสามย่าน (Samyan Station) ใช้ทางออกหมายเลข 2 ',
  },
  mrtStep3: {
    en: '',
    th:
      '3. ลงสถานี จะเจออาคารจัตุรัสจามจุรี เข้ามหาวิทยาลัยโดยใช้ทางเชื่อมผ่านคณะบัญชี',
  },
  btsStep1: {
    en: '',
    th: '1. ใช้บริการสถานีที่ใกล้ที่สุด',
  },
  btsStep2: {
    en: '',
    th: '2. ลงสถานีสยาม (Siam Station) ใช้ทางออกหมายเลข 2',
  },
  btsStep3: {
    en: '',
    th:
      '3. รอรถโดยสารภายในจุฬาลงกรณ์มหาวิทยาลัยหน้าโรงหนัง Lido เพื่อเดินทางเข้ามาในมหาวิทยาลัยได้',
  },
  busLine: {
    en: 'Line',
    th: 'สาย',
  },
  arriveDonMaung: {
    en: 'Arrive at Don Muang Airport',
    th: '',
  },
  publicTransport: {
    en: 'Option A : Public transport (Bus)',
    th: '',
  },
  line29Step1: {
    en: '',
    th:
      '1. จาก Airport ออกทางอาคารผู้โดยสาร 1 [ชั้น 1 ประตู 6] หรือ อาคารผู้โดยสาร 2 [ชั้น 1 ประตู 12] ',
  },
  line29Step2: {
    en: '',
    th:
      '2. รอรถเมล์สาย 29 วิ่งเส้นวิภาวดี มุ่งหน้าอนุสาวรีย์ชัยฯ สยาม ไปสุดที่หัวลำโพง',
  },
  line29Step3: {
    en: '',
    th: '3. ลงป้ายหัวลำโพงแล้วต่อ MRT สถานีหัวลำโพงไปสถานีสามย่าน',
  },
  lineA2Step1: {
    en: '',
    th: '1. จาก Airport ออกประตู 6 ด้านขวามือ (ที่เดียวกับสาย A1)',
  },
  lineA2Step2: {
    en: '',
    th: '2. รอรถเมล์สาย A2 ตามรูป เส้นทางวิ่งขึ้นทางด่วน ไปลงอนุสาวรีย์ชัยฯ',
  },
  lineA2Step3: {
    en: '',
    th: '3. ลงป้าย BTS อนุสาวรีย์ชัยฯ',
  },
  lineA1Step1: {
    en: '',
    th: '1. จาก Airport ออกประตู 6 ด้านขวามือ',
  },
  lineA1Step2: {
    en: '',
    th:
      '2. รอรถเมล์สาย A1 ตามรูป เส้นทางวิ่งขึ้นทางด่วน ไปลงหน้าเซนทรัลลาดพร้าว สุดสายที่\n' +
      'สถานีขนส่งหมอชิต ราคาโดยสาร 30 บาท',
  },
  lineA1Step3: {
    en: '',
    th: '3. ลงป้าย BTS หมอชิต หรือ MRT จัตุจักร (สถานีอยู่ติดกัน)',
  },
  taxi: {
    en: 'Option B : Taxi',
    th: '',
  },
  taxiDirection: {
    en: '',
    th:
      'เรียก Taxi ไปยังปลายทาง (อาคารวิศวกรรม 4 คณะวิศวกรรมศาสตร์ ตุฬาลงกรณ์มหาวิทยาลัย)',
  },
  airportLinkStep1: {
    en: '',
    th:
      '1. จาก Airport ลงมาที่ชั้นใต้ดิน จะเจอสถานีต้นทางของ Airport Link (สถานี Suvarnabhumi) ',
  },
  airportLinkStep2: {
    en: '',
    th: '2. ซื้อตั๋วไปลงสถานีพญาไท (Phaya Thai) ราคา 45 บาท ',
  },
  airportLinkStep3: {
    en: '',
    th: '3. ออกทางออกที่เชื่อมไป BTS ',
  },
  airportLinkUrl: {
    en: '',
    th: 'ตรวจสอบเวลาเดินทาง',
  },
  airportLinkHour: {
    en: '*Operating hours from 5:00AM - 12.00PM',
    th: '*เวลาให้บริการเริ่มตั้งแต่ 05.30AM - 00.00PM',
  },
  airportLink: {
    en: 'Option A : Airport Link',
    th: 'ทางเลือก A : แอร์พอร์ตลิ้งค์',
  },
  arriveSuvarnabhumiAirport: {
    en: 'Arrive at Suvarnabhumi Airport',
    th: 'ลงสนามบินสุวรรณภูมิ',
  },
  address: {
    en: 'Address',
    th: 'ที่อยู่',
  },
  ieAddress: {
    en:
      '4th floor, Engineering Building 4, Department of Industrial Engineering, Faculty of Engineering, Chulalongkorn University, Phayathai Road, Patumwan, Bangkok 10330',
    th:
      'ชั้น 4 อาคารเจริญวิศวกรรม (ตึก 4) ภาควิชาวิศวกรรมอุตสาหการ คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย เลขที่ 254 ถนนพญาไท เขตปทุมวัน กรุงเทพฯ 10330',
  },
  tel: {
    en: 'Tel',
    th: 'เบอร์ติดต่อ',
  },
  fax: {
    en: 'Fax',
    th: 'โทรสาร',
  },
  location: {
    en: 'Location',
    th: 'สถานที่',
  },
  transportation: {
    en: 'Transportation',
    th: 'การเดินทาง',
  },
  suvarnabhumi: {
    en: 'Suvarnabhumi Airport',
    th: 'สนามบินสุวรรณภูมิ',
  },
  donMuang: {
    en: 'Don Maung Airport',
    th: 'สนามบินดอนเมือง',
  },
  bts: {
    en: 'BTS',
    th: 'บี.ที.เอส',
  },
  mrt: {
    en: 'MRT',
    th: 'เอ็ม.อาร์.ที',
  },
  cuPopBus: {
    en: 'CU Pop Bus',
    th: 'รถบัสจุฬาฯ',
  },
  // COMMON
  headOfDepartment: {
    en: 'Head of Department',
    th: 'หัวหน้าภาควิชา',
  },
  deputyChief: {
    en: 'Deputy Chief of Department',
    th: 'รองหัวหน้าภาควิชา',
  },
  committee: {
    en: 'Executive Committees',
    th: 'กรรมการบริหารภาควิชา',
  },
  'our-history': {
    en: 'Our history',
    th: 'ประวัติความเป็นมา',
  },
  vision: {
    en: 'Vision',
    th: 'วิศัยทัศน์',
  },
  aboutIE: {
    en: 'About IE Chula',
    th: 'เกี่ยวกับ IE Chula',
  },
  welcomeToIE: {
    en: 'Welcome to IE Chula!',
    th: 'ยินดีต้อนรับสู่ภาควิชาวิศวกรรมอุตสาหการ จุฬาฯ',
  },
  search: {
    en: 'Search...',
    th: 'ค้นหา...',
  },
  labs: {
    en: 'Research Center & Labs',
    th: 'ศูนย์วิจัยและห้องปฏิบัติการ',
  },
  email: {
    en: 'Email',
    th: 'อีเมล์',
  },
  job: {
    en: 'Job',
    th: 'สมัครงาน',
  },
  event: {
    en: 'Event',
    th: 'กิจกรรม',
  },
  scholarship: {
    en: 'Scholarships',
    th: 'ทุนการศึกษา',
  },
  scholarshipLink: {
    en: 'Other sources',
    th: 'แหล่งข้อมูลทุนอื่นๆ',
  },
  scholarshipDetail: {
    en: 'Scholarship Detail',
    th: 'รายละเอียดทุน',
  },
  sponsorLink: {
    en: 'Link to provider',
    th: 'ลิ้งค์ไปที่หน่วยงาน',
  },
  readDetail: {
    en: 'Read detail',
    th: 'อ่านรายละเอียด',
  },
  researchArea: {
    en: 'Research area',
    th: 'งานวิจัย',
  },
  advisor: {
    en: 'Advisor',
    th: 'อาจารย์ที่ปรึกษา',
  },
  relatedFiles: {
    en: 'Related Files',
    th: 'ไฟล์ที่เกี่ยวข้อง',
  },
  relatedImages: {
    en: 'Related images',
    th: 'รูปภาพที่เกี่ยวข้อง',
  },
  amountCredits: {
    en: 'credits',
    th: 'หน่วยกิต',
  },
  amountCourses: {
    en: 'courses',
    th: 'วิชา',
  },
  article: {
    en: 'article',
    th: 'บทความ',
  },
  book: {
    en: 'Book',
    th: 'หนังสือ',
  },
  academic: {
    en: 'Academic',
    th: 'ข่าววิชาการ',
  },
  announcement: {
    en: 'Announcement',
    th: 'ประกาศสำคัญ',
  },
  seeAll: {
    en: 'SEE ALL',
    th: 'ดูทั้งหมด',
  },
  clickToZoom: {
    en: 'click to zoom image',
    th: 'คลิกเพื่อดูรูปขนาดเต็ม',
  },
  researchers: {
    en: 'Our Researchers',
    th: 'นักวิจัย',
  },
  ieStaff: {
    en: 'Our Staffs',
    th: 'เจ้าหน้าที่ทั้งหมด',
  },
  adminStaff: {
    en: 'Administrative staffs',
    th: 'เจ้าหน้าที่ธุรการ',
  },
  labStaff: {
    en: 'Laboratory staffs',
    th: 'เจ้าหน้าที่ปฏิบัติการ',
  },
  forms: {
    en: 'Forms',
    th: 'แบบฟอร์ม',
  },
  all: {
    en: 'All',
    th: 'ทั้งหมด',
  },
  morning: {
    en: 'Morning',
    th: 'เช้า',
  },
  afternoon: {
    en: 'Afternoon',
    th: 'บ่าย',
  },
  otherPosts: {
    en: 'Other Posts',
    th: 'โพสอื่นๆ',
  },
  programs: {
    en: 'DEGREE PROGRAMS',
    th: 'หลักสูตรที่เปิดรับ',
  },
  programDescription: {
    en:
      'IECU campus is a place for discovery, alive with debate, both intellectually and physically.',
    th: '',
  },
  bachelorDegree: {
    en: 'Bachelor of Engineering',
    th: 'หลักสูตรวิศวกรรมศาสตรบัณฑิต',
  },
  bachelorDegreeShort: {
    en: 'B.Eng.(IE)',
    th: 'วศ.บ. (อุตสาหการ)',
  },
  generalProgram: {
    en: 'General student program',
    th: 'หลักสูตรปรกติ',
  },
  coopProgram: {
    en: 'Co-operative Education Program',
    th: 'หลักสูตรสหกิจศึกษา',
  },
  masterDegree: {
    en: 'Master of Engineering',
    th: 'หลักสูตรวิศวกรรมศาสตรมหาบัณฑิต',
  },
  masterDegreeShort: {
    en: 'M.Eng.(IE)',
    th: 'วศ.ม. (อุตสาหการ)',
  },
  regularProgram: {
    en: 'Regular Program',
    th: 'หลักสูตรภาคปรกติ',
  },
  executiveProgram: {
    en: 'Executive Program',
    th: 'หลักสูตรภาคนอกเวลาราชการ',
  },
  doctorDegree: {
    en: 'Doctor of Philosophy',
    th: 'หลักสูตรวิศวกรรมศาสตรดุษฏีบัณฑิต',
  },
  doctorDegreeShort: {
    en: 'Ph.D.(IE)',
    th: 'วศ.ด. (อุตสาหการ) ',
  },
  shortcuts: {
    en: 'Shortcuts',
    th: 'ทางลัด',
  },
  news: {
    en: 'News',
    th: 'ข่าวสาร',
  },
  chulalongkorn: {
    en: 'Chulalongkorn',
    th: 'จุฬาลงกรณ์',
  },
  university: {
    en: 'University',
    th: 'มหาวิทยาลัย',
  },
  industrial: {
    en: 'Industrial',
    th: 'อุตสาหการ',
  },
  engineering: {
    en: 'Engineering',
    th: 'วิศวกรรม',
  },
  home: {
    en: 'Home',
    th: 'หน้าหลัก',
  },
  searchBy: {
    en: 'Search by',
    th: 'ค้นหาโดย',
  },
  filter: {
    en: 'Filter',
    th: 'ตัวกรอง',
  },
  clearSearch: {
    en: 'Clear search',
    th: 'ล้างเสิร์ช',
  },
  clearFilter: {
    en: 'Clear filter',
    th: 'ล้างตัวกรอง',
  },
  download: {
    en: 'Download',
    th: 'ดาวน์โหลด',
  },
  social: {
    en: 'Social',
    th: 'โซเชียล',
  },
  contact: {
    en: 'Contact',
    th: 'ติดต่อ',
  },
  // COURSE
  searchCoursePlaceholder: {
    en: 'Type code or name...',
    th: 'พิมพ์รหัส หรือ ชื่อรายวิชา...',
  },
  allCourses: {
    en: 'All Courses',
    th: 'รายวิชาทั้งหมด',
  },
  noMoreCourse: {
    en: 'No more courses',
    th: 'ไม่พบรายวิชาอื่นๆ',
  },
  courseType: {
    en: 'Type',
    th: 'ประเภทรายวิชา',
  },
  courseLevel: {
    en: 'Level',
    th: 'ระดับ',
  },
  courseCategory: {
    en: 'Category',
    th: 'ความเชี่ยวชาญ',
  },
  courseTitle: {
    en: 'Title',
    th: 'ชื่อรายวิชา',
  },
  courseNumber: {
    en: 'Course No.',
    th: 'รหัสวิชา',
  },
  coursePrerequisites: {
    en: 'Prerequisites',
    th: 'รายวิชาที่มาก่อน',
  },
  courseDescription: {
    en: 'Course description',
    th: 'เกี่ยวกับรายวิชา',
  },
  courseMethod: {
    en: 'method',
    th: 'วิธีจัดการเรียนการสอน',
  },
  courseHours: {
    en: 'Hours',
    th: 'ชั่วโมง',
  },
  courseCredits: {
    en: 'Credits',
    th: 'เครดิต',
  },
  courseAtmosphere: {
    en: 'Atmosphere',
    th: 'บรรยากาศห้องเรียน',
  },
  courseSyllabus: {
    en: 'Full Syllabus',
    th: 'ประมวลรายวิชา',
  },
  // LECTURER
  lecturer: {
    en: 'Lecturer',
    th: 'อาจารย์ผู้สอน',
  },
  lecturers: {
    en: 'Lecturers',
    th: 'อาจารย์ผู้สอน',
  },
  viewProfile: {
    en: 'View profile',
    th: 'ดูโปรไฟล์',
  },
  searchLecturerPlaceholder: {
    en: 'Type name...',
    th: 'พิมพ์ชื่ออาจารย์',
  },
  expertise: {
    en: 'Expertise',
    th: 'ความเชี่ยวชาญ',
  },
  education: {
    en: 'Education',
    th: 'การศึกษา',
  },
  overview: {
    en: 'Overview',
    th: 'ข้อมูลทั่วไป',
  },
  courses: {
    en: 'Courses',
    th: 'รายวิชาที่สอน',
  },
  publication: {
    en: 'Publication',
    th: 'ผลงานตีพิมพ์',
  },
  // SCHEDULE
  schedule: {
    en: 'Schedule',
    th: 'ตารางสอน',
  },
  firstSemester: {
    en: '1st Semester 2024',
    th: 'ภาคต้น 2567',
  },
  secondSemester: {
    en: '2nd Semester 2023',
    th: 'ภาคปลาย 2566',
  },
  secRoom: {
    en: 'sec-room',
    th: 'ตอนเรียน-ห้องเรียน',
  },
  viewScheduleBy: {
    en: 'View schedule by',
    th: 'ดูตารางสอนเฉพาะ',
  },
  fullCourseDetail: {
    en: 'More course detail',
    th: 'ดูข้อมูลรายวิชานี้',
  },
  otherSection: {
    en: 'Other section',
    th: 'ตอนเรียนอื่นๆ',
  },
  '80anniversary': {
    en: '80 years IE Chula 1942-2022',
    th: '80 ปี IE Chula 2485-2565',
  },
  '80anniversaryDescription': {
    en: '',
    th:
      'สารคดีชุดพิเศษ ในวาระครบรอบ 80 ปี IE Chula 2485-2566 เป็นกิจกรรมที่ภาควิชาวิศวกรรมอุตสาหการ จุฬาลงกรณ์มหาวิทยาลัย จัดทำขึ้นในวาระที่ภาควิชาฯ มีอายุครบ 80 ปี ในปี พ.ศ.2565 เนื่องจากก่อตั้งขึ้นตั้งแต่ปี พ.ศ.2485',
  },
};
