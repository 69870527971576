import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from 'emotion';
import CustomCard from 'components/molecules/CustomCard';
import ROUTES from 'constants/routes';
import { lecturerService } from 'factories/User/Lecturer';

const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
`;

const cardCss = css`
  .ant-card-cover {
    > .Box {
      border-top: none;
    }
  }
`;

const RoleCard = ({ className, data, loading, locale, CardProps }) => (
  <StyledLink to={ROUTES.lecturerDetail.get(data.id, data.name)}>
    <CustomCard
      className={`${cardCss} ${className}`}
      {...CardProps}
      image={data.avatarUrl}
      loading={loading}
      actions={null}
    >
      <CustomCard.Meta title={lecturerService.getName(data, locale)} />
    </CustomCard>
  </StyledLink>
);

RoleCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  CardProps: PropTypes.shape({}),
  locale: PropTypes.string,
};
RoleCard.defaultProps = {
  className: '',
  data: {},
  loading: false,
  CardProps: {},
  locale: 'en',
};

export default RoleCard;
