import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import partition from 'lodash/partition';
import groupBy from 'lodash/groupBy';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import useIntl from 'hooks/useIntl';
import useScrollToElement from 'hooks/useScrollToElement';
import { useInitFetch } from 'contexts/staff';
import { Row, Col } from 'antd';
import Text from 'components/atoms/Text';
import StaffCard from 'components/molecules/StaffCard';
import Container from 'components/layouts/Container';
import Query from 'factories/query';

const loadingArray = Array(6)
  .fill(0)
  .map((i, index) => ({ id: i + index }));

const StaffPage = ({ location }) => {
  const query = Query().toObject(location.search);
  useScrollToElement(query.dept);
  const { staffs, status } = useInitFetch();
  const { getMsg, locale } = useIntl();
  const staffByDept = groupBy(staffs, 'department');
  const renderCard = item => {
    const title = get(item, ['name', locale]);
    return (
      <Col key={item.id} xs={24} md={12} lg={8} style={{ display: 'flex' }}>
        <StaffCard
          avatarUrl={item.avatarUrl}
          loading={status.isRequest}
          title={title}
          job={item.job}
          contact={item.contact}
        />
      </Col>
    );
  };
  const orderHeadFirst = (array = []) => {
    const result = partition(array, o => (o.job || '').includes('หัวหน้า'));
    return [...(result[0] || []), ...(result[1] || [])];
  };
  return (
    <Container style={{ flex: 'auto' }}>
      <Element name={'admin'}>
        <Text.Title level={2} letterSpacing={0.5}>
          {getMsg('adminStaff')}
        </Text.Title>
      </Element>
      <Row gutter={16} type={'flex'}>
        {(status.isRequest
          ? loadingArray
          : orderHeadFirst(staffByDept.admin) || []
        ).map(renderCard)}
      </Row>
      <br />
      <br />
      <Element name={'lab'}>
        <Text.Title level={2} letterSpacing={0.5}>
          {getMsg('labStaff')}
        </Text.Title>
      </Element>
      <Row gutter={16} type={'flex'}>
        {(status.isRequest ? loadingArray : staffByDept.lab || []).map(
          renderCard,
        )}
      </Row>
    </Container>
  );
};

StaffPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
StaffPage.defaultProps = {};

export default withRouter(StaffPage);
