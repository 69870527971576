import { useState, useReducer } from 'react';
import matchesProperty from 'lodash/matchesProperty';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import toArray from 'lodash/toArray';
import { mergeItem, deleteItem } from 'helpers/functions';

const reducer = (state = {}, action) => {
  const { sortedString, response } = action;
  switch (action.type) {
    case 'SET_QUERY':
      return {
        ...state,
        [sortedString]: response,
      };
    case 'CONCAT_QUERY':
      return {
        ...state,
        [sortedString]: uniqBy(
          [...(state[sortedString] || []), ...response],
          'id',
        ),
      };
    default:
      return state;
  }
};

const useCollection = (initialState = [], key = 'id') => {
  const [state, setState] = useState(initialState);
  const [query, dispatch] = useReducer(reducer, {});
  const concatCollection = addedCollection =>
    setState(uniqBy([...state, ...addedCollection], 'id'));
  const mergeOneItem = item => setState(mergeItem(state, item, key));
  const deleteOneItem = item => setState(deleteItem(state, item[key]));
  const getOneItem = id =>
    find(state, matchesProperty(key, id)) ||
    find(flatten(toArray(query)), matchesProperty(key, id));
  const getCollection = ids =>
    ids ? ids.map(getOneItem).filter(val => !!val) : state;

  const getResourceQuery = sortedString => query[sortedString] || [];
  return {
    collection: state,
    setCollection: setState,
    concatCollection,
    mergeOneItem,
    deleteOneItem,
    getOneItem,
    getCollection,

    resourceQuery: query,
    setResourceQuery: sortedString => collection =>
      dispatch({ type: 'SET_QUERY', response: collection, sortedString }),
    concatResourceQuery: sortedString => collection =>
      dispatch({ type: 'CONCAT_QUERY', response: collection, sortedString }),
    getResourceQuery,
  };
};

export default useCollection;
