import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Card } from 'antd';
import Box from '../atoms/Box';
import Icon from '../atoms/Icon';

const StyledCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    max-width: 100%;
    flex-grow: 1;
    flex-direction: column;
    margin: 8px 0;
    max-width: 400px;
    
    .CardAvatar {
      position: absolute;
      
      &.-img {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;  
        width: 100%;
        height: 100%;
      }
      &.-icon {
        font-size: 60px;
        color: ${theme.text.divider};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ant-card-head {
      padding: 0 20px;
      color: ${theme.text.secondary};
    }
    .ant-card-body {
      flex-grow: 1;
      padding: 24px 20px;
    }
    .ant-card-meta-title {
      white-space: unset;
      font-size: 18px;
      font-weight: bold;
    }
    .ant-card-actions {
      .Box-action {
        &.-disabled {
          pointer-events: none;
          color: ${theme.text.disabled};
        }
        padding: 0 16px;
        word-break: break-all;
        color: ${theme.text.secondary};
        :hover {
          color: ${theme.primary.main};
          .ant-typography {
            color: ${theme.primary.main};
          }
        }
        
        .Icon-root {
          font-size: 20px;
          display: block;
        }
        
        .ant-typography {
          margin-top: 4px;
          font-size: 12px;
        }
      }
    }
  `}
`;

const { Meta } = Card;

const CustomCard = ({ image, loading, iconPlaceholder, ...props }) => (
  <StyledCard
    cover={
      <Box
        bgColor={'grey.200'}
        position={'relative'}
        pb={'56.26%'}
        borderTop={'1px solid #f7f7f7'}
        borderBottom={'1px solid #f7f7f7'}
      >
        {image ? (
          <img className={'CardAvatar -img'} alt="example" src={image} />
        ) : (
          <Icon className={'CardAvatar -icon'} type={iconPlaceholder} />
        )}
      </Box>
    }
    loading={loading}
    {...props}
  />
);

CustomCard.propTypes = {
  image: PropTypes.string,
  loading: PropTypes.bool,
  iconPlaceholder: PropTypes.string,
};
CustomCard.defaultProps = {
  image: '',
  loading: false,
  iconPlaceholder: 'user',
};
CustomCard.Meta = Meta;

export default CustomCard;
