import createCollectionContext from 'hooks/createCollectionContext';
import FireApi from 'firebase/fireApi';
import toHOC from './toHOC';

export const {
  Provider,
  Consumer,
  useConsumer,
  useInitFetch,
  Context,
} = createCollectionContext({
  collectionName: 'rooms',
  itemName: 'room',
  fetchingApi: FireApi.fetchRooms,
});

export const RoomProvider = Provider;
export const RoomConsumer = Consumer;
export const withConsumer = toHOC(Consumer);
export default Context;
