import React from 'react';
import Hidden from 'components/responsive/Hidden';
import { withIntl } from 'contexts/intl';
import NewHeader from './NewHeader';
import MiniHeader from './MiniHeader';

const Header = props => (
  <React.Fragment>
    <Hidden mdDown>
      <NewHeader {...props} />
    </Hidden>
    <Hidden mdUp>
      <MiniHeader {...props} />
    </Hidden>
  </React.Fragment>
);

export default withIntl(Header);
