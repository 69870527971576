import { useEffect } from 'react';
import useApi from 'hooks/useApi';
import { useConsumer } from 'contexts/post';
import FireApi from 'firebase/fireApi';

export default (tagId, limit = 3) => {
  const sortedString = `?content=${tagId}`;
  const fetchPosts = () =>
    FireApi.fetchPosts({
      tagId,
      limit,
    });
  const { setPostQuery, postQuery } = useConsumer();
  const data = (postQuery[sortedString] || []).slice(0, limit);
  const { api, status, reset } = useApi(fetchPosts);
  useEffect(() => {
    let ignore;
    if (!data || data.length < limit) {
      api().then(response => {
        if (!ignore) {
          setPostQuery(sortedString)(response);
        }
      });
    }
    return () => {
      ignore = true;
    };
  }, []);
  return {
    status,
    reset,
    data,
  };
};
