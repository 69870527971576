import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import cx from 'clsx';
import styled from '@emotion/styled';
import { Skeleton } from 'antd';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import useIntl from 'hooks/useIntl';

const createGradient = ({ theme, hasGradient }) =>
  hasGradient
    ? `linear-gradient(to bottom,rgba(${
        theme.primary.rgb
      }, 0.8),rgba(0, 0, 0, 0.8))`
    : '';

const createBgUrl = ({ coverImage }) =>
  coverImage ? `url('${coverImage}')` : '';

const compose = (...fns) => props =>
  fns
    .map(fn => fn(props))
    .filter(val => !!val)
    .join(',');

const Div = styled('div')`
  ${({ theme }) => `
    background: ${theme.grey[200]};
  `};
  background-image: ${compose(
    createGradient,
    createBgUrl,
  )};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;

  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.38);
  }

  .SmallPost-tagList {
    margin: -12px;
  }
  .SmallPost-tagWrap {
    padding: 4px;
  }
  .SmallPost-tag {
    border-radius: 4px;
    background-color: ${({ dark, theme }) =>
      dark ? theme.white.text : theme.primary.main};
  }
  .created-date {
    position: absolute;
    -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.text.primary};
    .ant-typography {
      color: ${({ theme }) => theme.white.text};
      line-height: 1;
    }
  }
`;

const SmallPost = ({
  className,
  tags,
  createdAt,
  title,
  dark,
  loading,
  ...props
}) => {
  const { formatDate } = useIntl();
  return (
    <Div
      dark={dark}
      {...omit(props, ['content'])}
      className={cx('SmallPost-root', className)}
    >
      {loading ? (
        <Skeleton loading={loading} active paragraph={{ rows: 5 }} />
      ) : (
        <>
          <Box className="SmallPost-tagList">
            {tags.map(tag => (
              <Box
                key={tag}
                className={'SmallPost-tagWrap'}
                borderRadius={4}
                display={'inline-block'}
              >
                <Text.Title
                  level={4}
                  className={'SmallPost-tag'}
                  m={0}
                  px={8}
                  py={4}
                  letterSpacing={'1px'}
                  color={dark ? 'primary.main' : 'white.text'}
                  fontSize={{ xs: 10, sm: 12, md: 14 }}
                  fontWeight={600}
                  textTransform="uppercase"
                >
                  {tag}
                </Text.Title>
              </Box>
            ))}
          </Box>
          <Box>
            <Text.Title
              level={4}
              mt={16}
              mb={32}
              fontWeight={600}
              letterSpacing={'0.5px'}
              color={dark ? 'white.text' : 'text.primary'}
            >
              {title}
            </Text.Title>
          </Box>
          <Box pl={24} pr={8} py={4} className="created-date">
            <Text textTransform="uppercase">{formatDate(createdAt)}</Text>
          </Box>
        </>
      )}
    </Div>
  );
};

SmallPost.propTypes = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  dark: PropTypes.bool,
  coverImage: PropTypes.string,
  hasGradient: PropTypes.bool,
  className: PropTypes.string,
};
SmallPost.defaultProps = {
  tags: [],
  loading: false,
  dark: false,
  hasGradient: false,
  coverImage: '',
  className: '',
};

export default SmallPost;
