import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import ROUTES from 'constants/routes';
import useIntl from 'hooks/useIntl';

const linkCss = css`
  flex: auto;
  display: flex;
  align-items: center;
`;

const listCss = css`
  &.ant-list-bordered .ant-list-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ant-list-item:hover {
    background: #f7f7f7;
  }
`;

const PostList = ({ posts }) => {
  const { displayText } = useIntl();
  return (
    <List
      className={listCss}
      bordered
      dataSource={posts}
      renderItem={item => (
        <List.Item>
          <Link
            className={linkCss}
            to={ROUTES.postDetail.get(item.id, displayText(item.title))}
          >
            <List.Item.Meta
              title={
                <Text.Body fontWeight={'bold'} mb={4} color={'primary.main'}>
                  {item.title}
                </Text.Body>
              }
              description={
                <Text.Paragraph mb={0}>{item.createdAt}</Text.Paragraph>
              }
            />
            <Icon type={'far fa-chevron-right'} />
          </Link>
        </List.Item>
      )}
    />
  );
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})),
};
PostList.defaultProps = {
  posts: [],
};

export default PostList;
