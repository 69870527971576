/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const HfeTH = () => (
  <div>
    {/*<Box mx={{ xs: -16, sm: -32 }} mb={24}>*/}
    {/*  <img*/}
    {/*    style={{ display: 'block', maxWidth: '100%' }}*/}
    {/*    alt={'cover'}*/}
    {/*    src={*/}
    {/*      // eslint-disable-next-line max-len*/}
    {/*      'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FDRMIS_onBlack.jpg?alt=media&token=c52de49d-c085-4a5a-a57b-d8cb8412453e'*/}
    {/*    }*/}
    {/*  />*/}
    {/*</Box>*/}
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      Human Factors and Ergonomics Laboratory (HFE)
    </Text.Heading>
    <Text.Topic>งานวิจัยที่เกี่ยวข้อง</Text.Topic>
    <Text.Body>
      <ul>
        <li>Human Factors and Ergonomics</li>
        <li>Work Physiology</li>
        <li>Biomechanics</li>
      </ul>
    </Text.Body>

    <Text.Topic>บุคลากร</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/DDOu5LsG6d6Mq9lKkmHF/Phairoat%20Ladavichitkul',
            )}
          >
            ผศ. ดร.ไพโรจน์ ลดาวิจิตรกุล
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/fvxjN0ykqSGH71YaMPjh/Haruetai%20Lohasiriwat',
            )}
          >
            ผศ. หฤทัย โลหะศิริวัฒน์
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>สถานที่</Text.Topic>
    <Text.Body>ห้อง 815 ชั้น 8 อาคารเจริญวิศวกรรม (ตึก 4)</Text.Body>
  </div>
);

HfeTH.propTypes = {};
HfeTH.defaultProps = {};

export default HfeTH;
