/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'clsx';
import styled from '@emotion/styled';
import Icon from '../atoms/Icon';
import Text from '../atoms/Text';

const Item = styled('button')`
  ${({ theme }) => `
    padding: 0 16px;
    word-break: break-all;
    background: none;
    border: none;
    cursor: pointer;
    
    color: ${theme.text.secondary};
    :hover {
      color: ${theme.primary.main};
      .ant-typography {
        color: ${theme.primary.main};
      }
    }
    
    :focus {
      outline: 0;
    }
    
    &.-disabled {
      pointer-events: none;
      color: ${theme.text.disabled};
    }
  
    .Icon-root {
      font-size: 20px;
      display: block;
    }
  
    .ant-typography {
      margin-top: 4px;
      font-size: 12px;
    }
  `}
`;

const Single = ({
  href,
  label,
  disabled,
  external,
  icon,
  renderLabel,
  ...rest
}) => (
  <Item
    key={label}
    className={cx('Actions-item', disabled && '-disabled')}
    {...href && {
      as: 'a',
      href,
    }}
    {...external && {
      target: '_blank',
      rel: 'noopener',
    }}
    {...rest}
  >
    <Icon type={icon} />
    <Text>{label || '-'}</Text>
  </Item>
);

const Actions = ({ actions }) => actions.map(Single);

Actions.propTypes = {};
Actions.defaultProps = {};
Actions.Item = Single;

export default Actions;
