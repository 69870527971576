import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash/last';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { animateScroll } from 'react-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import FireApi from 'firebase/fireApi';
import { searchCourses } from 'algolia/course';
import Query from 'factories/query';
import withLecturersInCourses from 'hocs/withLecturersInCourses';
import { withConsumer } from 'contexts/course';
import { withIntl } from 'contexts/intl';
import { useConsumer as useDBConsumer, mapCount } from 'contexts/db';
import compose from 'helpers/compose';

// COMPONENTS
import { Col, Row, Empty, Button, Affix } from 'antd';
import Box from 'components/atoms/Box';
import AlgoliaTag from 'components/atoms/AlgoliaTag';
import CourseItem from 'components/molecules/CourseItem';
import ListSkeleton from 'components/molecules/ListSkeleton';
import Container from 'components/layouts/Container';
import {
  TypeFilter,
  LevelFilter,
  CategoryFilter,
} from 'components/molecules/Filter';
import SearchInput from 'components/atoms/SearchInput';
import Hidden from 'components/responsive/Hidden';
import Text from 'components/atoms/Text';
import Drawer from 'components/molecules/Drawer';
import LecturerFilter from 'containers/LecturerFilter';

// CONSTANTS
import { CATEGORIES, LEVELS, COURSE_TYPES } from 'constants/temporary';

const PAGE_SIZE = 10;

const CoursesPage = ({
  // router
  location: { search, pathname },
  history,
  // intl
  locale,
  getMsg,
  mapLocale,
  // others
  courses,
  setCourses,
  concatCourses,
  getCourseQuery,
  setCourseQuery,
  concatCourseQuery,
}) => {
  const { keyword } = Query(search).get();
  const [tempKeyword, setTempKeyword] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const query = Query().toObject(search);
  const sortedSearch = Query().sortString(search);
  const db = useDBConsumer();

  // courses that will be displayed
  const displayedCourses =
    search || keyword ? getCourseQuery(sortedSearch) : courses || [];

  const filter = {
    level: query.level,
    type: query.type,
    categoryId: query.category,
    lecturerId: query.lecturer,
    limit: PAGE_SIZE,
  };
  const handleInfiniteOnLoad = () => {
    setScrollLoading(true);
    let promise = () =>
      FireApi.fetchCourses({
        ...filter,
        lastId: get(last(displayedCourses), 'id'),
      }).then(response => {
        if (search) {
          concatCourseQuery(sortedSearch)(response);
        } else {
          concatCourses(response);
        }
        return response;
      });

    if (keyword) {
      promise = () =>
        searchCourses({
          query: keyword,
          page: Math.ceil(displayedCourses.length / PAGE_SIZE),
        }).then(({ hits }) => {
          concatCourseQuery(sortedSearch)(hits);
          return hits;
        });
    }

    promise().then(response => {
      setScrollLoading(false);
      if (response.length < PAGE_SIZE) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };
  const onSearch = string => {
    setLoading(true);
    searchCourses({ query: string }).then(response => {
      setLoading(false);
      setCourseQuery(sortedSearch)(response.hits);
      if (response.hits.length < PAGE_SIZE) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };
  const handleSearch = val => {
    if (val) {
      setTempKeyword('');
      history.replace(`${pathname}?keyword=${val}`);
    }
  };
  useEffect(() => {
    if (keyword && !displayedCourses.length) {
      onSearch(keyword);
    }
  }, [keyword]);
  useEffect(() => {
    setHasMore(true);
    animateScroll.scrollToTop({
      duration: 400,
      smooth: true,
      delay: 0,
    });
    if (!keyword && !displayedCourses.length) {
      setLoading(true);
      FireApi.fetchCourses(filter).then(response => {
        setLoading(false);
        if (search) {
          setCourseQuery(sortedSearch)(response);
        } else {
          setCourses(response);
        }
        if (response.length < PAGE_SIZE) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      });
    }
  }, [search]);
  const renderContent = () => (
    <React.Fragment>
      {loading && <ListSkeleton size={8} />}
      {!loading && !scrollLoading && isEmpty(displayedCourses) && (
        <Box p={100}>
          <Empty />
        </Box>
      )}
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={!scrollLoading && hasMore}
      >
        <CourseItem.Group
          locale={locale}
          getMsg={getMsg}
          courses={displayedCourses}
        />
        {scrollLoading && hasMore && (
          <Box pt={8}>
            <ListSkeleton size={1} />
          </Box>
        )}
      </InfiniteScroll>
      {!hasMore && (
        <Text.Paragraph textAlign={'center'} color={'grey.500'} my={16}>
          {getMsg('noMoreCourse')}
        </Text.Paragraph>
      )}
    </React.Fragment>
  );
  const renderFilters = () => (
    <>
      <TypeFilter
        title={getMsg('courseType').toUpperCase()}
        menus={COURSE_TYPES}
        getMenus={menus => mapCount(mapLocale(menus, ['name']), db, 'type')}
      />
      <LevelFilter
        title={getMsg('courseLevel').toUpperCase()}
        menus={LEVELS}
        getMenus={menus => mapCount(mapLocale(menus, ['name']), db, 'level')}
      />
      <CategoryFilter
        title={getMsg('courseCategory').toUpperCase()}
        menus={mapCount(
          CATEGORIES.filter(o => !o.filterHidden),
          db,
          'category',
        )}
        amountShowed
      />
    </>
  );
  const renderSearchInput = () => (
    <SearchInput
      placeholder={getMsg('searchCoursePlaceholder')}
      value={tempKeyword}
      onChange={e => setTempKeyword(e.target.value)}
      onSearch={handleSearch}
    />
  );
  return (
    <Container>
      <Hidden smDown>
        <Row type={'flex'} justify={'space-between'}>
          <Col xs={24} sm={6}>
            <Box py={16} {...Box.justifyBetween}>
              <Text textStyle={'categoryHeading'}>{getMsg('allCourses')}</Text>
              {!isEmpty(query) && (
                <Button
                  type="primary"
                  shape="round"
                  size={'small'}
                  onClick={() => history.replace(pathname)}
                  htmlType={'button'}
                >
                  {query.keyword
                    ? getMsg('clearSearch')
                    : getMsg('clearFilter')}
                </Button>
              )}
            </Box>
            <Box pr={8}>
              {renderFilters()}
              <LecturerFilter />
            </Box>
          </Col>
          <Col xs={24} sm={17}>
            <Hidden mdUp>
              <Affix offsetTop={0}>
                <Box p={16} mx={-16} pb={8} bgColor={'grey.100'}>
                  {renderSearchInput()}
                  <AlgoliaTag />
                </Box>
              </Affix>
            </Hidden>
            <Hidden mdDown>
              <Affix offsetTop={64}>
                <Box p={16} mx={-16} pb={8} bgColor={'grey.100'}>
                  {renderSearchInput()}
                  <AlgoliaTag />
                </Box>
              </Affix>
            </Hidden>
            {renderContent()}
          </Col>
        </Row>
      </Hidden>
      <Hidden smUp>
        <Affix offsetTop={0} onChange={affixed => setIsSticky(affixed)}>
          <Box
            bgColor={'grey.100'}
            px={16}
            mx={-16}
            pb={16}
            boxShadow={isSticky ? '0 0 10px -2px rgba(0,0,0,0.4)' : ''}
          >
            <Box pb={8} {...Box.justifyBetween}>
              <Text textStyle={'categoryHeading'}>{getMsg('allCourses')}</Text>
              <Box>
                {!isEmpty(query) && (
                  <Button
                    type="primary"
                    shape="round"
                    size={'small'}
                    onClick={() => history.replace(pathname)}
                    htmlType={'button'}
                  >
                    {query.keyword
                      ? getMsg('clearSearch')
                      : getMsg('clearFilter')}
                  </Button>
                )}
                <Button
                  className={'simple'}
                  size={'large'}
                  type="primary"
                  ghost
                  icon="filter"
                  onClick={() => setOpen(true)}
                  htmlType={'button'}
                >
                  {getMsg('filter')}
                </Button>
              </Box>
            </Box>
            {renderSearchInput()}
          </Box>
        </Affix>
        {renderContent()}
        <Drawer
          title={getMsg('filter')}
          placement={'right'}
          onClose={() => setOpen(false)}
          visible={open}
        >
          <Box px={8}>
            <Box textAlign={'right'} mr={8}>
              {!isEmpty(query) && (
                <Button
                  type="primary"
                  shape="round"
                  size={'small'}
                  onClick={() => {
                    setOpen(false);
                    history.replace(pathname);
                  }}
                  htmlType={'button'}
                >
                  {getMsg('clearFilter')}
                </Button>
              )}
            </Box>
            {renderFilters()}
            <LecturerFilter />
          </Box>
        </Drawer>
      </Hidden>
    </Container>
  );
};

CoursesPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  setCourses: PropTypes.func.isRequired,
  concatCourses: PropTypes.func.isRequired,
  getCourseQuery: PropTypes.func.isRequired,
  setCourseQuery: PropTypes.func.isRequired,
  concatCourseQuery: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  getMsg: PropTypes.func.isRequired,
  mapLocale: PropTypes.func.isRequired,
};
CoursesPage.defaultProps = {
  courses: null,
};

export default compose(
  withConsumer,
  withLecturersInCourses,
  withIntl,
)(CoursesPage);
