import React from 'react';
import { Menu, Icon, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import withFilterQuery from 'hocs/withFilterQuery';

const FilterWrapper = styled.div`
  width: 100%;
  .ant-menu {
    margin-bottom: 16px;
    border-right: 0px;
    background-color: rgba(0, 0, 0, 0);
    .ant-menu-item-group-title {
      padding: 8px 8px;
    }
    .ant-menu-item-group-list {
      padding: 4px;
    }
    .ant-menu-item {
      transition: ${({ theme }) => theme.transitions.create()};
      border-radius: ${({ theme }) => theme.borderRadius}px;
      height: 28px;
      display: flex;
      padding: 0 0 0 16px !important;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.text.primary};
      .anticon {
        margin: 0;
        margin-right: 8px;
        font-size: 16px;
        color: #ffffff !important;
      }
      &.ant-menu-item-active:not(.ant-menu-item-selected) {
        background-color: ${({ theme }) => theme.grey[300]};
      }
    }
    .ant-menu-item-selected {
      background-color: ${({ theme }) => theme.primary.main};
      color: ${({ theme }) => theme.primary.contrastText};
      :after {
        display: none;
      }
    }
  }

  .ant-skeleton-content * {
    border-radius: ${({ theme }) => theme.borderRadius}px;
  }
`;

const MenuItemGroup = Menu.ItemGroup;

const Filter = ({
  title,
  amountShowed,
  loading,
  selectedItems,
  getMenus,
  menus = [],
  onClick,
  onRemove,
}) => (
  <FilterWrapper>
    {loading ? (
      <React.Fragment>
        <Text p={'8px'} color={'text.disabled'} mb={'0.5em'}>
          {title}
        </Text>
        <Box pl={'8px'}>
          <Skeleton active loading paragraph={{ rows: 4 }} />
        </Box>
      </React.Fragment>
    ) : (
      <Menu onClick={onClick} selectedKeys={selectedItems} mode="inline">
        <MenuItemGroup
          title={`${title}${
            amountShowed ? ` (${getMenus(menus).length})` : ''
          }`}
        >
          {getMenus(menus).map(menu => (
            <Menu.Item key={menu.id}>
              {menu.name}
              {selectedItems.includes(menu.id) && (
                <Icon
                  className="close-icon"
                  type="close-circle"
                  theme="filled"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove(menu.id);
                  }}
                />
              )}
            </Menu.Item>
          ))}
        </MenuItemGroup>
      </Menu>
    )}
  </FilterWrapper>
);

Filter.propTypes = {
  loading: PropTypes.bool,
  amountShowed: PropTypes.bool,
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  title: PropTypes.string,
  onRemove: PropTypes.func,
  getMenus: PropTypes.func,
};

Filter.defaultProps = {
  menus: [],
  amountShowed: false,
  loading: false,
  selectedItems: ['test1'],
  onClick: () => {},
  title: 'title',
  onRemove: () => {},
  getMenus: menus => menus,
};

export const TypeFilter = withFilterQuery({
  key: 'type',
  mapProps: ({ queryValue, attachQuery, detachQuery }) => ({
    selectedItems: queryValue,
    onClick: ({ key }) => attachQuery(key),
    onRemove: detachQuery,
  }),
})(Filter);

export const LevelFilter = withFilterQuery({
  key: 'level',
  mapProps: ({ queryValue, attachQuery, detachQuery }) => ({
    selectedItems: queryValue,
    onClick: ({ key }) => attachQuery(key),
    onRemove: detachQuery,
  }),
})(Filter);

export const CategoryFilter = withFilterQuery({
  key: 'category',
  mapProps: ({ queryValue, attachQuery, detachQuery }) => ({
    selectedItems: queryValue,
    onClick: ({ key }) => attachQuery(key),
    onRemove: detachQuery,
  }),
  attachQuery(query) {
    return query.detachQuery('lecturer');
  },
})(Filter);

export default Filter;
