import React from 'react';
import PropTypes from 'prop-types';
import DownloadUI from 'components/atoms/DownloadUI';

const Bulletin = ({ data, defaultLabel }) => {
  const { bulletin1, bulletinLabel1, bulletin2, bulletinLabel2 } = data;
  return (
    <div>
      <DownloadUI
        label={bulletinLabel1 || defaultLabel}
        href={bulletin1}
        style={{ marginRight: 16, marginBottom: 16 }}
      />
      {bulletin2 && (
        <DownloadUI
          label={bulletinLabel2 || defaultLabel}
          href={bulletin2}
          style={{ marginBottom: 24 }}
        />
      )}
    </div>
  );
};

Bulletin.propTypes = {
  data: PropTypes.shape({}),
  defaultLabel: PropTypes.string,
};
Bulletin.defaultProps = {
  data: {},
  defaultLabel: 'Bulletin',
};

export default Bulletin;
