import React from 'react';
import last from 'lodash/last';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Empty } from 'antd';
import Box from 'components/atoms/Box';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import PostTimeline from 'components/organisms/PostTimeline';
import ListSkeleton from 'components/molecules/ListSkeleton';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useIntl from 'hooks/useIntl';
import { Context } from 'contexts/post';
import FireApi from 'firebase/fireApi';
import { POST_TAGS } from 'constants/temporary';
import ROUTES from 'constants/routes';

const menus = POST_TAGS.map(id => ({ id, label: id }));
const PAGE_SIZE = 8;

const NewsPage = ({
  match: {
    params: { tag },
  },
  history,
}) => {
  const {
    result,
    onInfiniteLoad,
    hasMore,
    setHasMore,
    status,
  } = useInfiniteScroll({
    api: FireApi.fetchPosts,
    Context,
    pageSize: PAGE_SIZE,
    tag,
  });
  const { locale } = useIntl();
  const boxProps = {
    px: 16,
    py: 16,
  };
  const renderLoader = () => {
    if (status.isRequest && !result.length) {
      return <ListSkeleton key={0} size={8} boxProps={boxProps} />;
    }
    if (status.isRequest && result.length) {
      return (
        <Box pt={-24} key={0}>
          <ListSkeleton size={1} boxProps={boxProps} />
        </Box>
      );
    }
    return null;
  };
  return (
    <NestedMenuTabsLayout
      menus={menus}
      headingId={'News'}
      defaultSelectedKeys={[tag]}
      onMenuClick={item => {
        history.replace(ROUTES.news.get(`/${item.key}`));
        setHasMore(true);
      }}
    >
      {([key]) => (
        <>
          <InfiniteScroll
            loadMore={() => {
              onInfiniteLoad({
                limit: PAGE_SIZE,
                lastId: get(last(result), 'id'),
                tagId: key,
              });
            }}
            hasMore={hasMore}
          >
            <PostTimeline posts={result} locale={locale} />
          </InfiniteScroll>
          {renderLoader()}
          {!result.length && !status.isRequest && <Empty />}
        </>
      )}
    </NestedMenuTabsLayout>
  );
};

NewsPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
NewsPage.defaultProps = {};

export default NewsPage;
