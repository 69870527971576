import React from 'react';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import InternshipInfo from './InternshipInfo';
import InternshipFiles from './InternshipFiles';

const menus = [
  {
    id: 'intern_info',
    label: 'What you need to know',
  },
  {
    id: 'intern_files',
    label: 'Essential files & forms',
  },
];

const Internship = () => (
  <NestedMenuTabsLayout
    menus={menus}
    headingId={'internship'}
    defaultSelectedKeys={['intern_info']}
  >
    {([key]) => (
      <>
        {key === 'intern_info' && <InternshipInfo />}
        {key === 'intern_files' && <InternshipFiles />}
      </>
    )}
  </NestedMenuTabsLayout>
);

export default Internship;
