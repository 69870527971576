import withCatchLogger from './withCatchLogger';
import withResultLogger from './withResultLogger';

const STAGE = process.env.REACT_APP_STAGE;

const loggers = [withResultLogger, withCatchLogger];

export const withBodyLog = (api, name) => (...args) => {
  if (STAGE !== 'production') {
    // eslint-disable-next-line no-console
    console.log(name, args[0]);
  }
  return api(...args);
};

export default (promise, domain) =>
  loggers.reduce((prev, curr) => curr(prev(promise, domain), domain));
