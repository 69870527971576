import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Tooltip } from 'antd';
import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';

const AvatarGroup = ({
  users,
  tooltipEnabled,
  overlapped,
  imageProps,
  locale,
  ...props
}) => (
  <Box {...props}>
    {users
      .filter(u => !!u)
      .map(({ id, name, avatarUrl }, i) => {
        const image = (
          <Image
            key={id || i}
            avatar
            icon={'user'}
            size={36}
            {...imageProps}
            src={avatarUrl}
            {...(overlapped
              ? {
                  ml: i > 0 ? -8 : 0,
                  border: '2px solid #ffffff',
                }
              : {
                  mr: 8,
                })}
          />
        );
        return tooltipEnabled ? (
          <Tooltip key={id || i} title={get(name, [locale])}>
            {image}
          </Tooltip>
        ) : (
          image
        );
      })}
  </Box>
);

const mapLecturers = (lecturers, ids, locale = 'en') => {
  if (!lecturers) return [];
  return ids.map(id => {
    const lecturer = lecturers.find(({ id: _id }) => _id === id);
    return {
      ...lecturer,
      name: get(lecturer, ['name', locale]),
    };
  });
};

AvatarGroup.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})),
  tooltipEnabled: PropTypes.bool,
  overlapped: PropTypes.bool,
  imageProps: PropTypes.shape({}),
  locale: PropTypes.string,
};
AvatarGroup.defaultProps = {
  users: [],
  tooltipEnabled: true,
  overlapped: true,
  imageProps: {},
  locale: 'en',
};
AvatarGroup.mapLecturers = mapLecturers;

export default AvatarGroup;
