/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const RomEN = () => (
  <div>
    <Box mx={{ xs: -16, sm: -32 }} mb={24}>
      <img
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: 240,
          margin: 'auto',
        }}
        alt={'cover'}
        src={
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FROM.jpg?alt=media&token=c378da8d-d62b-4a9d-b3a4-2b079f41e2a6'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      Resources and Operations Management (ROM)
    </Text.Heading>
    <Text.Topic>Research area</Text.Topic>
    <Text.Body>
      <ul>
        <li>Supply Chain Management</li>
        <li>Logistics Management</li>
        <li>Enterprise Resources Planning</li>
        <li>System & Operation Design</li>
        <li>Business Process Improvement</li>
        <li>Production Planning and Control</li>
        <li>Warehouse Management</li>
      </ul>
    </Text.Body>

    <Text.Topic>Members</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/jdZe6MhYUgo57TDzkBMV/Paveena%20Chaovalitwongse',
            )}
          >
            Assoc.Prof Paveena Chaovalitwongse Ph.D.
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/edKtC4lJP8u3Rqvt8fXC/Naragain%20Phumchusri',
            )}
          >
            Assoc.Prof Naragain Phumchusri Ph.D.
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/54SBOSU5ssC7VSXdH3gY/Poom%20Luangjamekorn',
            )}
          >
            Asst.Prof Poom Luangjamekorn
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/eqQyIsNixMu42Jiqv56r/Amonsiri%20Vilasdaechanont',
            )}
          >
            Lect. Amonsiri Vilasdaechanont Ph.D.
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>Location</Text.Topic>
    <Text.Body>Room 716, 7F, Engineering Building 4</Text.Body>
  </div>
);

RomEN.propTypes = {};
RomEN.defaultProps = {};

export default RomEN;
