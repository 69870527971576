import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

const BackgroundCol = styled(props => <Col {...omit(props, ['isMiddle'])} />)`
  height: 100%;
  ${({ isMiddle, theme }) =>
    `border-left: 1px ${isMiddle ? 'dashed' : 'solid'} ${
      isMiddle ? theme.grey[200] : '#e5e5e5'
    };`}
`;

const BackgroundRow = styled(props => (
  <Row {...omit(props, ['hiddenEndLine'])} />
))`
  height: 100%;
  ${({ hiddenEndLine }) =>
    !hiddenEndLine ? 'border-right: 1px solid #e5e5e5' : ''};
`;

const TimeBackground = ({ start, end, size, hiddenEndLine }) => {
  const span = size / ((end - start) * 2);
  if (span < 1) return null;
  return (
    <BackgroundRow
      prefixCls={size === 10 ? 'iecu-row' : undefined}
      type="flex"
      align="bottom"
      hiddenEndLine={hiddenEndLine}
    >
      {Array((end - start) * 2)
        .fill(1)
        .map((_, index) => (
          <BackgroundCol
            // eslint-disable-next-line react/no-array-index-key
            key={`schedule-background-${index}`}
            prefixCls={size === 10 ? 'iecu-col' : undefined}
            span={span}
            isMiddle={index % 2 === 1}
          />
        ))}
    </BackgroundRow>
  );
};

TimeBackground.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  size: PropTypes.oneOf([10, 24]),
  hiddenEndLine: PropTypes.bool,
};

TimeBackground.defaultProps = {
  size: 24,
  hiddenEndLine: false,
};

export default TimeBackground;
