import omitBy from 'lodash/omitBy';
import StorageApi from '../firebase/storageApi';

const ImageFactory = () => {
  const mapFileList = (fileList, cb = () => {}) =>
    fileList.map(item => ({
      ...omitBy(item, (value, key) => key === 'originFileObj' || !value),
      ...cb(item),
    }));
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.fileList) {
      return mapFileList(e.fileList);
    }
    return e && e.fileList;
  };

  const normImageFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.fileList) {
      return mapFileList(e.fileList, item => ({
        thumbUrl: item.response || item.thumbUrl,
      }));
    }
    return e && e.fileList;
  };

  const removeStringUrl = images =>
    images.filter(img => typeof img !== 'string');

  const createCustomRequest = prefixRef => async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    try {
      const fileUrl = await StorageApi.uploadFile(prefixRef, file, onProgress);
      onSuccess(fileUrl);
    } catch (_) {
      onError();
    }
  };

  const mapImages = (images, size) =>
    images.map((image, index) => ({
      src:
        typeof image === 'string' ? image : image.thumbnail || image.response,
      ...size,
      name: typeof image === 'string' ? `File no.${index + 1}` : image.name,
    }));

  return {
    mapImages,
    normFile,
    normImageFile,
    removeStringUrl,
    createCustomRequest,
  };
};

export const imageService = ImageFactory();

export default ImageFactory;
