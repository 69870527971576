import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { breakpoints } from '../../theme';

const Hidden = ({
  width,
  only,
  except,
  xsDown,
  xsUp,
  smDown,
  smUp,
  mdDown,
  mdUp,
  children,
}) => {
  const { values } = breakpoints;
  if (only) {
    if (only === 'xs' && width < values.sm) return null;
    if (only === 'sm' && width >= values.sm && width < values.md) return null;
    if (only === 'md' && width >= values.md && width < values.lg) return null;
    if (only === 'lg' && width >= values.lg && width < values.xl) return null;
  }

  if (except) {
    if (except === 'xs' && width < values.sm) return children;
    if (except === 'sm' && width >= values.sm && width < values.md)
      return children;
    if (except === 'md' && width >= values.md && width < values.lg)
      return children;
    if (except === 'lg' && width >= values.lg && width < values.xl)
      return children;
    return null;
  }

  if (xsDown && width <= values.xs) return null;
  if (smDown && width <= values.sm) return null;
  if (mdDown && width <= values.md) return null;

  if (xsUp && width >= values.xs) return null;
  if (smUp && width >= values.sm) return null;
  if (mdUp && width >= values.md) return null;
  return children;
};

Hidden.propTypes = {
  /**
   * If true, screens this size and down will be hidden.
   */
  mdDown: PropTypes.bool,
  /**
   * If true, screens this size and up will be hidden.
   */
  mdUp: PropTypes.bool,
  /**
   * Hide the given breakpoint(s).
   */
  only: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    PropTypes.arrayOf(PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])),
  ]),
  /**
   * If true, screens this size and down will be hidden.
   */
  smDown: PropTypes.bool,
  /**
   * If true, screens this size and up will be hidden.
   */
  smUp: PropTypes.bool,
  /**
   * If true, screens this size and down will be hidden.
   */
  xsDown: PropTypes.bool,
  /**
   * If true, screens this size and up will be hidden.
   */
  xsUp: PropTypes.bool,
};
Hidden.defaultProps = {};

export default withSizes(props => props)(Hidden);
