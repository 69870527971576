import React from 'react';
import HomepageBanner from 'components/organisms/HomepageBanner';
import Homepage80Anniversary from 'components/organisms/Homepage80Anniversary';
import HomepagePosts from 'components/organisms/HomepagePosts';
import GroupShortcut from 'components/organisms/GroupShortcut';
import HomepagePrograms from 'components/organisms/HomepagePrograms';
import { withIntl } from 'contexts/intl';

const Homepage = () => (
  <>
    <HomepageBanner />
    <Homepage80Anniversary />
    <HomepagePosts />
    <GroupShortcut />
    <HomepagePrograms />
  </>
);

Homepage.propTypes = {};
Homepage.defaultProps = {};

export default withIntl(Homepage);
