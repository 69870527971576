/* eslint-disable no-console */
const STAGE = process.env.REACT_APP_STAGE;

export default (promise, domain = '') => (...args) =>
  promise(...args).then(result => {
    if (STAGE !== 'production') {
      console.log(
        `%c ${domain.toUpperCase()} : Result`,
        'background: #5cc779; color: #ffffff',
        result,
      );
    }
    return result;
  });
