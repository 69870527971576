import React from 'react';
import { withRouter } from 'react-router-dom';
import Query from 'factories/query';

export default ({
  key,
  mapProps = props => props,
  attachQuery = obj => obj,
  detachQuery = obj => obj,
}) => WrappedComponent =>
  withRouter(props => {
    const { location, history } = props;
    const pushRoute = result =>
      history.replace(`${location.pathname}?${Query(result).toString()}`);
    return (
      <WrappedComponent
        {...props}
        {...mapProps({
          queryValue: Query(location.search).valuesOf(key),
          attachQuery: value => {
            const query = attachQuery(
              Query(location.search)
                .attachQuery({ [key]: value })
                .detachQuery('keyword'),
            ).toString();
            return pushRoute(query);
          },
          detachQuery: () => {
            const query = detachQuery(
              Query(location.search).detachQuery(key),
            ).toString();
            return pushRoute(query);
          },
        })}
      />
    );
  });
