import React from 'react';
import cx from 'clsx';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled from '@emotion/styled';
import { withSizes } from 'react-sizes';
import { imageService } from 'factories/Image';

const StyledGallery = styled.div`
  img {
    object-fit: cover;
  }
`;

const galleryCss = css`
  .react-photo-gallery--gallery > div > img {
    height: 100%;
    object-fit: contain;
  }
`;

class ImageGallery extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0, lightboxIsOpen: false };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    const { currentImage } = this.state;
    this.setState({
      currentImage: currentImage - 1,
    });
  }

  gotoNext() {
    const { currentImage } = this.state;
    this.setState({
      currentImage: currentImage + 1,
    });
  }

  render() {
    const {
      className,
      images,
      width,
      height,
      galleryMinHeight,
      galleryProps,
      singlePhoto,
      isMobile,
    } = this.props;
    const { currentImage, lightboxIsOpen } = this.state;
    const photos = imageService.mapImages(images, { width, height });
    return (
      <StyledGallery
        className={cx(className, singlePhoto && galleryCss)}
        style={{ minHeight: galleryMinHeight }}
      >
        <Gallery
          columns={isMobile ? 2 : 4}
          {...galleryProps}
          photos={photos}
          onClick={this.openLightbox}
        />
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal
              onClose={this.closeLightbox}
              styles={{
                blanket: base => ({
                  ...base,
                  zIndex: 2000,
                }),
                positioner: base => ({
                  ...base,
                  zIndex: 2010,
                }),
              }}
            >
              <Carousel currentIndex={currentImage} views={photos} />
            </Modal>
          ) : null}
        </ModalGateway>
      </StyledGallery>
    );
  }
}

ImageGallery.propTypes = {
  className: PropTypes.string,
  singlePhoto: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ),
  width: PropTypes.number,
  height: PropTypes.number,
  galleryMinHeight: PropTypes.number,
  galleryProps: PropTypes.shape({}),
  isMobile: PropTypes.bool,
};

ImageGallery.defaultProps = {
  className: '',
  singlePhoto: false,
  images: [],
  galleryMinHeight: undefined,
  width: 1.5,
  height: 1,
  galleryProps: {},
  isMobile: false,
};

export default withSizes(({ width }, { width: imageWidth, height }) => ({
  isMobile: width < 600,
  width: imageWidth,
  height,
}))(ImageGallery);
