import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Menu } from 'antd';
import useIntl from 'hooks/useIntl';

const StyledMenu = styled(Menu)`
  &.ant-menu {
    background: transparent;
  }
  .ant-menu-submenu > .ant-menu {
    background: transparent;
  }

  &.ant-menu-inline {
    border-right: none;
  }
  > .ant-menu-item,
  > .ant-menu-submenu {
    padding-left: 24px !important;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    border-top-left-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }

  .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-menu-submenu-open {
    > .ant-menu-submenu-title {
      font-weight: bold;
    }
  }

  .SubMenu {
    > .ant-menu-sub {
      padding-left: 12px !important;
      .ant-menu-submenu-title {
        padding-left: 12px !important;
      }
      .ant-menu-item {
        padding-left: 24px !important;
      }
    }
    &.-level1 > .ant-menu-sub {
      border-left: 1px solid #e8e8e8;
    }
  }
`;

const MenuList = ({ menus, onTitleClick, ...props }) => {
  const { getMsg } = useIntl();
  // eslint-disable-next-line react/prop-types
  const renderSubMenus = level => item => {
    const { id, translationId, label, subMenus } = item;
    const title = getMsg(translationId || id) || label;
    return subMenus && subMenus.length ? (
      <Menu.SubMenu
        className={`SubMenu -level${level}`}
        key={id}
        title={title}
        onTitleClick={onTitleClick}
      >
        {subMenus.map(renderSubMenus(level + 1))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={id}>
        <span>{title}</span>
      </Menu.Item>
    );
  };
  return (
    <StyledMenu mode={'inline'} inlineIndent={0} {...props}>
      {menus.map(renderSubMenus(1))}
    </StyledMenu>
  );
};

MenuList.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};
MenuList.defaultProps = {
  menus: [],
  onClick: () => {},
  onTitleClick: () => {},
};

export default MenuList;
