/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import without from 'lodash/without';

const getValidArray = array => array.filter(item => !!item);

export default (levels = [], defaultOpenKeys = levels) => {
  const [selectedKeys, setSelectedKeys] = useState(levels);
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);
  useEffect(() => {
    if (levels !== selectedKeys) {
      setSelectedKeys(levels);
      setOpenKeys(levels);
    }
  }, [levels]);
  const validSelectedKeys = getValidArray(selectedKeys);
  return {
    selectedKeys: validSelectedKeys,
    lastSelectedKey: validSelectedKeys[validSelectedKeys.length - 1],
    setFirstKey: key => setSelectedKeys([key, ...validSelectedKeys.slice(1)]),
    setSecondKey: key =>
      setSelectedKeys([
        validSelectedKeys[0],
        key,
        ...validSelectedKeys.slice(2),
      ]),
    setSelectedKeys,
    openKeys,
    toggleOpenKey: newKey =>
      setOpenKeys(
        openKeys.indexOf(newKey) === -1
          ? [...openKeys, newKey]
          : without(openKeys, newKey),
      ),
    setOpenKeys,
  };
};
