/* eslint-disable  */
import React from 'react';
import Text from 'components/atoms/Text';

const OurHistory = () => (
  <div>
    <Text.Title level={2}>ประวัติความเป็นมา</Text.Title>
    <img
      alt={'history_01'}
      src={
        // eslint-disable-next-line max-len
        'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/history/history_01.jpg'
      }
      style={{ maxWidth: '100%' }}
    />
    <br />
    <br />
    <Text.Body>
      <span style={{ fontSize: 20, color: '#AC171C' }}>
        <b>แผนกวิชาวิศวกรรมอุตสาหการ จุฬาลงกรณ์มหาวิทยาลัย</b>
      </span>{' '}
      ได้ก่อตั้งขึ้นในปี พ.ศ. 2485
      หลังจาก "โรงเรียนยันตรศึกษา" ซึ่งเป็นต้นกำเนิดคณะวิศวกรรมศาสตร์
      จุฬาลงกรณ์มหาวิทยาลัย ก่อตั้งขึ้นได้ 29 ปี
      ถือได้ว่าเป็นสถาบันการศึกษาด้านวิศวกรรมอุตสาหการแห่งแรกของประเทศไทยที่ได้มีส่วนผลิตบุคคลากรด้านวิศวกรรมอุตสาหการออกรับใช้สังคมมายาวนานกว่าค่อนศตวรรษ
      จากการให้สัมภาษณ์ของ ศ.นิทัศน์ ประกาศวุฒิสาร ซึ่งเป็นวิศวกรอุตสาหการ
      รุ่นแรกสมัยที่หนึ่ง และเป็นอดีตหัวหน้าแผนกวิชาวิศวกรรมอุตสาหการ
      ท่านเล่าให้ฟังว่าสมัยนั้นเป็นสมัยน้ำท่วมกรุงเทพฯ ครั้งที่ 2
      ซึ่งเป็นน้ำท่วมใหญ่ขนาดปลาในบ่อสวนลุมพินีว่ายมาถึงจุฬาฯ
      เข้ามาในห้องเรียนที่อาคารวิศวกรรมศาสตร์ หลังที่ 2
      ท่านที่เคยแวะเวียนเข้าไปที่นั่นคงจะพอนึกออกได้ว่าระดับน้ำสูงมากขนาดไหน
    </Text.Body>
    <Text.Body textIndent={'20px'}>
      ปี พ.ศ.2485 ศ.หลวงอนุสาสน์ยันตรกรรม เป็นหัวหน้าแผนกวิศวกรรมอุตสาหการคนแรก
      ท่านได้จัดหลักสูตรให้เน้นหนักไปในทางวิศวกรรมเครื่องกลและยังเพิ่มรายวิชาต่างๆ
      ทางวิศวกรรมเคมีเข้ามาในหลักสูตรอีกเป็นจำนวนมาก
      ด้วยเหตุนี้ขณะที่แผนกวิชาวิศวกรรมสาขาอื่นๆ (สมัยนั้นมี 5 แผนกวิชา คือ
      วิศวกรรมโยธา วิศวกรรมไฟฟ้า วิศวกรรมเครื่องกล วิศวกรรมเหมืองแร่
      และวิศวกรรมอุตสาหการ) แยกชั้นเรียนตอนต้นปีที่ 4
      แต่นิสิตแผนกวิศวกรรมอุตสาหการเท่านั้นที่แยกชั้นเรียนตอนต้น โดยไปเรียนวิชา
      Organic Chemistry กับนิสิตคณะวิทยาศาสตร์ชั้นปีที่ 3 ซึ่งสอนโดย ศ.ดร.แถบ
      นีละนิธิ และไปเรียนวิชาเคมีประยุกต์ต่างๆ จาก ศ.ดร.กัลย์ อิศรเสนาฯ และ
      ศ.ทองสุข พงศทัต เป็นต้น นอกจากวิชาเคมีดังกล่าวข้างต้นนี้แล้ว
      วิศวกรอุตสาหการรุ่นแรกๆ
      ยังต้องเรียนวิชาอุตสาหกรรมที่เกี่ยวข้องกับทางเคมีอีก เช่น
      เรียนวิชาการทำน้ำตาล (Sugar Technology) วิชาการทำเครื่องเคลือบดินเผา
      (Ceramic Technology) และวิชาการทำแอลกอฮอล์และสุราต่างๆ เป็นต้น
      จะเห็นได้ว่า หลักสูตรทางวิศวกรรมอุตสาหการรุ่นแรกๆ
      จะเน้นไปทางวิศวกรรมเครื่องกลผสมกับวิศวกรรมเคมีซึ่งยังไม่มีการจัดตั้งเป็นแผนกวิชาขึ้นในสมัยนั้น
    </Text.Body>
    <Text.Body textIndent={'20px'}>
      ต่อมาในปี พ.ศ.2486 ซึ่งเป็นปีแรกที่เข้าสู่ระยะรีบเร่งในการผลิตคน
      ทางด้านมหาวิทยาลัยได้ส่งเสริมให้เร่งผลิตบัณฑิตในปี 2486 ให้ผลิตปีละ 2 รุ่น
      โดยเพิ่มชั่วโมงเรียนไปถึง 2-3 ทุ่ม แต่ก็ทำได้เพียงปีเดียวคือปี 2486
      ผลิตบัณฑิตได้ 2 รุ่น แต่ปีต่อๆ มาก็ไม่ได้ทำ
      อาจเป็นเพราะสิ้นเปลืองค่าใช้จ่ายมาก
      การสอนวิชาวิศวกรรมอุตสาหการโดยหลักสูตรแรกนี้ได้ดำเนินมาจนถึงปี พ.ศ.2490
      ก็ต้องปิดไปชั่วคราว
      ทั้งนี้เป็นเพราะสมัยนั้นมีผู้มาเรียนทางด้านอุตสาหกรรมน้อย
      และงานทางด้านอุตสาหกรรมภายนอกที่จะรองรับก็ยังไม่เจริญตามมากเท่าที่ควร
    </Text.Body>
    <img
      alt={'history_01'}
      src={
        // eslint-disable-next-line max-len
        'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/history/history_02.jpg'
      }
      style={{ maxWidth: '30%', float: 'left', marginRight: 20 }}
    />
    <Text.Body>
      ในช่วงระยะเวลาที่ไม่มีการสอนทางภาควิชาวิศวกรรมอุตสาหการ (พ.ศ. 2490-2500)
      นั้น ทางคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ก็ได้ดำเนินการทางบุคคลากร
      โดยทำการส่งเสริมอาจารย์ทางแผนกวิชาวิศวกรรมอุตสาหการ
      ให้ไปเรียนต่อทางวิศวกรรมอุตสาหการในอเมริกา
      ดังนั้นหลักสูตรวิศวกรรมอุตสาหการรุ่นต่อๆ
      มาจึงละม้ายเหมือนไปทางหลักสูตรวิศวกรรมอุตสาหการของอเมริกา
    </Text.Body>

    <Text.Body>
      ระยะนั้นรัฐบาลมีนโยบายพัฒนาเศรษฐกิจของประเทศเป็นขั้น
      โดยได้ร่างแผนพัฒนาเศรษฐกิจและสังคมแห่งชาติไว้เป็นช่วงๆ ละ 5 ปี
      แผนพัฒนาเศรษฐกิจและสังคมแห่งชาติแผนที่ 1 ตั้งไว้ในช่วง พ.ศ.2504-2508
      และเพื่อให้มีฐานทางด้านการลงทุนอุตสาหกรรม
      เพื่อรองรับแผนพัฒนาที่สร้างขึ้นมา
      ทางรัฐบาลจึงตั้งสำนักงานคณะกรรมการส่งเสริมการลงทุนขึ้น
      แผนกวิชาวิศวกรรมอุตสาหการในระยะนั้นพิจารณาดูแล้วเห็นว่าเพื่อให้สอดคล้องกับนโยบายของรัฐบาลจึงเห็นควรที่จะเปิดการสอนทางด้านวิศวกรรมอุตสาหการขึ้นมาอีกครั้งเพื่อผลิตบัณฑิตทางด้านอุตสาหการ
      ไปรับใช้งานอุตสาหกรรมที่จะเพิ่มขึ้นมาตามลำดับ ตามแผนพัฒนาเศรษฐกิจของรัฐบาล
      ด้วยเหตุนี้บัณฑิตของวิศวกรรมอุตสาหการจึงเกิดขึ้นมาครั้งที่สองในปี พ.ศ.2500
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      ผู้ที่มาเรียนวิศวกรรมอุตสาหการช่วงเวลานี้นั้นจะมีมาจาก 3 แหล่ง คือ
      พวกแรกจะเป็นนิสิตที่เข้ามาเพื่อเรียนโดยตรงตั้งแต่ปีที่ 1
      พวกที่สองนี้เริ่มใช้ตั้งแต่ปี พ.ศ.2504
      เป็นพวกที่เรียนสำเร็จอนุปริญญาวิทยาศาสตร์ซึ่งทางแผนกวิศวกรรมอุตสาหการพิจารณาแล้วเห็นว่ามีความสามารถที่จะชักจูงให้มาเรียนทางแนววิศวกรรมเคมีเพิ่มเติมได้
      และเมื่อจบออกไปแล้วก็ยังสามารถไปช่วยทำงาน ให้ประเทศชาติได้อีกมาก
    </Text.Body>

    <img
      alt={'history_01'}
      src={
        // eslint-disable-next-line max-len
        'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/history/history_03.jpg'
      }
      style={{ maxWidth: '100%' }}
    />
    <br />
    <br />

    <Text.Body textIndent={'20px'}>
      ด้วยเหตุนี้วิศวกรรมอุตสาหการตั้งแต่ พ.ศ.2504
      เป็นต้นมาจึงแยกออกเป็นสองกลุ่ม กลุ่มแรกคือวิศวกรรมอุตสาหการโรงงาน
      กลุ่มที่สองคือวิศวกรรมอุตสาหการเคมี
      การกระทำในลักษณะนี้ได้มีมาเรื่อยจนกระทั่งปี พ.ศ.2518
      คณะวิศวกรรมศาสตร์ได้จัดตั้งแผนกวิชาวิศวกรรมเคมีขึ้น
      แผนกวิศวกรรมอุตสาหการเคมี จึงแยกออกไปรวมกับวิศวกรรมเคมีตั้งแต่บัดนั้น
      ส่วนพวกที่สามที่เข้ามาเรียนวิศวกรรมอุตสาหการโรงงานนั้นจะมาจากผู้ที่จบวิศวกรรมเครื่องกลแล้วมาศึกษาในแผนกวิศวกรรมอุตสาหการอีกหนึ่งปี
      ก็จะมีคุณวุฒิครบตามหลักสูตรวิศวกรรมอุตสาหการโรงงาน
      พวกที่จบสองหลักสูตรนี้จะได้เงินเดือนเพิ่มขึ้นอีก
      การดำเนินงานแบบนี้มาสิ้นสุดลงเมื่อทางรัฐบาลออกนโยบายพิจารณาเงินเดือน
      โดยยึดหลักใช้ปริญญาสูงสุดเพียงอย่างเดียว
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      วิศวกรอุตสาหการโรงงานช่วงที่สองนี้จึงมีลักษณะคล้ายคลึงกับวิศวกรรมเครื่องกลมากยกเว้นแต่
      ต้องเรียนวิชาหลักของวิศวกรรมอุตสาหการเพิ่มเติมและผนวกกับวิชาด้านบริหารเข้าไปด้วย
      ด้วยเหตุนี้วิศวกรอุตสาหการจึงเป็นวิศวกรแผนกเดียวที่เรียนรู้งานบริหารควบคู่ไปกับวิชาชีพวิศวกร
      การปรับปรุงหลักสูตรได้ดำเนินอยู่ตลอดเวลา
      แผนกวิศวกรรมอุตสาหการได้เปิดสอนหลักสูตรปริญญาโทขึ้นในปี พ.ศ.2516
      และได้เปลี่ยนชื่อเป็น "ภาควิชาวิศวกรรมอุตสาหการ" เมื่อปี พ.ศ.2522
      ตามพระราชบัญญัติจุฬาลงกรณ์มหาวิทยาลัย พ.ศ.2522 ที่ออกมาใหม่
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      ปัจจุบันการบริหารงานวิชาการของภาควิชาวิศวกรรมอุตสาหการ จุฬาฯ
      ได้มีการปรับเปลี่ยนไปมากจากแต่ก่อน เพื่อดำรงความทันสมัยในสายวิชาการ
      และการประยุกต์องค์ความรู้ เทคนิค ให้ทันต่อความต้องการของอุตสาหกรรม
      ในการผลิตบัณฑิตและผลงานวิชาการ
      ให้มีคุณภาพตอบโจทย์กับอุตสาหกรรมในปัจจุบันและในอนาคต
    </Text.Body>
  </div>
);

OurHistory.propTypes = {};
OurHistory.defaultProps = {};

export default OurHistory;
