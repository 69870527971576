import CollectionFactory from './CollectionFactory';

const FireStoreFactory = (fireApp, settings) => {
  const FireStore = fireApp.firestore();
  if (settings) {
    FireStore.settings(settings);
  }

  return {
    makeCollection(collectionName) {
      return CollectionFactory(FireStore.collection(collectionName));
    },
  };
};

export default FireStoreFactory;
