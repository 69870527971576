import React from 'react';
import get from 'lodash/get';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';

const InternshipInfo = () => {
  const dbAssets = useConsumer();
  return (
    <>
      <Text.Title level={3}>โครงการฝึกงาน</Text.Title>
      <Text.Paragraph fontSize={16} mb={24} color={'text.secondary'}>
        ภาคฤดูร้อน
      </Text.Paragraph>
      <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
        กำหนดการฉบับย่อ
      </Text.Paragraph>
      <MonthlyTimeline
        events={[
          {
            title: 'พฤษภาคม',
            list: [
              // eslint-disable-next-line max-len
              'นิสิตชั้นปีที่ 3 ต้องลงทะเบียนเรียนรายวิชา 2100301 ตามตอนเรียน ที่ภาควิชากำหนด',
              'ลงทะเบียนที่เว็บไซต์ตามที่คณะกำหนด',
            ],
            current: isInRange([1, 1], [16, 5]),
          },
          {
            title: 'ปลายพฤษภาคม',
            subtitle: 'เริ่มโครงการฝึกงาน',
            current: isInRange([21, 5], [30, 7]),
          },
          {
            title: 'ปลายกรกฎาคม - ต้นสิงหาคม',
            subtitle: 'จบโครงการฝึกงานของนิสิต',
            current: isInRange([31, 7], [1, 8]),
          },
          {
            title: 'สิงหาคม',
            subtitle: 'ส่งรายงานฝึกงานไม่เกิน 1 อาทิตย์หลังจบการฝึกงาน',
            current: isInRange([2, 8], [10, 8]),
          },
        ]}
      />
      <Text>
        {/* eslint-disable-next-line max-len */}
        *นิสิตควรดาวน์โหลดไฟล์ด้านล่างและศึกษาข้อมูลเกี่ยวกับโครงการฝึกงานอย่างละเอียด
        เพื่อป้องกันไม่ให้เกิดความผิดพลาดและเกิดประโยชน์สูงสุดกับนิสิต
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'Slide เกี่ยวกับการฝึกงาน'}
        href={get(dbAssets, 'internship.internSlide')}
        block
      />
      <br />
      <br />
      <DownloadUI
        label={'ขั้นตอนการฝึกงานอย่างละเอียด'}
        href={get(dbAssets, 'internship.internProcess')}
        block
      />
      <br />
      <br />
      <DownloadUI
        label={'เกณฑ์การพิจารณาประเมินผล'}
        href={get(dbAssets, 'internship.internAssessmentCriteria')}
        block
      />
      <br />
      <br />
      <br />
    </>
  );
};

InternshipInfo.propTypes = {};
InternshipInfo.defaultProps = {};

export default InternshipInfo;
