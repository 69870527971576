import createCollectionContext from 'hooks/createCollectionContext';
import FireApi from 'firebase/fireApi';
import toHOC from './toHOC';

export const {
  Provider,
  Consumer,
  useConsumer,
  useInitFetch,
  Context,
} = createCollectionContext({
  collectionName: 'courses',
  itemName: 'course',
  queryName: 'course',
  fetchingApi: FireApi.fetchCourses,
});

export const CourseProvider = Provider;
export const CourseConsumer = Consumer;
export const withConsumer = toHOC(Consumer);
export default Context;
