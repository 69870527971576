import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Text from 'components/atoms/Text';
import TimeTablePart from './TimeTablePart';

const TimeTableDay = ({ color, title, getScheduleList, setItemProps }) => (
  <Row type="flex" align="middle">
    <Col xs={0} md={2}>
      <Text fontWeight={900}>{title}</Text>
    </Col>
    <Col xs={24} md={22}>
      <Row type="flex">
        <Col span={12}>
          <TimeTablePart
            color={color}
            start={8}
            end={13}
            size={10}
            scheduleList={getScheduleList('morning')}
            setItemProps={item => setItemProps(item, 'morning')}
            hiddenEndLine
          />
        </Col>
        <Col span={12}>
          <TimeTablePart
            color={color}
            start={13}
            end={18}
            size={10}
            scheduleList={getScheduleList('evening')}
            setItemProps={item => setItemProps(item, 'evening')}
          />
        </Col>
      </Row>
    </Col>
  </Row>
);

TimeTableDay.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  getScheduleList: PropTypes.func,
  setItemProps: PropTypes.func,
};
TimeTableDay.defaultProps = {
  color: '#000000',
  title: '',
  getScheduleList: () => {},
  setItemProps: () => {},
};

export default TimeTableDay;
