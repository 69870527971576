import get from 'lodash/get';
import partition from 'lodash/partition';
import { replaceUndefined } from 'helpers/functions';
import {
  PREFIX_BY_ID,
  SUFFIX_BY_ID,
  USER_BY_TYPES,
  RANK,
} from 'constants/temporary';

const LecturerFactory = () => {
  const schema = {
    avatarUrl: '',
    categories: [],
    courses: [],
    description: {
      en: '',
      th: '',
    },
    education: [],
    publication: [],
    name: {
      en: '',
      th: '',
    },
    resumeUrl: '',
    type: USER_BY_TYPES.LECTURER,
  };

  const attachBadge = (people, badge) =>
    people.map(item => ({ ...item, badge }));

  const getName = (item, locale = 'en') => {
    if (!item || !item.name) return '-';
    const { name, prefix, suffix } = item;
    const p = get(PREFIX_BY_ID, [prefix, 'label', locale], '');
    const s = get(SUFFIX_BY_ID, [suffix, 'label', locale], '');
    if (locale === 'en') {
      return `${p} ${name[locale]} ${s}`;
    }
    if (locale === 'th') return `${p} ${s}${name[locale]}`;
    return '';
  };

  const sortByFullName = (people, locale) =>
    people
      .map(item => ({
        ...item,
        fullName: getName(item, locale),
      }))
      .sort((a, b) => (a.fullName < b.fullName ? -1 : 1));

  const getPeopleByPrefix = (people, prefix) =>
    people.filter(({ prefix: p }) => prefix === p);

  const groupPeopleByPrefix = (people, prefixOrder) =>
    prefixOrder.map(prefix => getPeopleByPrefix(people, prefix));

  const orderByRank = (people, locale = 'en') => {
    const [headOfDept, others] = partition(people, o => o.id === RANK.HEAD);
    const [
      professors,
      asstProfessors,
      assocProfessors,
      lecturers,
    ] = groupPeopleByPrefix(others, [
      PREFIX_BY_ID.professor.id,
      PREFIX_BY_ID.assocProfessor.id,
      PREFIX_BY_ID.asstProfessor.id,
      PREFIX_BY_ID.lecturer.id,
    ]);
    const groups = [
      attachBadge(headOfDept, 'Head of Dept.'),
      professors,
      asstProfessors,
      assocProfessors,
      lecturers,
    ];
    return groups
      .map(group => sortByFullName(group, locale))
      .reduce((result, group) => [...result, ...group], []);
  };

  return {
    toApi: data => replaceUndefined(schema, data),
    getName,
    orderByRank,
  };
};

export const lecturerService = LecturerFactory();

export default LecturerFactory;
