import React from 'react';
import get from 'lodash/get';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';

const CoopProgram = () => {
  const dbAssets = useConsumer();
  return (
    <>
      <Text.Title level={3}>Co-operative Program</Text.Title>
      <Text.Paragraph fontSize={16} mb={24} color={'text.secondary'}>
        ภาคการศึกษาต้น - ปลาย
      </Text.Paragraph>
      <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
        กำหนดการฉบับย่อ
      </Text.Paragraph>
      <MonthlyTimeline
        events={[
          {
            title: '2 สัปดาห์ก่อนวันสอบนำเสนอ ',
            subtitle: 'ส่งรายงานนำเสนอโครงร่างหัวข้อโครงงาน ',
            list: ['จำนวน 3 ชุด (อาจารย์ที่ปรึกษาและกรรมการ 2 ท่าน)'],
            links: [
              {
                label: 'รูปแบบรายงาน',
                href: get(dbAssets, 'bachelorProject.preProjectReportTemplate'),
              },
            ],
          },
          {
            title: 'ภายในสิงหาคม',
            subtitle: 'สอบนำเสนอหัวข้อโครงงาน',
            list: [
              // eslint-disable-next-line max-len
              'นําเสนอ 15 นาที ถาม-ตอบ 10 นาที (กรุณารักษาเวลา)',
              'ใช้ Powerpoint Template ของคณะฯ',
              'เตรียมเอกสารประกอบการนําเสนอให้กรรมการทุกท่าน',
              'เตรียมแบบฟอร์มให้คะแนนพร้อมกรอกรายละเอียด',
            ],
            links: [
              {
                label: 'Powerpoint Template',
                href: get(
                  dbAssets,
                  'bachelorProject.preProjectPowerpointTemplate',
                ),
              },
              {
                label: 'แบบฟอร์มให้คะแนน',
                href: get(dbAssets, 'bachelorProject.preProjectAssessmentForm'),
              },
            ],
            current: isInRange([1, 8], [31, 8]),
          },
          {
            title: '2 สัปดาห์ก่อนวันสอบนำเสนอ ',
            subtitle: 'ส่งรายงานนำเสนอโครงงาน',
            list: ['จำนวน 3 ชุด (อาจารย์ที่ปรึกษาและกรรมการ 2 ท่าน)'],
            links: [
              {
                label: 'รูปแบบรายงาน',
                href: get(
                  dbAssets,
                  'bachelorProject.seniorProjectReportTemplate',
                ),
              },
            ],
          },
          {
            title: 'กลางธันวาคม',
            subtitle: 'สอบนำเสนอโครงงาน (Senior Project)',
            list: [
              // eslint-disable-next-line max-len
              'นําเสนอ 20 นาที ถาม-ตอบ 10 นาที (กรุณารักษาเวลา)',
              'ใช้ Powerpoint Template ของคณะฯ',
              'เตรียมเอกสารประกอบการนําเสนอให้กรรมการทุกท่าน',
              'เตรียมแบบฟอร์มให้คะแนนพร้อมกรอกรายละเอียด',
            ],
            links: [
              {
                label: 'Powerpoint Template',
                href: get(
                  dbAssets,
                  'bachelorProject.seniorProjectPowerpointTemplate',
                ),
              },
              {
                label: 'แบบฟอร์มให้คะแนน (อ.ที่ปรึกษา 1 ชุด)',
                href: get(dbAssets, 'bachelorProject.assessmentFormX1'),
              },
              {
                label: 'แบบฟอร์มให้คะแนน (กรรมการ+อ.ที่ปรึกษา 3 ชุด)',
                href: get(dbAssets, 'bachelorProject.assessmentFormX3'),
              },
            ],
            current: isInRange([14, 12], [18, 12]),
          },
          {
            title: 'หลังสอบนำเสนอ 1 สัปดาห์',
            subtitle: 'ส่งรายงานฉบับสมบูรณ์ และ ไฟล์ข้อมูลทั้งหมด',
            list: ['ติดต่ออาจารย์ผู้ดูแลโดยตรง'],
            current: isInRange([19, 12], [21, 12]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'Co-operative ฉบับเต็ม'}
        href={get(dbAssets, 'bachelorProject.coopDetail')}
      />
    </>
  );
};

CoopProgram.propTypes = {};
CoopProgram.defaultProps = {};

export default CoopProgram;
