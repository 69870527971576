import React from 'react';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import FirstSemester from './FirstSemester';
import SecondSemester from './SecondSemester';
import Summer from './Summer';

const menus = [
  {
    id: '1st-semester',
    label: '1st Semester',
  },
  {
    id: '2nd-semester',
    label: '2nd Semester',
  },
  {
    id: 'summer',
    label: 'Summer',
  },
];

const CurrentAC = () => (
  <NestedMenuTabsLayout
    menus={menus}
    headingId={'academic-calendar'}
    defaultSelectedKeys={['1st-semester']}
  >
    {([key]) => (
      <>
        {key === '1st-semester' && <FirstSemester />}
        {key === '2nd-semester' && <SecondSemester />}
        {key === 'summer' && <Summer />}
      </>
    )}
  </NestedMenuTabsLayout>
);

export default CurrentAC;
