import React from 'react';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ImageGallery from 'components/molecules/ImageGallery';
import useIntl from 'hooks/useIntl';

const MRT = () => {
  const { getMsg } = useIntl();
  return (
    <Box pb={24}>
      <Text.Title level={3}>{getMsg('mrt')}</Text.Title>
      <ImageGallery
        singlePhoto
        galleryMinHeight={300}
        galleryProps={{
          columns: 1,
        }}
        images={[
          // eslint-disable-next-line max-len
          'https://wp-assets.dotproperty-kh.com/wp-content/uploads/sites/14/2014/09/09085357/MRT-BTS-Map-Bangkok.jpg',
        ]}
      />
      <Text textStyle={'caption'}>{getMsg('clickToZoom')}</Text>
      <br />
      <Text.Paragraph fontSize={16}>{getMsg('mrtStep1')}</Text.Paragraph>
      <Text.Paragraph fontSize={16}>{getMsg('mrtStep2')}</Text.Paragraph>
      <Text.Paragraph fontSize={16}>{getMsg('mrtStep3')}</Text.Paragraph>
    </Box>
  );
};

MRT.propTypes = {};
MRT.defaultProps = {};

export default MRT;
