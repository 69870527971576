import React from 'react';
import { keyframes } from 'emotion';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import HrefButton from 'components/atoms/HrefButton';

const rotate = () =>
  keyframes({
    '0%': {
      transform: 'rotateY(0deg)',
    },
    '50%': {
      transform: 'rotateY(180deg)',
    },
    '100%': {
      transform: 'rotateY(360deg)',
    },
  });

const StyledTimeline = styled(Timeline)`
  margin-left: 16px;

  .ant-timeline-item-head-blue {
    color: #1890ff;
    border-color: #1890ff;
  }

  .ant-timeline-item-content {
    margin-left: 24px;

    ul {
      margin-top: -8px;
      padding-left: 24px;
      font-size: 16px;
      > li {
        margin-bottom: 0.5em;
      }
    }
  }

  .ant-timeline-item.-current {
    .ant-timeline-item-tail {
      border-left-style: dotted;
    }
  }

  .ant-timeline-item-head-custom {
    i {
      animation: ${rotate()} 3s infinite;
    }
  }

  h4 + ul {
    margin-top: 8px !important;
  }
`;

const MonthlyTimeline = ({ events, smallTitle }) => (
  <StyledTimeline>
    {events.map(({ title, subtitle = '', current, list, links }) => (
      <Timeline.Item
        key={`${title}-${subtitle.substring(0, 5)}`}
        className={current ? '-current' : ''}
        dot={
          current ? (
            <Icon
              fontSize={24}
              color={'primary.main'}
              type={'fas fa-map-marker-alt'}
            />
          ) : null
        }
      >
        {smallTitle ? (
          <Text.Body mb={4}>{title}</Text.Body>
        ) : (
          <Text.Title level={4} fontSize={17} mb={4}>
            {title}
          </Text.Title>
        )}
        {subtitle && <Text.Paragraph fontSize={16}>{subtitle}</Text.Paragraph>}
        {list && list.length > 0 && (
          <ul>
            {list.map(text => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        )}
        {links && links.length > 0 && (
          <Box mt={8} pb={16}>
            {links.map(({ label, href }) => (
              <HrefButton
                key={label}
                href={href}
                icon={'fal fa-file-download'}
                label={label}
              />
            ))}
          </Box>
        )}
      </Timeline.Item>
    ))}
  </StyledTimeline>
);

MonthlyTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  smallTitle: PropTypes.bool,
};
MonthlyTimeline.defaultProps = {
  events: [],
  smallTitle: false,
};

export default MonthlyTimeline;
