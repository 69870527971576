import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Skeleton } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import SmallPost from './SmallPost';

const Div = styled('div')`
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s;

  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.38);
    .SmallPost-root {
      transform: none;
      box-shadow: none;
    }
  }

  .SmallPost-root {
    border-radius: 0;
    flex: 1;
  }
`;

const BigPost = ({
  className,
  tags,
  createdAt,
  title,
  coverImage,
  loading,
  ...props
}) => (
  <Div className={cx('BigPost-root', className)}>
    <SmallPost
      {...props}
      loading={loading}
      tags={tags}
      coverImage={coverImage}
      title={coverImage ? '' : title}
      createdAt={createdAt}
    />
    {coverImage && (
      <Box px={16} py={8}>
        <Skeleton active loading={loading} paragraph={{ rows: 2 }}>
          <Text.Title level={4} fontWeight={600}>
            {title}
          </Text.Title>
        </Skeleton>
      </Box>
    )}
  </Div>
);

BigPost.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.shape({}).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  coverImage: PropTypes.string,
  loading: PropTypes.bool,
};
BigPost.defaultProps = {
  className: '',
  tags: [],
  coverImage: '',
  loading: false,
};

export default BigPost;
