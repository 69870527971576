import Color from 'color';
import createBreakpoints from './createBreakpoints';
import transitions from './transitions';

const grey = {
  50: '#fafafa',
  100: '#f7f7f7',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

export const breakpoints = createBreakpoints({});
const primary = {
  main: '#ac171c',
  dark: '#750000',
  light: '#e55044',
  rgb: '172, 23, 28',
  extraLight: Color('#e55044')
    .fade(0.8)
    .string(),
  contrastText: '#ffffff',
};
const secondary = {
  main: '#1890ff',
  light: '#6ec0ff',
  dark: '#0063cb',
  contrastText: '#ffffff',
};
const valid = {
  main: '',
};
const error = {
  main: '',
};
const text = {
  primary: 'rgba(0,0,0,0.85)',
  secondary: 'rgba(0,0,0,0.65)',
  disabled: 'rgba(0,0,0,0.45)',
  hint: 'rgba(0,0,0,0.45)',
  divider: 'rgba(0,0,0,0.25)',
  white: '#ffffff',
};
const white = {
  text: '#ffffff',
  primary: 'rgba(255,255,255,0.85)',
  secondary: 'rgba(255,255,255,0.65)',
  hint: 'rgba(255,255,255,0.45)',
  divider: 'rgba(255,255,255,0.25)',
};
const colors = {
  primary,
  secondary,
  valid,
  error,
  grey,
  text,
  white,
};

export default {
  ...colors,
  colors,
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: grey[800],
    default: '#303030',
  },
  // space is for overriding default styled-system
  space: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  spacing: 8,
  borderRadius: 4,
  breakpoints: {
    ...breakpoints,
    ...breakpoints.values,
  },
  createMediaQueries: breakpoints.createMediaQueries,
  transitions,
  zIndex: {},
  textStyles: {
    categoryHeading: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      color: text.primary,
    },
    cardHeading: {
      fontWeight: 'bold',
      fontSize: '1.125rem',
      color: text.secondary,
    },
    cardSubheading: {
      color: text.secondary,
      fontSize: '1rem',
    },
    copyright: {
      fontSize: 12,
      color: text.divider,
      textAlign: 'center',
      margin: 0,
    },
    signature: {
      color: text.divider,
      fontSize: 8,
      textAlign: 'center',
      margin: 0,
    },
    label: {
      padding: '0 0 8px',
      color: text.primary,
      fontWeight: 'bold',
    },
    tableLabel: {
      fontSize: 10,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#888888',
    },
    caption: {
      display: 'block !important',
      textAlign: 'center',
      color: grey[400],
    },
    overline: {
      display: 'block !important',
      fontWeight: 300,
      fontSize: 11,
      letterSpacing: 0.5,
      marginBottom: 4,
      textTransform: 'uppercase',
      color: 'rgba(0,0,0,0.85)',
    },
    update: {
      display: 'block !important',
      color: 'rgba(0,0,0,0.65)',
      fontStyle: 'italic',
      fontWeight: 500,
      letterSpacing: '1px',
    },
    bullet: {
      fontSize: 14,
      textTransform: 'uppercase',
      color: '#ac171c',
    },
  },
};
