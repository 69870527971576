import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import styled from '@emotion/styled';
import compose from 'helpers/compose';
import { withConsumer as withLecturer } from 'contexts/lecturer';
import { withConsumer as withRoom } from 'contexts/room';
import { withConsumer as withCourse } from 'contexts/course';
import { Checkbox, Modal, Button, Menu } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import CourseModal from 'components/organisms/CourseModal';
import Hidden from 'components/responsive/Hidden';
import Schedule from 'factories/Schedule';
import { SEMESTER } from 'constants/temporary';
import TimeTable from 'components/organisms/TimeTable';
import useSchedule from 'hooks/useSchedule';
import useIntl from 'hooks/useIntl';
import { useConsumer } from 'contexts/db';
import DownloadUI from 'components/atoms/DownloadUI';

const HorizontalCheckboxGroup = styled(Checkbox.Group)`
  display: block;
  .ant-checkbox-group-item {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      :after {
        width: 6.714286px;
        height: 11.142857px;
      }
    }
  }
`;

const SchedulePage = () => {
  const {
    semester,
    setSemester,
    courses,
    users,
    rooms,
    schedule,
    status,
  } = useSchedule(SEMESTER.SECOND);
  const dbAssets = useConsumer();
  const { getMsg, locale } = useIntl();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState({});
  const [modalData, setModalData] = useState(null);
  const [modalOpened, setModalOpened] = useState(null);
  const openModal = data => {
    setModalData(data);
    setModalOpened(true);
  };
  const closeModal = () => setModalOpened(false);

  const onSelectCourses = (_part, _day) => selectedCourses =>
    setSelectedSchedules({
      ...selectedSchedules,
      [_part]: {
        ...get(selectedSchedules, [_part], {}),
        [_day]: selectedCourses,
      },
    });

  const handledSelect = onSelect => (_part, _day) => item => {
    const selectedCourses = get(selectedSchedules, [_part, _day], []);
    if (isEmpty(selectedCourses)) return onSelect(_part, _day)([item]);
    if (
      selectedCourses.find(
        selectedSchedule => selectedSchedule.course.id === item.course.id,
      )
    ) {
      return onSelect(_part, _day)(
        selectedCourses.reduce((acc, selectedSchedule) => {
          if (selectedSchedule.course.id === item.course.id) return acc;
          return [...acc, selectedSchedule];
        }, []),
      );
    }
    return onSelect(_part, _day)([
      ...selectedCourses.reduce((acc, selectedSchedule) => {
        if (
          item.end <= selectedSchedule.start ||
          item.start >= selectedSchedule.end
        )
          return [...acc, selectedSchedule];
        return acc;
      }, []),
      item,
    ]);
  };

  const isAvailableCourse = (_part, _day) => item => {
    const selectedCourses = get(selectedSchedules, [_part, _day], []);
    if (isEmpty(selectedCourses)) return true;
    if (
      selectedCourses.find(
        selectedSchedule => selectedSchedule.course.id === item.course.id,
      )
    )
      return true;
    return selectedCourses.every(
      selectedSchedule =>
        item.end <= selectedSchedule.start ||
        item.start >= selectedSchedule.end,
    );
  };

  const getSelectedCourses = () => {
    let courseAmount = 0;
    Object.values(selectedSchedules).forEach(ele => {
      Object.values(ele).forEach(() => {
        courseAmount += 1;
      });
    });
    return courseAmount;
  };

  const getCredits = () => {
    let credits = 0;
    Object.values(selectedSchedules).forEach(ele => {
      Object.values(ele).forEach(day => {
        Schedule()
          .filterBy(day, filters)
          .forEach(item => {
            credits += get(item, ['course', 'credit'], 0);
          });
      });
    });
    return credits;
  };

  const onSelectSemester = e => {
    setSemester(e.key);
  };

  const options = Schedule().getOptions(locale);
  const parsedSchedule = Schedule(schedule.data).toObject({
    courses,
    rooms,
    users,
  });
  const getAllSchedule = data => {
    if (isArray(data)) return data;
    if (typeof data === 'object')
      return Object.values(data).reduce(
        (acc, subData) => [...getAllSchedule(subData), ...acc],
        [],
      );
    return [];
  };

  const otherSchedules = getAllSchedule(parsedSchedule).filter(
    ({ course, id }) =>
      course.code === get(modalData, 'course.code', '') &&
      get(modalData, 'id', '') !== id,
  );

  return (
    <Box py={{ xs: 16, sm: 32, md: 40 }} px={{ xs: 0, md: 24 }}>
      <CourseModal
        getMsg={getMsg}
        visible={modalOpened}
        data={modalData}
        course={get(modalData, 'course')}
        lecturers={get(modalData, 'lecturers')}
        otherSchedules={otherSchedules}
        onCancel={closeModal}
      />
      <Box display={'flex'} justifyContent={'flex-end'} p={16}>
        <DownloadUI
          label={get(dbAssets, 'admission.master.regularTimeTableLabel')}
          href={get(dbAssets, 'admission.master.regularTimeTable')}
          style={{ marginRight: 16 }}
        />
        <DownloadUI
          label={get(dbAssets, 'admission.master.executiveTimeTableLabel')}
          href={get(dbAssets, 'admission.master.executiveTimeTable')}
        />
      </Box>
      <Box mb={24} textAlign="center">
        <Menu
          style={{ background: 'none' }}
          onClick={onSelectSemester}
          selectedKeys={[semester]}
          mode="horizontal"
        >
          <Menu.Item key={SEMESTER.FIRST}>{getMsg('firstSemester')}</Menu.Item>
          <Menu.Item key={SEMESTER.SECOND}>
            {getMsg('secondSemester')}
          </Menu.Item>
        </Menu>
      </Box>
      <Hidden mdUp>
        <Box pb={16} px={16} {...Box.justifyBetween}>
          <Text.Title level={2} textStyle={'categoryHeading'} m={0}>
            {getMsg('schedule')}
          </Text.Title>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            {getMsg('filter')}
          </Button>
          <Modal
            title={getMsg('viewScheduleBy')}
            visible={showFilters}
            onCancel={() => setShowFilters(false)}
            footer={null}
          >
            <HorizontalCheckboxGroup
              options={options}
              value={filters}
              onChange={setFilters}
            />
            <Button
              style={{ marginTop: 16 }}
              type={'primary'}
              block
              onClick={() => {
                setFilters([]);
                setShowFilters(false);
              }}
            >
              {getMsg('clearFilter')}
            </Button>
          </Modal>
        </Box>
      </Hidden>
      <div style={{ position: 'relative' }}>
        <TimeTable
          getMsg={getMsg}
          loading={status.isRequest}
          getScheduleList={(...params) =>
            Schedule().filterBy(get(parsedSchedule, params, []), filters)
          }
          setItemProps={(item, _part, day) => {
            const selectedCourses = get(selectedSchedules, [_part, day], []);
            return {
              checked: Boolean(
                selectedCourses.find(
                  selectedSchedule =>
                    selectedSchedule.course.id === item.course.id,
                ),
              ),
              onClick: () => openModal(item),
              onSelect: handledSelect(onSelectCourses)(_part, day),
              disabled: !isAvailableCourse(_part, day)(item),
            };
          }}
        >
          <Hidden mdDown>
            <Box p={12} pt={8} bgColor="#f7f7f7" {...Box.justifyBetween}>
              <Box>
                <Text.Topic m={0} ml={{ xs: 0, md: 120 }}>
                  {getSelectedCourses()} {getMsg('amountCourses')} -{' '}
                  {getCredits()} {getMsg('amountCredits')}
                </Text.Topic>
              </Box>
              <Box>
                <Checkbox.Group
                  options={options}
                  value={filters}
                  onChange={setFilters}
                />
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box
              pl={{ xs: 12, sm: 16 }}
              top={0}
              position="absolute"
              height="calc(100% - 8px)"
              {...Box.alignCenter}
            >
              <Text.Body mb={0} fontWeight={'bold'}>
                {getSelectedCourses()} {getMsg('amountCourses')} -{' '}
                {getCredits()} {getMsg('amountCredits')}
              </Text.Body>
            </Box>
          </Hidden>
        </TimeTable>
      </div>
    </Box>
  );
};

SchedulePage.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  setCourses: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  setRooms: PropTypes.func.isRequired,
  lecturers: PropTypes.arrayOf(PropTypes.shape({})),
  setLecturers: PropTypes.func.isRequired,
};
SchedulePage.defaultProps = {
  courses: null,
  rooms: null,
  lecturers: null,
};

export default compose(
  withCourse,
  withLecturer,
  withRoom,
)(SchedulePage);
