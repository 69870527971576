import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Icon from './Icon';

const HrefButton = ({ children, label, icon, href, disabled, ...props }) => (
  <Button
    href={href}
    target={'_blank'}
    rel={'noopener'}
    type={'primary'}
    style={{
      marginRight: 16,
      marginTop: 8,
      minHeight: 40,
      lineHeight: '38px',
    }}
    shape={'round'}
    disabled={disabled || !href}
    {...props}
  >
    {icon && <Icon type={icon} fontSize={16} style={{ marginRight: 8 }} />}
    {label || children}
  </Button>
);

HrefButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};
HrefButton.defaultProps = {
  icon: '',
  href: undefined,
  children: null,
  disabled: false,
};

export default HrefButton;
