import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import { withIntl } from '../../contexts/intl';

const AlgoliaTag = ({ getMsg }) => (
  <Box {...Box.alignCenter} justifyContent={'flex-end'} mt={8}>
    <Text
      component={'a'}
      href={'https://www.algolia.com/'}
      target={'_blank'}
      fontSize={12}
      color={'grey.500'}
    >
      {getMsg('searchBy')} algolia
    </Text>
  </Box>
);

AlgoliaTag.propTypes = {
  getMsg: PropTypes.func.isRequired,
};

export default withIntl(AlgoliaTag);
