import React from 'react';
import PropTypes from 'prop-types';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import ROUTES from 'constants/routes';
import OurHistory from './OurHistory';
import Vision from './Vision';

const menus = [
  {
    id: 'our-history',
    label: 'Our History',
  },
  // {
  //   id: 'vision-and-message',
  //   label: 'Vision & Message',
  // },
];

const AboutIE = ({
  match: {
    params: { page },
  },
  history,
}) => (
  <NestedMenuTabsLayout
    menus={menus}
    headingId={'aboutIE'}
    defaultSelectedKeys={[page]}
    onMenuClick={item => {
      history.replace(ROUTES.aboutIE.get(`/${item.key}`));
    }}
  >
    {([key]) => (
      <>
        {key === 'our-history' && <OurHistory />}
        {key === 'vision-and-message' && <Vision />}
      </>
    )}
  </NestedMenuTabsLayout>
);

AboutIE.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
AboutIE.defaultProps = {};

export default AboutIE;
