import get from 'lodash/get';
import { USER_BY_TYPES } from 'constants/temporary';
import { lecturerService } from './Lecturer';
import { staffService } from './Staff';
import { researcherService } from './Researcher';

const UserFactory = user => {
  const SERVICES = {
    [USER_BY_TYPES.LECTURER]: lecturerService,
    [USER_BY_TYPES.STAFF]: staffService,
    [USER_BY_TYPES.RESEARCHER]: researcherService,
  };
  const service = SERVICES[get(user, ['type'], '')] || {};
  const createFilterOption = (users, locale = 'en') => (value, option) => {
    const item = users.find(({ id }) => id === option.key);
    return `${item.code}_${item.name[locale]}`
      .toLowerCase()
      .includes(value.toLowerCase());
  };
  return {
    createFilterOption,
    toApi: data => {
      if (!SERVICES[data.type]) {
        throw new Error(`Service "${data.type}" is undefined!`);
      }
      if (!SERVICES[data.type].toApi) {
        throw new Error(`Service "${data.type}" has no method "toApi"!`);
      }
      return SERVICES[data.type].toApi(data);
    },
    getName: service.getName,
  };
};

export const userService = UserFactory();

export default UserFactory;
