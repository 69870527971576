import React from 'react';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';
import get from 'lodash/get';

const SecondSemester = () => {
  const dbAssets = useConsumer();
  return (
    <>
      <Text.Title level={3}>ภาคการศึกษาปลาย</Text.Title>
      <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
        ปฏิทินฉบับย่อ
      </Text.Paragraph>
      <MonthlyTimeline
        events={[
          {
            title: 'มกราคม',
            subtitle: 'วันเปิดเรียนภาคการศึกษาปลาย',
            current: isInRange([1, 1], [28, 2]),
          },
          {
            title: 'ต้นมีนาคม',
            subtitle: 'สอบกลางภาค (ถ้ามี)',
            current: isInRange([1, 3], [10, 3]),
          },
          {
            title: 'ต้นเมษายน',
            subtitle: 'ปิดกิจกรรม',
            current: isInRange([4, 4], [10, 4]),
          },
          {
            title: 'ต้นพฤษภาคม',
            subtitle: 'วันสุดท้ายของการเรียน',
            current: isInRange([27, 4], [1, 5]),
          },
          {
            title: 'กลางพฤษภาคม',
            subtitle: 'สอบปลายภาค',
            current: isInRange([2, 5], [20, 5]),
          },
          {
            title: 'ปลายพฤษภาคม',
            subtitle: 'วันปิดเรียนภาคการศึกษาปลาย',
            current: isInRange([21, 5], [31, 5]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'ปฏิทินฉบับเต็ม'}
        href={get(dbAssets, 'calendar.academicYear')}
      />
      <br />
      <br />
      <br />
    </>
  );
};

SecondSemester.propTypes = {};
SecondSemester.defaultProps = {};

export default SecondSemester;
