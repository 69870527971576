import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styled from '@emotion/styled';
import { fontSize, color, lineHeight } from 'styled-system';
import { Icon as AntIcon } from 'antd';

const Icon = styled(({ className, type, antProps, ...props }) => {
  if (type && type.includes('fa-')) {
    return <i className={cx('Icon-root', type, className)} {...props} />;
  }
  return (
    <AntIcon
      className={cx('Icon-root', className)}
      type={type}
      {...antProps}
      {...props}
    />
  );
})`
  ${fontSize}
  ${color}
  ${lineHeight}
`;

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  antProps: PropTypes.shape({}),
};
Icon.defaultProps = {
  antProps: {},
};

export default Icon;
