import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import useScrollToElement from 'hooks/useScrollToElement';
import Query from 'factories/query';
import { useConsumer } from 'contexts/db';

const menus = [
  {
    id: 'preparing-proposal',
  },
  {
    id: 'submitting-proposal',
  },
  {
    id: 'preparing-defense',
  },
  {
    id: 'submitting-thesis',
  },
];

const rootCss = css`
  .MenuTabs-tabs1 {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    z-index: 10;
  }
`;

const FirstSemester = ({ location, history }) => {
  const dbAssets = useConsumer();
  const query = Query().toObject(location.search);
  useScrollToElement(query.topic, { offset: -56 });
  return (
    <NestedMenuTabsLayout
      className={rootCss}
      menus={menus}
      headingId={'thesis-process'}
      defaultSelectedKeys={[query.topic || menus[0].id]}
      onMenuClick={({ key }) =>
        history.replace(`${location.pathname}?topic=${key}`)
      }
    >
      {() => (
        <>
          <Text.Title level={3}>ขั้นตอนการทำวิทยานิพนธ์</Text.Title>
          <Text.Paragraph fontSize={16} color={'text.primary'}>
            นิสิตที่จะสำเร็จการศึกษา ต้องมีรายวิชาวิทยานิพนธ์ที่ได้ S
            ครบตามจำนวน 12 หน่วยกิต
          </Text.Paragraph>
          <Element name={'preparing-proposal'}>
            <Text.Topic>ก่อนสอบโครงร่างวิทยานิพนธ์</Text.Topic>
          </Element>
          <br />
          <MonthlyTimeline
            smallTitle
            events={[
              {
                title: 'หาหัวข้อวิทยานิพนธ์และอาจารย์ที่ปรึกษา',
              },
              {
                title: 'พิมพ์โครงร่างวิทยานิพนธ์ใน CU i-thesis',
              },
              {
                title: 'จัดทำเอกสารแต่งตั้งประธาน ยื่นให้กับ จนท.ภาควิชา',
              },
              {
                title: 'จองห้องสอบโครงร่างวิทยานิพนธ์ในระบบ IE UP',
                links: [
                  {
                    label: 'เข้าสู่ระบบ IE UP',
                    href: 'http://ie.eng.chula.ac.th/~ieup/',
                  },
                ],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'ส่งรูปเล่มโครงร่างวิทยานิพนธ์พร้อมแนบเอกสารเข้าฟังให้ จนท.ภาควิชา',
              },
              {
                title: 'ส่งรูปเล่มโครงร่างวิทยานิพนธ์ให้คณะกรรมการภายนอก',
              },
              {
                title: 'สอบโครงร่างวิทยานิพนธ์!',
              },
            ]}
          />
          <Element name={'submitting-proposal'}>
            <Text.Topic>หลังสอบโครงร่างวิทยานิพนธ์</Text.Topic>
          </Element>
          <br />
          <MonthlyTimeline
            smallTitle
            events={[
              {
                title: 'แก้ไขโครงร่างวิทยานิพนธ์',
              },
              {
                title: 'นำใบบวศ1.ก-ง.พร้อมลายเซ็น ส่งให้ จนท. ภาควิชา',
              },
              {
                title: 'แก้โครงร่างฯใน CU e-thesis กด save as proposal',
              },
            ]}
          />
          <Element name={'preparing-defense'}>
            <Text.Topic>ก่อนสอบวิทยานิพนธ์</Text.Topic>
          </Element>
          <br />
          <MonthlyTimeline
            smallTitle
            events={[
              {
                title:
                  // eslint-disable-next-line max-len
                  'พิมพ์วิทยานิพนธ์ฉบับร่างใน CU e-thesis กด save as draft thesis',
              },
              {
                title: 'แจ้งอาจารย์ที่ปรึกษาและนัดวันสอบกับกรรมการ',
              },
              {
                title: 'จองห้องสอบวิทยานิพนธ์ในระบบ IE UP',
                links: [
                  {
                    label: 'เข้าสู่ระบบ IE UP',
                    href: 'http://ie.eng.chula.ac.th/~ieup/',
                  },
                ],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'ส่งรูปเล่มวิทยานิพนธ์พร้อมแนบใบ บ.2, บ.3, เอกสารเข้าฟัง, ใบจองห้องสอบ และใบตอบรับการตีพิมพ์ ให้จนท.ภาควิชา',
                links: [
                  {
                    label: 'โหลดแบบฟอร์ม',
                    href: '/forms?category=master',
                  },
                ],
              },
              {
                title: 'รับใบ บ.2 จาก จนท.ภาควิชา ไปส่งทะเบียนคณะ',
              },
              {
                title: 'ทะเบียนคณะนัดรับใบสอบและ จดหมายเชิญ กรรมการภายนอก',
              },
              {
                title: 'ส่งจดหมายเชิญพร้อมเล่มวิทยานิพนธ์ให้กรรมการภายนอก',
              },
              {
                title: 'สอบวิทยานิพนธ์!',
              },
            ]}
          />
          <Element name={'submitting-thesis'}>
            <Text.Topic>หลังสอบวิทยานิพนธ์</Text.Topic>
          </Element>
          <br />
          <MonthlyTimeline
            smallTitle
            events={[
              {
                title: 'แก้ไขเล่มวิทยานิพนธ์',
              },
              {
                title: 'กรอกเอกสารเผยแพร่พร้อมแนบบทความให้ จนท.ภาควิชา',
              },
              {
                title: 'จองห้องสอบวิทยานิพนธ์ในระบบ IE UP',
                links: [
                  {
                    label: 'เข้าสู่ระบบ IE UP',
                    href: 'http://ie.eng.chula.ac.th/~ieup/',
                  },
                ],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'พิมพ์วิทยานิพนธ์ฉบับสมบรูณ์ ใน CU e-thesis กด save as complete thesis',
              },
              {
                title: 'ตรวจสอบ plagiarism (Turnitin)',
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'ขอแบบใบบ.4 จากจนท.ภาควิชา โดยต้องส่งเอกสารแนบเพื่อที่จะออกเลขเอกสารและให้หัวหน้าภาคลงนาม',
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'นำเล่มวิทยานิพนธ์ที่มีบาร์โค้ดและมีลายเซ็นครบ พร้อมใบ บ.4 ให้คณะทะเบียน',
              },
              {
                title: 'รับคืนเล่มวิทยานิพนธ์ฉบับสมบูรณ์ท่ีมีลายเซ็นคณบดี',
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'ส่งเล่มวิทยานิพนธ์ฉบับสมบูรณ์ แนบ Submission Document ที่บัณฑิตวิทยาลัยก่อนกำหนด',
              },
            ]}
          />
          <Text>
            *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย
            หากต้องการวันที่ตามปฏิทิน โปรดดาวน์โหลดฉบับเต็ม
          </Text>
          <br />
          <br />
          <DownloadUI
            label={'ขั้นตอนการทำวิทยานิพนธ์ฉบับเต็ม'}
            href={get(dbAssets, 'thesis.processDetail')}
          />
          <br />
          <br />
          <DownloadUI
            label={'คู่มือการเข้าระบบ IE UP'}
            href={get(dbAssets, 'thesis.ieupAccessManual')}
          />
          <br />
          <br />
          <DownloadUI
            label={'คู่มือการจ่ายเงินและลงทะเบียนระบบ IE UP'}
            href={get(dbAssets, 'thesis.ieupRegistrationManual')}
          />
          <br />
          <br />
        </>
      )}
    </NestedMenuTabsLayout>
  );
};

FirstSemester.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
FirstSemester.defaultProps = {};

export default withRouter(FirstSemester);
