import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Box from 'components/atoms/Box';
import ScheduleItem from 'components/molecules/ScheduleItem';
import TimeBackground from './TimeBackground';

const CourseCol = styled(Col)`
  margin-bottom: 8px;
`;

const TimeTablePart = ({
  color,
  scheduleList,
  size,
  start,
  end,
  setItemProps,
  hiddenEndLine,
  rootProps,
}) => (
  <Box position="relative" height="100%" py={32} {...rootProps}>
    <Box position="relative" zIndex={1}>
      <Row prefixCls={size === 10 ? 'iecu-row' : undefined}>
        {sortBy(scheduleList, 'course.code').map((item, index) => (
          <CourseCol
            key={`schedule-${item.id}`}
            prefixCls={size === 10 ? 'iecu-col' : undefined}
            span={
              (((item.end || end) - (item.start || start)) / (end - start)) *
              size
            }
            offset={
              item.start ? ((item.start - start) / (end - start)) * size : 0
            }
          >
            <ScheduleItem
              color={color}
              schedule={item}
              {...setItemProps(item, index, scheduleList)}
            />
          </CourseCol>
        ))}
      </Row>
    </Box>
    <Box
      position="absolute"
      height="100%"
      width="100%"
      top={0}
      left={0}
      zIndex={0}
    >
      <TimeBackground
        start={start}
        end={end}
        size={size}
        hiddenEndLine={hiddenEndLine}
      />
    </Box>
  </Box>
);

TimeTablePart.propTypes = {
  color: PropTypes.string,
  scheduleList: PropTypes.arrayOf(PropTypes.shape({})),
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  size: PropTypes.oneOf([10, 24]),
  setItemProps: PropTypes.func,
  hiddenEndLine: PropTypes.bool,
  rootProps: PropTypes.shape({}),
};
TimeTablePart.defaultProps = {
  color: '#000000',
  size: 24,
  scheduleList: [],
  setItemProps: () => {},
  hiddenEndLine: false,
  rootProps: {},
};

export default TimeTablePart;
