import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import get from 'lodash/get';
import Paper from 'components/atoms/Paper';
import Box from 'components/atoms/Box';
import { Radio, Row, Col, Popover } from 'antd';
import Text from 'components/atoms/Text';
import AvatarGroup from 'components/atoms/AvatarGroup';

const BigRadio = styled(Radio)`
  &.ant-radio-wrapper {
    font-size: 16px;
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      :after {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

const StyledPaper = styled(Paper)`
  cursor: pointer;
  border-top: 2px solid
    ${({ theme, color, disabled }) => (disabled ? theme.grey[300] : color)};
`;

const ScheduleItem = ({
  color,
  schedule,
  schedule: { course, lecturers, room, section },
  checked,
  onSelect,
  onClick,
  disabled,
  ...props
}) => (
  <StyledPaper
    pad={'none'}
    shape={'square'}
    color={color}
    disabled={disabled}
    onClick={() => onClick(schedule)}
    {...props}
  >
    <Box
      pt={4}
      pb={8}
      px={{
        xs: 4,
        sm: 8,
      }}
      opacity={disabled ? 0.4 : 1}
    >
      <Row type={'flex'} style={{ flexWrap: 'nowrap' }}>
        <Col>
          {onSelect && (
            <Box
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onSelect(schedule);
              }}
            >
              <BigRadio size={'large'} checked={checked} />
            </Box>
          )}
        </Col>
        <Col style={{ flex: 'auto' }}>
          <Box>
            <Text
              fontSize={{ xs: 12, sm: 14 }}
              mt={2}
              mr={8}
              color={'primary.main'}
              fontWeight={'bold'}
            >
              {course.code}
            </Text>
            <Text
              fontSize={{ xs: 12, sm: 14 }}
              fontWeight={'bold'}
              mt={2}
              mr={8}
            >
              {course.name}
            </Text>
          </Box>
          <Box display={'flex'} flexWrap={'wrap'}>
            {section || room ? (
              <Text
                fontSize={{ xs: 12, sm: 14 }}
                color={'grey.500'}
                mt={2}
                mr={'auto'}
              >
                {section ? `${section}, ` : ''} {get(room, 'name', '')}
              </Text>
            ) : (
              <Text mr={'auto'} />
            )}
            <AvatarGroup users={lecturers} mt={4} ml={4} />
          </Box>
        </Col>
      </Row>
    </Box>
  </StyledPaper>
);

ScheduleItem.propTypes = {
  color: PropTypes.string.isRequired,
  schedule: PropTypes.shape({}).isRequired,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
ScheduleItem.defaultProps = {
  checked: false,
  disabled: false,
  onClick: () => {},
  onSelect: null,
};

const ScheduleItemPopover = ({ popover, ...props }) =>
  popover ? (
    <Popover {...popover}>
      <ScheduleItem {...props} />
    </Popover>
  ) : (
    <ScheduleItem {...props} />
  );

ScheduleItemPopover.propTypes = {
  popover: PropTypes.shape({}),
};
ScheduleItemPopover.defaultProps = {
  popover: undefined,
};

export default ScheduleItemPopover;
