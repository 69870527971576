import React from 'react';
import PropTypes from 'prop-types';
import some from 'lodash/some';
import matchesProperty from 'lodash/matchesProperty';
import { css } from 'emotion';
import { withRouter } from 'react-router-dom';
import useIntl from 'hooks/useIntl';
import useMenus from 'hooks/useMenus';
import { Tabs } from 'antd';
import Container from 'components/layouts/Container';
import Seperator from 'components/layouts/Seperator';
import MenuList from 'components/molecules/MenuList';
import Hidden from 'components/responsive/Hidden';
import Box from 'components/atoms/Box';

const styles = css`
  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .MenuTabs-tabs1 + .MenuTabs-tabs2 {
    padding-top: 8px;
  }

  .MenuTabs-tabs2 {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    z-index: 10;
  }
`;

const NestedMenuTabsLayout = ({
  headingId,
  children,
  menus,
  neglectedKeys,
  defaultSelectedKeys,
  defaultOpenKeys,
  hideSecondTabs,
  onMenuClick,
  className,
}) => {
  const { getMsg } = useIntl();
  const {
    selectedKeys,
    lastSelectedKey,
    setFirstKey,
    setSecondKey,
    setSelectedKeys,
    openKeys,
    toggleOpenKey,
  } = useMenus(defaultSelectedKeys, defaultOpenKeys);
  const getSecondMenus = firstKey => {
    if (!firstKey) return firstKey;
    const menu = menus.find(matchesProperty('id', firstKey));
    return menu ? menu.subMenus : [];
  };
  const onChangeFirstKey = firstKey => {
    const secondMenus = getSecondMenus(firstKey);
    if (secondMenus) {
      if (!some(secondMenus, matchesProperty('id', selectedKeys[1]))) {
        return setSelectedKeys([
          firstKey,
          secondMenus[0].id,
          ...selectedKeys.slice(2),
        ]);
      }
    }
    return setFirstKey(firstKey);
  };
  // get second menus for tabs
  const secondMenus = getSecondMenus(selectedKeys[0]);
  const onTabToggleKey = key => {
    if (neglectedKeys.indexOf(key) === -1) {
      toggleOpenKey(key);
    }
  };
  return (
    <Container className={`${styles} ${className}`} py={'0!important'}>
      <Hidden smDown>
        <Seperator heading={getMsg(headingId)}>
          {() => [
            <MenuList
              menus={menus}
              openKeys={openKeys}
              selectedKeys={[lastSelectedKey]}
              onClick={e => {
                setSelectedKeys(e.keyPath.reverse());
                onMenuClick(e);
              }}
              onTitleClick={e => toggleOpenKey(e.key)}
            />,
            <Box
              py={{ xs: 16, sm: 36 }}
              px={{ xs: 16, sm: 36 }}
              height={'100%'}
            >
              {children(selectedKeys)}
            </Box>,
          ]}
        </Seperator>
      </Hidden>
      <Hidden smUp>
        <Box mx={-16} py={{ xs: 24, sm: 48 }}>
          <Tabs
            className={'MenuTabs-tabs1'}
            activeKey={selectedKeys[0]}
            onChange={key => {
              onChangeFirstKey(key);
              onTabToggleKey(key);
              onMenuClick({ key, keyPath: [key], isTabs1: true });
            }}
          >
            {menus.map(({ id, translationId, label }) => (
              <Tabs.TabPane
                tab={getMsg(translationId || id) || label}
                key={id}
              />
            ))}
          </Tabs>
          {!hideSecondTabs(selectedKeys) && secondMenus && (
            <Tabs
              className={'MenuTabs-tabs2'}
              type={'card'}
              activeKey={selectedKeys[1]}
              onChange={key => {
                setSecondKey(key);
                onTabToggleKey(key);
                onMenuClick({
                  key,
                  keyPath: [selectedKeys[0], key],
                  isTabs2: true,
                });
              }}
            >
              {secondMenus.map(({ id, translationId, label }) => (
                <Tabs.TabPane
                  tab={getMsg(translationId || id) || label}
                  key={id}
                />
              ))}
            </Tabs>
          )}
          <Box p={16} bgColor={'#fff'} minHeight={400}>
            {children(selectedKeys)}
          </Box>
        </Box>
      </Hidden>
    </Container>
  );
};

NestedMenuTabsLayout.propTypes = {
  className: PropTypes.string,
  headingId: PropTypes.string.isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.func.isRequired,
  menus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  neglectedKeys: PropTypes.arrayOf(PropTypes.string),
  onMenuClick: PropTypes.func,
  hideSecondTabs: PropTypes.func,
};
NestedMenuTabsLayout.defaultProps = {
  className: '',
  neglectedKeys: [],
  onMenuClick: () => {},
  hideSecondTabs: () => false,
};

export default withRouter(NestedMenuTabsLayout);
