import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import Box from 'components/atoms/Box';
import Hidden from 'components/responsive/Hidden';
import FullTimeTable from './FullTimeTable';
import MiniTimeTable from './MiniTimeTable';

const TimeTable = ({ loading, ...props }) => (
  <Box pb={{ md: 16, xs: 8 }} bgColor="#ffffff">
    {loading ? (
      <Box p={16}>
        <Skeleton active />
        <br />
        <br />
        <Skeleton active />
        <br />
        <br />
        <Skeleton active />
        <br />
        <br />
        <Skeleton active />
        <br />
        <br />
        <Skeleton active />
      </Box>
    ) : (
      <>
        <Hidden mdDown>
          {/* show when screen is wider than vertical tablet */}
          <FullTimeTable {...props} />
        </Hidden>
        <Hidden mdUp>
          {/* show when screen is vertical tablet or below */}
          <MiniTimeTable {...props} />
        </Hidden>
      </>
    )}
  </Box>
);

TimeTable.propTypes = {
  loading: PropTypes.bool,
};
TimeTable.defaultProps = {
  loading: false,
};

export default TimeTable;
