import firebase from 'firebase/app';
import 'firebase/firestore';
import FireFactory from './factory';

/**
 * STAGE will define which firebase-project to use.
 */
const STAGE = process.env.REACT_APP_STAGE;

const config = {
  development: {
    apiKey: 'AIzaSyD-IZJXe6ni1KgUvU0b50hePgDOuYJScF0',
    authDomain: 'staging-ie-chula.firebaseapp.com',
    projectId: 'staging-ie-chula',
    storageBucket: 'staging-ie-chula.appspot.com',
  },
  staging: {
    apiKey: 'AIzaSyD-IZJXe6ni1KgUvU0b50hePgDOuYJScF0',
    authDomain: 'staging-ie-chula.firebaseapp.com',
    projectId: 'staging-ie-chula',
    storageBucket: 'staging-ie-chula.appspot.com',
  },
  production: {
    apiKey: 'AIzaSyDOMhfmMkUOs9ysFMVkzvHAD2IbdU6y_r0',
    authDomain: 'prod-ie-chula.firebaseapp.com',
    projectId: 'prod-ie-chula',
    storageBucket: 'prod-ie-chula.appspot.com',
  },
};

const firebaseApp = firebase.initializeApp(config[STAGE] || config.development);
const fireService = FireFactory({ app: firebaseApp });
// eslint-disable-next-line import/prefer-default-export
export const fireStoreService = fireService.fireStore;
