import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Box';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import Query from 'factories/query/Query';
import useIntl from 'hooks/useIntl';
import ROUTES from 'constants/routes';
import Hidden from 'components/responsive/Hidden';
import BottomNav from 'components/organisms/BottomNav';
import AdmissionBachelor from './AdmissionBachelor';
import AdmissionMaster from './AdmissionMaster';
import AdmissionSasin from './AdmissionSasin';
import AdmissionDoctorate from './AdmissionDoctorate';

const masterSubMenus = [
  {
    id: 'm-eng',
    label: 'M.Eng (IE)',
    subMenus: [
      {
        id: 'm-program',
        translationId: 'program',
        label: 'Program',
      },
      {
        id: 'm-how-to-apply',
        translationId: 'how-to-apply',
        label: 'How to apply',
      },
      {
        id: 'm-fees',
        translationId: 'fees',
        label: 'Fees & Funding',
      },
    ],
  },
  {
    id: 'sce',
    label: 'SCE (Sasin Chula Engineering)',
    subMenus: [
      {
        id: 'sce-program',
        translationId: 'program',
        label: 'Program',
      },
      {
        id: 'sce-how-to-apply',
        translationId: 'how-to-apply',
        label: 'How to apply',
      },
      {
        id: 'sce-fees',
        translationId: 'fees',
        label: 'Fees & Funding',
      },
    ],
  },
];

const menus = [
  {
    id: 'bachelor',
    label: 'Bachelor degree',
    subMenus: [
      {
        id: 'b-program',
        translationId: 'program',
        label: 'Program',
      },
      {
        id: 'b-how-to-apply',
        translationId: 'how-to-apply',
        label: 'How to apply',
      },
      {
        id: 'b-fees',
        translationId: 'fees',
        label: 'Fees & Funding',
      },
    ],
  },
  {
    id: 'master',
    label: 'Master degree',
    nested: true,
    subMenus: masterSubMenus,
  },
  {
    id: 'doctorate',
    label: 'Doctorate degree',
    subMenus: [
      {
        id: 'd-program',
        translationId: 'program',
        label: 'Program',
      },
      {
        id: 'd-how-to-apply',
        translationId: 'how-to-apply',
        label: 'How to apply',
      },
      {
        id: 'd-fees',
        translationId: 'fees',
        label: 'Fees & Funding',
      },
    ],
  },
];

const ICONS = {
  program: 'far fa-diploma',
  'how-to-apply': 'far fa-pencil',
  fees: 'far fa-dollar-sign',
};

const Admission = ({
  history,
  match: {
    params: { degree, type },
  },
  location,
}) => {
  const query = Query().toObject(location.search);
  const { topic } = query;
  const { getMsg } = useIntl();
  const renderBottomNav = items =>
    ReactDOM.createPortal(
      <Hidden smUp>
        <BottomNav
          activeId={topic}
          menus={items.map(item => {
            const { translationId } = item;
            return {
              ...item,
              icon: ICONS[translationId],
              label: getMsg(translationId),
            };
          })}
          onClick={id => history.replace(`${location.pathname}?topic=${id}`)}
        />
      </Hidden>,
      document.getElementById('root'),
    );
  return (
    <NestedMenuTabsLayout
      headingId={'programs'}
      menus={menus}
      defaultSelectedKeys={[degree, type, topic]}
      defaultOpenKeys={[degree, type, topic]}
      hideSecondTabs={([firstKey]) => firstKey !== 'master'}
      onMenuClick={({ keyPath, isTabs1, isTabs2 }) => {
        const firstLevel = menus.find(({ id }) => id === keyPath[0]);
        if (isTabs1) {
          // 1st level tabs in mobile clicked
          if (firstLevel.nested) {
            history.replace(
              ROUTES.admission.get(
                `/${keyPath[0]}/${firstLevel.subMenus[0].id}?topic=${
                  firstLevel.subMenus[0].subMenus[0].id
                }`,
              ),
            );
          }
          return history.replace(
            ROUTES.admission.get(
              `/${keyPath[0]}?topic=${get(firstLevel, 'subMenus[0].id')}`,
            ),
          );
        }

        if (isTabs2) {
          // 2nd level tabs in mobile clicked
          const secondLevel = firstLevel.subMenus.find(
            ({ id }) => id === keyPath[1],
          );
          return history.replace(
            ROUTES.admission.get(
              `/${keyPath[0]}/${keyPath[1]}?topic=${get(
                secondLevel,
                'subMenus[0].id',
              )}`,
            ),
          );
        }

        // menuList in desktop
        if (keyPath.length > 2) {
          return history.replace(
            ROUTES.admission.get(
              `/${keyPath[0]}/${keyPath[1]}?topic=${keyPath[2]}`,
            ),
          );
        }

        return history.replace(
          ROUTES.admission.get(
            `/${keyPath[0]}${keyPath[1] ? `?topic=${keyPath[1]}` : ''}`,
          ),
        );
      }}
    >
      {([key, secondKey]) => {
        const items =
          key === 'master'
            ? masterSubMenus.find(({ id }) => id === secondKey).subMenus
            : menus.find(({ id }) => id === key).subMenus;
        return (
          <>
            {key === 'bachelor' && <AdmissionBachelor scrollValue={topic} />}
            {key === 'master' && (
              <>
                {secondKey === 'm-eng' && (
                  <AdmissionMaster scrollValue={topic} />
                )}
                {secondKey === 'sce' && <AdmissionSasin scrollValue={topic} />}
              </>
            )}
            {key.includes('doctorate') && (
              <AdmissionDoctorate scrollValue={topic} />
            )}
            {renderBottomNav(items)}
            <Hidden only={'xs'}>
              <Box minHeight={320} />
            </Hidden>
          </>
        );
      }}
    </NestedMenuTabsLayout>
  );
};

Admission.propTypes = {
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
Admission.defaultProps = {};

export default Admission;
