import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import CustomCard from 'components/molecules/CustomCard';
import Actions from 'components/molecules/Actions';

const StaffCard = ({ avatarUrl, title, job, contact, loading, CardProps }) => {
  const mail = contact ? contact.email : '';
  const phone = contact ? contact.phone : '';
  return (
    <CustomCard
      {...CardProps}
      image={avatarUrl}
      loading={loading}
      actions={
        loading
          ? null
          : [
              <Tooltip title={mail}>
                <Actions.Item
                  href={`mailto:${mail}`}
                  icon={'mail'}
                  label={mail ? 'email' : '-'}
                  disabled={!mail}
                  external
                />
              </Tooltip>,
              <Actions.Item
                href={`tel:${phone}`}
                icon={'phone'}
                label={phone || '-'}
                external
                disabled={!phone}
              />,
            ]
      }
    >
      <CustomCard.Meta title={title} description={job} />
    </CustomCard>
  );
};

StaffCard.propTypes = {
  avatarUrl: PropTypes.string,
  title: PropTypes.string,
  job: PropTypes.string,
  contact: PropTypes.shape({}),
  loading: PropTypes.bool,
  CardProps: PropTypes.shape({}),
};
StaffCard.defaultProps = {
  avatarUrl: '',
  title: '',
  job: '',
  contact: {},
  loading: false,
  CardProps: undefined,
};

export default StaffCard;
