/* eslint-disable max-len */
import keyBy from 'lodash/keyBy';

// --------------------------------------------------------------------------------//
// แสดงที่แถบ เกี่ยวกับเรา----->โครงสร้างองค์กร----->หัวหน้าภาควิชาและรอง,กรรมการ,หัวหน้าธุรการ  //
// --------------------------------------------------------------------------------//
export const RANK = {
  HEAD: '0iZTWPWMAYod3BbsYmKD', // ดึงข้อมูลจาก firebase
  CHIEFS: [
    //    รองหัวหน้าภาค
    '2tRYPZMdlsWFiURxBdC9', // ศ. ดร.สมเกียรติ ตั้งจิตสิตเจริญ
    'K14thUQ7fYsIuhTOjaFs', // ผศ. ดร.โอฬาร กิตติธีรพรชัย
    'fvxjN0ykqSGH71YaMPjh', // ผศ. หฤทัย โลหะศิริวัฒน์
    '9maBFjohH9H11PeAHJhc', // ผศ. ดร.อริศรา เจียมสงวนวงศ์
    'SQfhCsSyc7n1RP5eNUAO', // ผศ. ดร.มานิดา สว่างเนตร นอยแบร์ท
  ],
  COMMITTEES: [
    'trs4iu7cbVchFQWfVbaa', // รศ. ดร.วิภาวี ธรรมาภรณ์พิลาศ
    'edKtC4lJP8u3Rqvt8fXC', // ผศ. ดร.นระเกณฑ์ พุ่มชูศรี
    'cMFMMo2YdEHFYYNA1ccO', // ผศ. ดร.นภัสสวงศ์ โอสถศิลป์
    '54SBOSU5ssC7VSXdH3gY', // ผศ. ภูมิ เหลืองจามีกร
    '6jP6OdRzH6efcT2iiphs', // Asst.Prof Pisit Jarumaneeroj Ph.D.
  ],
};
// --------------------------------------------------------------------------------//
// --------------------------------------------------------------------------------//

export const PREFIXES = [
  { id: 'professor', label: { en: 'Prof', th: 'ศ.' } },
  { id: 'asstProfessor', label: { en: 'Asst.Prof', th: 'ผศ.' } },
  { id: 'assocProfessor', label: { en: 'Assoc.Prof', th: 'รศ.' } },
  { id: 'lecturer', label: { en: 'Lect.', th: 'อ.' } },
];

export const PREFIX_BY_ID = keyBy(PREFIXES, 'id');

export const SUFFIXES = [
  { id: 'none', label: { en: '', th: '' } },
  { id: 'phd', label: { en: 'Ph.D.', th: 'ดร.' } },
  { id: 'dEng', label: { en: 'D.Eng', th: 'ดร.' } },
];

export const SUFFIX_BY_ID = keyBy(SUFFIXES, 'id');

const COURSE_ICON_PATH =
  'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/course_icons';
export const CATEGORIES = [
  {
    id: 'svIUwb2Sr0KOVp6mdPf6',
    // name: 'Economic Decision Analysis',
    name: 'Econ Decision Analysis',
    icon: `${COURSE_ICON_PATH}/Econ.png`,
    translation: {
      en: 'Economic Decision Analysis',
      th: 'การวิเคราะห์การตัดสินใจด้านเศรษฐกิจ',
    },
  },
  {
    id: '5lqults0BtbONyxccoMf',
    name: 'Human Factors & Ergonomics',
    icon: `${COURSE_ICON_PATH}/Ergo.png`,
    translation: {
      en: 'Human Factors & Ergonomics',
      th: 'การยศาสตร์และปัจจัยมนุษย์',
    },
  },
  {
    // will be removed soon
    id: 'B2e3WUVG1IH074N19q00',
    filterHidden: true,
    // name: 'Risk & Information System Management',
    name: 'Info System and Risk mgmt',
    icon: `${COURSE_ICON_PATH}/Risk.png`,
    translation: {
      en: 'Info System and Risk management',
      th: 'ระบบข้อมูลและการจัดการความเสี่ยง',
    },
  },
  {
    id: 'ULHIiovdAFzg0qmI45Tr',
    name: 'Logistics & Supply Chain',
    icon: `${COURSE_ICON_PATH}/Logistics.png`,
    translation: {
      en: 'Logistics & Supply Chain',
      th: 'โลจิสติกส์และห่วงโซ่อุปทาน',
    },
  },
  {
    id: 'hye0V5onm1RRLGS9tccp',
    // name: 'Manufacturing Technology',
    name: 'Manufacturing Engineering',
    icon: `${COURSE_ICON_PATH}/Manufac.png`,
    translation: {
      en: 'Manufacturing Engineering',
      th: 'วิศวกรรมการผลิต',
    },
  },
  {
    id: '0MHUpAwNNeM0kprpUG9m',
    name: 'Management Science',
    icon: `${COURSE_ICON_PATH}/Optim.png`,
    translation: {
      en: 'Management Science',
      th: 'วิทยาการจัดการ',
    },
  },
  {
    id: 'JvGYL8d4dFcHKglYJ9kx',
    name: 'Quality Engineering',
    icon: `${COURSE_ICON_PATH}/Quality.png`,
    translation: {
      en: 'Quality Engineering',
      th: 'วิศวกรรมคุณภาพ',
    },
  },
  {
    id: 'fszXGGt5EQNp0DI6UBG4',
    name: 'Statistics & Data Analysis',
    icon: `${COURSE_ICON_PATH}/Stat.png`,
    translation: {
      en: 'Data Analysis & Statistics',
      th: 'การวิเคราะห์ข้อมูลและสถิติ',
    },
  },
  {
    // will be removed soon
    id: 'bvCiVrDRM7U9two07lW3',
    filterHidden: true,
    name: 'Systems Engineering',
    icon: `${COURSE_ICON_PATH}/System.png`,
    translation: {
      en: 'Systems Engineering',
      th: 'วิศวกรรมระบบ',
    },
  },
  {
    id: 'tqmxOt8oiR4ecWa9NGkL',
    name: 'Information Systems',
    icon: `${COURSE_ICON_PATH}/System.png`,
    translation: {
      en: 'Information Systems',
      th: 'ระบบสารสนเทศ',
    },
  },
  {
    id: 'XCMtD3ySFbDhob5QJyvH',
    name: 'Strategic & Risk Management',
    icon: `${COURSE_ICON_PATH}/Risk.png`,
    translation: {
      en: 'Strategic & Risk Mgmt.',
      th: 'การบริหารเชิงกลยุทธ์และความเสี่ยง',
    },
  },
  {
    id: '8XSyHOBH5gZOj9XNWjE0',
    name: 'Safety Engineering',
    icon: `${COURSE_ICON_PATH}/Safety.png`,
    translation: {
      en: 'Safety Engineering',
      th: 'วิศวกรรมความปลอดภัย',
    },
  },
];

export const CATEGORY_BY_ID = keyBy(CATEGORIES, 'id');

export const LEVELS = [
  {
    id: 'sophomore',
    name: {
      en: 'Sophomore',
      th: 'นิสิตชั้นปี 2',
    },
    shortName: {
      en: 'yr 2',
      th: 'ปี 2',
    },
    scheduleFilterIncluded: true,
    min: '2104200',
    max: '2104299',
  },
  {
    id: 'junior',
    name: {
      en: 'Junior',
      th: 'นิสิตชั้นปี 3',
    },
    shortName: {
      en: 'yr 3',
      th: 'ปี 3',
    },
    scheduleFilterIncluded: true,
    min: '2104300',
    max: '2104399',
  },
  {
    id: 'senior',
    name: {
      en: 'Senior',
      th: 'นิสิตชั้นปี 4',
    },
    shortName: {
      en: 'yr 4',
      th: 'ปี 4',
    },
    scheduleFilterIncluded: true,
    min: '2104400',
    max: '2104599',
  },
  {
    id: 'master',
    name: {
      en: 'Master',
      th: 'นิสิต ป.โท',
    },
    shortName: {
      en: 'Master',
      th: 'ป.โท',
    },
    scheduleFilterIncluded: true,
    min: '2104500',
    max: '2104816', // only 811 & 816 in 8xx that are "master"
  },
  {
    id: 'doctorate',
    name: {
      en: 'Doctorate',
      th: 'นิสิต ป.เอก',
    },
    shortName: {
      en: 'Ph.D',
      th: 'ป.เอก',
    },
    min: '2104500',
    max: '2104899',
  },
];

export const LEVEL_BY_ID = keyBy(LEVELS, 'id');

export const COURSE_TYPES = [
  {
    id: 'required',
    name: {
      en: 'Required',
      th: 'วิชาบังคับ',
    },
  },
  {
    id: 'approvedElective',
    name: {
      en: 'Elective',
      th: 'วิชาเลือก',
    },
  },
];

export const COURSE_TYPE_BY_ID = keyBy(COURSE_TYPES, 'id');

export const SEMESTER = {
  FIRST: 'firstSemester',
  SECOND: 'secondSemester',
};

export const POST_TAGS = [
  'announcement',
  'academic',
  'job',
  'article',
  'book',
  'event',
];
export const POST_TAGS_BY_ID = POST_TAGS.reduce(
  (result, tag) => ({ ...result, [tag]: tag }),
  {},
);

export const ROLES = {
  DEVELOPER: 'developer',
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  AUTHOR: 'author',
};

export const STAFF_DEPT = {
  ADMIN: 'admin',
  LAB: 'lab',
};

export const USER_BY_TYPES = {
  LECTURER: 'lecturer',
  STAFF: 'staff',
  RESEARCHER: 'researcher',
};

export const FORM_CATEGORIES = [
  {
    id: 'general',
    label: {
      en: 'General',
      th: 'ทั่วไป',
    },
  },
  {
    id: 'bachelor',
    label: {
      en: 'Bachelor',
      th: 'ปริญญาตรี',
    },
  },
  {
    id: 'master',
    label: {
      en: 'Master',
      th: 'ปริญญาโท',
    },
  },
  {
    id: 'doctorate',
    label: {
      en: 'Doctorate',
      th: 'ปริญญาเอก',
    },
  },
];

export const FORM_CATEGORY_BY_ID = keyBy(FORM_CATEGORIES, 'id');

export const SCHOLARSHIP_SOURCES = [
  {
    id: '1',
    label: {
      en: 'Student affair | Chula Engineering',
      th: 'กิจการนิสิต คณะวิศวกรรมศาสตร์ จุฬาลงกร์มหาวิทยาลัย',
    },
    image:
      'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/scholarships/chula_eng.png',
    href:
      'https://www.facebook.com/กิจการนิสิต-คณะวิศวกรรมศาสตร์-จุฬาฯ-เพจ-858015854257493/?epa=SEARCH_BOX',
  },
  {
    id: '2',
    label: {
      en: 'Student affair | Chulalongkorn',
      th: 'กิจการนิสิต จุฬาลงกร์มหาวิทยาลัย',
    },
    image:
      'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/scholarships/student_affair_chula.png',
    href: 'http://www.sa.chula.ac.th/fund/ทุนการศึกษารายปี-และอื/#',
  },
  {
    id: '3',
    label: {
      en: 'Graduate school | Chulalongkorn',
      th: 'บัณฑิตวิทยาลัย จุฬาลงกร์มหาวิทยาลัย',
    },
    image:
      'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/scholarships/grad_chula.png',
    href: 'https://www.grad.chula.ac.th/scholarship.php?type=1',
  },
];

export const SCHOLARSHIP_CATEGORIES = [
  {
    id: 'bachelor',
    label: {
      en: 'Bachelor',
      th: 'ปริญญาตรี',
    },
  },
  {
    id: 'master',
    label: {
      en: 'Master',
      th: 'ปริญญาโท',
    },
  },
  {
    id: 'phd',
    label: {
      en: 'Ph.D.',
      th: 'ปริญญาเอก',
    },
  },
];

export const SCHOLARSHIP_CATEGORY_BY_ID = keyBy(SCHOLARSHIP_CATEGORIES, 'id');

export const FILES_STRUCTURE = {
  admission: {
    bachelor: [
      {
        id: 'admission_bachelor_bulletin1',
        inputId: 'admission_bachelor_bulletinLabel1',
        label: 'B.Eng. Bulletin (latest)',
      },
      {
        id: 'admission_bachelor_bulletin2',
        inputId: 'admission_bachelor_bulletinLabel2',
        label: 'B.Eng. Bulletin (old)',
      },
      {
        id: 'admission_bachelor_approvedElectiveList',
        inputId: 'admission_bachelor_approvedElectiveListLabel',
        label: 'Approved Elective List',
      },
    ],
    masterEng: [
      {
        id: 'admission_master_bulletin1',
        inputId: 'admission_master_bulletinLabel1',
        label: 'M.Eng. Bulletin (latest)',
      },
      {
        id: 'admission_master_bulletin2',
        inputId: 'admission_master_bulletinLabel2',
        label: 'M.Eng. Bulletin (old)',
      },
      {
        id: 'admission_master_applyDetail',
        label: 'รายละเอียดการรับสมัคร ป.โท',
      },
      {
        id: 'admission_master_detailGeneral',
        label: 'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (ปกติ)',
      },
      {
        id: 'admission_master_detailOvertime',
        label: 'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (นอกเวลา)',
      },
      {
        id: 'admission_master_detailSpecial',
        label: 'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (วิธีพิเศษ)',
      },
      {
        id: 'admission_master_testTopic',
        label: 'รายละเอียดหัวข้อที่จะสอบเข้า ป.โท',
      },
      {
        id: 'admission_master_trainingApplication',
        label: 'ใบสมัครโครงการอบรมความรู้พื้นฐาน',
      },
      {
        id: 'admission_master_trainingSchedule',
        label: 'ตารางการอบรมความรู้พื้นฐาน',
      },
      {
        id: 'admission_master_trainingTopic',
        label: 'รายวิชาการอบรมความรู้พื้นฐาน',
      },
      {
        id: 'admission_master_regularTimeTable',
        inputId: 'admission_master_regularTimeTableLabel',
        label: 'ตารางสอน ป.โท ภาคปกติ',
      },
      {
        id: 'admission_master_executiveTimeTable',
        inputId: 'admission_master_executiveTimeTableLabel',
        label: 'ตารางสอน ป.โท ภาคนอกเวลา',
      },
    ],
    masterSasin: [
      {
        id: 'admission_sasin_bulletin1',
        inputId: 'admission_sasin_bulletinLabel1',
        label: 'Sasin Chula Engineering Bulletin',
      },
    ],
    phd: [
      {
        id: 'admission_phd_bulletin1',
        inputId: 'admission_phd_bulletinLabel1',
        label: 'Ph.D. Bulletin (latest)',
      },
      {
        id: 'admission_phd_bulletin2',
        inputId: 'admission_phd_bulletinLabel2',
        label: 'Ph.D. Bulletin (old)',
      },
      {
        id: 'admission_phd_detailGeneral',
        label: 'รายละเอียด ป.เอก (ภาคต้น) (ปกติ)',
      },
      {
        id: 'admission_phd_detailSpecial',
        label: 'รายละเอียด ป.เอก (ภาคต้น) (วิธีพิเศษ)',
      },
    ],
  },
  calendar: {
    prospectStudent: [
      {
        id: 'calendar_academicYear',
        label: 'ปฏิทินการศึกษาของนิสิต ฉบับเต็ม',
      },
    ],
    currentStudent: [
      {
        id: 'calendar_bachelorAdmission',
        label: 'ปฏิทินการเข้าศึกษา ป.ตรี ฉบับเต็ม',
      },
      {
        id: 'calendar_masterBeginningAdmission',
        label: 'ปฏิทินการเข้าศึกษา ป.โท ฉบับเต็ม (ต้น)',
      },
      {
        id: 'calendar_masterEndAdmission',
        label: 'ปฏิทินการเข้าศึกษา ป.โท ฉบับเต็ม (ปลาย)',
      },
    ],
  },
  bachelorProject: {
    preProject: [
      {
        id: 'bachelorProject_preProjectDetail',
        label: 'กำหนดการสำคัญพรีโปรเจค นิสิต ปี4 (ต้น)',
      },
      {
        id: 'bachelorProject_preProjectMembersForm',
        label: 'แบบฟอร์มส่งงานครั้งที่ 1 รายชื่อกลุ่มและอาจารย์ทีี่ปรึกษา',
      },
      {
        id: 'bachelorProject_preProjectIntroAndProjectName',
        label: 'แบบฟอร์มส่งงานครั้งที่ 2 ชื่อหัวข้อโครงงานและบทคัดย่อ',
      },
      {
        id: 'bachelorProject_preProjectPowerpointTemplate',
        label: 'Pre-project Powerpoint Template',
      },
      {
        id: 'bachelorProject_preProjectReportTemplate',
        label: 'Pre-project Report Template',
      },
      {
        id: 'bachelorProject_preProjectAssessmentForm',
        label: 'แบบฟอร์มให้คะแนน (Pre-Project)',
      },
    ],
    seniorProject: [
      {
        id: 'bachelorProject_seniorProjectDetail',
        label: 'กำหนดการสำคัญโปรเจคจบ นิสิต ปี4 (ปลาย)',
      },
      {
        id: 'bachelorProject_assessmentFormX1',
        label: 'แบบฟอร์มให้คะแนน (อ.ที่ปรึกษา 1 ชุด)',
      },
      {
        id: 'bachelorProject_assessmentFormX3',
        label: 'แบบฟอร์มให้คะแนน (กรรมการ+อ.ที่ปรึกษา 3 ชุด)',
      },
      {
        id: 'bachelorProject_seniorProjectPowerpointTemplate',
        label: 'Senior-project Powerpoint Template',
      },
      {
        id: 'bachelorProject_seniorProjectReportTemplate',
        label: 'Senior-project Report Template',
      },
    ],
    coop: [
      {
        id: 'bachelorProject_coopDetail',
        label: 'กำหนดการสำคัญโครงการสหกิจ',
      },
    ],
  },
  internship: {
    guide: [
      {
        id: 'internship_internAssessmentCriteria',
        label: 'เกณฑ์การพิจารณาประเมินผลฝึกงาน',
      },
      {
        id: 'internship_internProcess',
        label: 'ขั้นตอนการฝึกงานอย่างละเอียด',
      },
      {
        id: 'internship_internSlide',
        label: 'Slide เกี่ยวกับการฝึกงาน',
      },
    ],
    en: [
      {
        id: 'internship_internRequestEN',
        label: 'จดหมายขอความอนุเคระห์ฝึกงาน (EN)',
      },
      {
        id: 'internship_internAcceptanceEN',
        label: 'แบบตอบรับการฝึกงาน (EN)',
      },
      {
        id: 'internship_internDeliverEN',
        label: 'จดหมายส่งตัวฝึกงาน (EN)',
      },
      {
        id: 'internship_internEvaluationEN',
        label: 'เอกสารประเมินผลการฝึกงาน (EN)',
      },
    ],
    th: [
      {
        id: 'internship_internRequestTH',
        label: 'จดหมายขอความอนุเคระห์ฝึกงาน (TH)',
      },
      {
        id: 'internship_internAcceptanceTH',
        label: 'แบบตอบรับการฝึกงาน (TH)',
      },
      {
        id: 'internship_internDeliverTH',
        label: 'จดหมายส่งตัวฝึกงาน (TH)',
      },
      {
        id: 'internship_internEvaluationTH',
        label: 'เอกสารประเมินผลการฝึกงาน (TH)',
      },
    ],
  },
  thesis: {
    processDetail: [
      {
        id: 'thesis_processDetail',
        label: 'ขั้นตอนการทำวิทยานิพนธ์ฉบับเต็ม',
      },
    ],
  },
};
