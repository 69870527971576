/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import compose from 'helpers/compose';
import FireApi from 'firebase/fireApi';
import withSizes from './withSizes';
import { withConsumer } from '../contexts/lecturer';

export default compose(
  withSizes(withSizes.mapSizes),
  withConsumer,
  WrappedComponent => ({ isTouch, lecturers, setLecturers, ...props }) => {
    useEffect(() => {
      if (isTouch && (!lecturers || !lecturers.length)) {
        FireApi.fetchLecturers().then(setLecturers);
      }
    }, []);
    return <WrappedComponent {...props} lecturers={lecturers} />;
  },
);
