import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import Query from 'factories/query/Query';
import ROUTES from 'constants/routes';
import BachelorCalendar from './BachelorCalendar';
import MasterBeginningCalendar from './MasterBeginningCalendar';
import MasterEndCalendar from './MasterEndCalendar';
import DoctorateBeginCalendar from './DoctorateBeginCalendar';
import DoctorateEndCalendar from './DoctorateEndCalendar';

const masterSubMenus = [
  {
    id: 'm-beginning',
    translationId: 'beginning',
    label: 'Beginning of the year',
    queryName: 'topic',
  },
  {
    id: 'm-end',
    translationId: 'end',
    label: 'End of the year',
  },
];
const doctorateSubMenus = [
  {
    id: 'd-beginning',
    translationId: 'beginning',
    label: 'Beginning of the year',
    queryName: 'topic',
  },
  {
    id: 'd-end',
    translationId: 'end',
    label: 'End of the year',
  },
];
const menus = [
  {
    id: 'bachelor',
    label: 'Bachelor timeline',
    subMenus: [
      {
        id: 'round-1',
        label: 'Round 1',
      },
      {
        id: 'round-2',
        label: 'Round 2',
      },
      {
        id: 'round-3',
        label: 'Round 3',
      },
      {
        id: 'round-4',
        label: 'Round 4',
      },
      {
        id: 'round-5',
        label: 'Round 5',
      },
    ],
  },
  {
    id: 'master',
    label: 'Master timeline',
    subMenus: masterSubMenus,
  },
  {
    id: 'doctorate',
    label: 'Doctorate timeline',
    subMenus: doctorateSubMenus,
  },
];

const AdmissionCalendar = ({
  history,
  match: {
    params: { degree },
  },
  location,
}) => {
  const query = Query().toObject(location.search);
  return (
    <NestedMenuTabsLayout
      menus={menus}
      headingId={'admission-calendar'}
      defaultSelectedKeys={[degree, query.topic]}
      onMenuClick={({ keyPath }) => {
        if (keyPath.length > 1) {
          // user click from menu list
          return history.replace(
            ROUTES.prospectAC.get(`/${keyPath[0]}?topic=${keyPath[1]}`),
          );
        }
        const nextTopic =
          // eslint-disable-next-line max-len
          degree !== keyPath[0] // user change first level, must reset second level
            ? get(menus.find(({ id }) => id === keyPath[0]), 'subMenus[0].id')
            : keyPath[1];
        return history.replace(
          ROUTES.prospectAC.get(`/${keyPath[0]}?topic=${nextTopic}`),
        );
      }}
    >
      {([key, secondKey]) => (
        <>
          {key === 'bachelor' && <BachelorCalendar />}
          {key === 'master' && (
            // render mobile
            <>
              {secondKey === 'm-beginning' && <MasterBeginningCalendar />}
              {secondKey === 'm-end' && <MasterEndCalendar />}
            </>
          )}
          {key === 'doctorate' && (
            // render mobile
            <>
              {secondKey === 'd-beginning' && <DoctorateBeginCalendar />}
              {secondKey === 'd-end' && <DoctorateEndCalendar />}
            </>
          )}
        </>
      )}
    </NestedMenuTabsLayout>
  );
};

AdmissionCalendar.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
};
AdmissionCalendar.defaultProps = {};

export default AdmissionCalendar;
