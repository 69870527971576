import React from 'react';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ImageGallery from 'components/molecules/ImageGallery';
import useIntl from 'hooks/useIntl';

const BTS = () => {
  const { getMsg } = useIntl();
  return (
    <Box pb={24}>
      <Text.Title level={3}>{getMsg('bts')}</Text.Title>
      <ImageGallery
        galleryMinHeight={225}
        galleryProps={{
          columns: 1,
        }}
        images={[
          // eslint-disable-next-line max-len
          'https://maps-bangkok.com/img/1200/bangkok-bts-map-2016.jpg',
        ]}
      />
      <Text textStyle={'caption'}>{getMsg('clickToZoom')}</Text>
      <br />
      <Text.Paragraph fontSize={16}>{getMsg('btsStep1')}</Text.Paragraph>
      <Text.Paragraph fontSize={16}>{getMsg('btsStep2')}</Text.Paragraph>
      <Text.Paragraph fontSize={16}>{getMsg('btsStep3')}</Text.Paragraph>
    </Box>
  );
};

BTS.propTypes = {};
BTS.defaultProps = {};

export default BTS;
