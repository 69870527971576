import createCollectionContext from 'hooks/createCollectionContext';
import FireApi from 'firebase/fireApi';
import toHOC from './toHOC';

export const {
  Provider,
  Consumer,
  useConsumer,
  useInitFetch,
  Context,
} = createCollectionContext({
  collectionName: 'forms',
  itemName: 'form',
  fetchingApi: FireApi.fetchForms,
});

export const FormProvider = Provider;
export const FormConsumer = Consumer;
export const withConsumer = toHOC(Consumer);
export default Context;
