import React from 'react';
import PropTypes from 'prop-types';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import Location from './aboutUs/Location';
import Suvarnablumi from './aboutUs/Suvarnablumi';
import DonMuang from './aboutUs/DonMuang';
import BTS from './aboutUs/BTS';
import MRT from './aboutUs/MRT';
import CuPopBus from './aboutUs/CuPopBus';

const menus = [
  {
    id: 'location',
    queryName: 'section',
  },
  {
    id: 'transportation',
    subMenus: [
      {
        id: 'suvarnabhumi',
      },
      {
        id: 'donMuang',
      },
      {
        id: 'bts',
      },
      {
        id: 'mrt',
      },
      {
        id: 'cuPopBus',
      },
    ],
  },
];

const AboutUsPage = ({
  match: {
    params: { topic, subtopic },
  },
}) => (
  <NestedMenuTabsLayout
    headingId={'contact'}
    menus={menus}
    defaultSelectedKeys={[topic, subtopic]}
    defaultOpenKeys={['transportation']}
    neglectedKeys={['transportation']}
  >
    {([key, secondKey]) => (
      <>
        {key === 'location' && <Location />}
        {key === 'transportation' && (
          // mobile use Tabs
          <>
            {secondKey === 'suvarnabhumi' && <Suvarnablumi />}
            {secondKey === 'donMuang' && <DonMuang />}
            {secondKey === 'bts' && <BTS />}
            {secondKey === 'mrt' && <MRT />}
            {secondKey === 'cuPopBus' && <CuPopBus />}
          </>
        )}
      </>
    )}
  </NestedMenuTabsLayout>
);

AboutUsPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
AboutUsPage.defaultProps = {};

export default AboutUsPage;
