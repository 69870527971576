import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from 'components/atoms/Box';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import styled from '@emotion/styled';

const Div = styled(Link)`
  ${({ theme }) => `
    background-color: rgba(${theme.primary.rgb}, 0.87);
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 6px;
  transition: 0.3s;
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.38);
    background-color: ${({ theme }) => theme.primary.main};
  }
  .ant-typography {
    color: #ffffff;
    font-size: 16px;
  }
  i {
    color: #ffffff;
    font-size: 40px;
  }
`;

const ShortcutWidget = ({ icon, title, href }) => (
  <Div className={'ShortcutWidget-root'} to={href}>
    <Icon type={icon} />
    <Box mt={16}>
      <Text.Title level={4} textAlign={'center'}>
        {title}
      </Text.Title>
    </Box>
  </Div>
);

ShortcutWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};
ShortcutWidget.defaultProps = {};

export default ShortcutWidget;
