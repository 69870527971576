import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';

const imageCss = css`
  display: block;
  max-width: 800px;
  margin: 24px auto;
  width: 100%;
`;

const NotFoundPage = () => (
  <div>
    <img
      className={imageCss}
      alt={'not-found'}
      src={
        // eslint-disable-next-line max-len
        'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/not-found.png'
      }
    />
    <Text.Heading
      level={3}
      plainFont={false}
      letterSpacing={1}
      textAlign={'center'}
      mb={24}
    >
      Page not found!
    </Text.Heading>
    <Box textAlign={'center'} pb={72}>
      <Button type={'primary'} shape={'round'}>
        <Link to={'/'}>Homepage</Link>
      </Button>
    </Box>
  </div>
);

NotFoundPage.propTypes = {};
NotFoundPage.defaultProps = {};

export default NotFoundPage;
