import withLogger from 'factories/decorators/withLogger';
import client from './algoliaConfig';

const COURSES_INDEX_NAME = process.env.REACT_APP_ALGOLIA_COURSE_INDEX_NAME;

const aglCourses = client.initIndex(COURSES_INDEX_NAME);

export const searchCourses = withLogger(
  config =>
    aglCourses.search({
      page: 0,
      hitsPerPage: 10,
      ...config,
    }),
  'AGL Courses',
);

export default aglCourses;
