/* eslint-disable max-len */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { Table, Row, Col, Divider } from 'antd';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import Bulletin from 'components/molecules/Bulletin';
import useScrollToElement from 'hooks/useScrollToElement';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';
import ROUTES from 'constants/routes';

const tableCss = css`
  .ant-table-tbody > tr:hover > td {
    background: none !important;
  }

  tr[data-row-key='4'] {
    background: #eaeaea;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const AdmissionMaster = ({ scrollValue }) => {
  const dbAssets = useConsumer();
  useScrollToElement(scrollValue);
  return (
    <div>
      <Element name={'m-program'}>
        <Text.Heading level={3} plainFont={false} letterSpacing={1}>
          หลักสูตรวิศวกรรมศาสตรมหาบัณฑิต <br /> สาขาวิชาวิศวกรรมอุตสาหการ
        </Text.Heading>
      </Element>
      <Text.SubHeading mb={24}>
        Master of Engineering | M.Eng.(IE)
      </Text.SubHeading>

      <Bulletin
        data={get(dbAssets, 'admission.master')}
        defaultLabel={'M.Eng.(IE) Bulletin'}
      />

      <Text.Topic>โครงสร้างหลักสูตร</Text.Topic>
      <Text.Body style={{ textIndent: 20 }}>
        ภาควิชาวิศวกรรมอุตสาหการได้เปิดสอนนิสิตระดับปริญญาโทในหลักสูตรวิศวกรรมศาสตรมหาบัณฑิตมานานกว่า
        20 ปี โดยมีการพัฒนาหลักสูตรตลอดช่วงเวลาที่ผ่านมา
        เพื่อให้สอดรับกับความต้องการของสังคม
        ภาควิชาวิศวกรรมอุตสาหการได้สร้างบัณฑิตที่มีคุณภาพไปเป็นวิศวกรอุตสาหการในภาคการผลิตและภาคบริการ
        ในการวางแผนงานห่วงโซ่อุปทาน งานวิศวคุณภาพ งานออกแบบ งานปรับปรุงระบบ
        กระบวนการทำงาน คุณภาพการผลิตและการส่งมอบบริการ ที่คำนึงถึงประสิทธิภาพ
        คุณภาพ ความปลอดภัยในการทำงาน รวมถึงสร้างนักวิจัยและนักวิชาการ
        โดยใช้องค์ความรู้ที่ได้ฝึกฝนผ่านการเรียนการสอน
        การเยี่ยมชมสถานประกอบการจริง และการทำวิทยานิพนธ์
      </Text.Body>
      <Text.Body style={{ textIndent: 20 }}>
        นอกจากการเรียนการสอนในวันเวลาราชการแล้ว ภาควิชาวิศวกรรมอุตสาหการ
        ยังได้จัดการเรียนการสอนในวันเสาร์และอาทิตย์
        {/* eslint-disable-next-line max-len */}
        เพื่อรองรับนิสิตที่ประสงค์จะเพิ่มพูนความรู้ความสามารถในระหว่างการทำงานอีกด้วย
        ซึ่งนิสิตทั้งสองกลุ่ม จะได้รับการฝึกฝนในมาตรฐานเดียวกัน
        และผ่านเกณฑ์การศึกษาเดียวกัน โดยมีแผนการศึกษา 2 รูปแบบดังนี้
      </Text.Body>

      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        แผน ก.1 (Thesis)
      </Text.Body>
      <Text.Body>
        เหมาะสำหรับผู้ที่จบการศึกษาปริญญาบัณฑิตสาขาวิศวกรรมอุตสาหการ
        นิสิตทำวิจัยเต็มเวลา เพื่อให้ได้ผลงานวิจัยในระดับสูง ใช้เวลาประมาณ 1 ปี
        นิสิตสามารถลงเรียนรายวิชาที่สนใจเพิ่มเติมแต่ไม่บังคับ
      </Text.Body>

      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        แผน ก.2 (Courses + Thesis)
      </Text.Body>
      <Text.Body>
        เหมาะสำหรับผู้ที่จบการศึกษาวิศวกรรมบัณฑิตทุกสาขา วิทยาศาสตร์บัณฑิต
        เภสัชศาสตร์บัณฑิตและสาขาที่เกี่ยวข้อง โดยนิสิตต้องเรียนวิชาบังคับ 2 วิชา
        (วิชาการปรับปรุงระบบงาน และ วิชาแบบจำลองระบบและการวิเคราะห์)
        เรียนวิชาเลือก 6 วิชา และทำวิทยานิพนธ์ ทำให้มีหน่วยกิตทั้งสิ้น 36
        หน่วยกิต โดยใช้เวลาทั้งหมดประมาณ 2 ปี
      </Text.Body>

      <Table
        scroll={{ x: true }}
        className={tableCss}
        pagination={false}
        bordered
        columns={[
          { title: 'หมวด', dataIndex: 'subject' },
          { title: 'แผน ก.1', dataIndex: 'plan1' },
          { title: 'หน่วยกิต', dataIndex: 'credit1' },
          { title: 'แผน ก.2', dataIndex: 'plan2' },
          { title: 'หน่วยกิต', dataIndex: 'credit2' },
        ]}
        dataSource={[
          {
            key: '1',
            subject: 'วิชาบังคับ',
            plan1: '-',
            credit1: 0,
            plan2: <Text mb={6}>2104696 การปรับปรุงระบบ</Text>,
            credit2: 3,
          },
          {
            key: '1',
            subject: 'วิชาบังคับ',
            plan1: '-',
            credit1: 0,
            plan2: <Text mb={6}>2104697 แบบจำลองระบบและการวิเคราะห์</Text>,
            credit2: 3,
          },
          {
            key: '2',
            subject: 'วิชาเลือก',
            plan1: '-',
            credit1: 0,
            plan2: 'วิชาเลือก 6 วิชา วิชาละ 3 หน่วยกิต',
            credit2: 18,
          },
          {
            key: '3',
            subject: 'วิทยานิพนธ์',
            plan1: '62739982',
            credit1: 36,
            plan2: '62739982 วิทยานิพนธ์',
            credit2: 12,
          },
          {
            key: '4',
            subject: 'รวมทั้งหมด',
            plan1: '',
            credit1: 36,
            plan2: '',
            credit2: 36,
          },
        ]}
      />

      <br />
      <br />
      <Element name={'m-how-to-apply'}>
        <Text.Topic>วิธีการสมัคร</Text.Topic>
      </Element>
      <Text.Body style={{ textIndent: 20 }}>
        ผู้สมัครสามารถเข้าไปดูรายละเอียดที่ www.grad.chula.ac.th เลือกเมนู
        “การเข้าศึกษา” เลือก “ขั้นตอนการสมัครเข้าศึกษา”
        อ่านรายละเอียดขั้นตอนการสมัครเข้าศึกษาก่อนแล้ว
        จึงดำเนินการตามที่บัณฑิตวิทยาลัยกำหนด
      </Text.Body>

      <Text.Topic>หลักฐานประกอบการสมัคร</Text.Topic>
      <Text.Body>
        <ul>
          <li>ใบสมัครติดรูปถ่าย</li>
          <li>สำเนาปริญญาบัตร 1 ฉบับ</li>
          <li>ใบรับรองคะแนน (Transcript) ในระดับปริญญาตรี 1 ฉบับ</li>
          <li>สำเนาบัตรประชาชน 1 ฉบับ</li>
          <li>
            สำเนาพร้อมฉบับจริงผลสอบภาษาอังกฤษ (CU-TEP) ตั้งแต่ 30 หรือ TOEFL
            ตั้งแต่ 400 คะแนน หรือ IELTS ตั้งแต่ 3.0 คะแนน 1 ฉบับ
            (ผลสอบภาษาอังกฤษสามารถใช้ได้ 2 ปี นับจากวันที่ทำการทดสอบ)
          </li>
        </ul>
      </Text.Body>
      <br />

      <Text.Topic>การส่งใบสมัคร</Text.Topic>
      <Text.Body>
        ผู้สมัครกรอกข้อมูลเรียบร้อยแล้ว พิมพ์ใบสมัครพร้อมเอกสารประกอบการรับสมัคร
        นำส่งทางไปรษณีย์ ถึงบัณฑิตวิทยาลัย (วันที่
        ที่ทำการไปรษณีย์ต้นทางประทับตรา ต้องไม่เกิน 1
        วันหลังวันสุดท้ายของการรับสมัครทางไปรษณีย์)
      </Text.Body>

      <Text.Topic mb={16}>ขั้นตอนการสอบเข้า</Text.Topic>
      <MonthlyTimeline
        events={[
          {
            title: 'กลางพฤษภาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'การสอบข้อเขียน 3 ชั่วโมง วิชา พื้นฐานสถิติสำหรับวิศวกรรมอุตสาหการ',
            links: [
              {
                label: 'รายละเอียดหัวข้อที่จะสอบ',
                href: get(dbAssets, 'admission.master.testTopic'),
              },
            ],
            current: isInRange([10, 5], [20, 5]),
          },
          {
            title: 'ปลายพฤษภาคม',
            subtitle: 'ประกาศผลสอบข้อเขียน',
            current: isInRange([21, 5], [28, 5]),
          },
          {
            title: 'หลังประกาศผลสอบข้อเขียน',
            subtitle: 'สอบสัมภาษณ์ (เฉพาะผู้สอบผ่านข้อเขียนเท่านั้น)',
            current: isInRange([29, 5], [30, 5]),
          },
          {
            title: 'หลังสอบสัมภาษณ์',
            subtitle: 'ประกาศผลการสอบคัดเลือก',
            current: isInRange([31, 5], [7, 6]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'รายละเอียดการรับสมัคร'}
        href={get(dbAssets, 'admission.master.applyDetail')}
      />
      <br />
      <br />
      <br />
      <Element name={'m-fees'}>
        <Text.Topic>ค่าใช้จ่าย</Text.Topic>
      </Element>
      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        หลักสูตรภาคในเวลาราชการ
      </Text.Body>
      <Row type={'flex'}>
        <Col xs={17} md={14}>
          <Text.Body ml={16} mb={0}>
            ค่ารับสมัครเข้าศึกษา
          </Text.Body>
        </Col>
        <Col>
          <Text.Body mb={0}>500 บาท</Text.Body>
        </Col>
        <Col xs={17} md={14}>
          <Text.Body ml={16}>ค่าเล่าเรียนภาคการศึกษาละ</Text.Body>
        </Col>
        <Col>
          <Text.Body>33,500 บาท</Text.Body>
        </Col>
      </Row>
      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        หลักสูตรภาคนอกเวลาราชการ
      </Text.Body>
      <Row type={'flex'}>
        <Col xs={17} md={14}>
          <Text.Body ml={16} mb={0}>
            ค่ารับสมัครเข้าศึกษา
          </Text.Body>
        </Col>
        <Col>
          <Text.Body mb={0}>600 บาท</Text.Body>
        </Col>
        <Col xs={17} md={14}>
          <Text.Body ml={16} mb={0}>
            ค่าเล่าเรียนภาคการศึกษาละ
          </Text.Body>
        </Col>
        <Col>
          <Text.Body mb={0}>33,500 บาท</Text.Body>
        </Col>
        <Col xs={17} md={14}>
          <Text.Body ml={16}>ค่าธรรมเนียมการศึกษาภาคการศึกษาละ</Text.Body>
        </Col>
        <Col>
          <Text.Body>30,000 บาท</Text.Body>
        </Col>
      </Row>
      <Text.Topic
        fontSize={20}
        fontWeight={'bold'}
        component={Link}
        to={ROUTES.scholarships.get('/master')}
      >
        ดูข้อมูลทุนการศึกษา ป.โท
      </Text.Topic>
      <br />
      <br />
      <Text.Body mb={0}>สอบถามรายละเอียดเพิ่มเติม</Text.Body>
      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        โทร <a href="tel:0-2218-6814-6">0-2218-6814-6</a>
      </Text.Body>
      <Text.Body fontWeight={'bold'} color={'text.primary'} mb={8}>
        อีเมล์{' '}
        <a
          rel={'noopener'}
          target={'_blank'}
          href="mailto:ie.info@eng.chula.ac.th"
        >
          ie.info@eng.chula.ac.th
        </a>
      </Text.Body>
      <Text mb={16} textStyle={'update'}>
        ** อัพเดทข้อมูลล่าสุด ปี 2562 **
      </Text>
      <Divider />
      <DownloadUI
        label={'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (ปกติ)'}
        href={get(dbAssets, 'admission.master.detailGeneral')}
      />
      <br />
      <br />
      <DownloadUI
        label={'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (วิธีพิเศษ)'}
        href={get(dbAssets, 'admission.master.detailSpecial')}
      />
      <br />
      <br />
      <DownloadUI
        label={'รายละเอียด ป.โท (ภาคต้น) แผน ก1 ก2 (นอกเวลา)'}
        href={get(dbAssets, 'admission.master.detailOvertime')}
      />
      <br />
      <Divider />
      <Text.Body fontWeight={'bold'} color={'primary.main'} mb={8}>
        หมายเหตุ
      </Text.Body>
      <Text.Body>
        สำหรับผู้ที่ไม่ได้ผ่านหลักสูตรวิศวกรรมศาสตรบัณฑิตสาขาวิศวกรรมอุตสาหการ
        เมื่อสอบผ่านจะต้องเข้ารับการอบรมความรู้พื้นฐานวิศวกรรมอุตสาหการที่ภาควิชาจัดอบรม
      </Text.Body>
      <DownloadUI
        label={'ใบสมัครโครงการอบรมความรู้พื้นฐาน'}
        href={get(dbAssets, 'admission.master.trainingApplication')}
      />
      <br />
      <br />
      <DownloadUI
        label={'ตารางการอบรมความรู้พื้นฐาน'}
        href={get(dbAssets, 'admission.master.trainingSchedule')}
      />
      <br />
      <br />
      <DownloadUI
        label={'รายวิชาการอบรมความรู้พื้นฐาน'}
        href={get(dbAssets, 'admission.master.trainingTopic')}
      />
      <br />
      <br />
      <br />
    </div>
  );
};

AdmissionMaster.propTypes = {
  scrollValue: PropTypes.string.isRequired,
};
AdmissionMaster.defaultProps = {};

export default AdmissionMaster;
