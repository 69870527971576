import React from 'react';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ImageGallery from 'components/molecules/ImageGallery';
import useIntl from 'hooks/useIntl';

const Suvarnablumi = () => {
  const { getMsg } = useIntl();
  return (
    <Box pb={24}>
      <Text.Title level={3}>{getMsg('arriveSuvarnabhumiAirport')}</Text.Title>
      <Text.Title level={4}>{getMsg('airportLink')}</Text.Title>
      <ImageGallery
        singlePhoto
        galleryMinHeight={144}
        galleryProps={{
          columns: 1,
        }}
        images={[
          // eslint-disable-next-line max-len
          'http://www.srtet.co.th/templates/arl/images/timetable.jpg',
        ]}
      />
      <Text textStyle={'caption'}>{getMsg('clickToZoom')}</Text>
      <br />
      <br />
      <Text.Paragraph fontSize={16}>{getMsg('airportLinkHour')}</Text.Paragraph>
      <Text.Paragraph fontSize={16}>
        {getMsg('airportLinkStep1')}
      </Text.Paragraph>
      <Text.Paragraph fontSize={16}>
        {getMsg('airportLinkStep2')}
      </Text.Paragraph>
      <Text.Paragraph fontSize={16}>
        {getMsg('airportLinkStep3')}
      </Text.Paragraph>
      <Text.Paragraph fontSize={16}>
        {getMsg('airportLinkUrl')}{' '}
        <a
          href={
            // eslint-disable-next-line max-len
            'http://www.srtet.co.th/index.php/th/cityline-calculate/cityline-timetable'
          }
          rel={'noopener'}
          target={'_blank'}
        >
          http://www.srtet.co.th/
        </a>
      </Text.Paragraph>
      <Text.Title level={4}>{getMsg('taxi')}</Text.Title>
      <Text.Paragraph fontSize={16}>{getMsg('taxiDirection')}</Text.Paragraph>
    </Box>
  );
};

Suvarnablumi.propTypes = {};
Suvarnablumi.defaultProps = {};

export default Suvarnablumi;
