import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';

const Div = styled('a')`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.08)`};

  &.-disabled {
    background: #e8e8e8;
    pointer-events: none;
    i {
      color: #c5c5c5;
    }
    &:after {
      background: none;
    }
  }

  i {
    color: ${({ theme }) => theme.primary.main};
    font-size: 20px;
  }

  :after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
    background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.08)`};
  }

  &:hover {
    &:after {
      transform-origin: center;
      transform: scale(1.5);
    }
  }
`;

const Arrow = ({ className, disabled, ...props }) => (
  <Div
    {...props}
    className={cx('Arrow-root', disabled && '-disabled', className)}
  />
);

// eslint-disable-next-line react/prop-types
const Left = ({ className, ...props }) => (
  <Arrow className={cx('Arrow-left', className)} {...props}>
    <Icon type={'far fa-chevron-left'} />
  </Arrow>
);
// eslint-disable-next-line react/prop-types
const Right = ({ className, ...props }) => (
  <Arrow className={cx('Arrow-right', className)} {...props}>
    <Icon type={'far fa-chevron-right'} />
  </Arrow>
);

Arrow.Left = Left;
Arrow.Right = Right;
Arrow.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
Arrow.defaultProps = {
  className: '',
  disabled: false,
};
export default Arrow;
