export default {
  organization: {
    pattern: '/organization',
    get: (search = '') => `/organization?${search}`,
  },
  aboutIE: {
    root: '/about-ie-chula',
    main: '/about-ie-chula/our-history',
    pattern: '/about-ie-chula/:page',
    get: (path = '') => `/about-ie-chula${path}`,
  },
  publication: {
    pattern: '/publication/:category',
    get: (path = '') => `/publication${path}`,
  },
  labs: {
    pattern: '/research-center_and_labs/:name',
    get: (path = '') => `/research-center_and_labs${path}`,
  },
  prospectAC: {
    root: '/prospect-student/calendar',
    main: '/prospect-student/calendar/master?topic=m-beginning',
    pattern: '/prospect-student/calendar/:degree',
    get: (search = '') => `/prospect-student/calendar${search}`,
  },
  admission: {
    root: '/prospect-student/admission',
    main: '/prospect-student/admission/master/m-eng?topic=m-program',
    pattern: [
      '/prospect-student/admission/:degree',
      '/prospect-student/admission/:degree/:type',
    ],
    get: (search = '') => `/prospect-student/admission${search}`,
  },
  currentAC: {
    pattern: '/current-student/academic-calendar',
    get: (search = '') => `/current-student/academic-calendar?${search}`,
  },
  internship: {
    pattern: '/current-student/internship',
    get: (search = '') => `/current-student/internship?${search}`,
  },
  seniorBachelor: {
    pattern: '/current-student/senior-bachelor',
    get: (search = '') => `/current-student/senior-bachelor?${search}`,
  },
  thesis: {
    pattern: '/current-student/thesis',
    get: (search = '') => `/current-student/thesis?${search}`,
  },
  news: {
    root: '/news',
    main: '/news/announcement',
    pattern: '/news/:tag',
    get: (path = '') => `/news${path}`,
  },
  scholarships: {
    root: '/scholarships',
    main: '/scholarships/all',
    pattern: '/scholarships/:category',
    get: (path = '') => `/scholarships${path}`,
  },
  courses: {
    pattern: '/courses',
    get: () => '/courses',
  },
  courseDetail: {
    pattern: '/courses/:courseId',
    get: (id, name = '') => `/courses/${id}/${name}`,
  },
  staffs: {
    pattern: '/staffs',
    get: (search = '') => `/staffs?${search}`,
  },
  researchers: {
    pattern: '/researchers',
    get: (search = '') => `/researchers?${search}`,
  },
  lecturers: {
    pattern: '/lecturers',
    get: (path = '') => `/lecturers${path}`,
  },
  lecturerDetail: {
    pattern: '/lecturers/:lecturerId',
    get: (id, name = '') => `/lecturers/${id}/${name}`,
  },
  schedule: {
    pattern: '/schedule',
    get: () => '/schedule',
  },
  forms: {
    pattern: '/forms',
    get: (search = '') => `/forms?${search}`,
  },
  postDetail: {
    pattern: '/posts/:postId',
    get: (id, title = '') => `/posts/${id}/${title}`,
  },
  aboutUs: {
    root: '/about-us',
    main: '/about-us/location',
    pattern: ['/about-us/:topic', '/about-us/:topic/:subtopic'],
    get: (path = '') => `/about-us${path}`,
  },
  admin: {
    pattern: '/admin',
    get: () => '/admin',
  },
  login: {
    pattern: '/login',
    get: () => '/login',
  },
  dashboard: {
    pattern: '/admin/dashboard',
    get: () => '/admin/dashboard',
  },
  adminCourses: {
    pattern: '/admin/courses',
    get: () => '/admin/courses',
  },
  adminRooms: {
    pattern: '/admin/rooms',
    get: () => '/admin/rooms',
  },
  adminPeople: {
    pattern: '/admin/people',
    get: () => '/admin/people',
  },
  adminSchedule: {
    pattern: '/admin/schedule',
    get: () => '/admin/schedule',
  },
  adminPosts: {
    pattern: '/admin/posts',
    get: () => '/admin/posts',
  },
  adminRoles: {
    pattern: '/admin/roles',
    get: () => '/admin/roles',
  },
  adminProfile: {
    pattern: '/admin/profile',
    get: () => '/admin/profile',
  },
  adminForm: {
    pattern: '/admin/forms',
    get: () => '/admin/forms',
  },
  adminScholarship: {
    pattern: '/admin/scholarships',
    get: () => '/admin/scholarships',
  },
  adminFiles: {
    pattern: '/admin/files',
    get: () => '/admin/files',
  },
};
