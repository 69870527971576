import union from 'lodash/union';
import matchesProperty from 'lodash/matchesProperty';

const isValidKey = (schema, object, key) =>
  schema[key] !== undefined || object[key] !== undefined;

const getValue = (schema, object, key) => {
  if (object[key] === undefined && schema[key] !== undefined) {
    return schema[key];
  }
  return object[key];
};

export const replaceUndefined = (schema, object) => {
  const schemaKeys = Object.keys(schema);
  const objectKeys = Object.keys(object);
  const allKeys = union(schemaKeys, objectKeys);
  return allKeys.reduce(
    (result, key) => ({
      ...result,
      ...(isValidKey(schema, object, key) && {
        [key]: getValue(schema, object, key),
      }),
    }),
    {},
  );
};

export const getItem = (items, ...args) => items.find(matchesProperty(...args));

export const replaceItemById = (items, newItem, key = 'id') =>
  items.map(item => {
    if (item[key] === newItem[key]) {
      return newItem;
    }
    return item;
  });

export const mergeItem = (items, newItem, key = 'id') =>
  !getItem(items, key, newItem[key])
    ? [newItem, ...items]
    : replaceItemById(items, newItem, key);

export const deleteItem = (items, id) => items.filter(item => item.id !== id);

export const getIcon = (val = '', defaultIcon = 'download') => {
  let icon = defaultIcon;
  [
    ['doc', 'word'],
    ['ppt', 'powerpoint'],
    ['pdf', 'pdf'],
    ['xls', 'excel'],
    ['zip', 'archive'],
    ['csv', 'csv'],
  ].some(([type, iconValue]) => {
    const isIncluded = val.includes(type);
    if (isIncluded) {
      icon = iconValue;
    }
    return isIncluded;
  });
  return icon;
};

export default {
  getItem,
  getIcon,
  mergeItem,
  replaceUndefined,
  replaceItemById,
  deleteItem,
};
