/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const AmpeTH = () => (
  <div>
    <Box mx={{ xs: -16, sm: -32 }} mb={24}>
      <img
        style={{ display: 'block', maxWidth: '50%', margin: 'auto' }}
        alt={'cover'}
        src={
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FAMPE.jpg?alt=media&token=526bb2e7-cb31-4152-82e0-146baa5cc8d5'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      Advanced Manufacturing & Precision Engineering Research and Development
      Center (AMPE)
    </Text.Heading>
    <Text.Topic>งานวิจัยที่เกี่ยวข้อง</Text.Topic>
    <Text.Body>
      <ul>
        <li>Intelligent Manufacturing System</li>
        <li>Intelligent Machine Tool</li>
        <li>Manufacturing Automation</li>
        <li>Monitoring and Optimization of Manufacturing Processes</li>
        <li>Advanced IT</li>
        <li>FMS/FMC/CIM</li>
      </ul>
    </Text.Body>

    <Text.Topic>บุคลากร</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/2tRYPZMdlsWFiURxBdC9/Somkiat%20Tangjitsitcharoen',
            )}
          >
            ศ. ดร.สมเกียรติ ตั้งจิตสิตเจริญ
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>สถานที่</Text.Topic>
    <Text.Body>ห้อง 814 ชั้น 8 อาคารเจริญวิศวกรรม (ตึก 4)</Text.Body>
  </div>
);

AmpeTH.propTypes = {};
AmpeTH.defaultProps = {};

export default AmpeTH;
