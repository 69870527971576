import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styled from '@emotion/styled';
import { Divider } from 'antd';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

const Root = styled('div')`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
  min-width: 375px;
  height: 56px;
  display: flex;
  justify-content: space-between;

  > .ant-divider-vertical {
    height: 72%;
    align-self: center;
    margin: 0;
  }

  > .BottomNav-menu {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;

    :focus {
      outline: 0;
    }

    &.-active {
      background-color: #f9efee;
      color: ${({ theme }) => theme.primary.main};

      .ant-typography {
        color: ${({ theme }) => theme.primary.main};
      }
    }
  }

  .Icon-root {
    font-size: 24px;
  }
`;

const BottomNav = ({ menus, activeId, onClick }) => {
  const renderDivider = index => {
    const activeIndex = menus.findIndex(({ id }) => id === activeId);
    if (
      index === activeIndex ||
      index + 1 === activeIndex ||
      index === menus.length - 1 // last item
    )
      return null;
    return <Divider type={'vertical'} />;
  };
  return (
    <>
      <Root>
        {menus.map(({ id, label, icon }, i) => (
          <React.Fragment key={id}>
            <button
              type={'button'}
              className={cx('BottomNav-menu', activeId === id && '-active')}
              onClick={e => onClick(id, e)}
            >
              <Icon
                type={activeId === id ? icon.replace('far', 'fas') : icon}
              />
              <Text fontSize={10} mt={4}>
                {label}
              </Text>
            </button>
            {renderDivider(i)}
          </React.Fragment>
        ))}
      </Root>
    </>
  );
};

BottomNav.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  activeId: PropTypes.string,
  onClick: PropTypes.func,
};
BottomNav.defaultProps = {
  menus: [],
  activeId: '',
  onClick: () => {},
};

export default BottomNav;
