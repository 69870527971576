import { replaceUndefined } from 'helpers/functions';
import { USER_BY_TYPES } from 'constants/temporary';

const StaffFactory = () => {
  const schema = {
    avatarUrl: '',
    name: {
      en: '',
      th: '',
    },
    contact: {
      email: '',
      phone: '',
    },
    job: '',
    type: USER_BY_TYPES.STAFF,
  };

  const getName = ({ name }, locale = 'en') => name[locale];

  return {
    toApi: data => replaceUndefined(schema, data),
    getName,
  };
};

export const staffService = StaffFactory();

export default StaffFactory;
