/* eslint-disable max-len, react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Transition } from 'react-spring/renderprops';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import styled from '@emotion/styled';
import { breakpoints } from 'theme';
import { withIntl } from 'contexts/intl';

const ParallaxBanner = styled(Box)`
  background-color: #450406;
  background-image: url('https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/IE-chula-homepage.jpg');
  /* Create the parallax scrolling effect */
  background-position: center 70%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: calc(100vh - 180px);
  text-align: center;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      text-align: left;
    }
    ${theme.breakpoints.up('xl')} {
    }
  `}

  .Banner-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .Banner-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    ${({ theme }) => `
      ${theme.breakpoints.up('md')} {
        background: linear-gradient(
          120deg,
          rgba(0, 0, 0, 0.87),
          rgba(0, 0, 0, 0) 60%
        );
      }
    `}
  }

  .banner-title {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 800;
    &.first-title {
      letter-spacing: 2px;
      font-weight: 900;
      font-size: 48px;
      color: ${({ theme }) => theme.white.text};
      &.-th {
        font-size: 46px;
      }
      ${breakpoints.up('sm')} {
        font-size: 72px;
        &.-th {
          font-size: 66px;
        }
      }
      ${breakpoints.up('md')} {
        font-size: 56px;
        &.-th {
          font-size: 60px;
        }
      }
      ${breakpoints.up('lg')} {
        font-size: 80px;
        &.-th {
          font-size: 72px;
        }
      }
      ${breakpoints.up('xl')} {
        font-size: 112px;
        &.-th {
          font-size: 128px;
        }
      }
    }
    &.second-title {
      font-size: 44px;
      letter-spacing: 1.8px;
      color: ${({ theme }) => theme.white.text};
      &.-th {
        line-height: 1;
        font-size: 56px;
        letter-spacing: 3px;
      }
      ${breakpoints.up('sm')} {
        font-size: 65.5px;
        &.-th {
          font-size: 80px;
        }
      }
      ${breakpoints.up('md')} {
        font-size: 56px;
        &.-th {
          font-size: 64px;
        }
      }
      ${breakpoints.up('lg')} {
        font-size: 72px;
        &.-th {
          font-size: 88px;
        }
      }
      ${breakpoints.up('xl')} {
        font-size: 102px;
        &.-th {
          font-size: 136px;
        }
      }
    }
  }
  .banner-subtitle {
    margin-top: 8px !important;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 3px;
    ${breakpoints.up('sm')} {
      font-size: 24px;
      line-height: 48px;
      &.-th {
        margin-top: 24px !important;
        font-size: 32px;
      }
    }
    ${breakpoints.up('md')} {
      font-size: 20px;
      line-height: 48px;
      margin-top: 4px !important;
      &.-th {
        margin-top: 16px !important;
        font-size: 24px;
      }
    }
    ${breakpoints.up('lg')} {
      margin-top: 16px !important;
      &.-th {
        margin-top: 24px !important;
        font-size: 36px;
      }
    }
    ${breakpoints.up('xl')} {
      margin-top: 24px !important;
      font-size: 40px;
      &.-th {
        margin-top: 80px !important;
        font-size: 56px;
      }
    }
    font-weight: 900;
    color: ${({ theme }) => theme.white.secondary};
  }

  // &.-th {
  //   .banner-subtitle {
  //     margin-top: 24px !important;
  //   }
  // }
`;

const RedBackground = styled.div`
  background: #881014;
  height: 48px;
  ${breakpoints.up('sm')} {
    height: 64px;
  }
  ${breakpoints.up('lg')} {
    height: 80px;
  }
`;

const HomepageBanner = ({ locale, getMsg }) => {
  const createMoveUpTransition = props => fn => (
    <Transition
      items
      from={{ opacity: 0, transform: 'translateY(24px)' }}
      enter={{ opacity: 1, transform: 'translateY(0)' }}
      {...props}
    >
      {() => fn}
    </Transition>
  );
  const initialTrail = 200;
  return (
    <>
      <ParallaxBanner className={cx(`-${locale}`)}>
        <div className={'Banner-gradient'} />
        <Box px={{ xs: 16, md: 50 }} className={'Banner-info'}>
          {createMoveUpTransition({
            key: `first-${locale}`,
            trail: initialTrail,
          })(style => (
            <Text.Title
              style={style}
              className={cx('banner-title', 'first-title', `-${locale}`)}
            >
              {getMsg(locale === 'en' ? 'industrial' : 'engineering')}
            </Text.Title>
          ))}
          {createMoveUpTransition({
            key: `second-${locale}`,
            trail: initialTrail,
          })(style => (
            <Text.Title
              style={style}
              className={cx('banner-title', 'second-title', `-${locale}`)}
            >
              {getMsg(locale === 'en' ? 'engineering' : 'industrial')}
            </Text.Title>
          ))}
          {createMoveUpTransition({
            key: `subtitle-${locale}`,
            trail: initialTrail + 200,
          })(style => (
            <Text.Title
              style={style}
              className={cx('banner-subtitle', `-${locale}`)}
            >
              {getMsg('chulalongkorn')}
              {locale === 'en' ? ' ' : ''}
              {getMsg('university')}
            </Text.Title>
          ))}
        </Box>
      </ParallaxBanner>
      <RedBackground />
    </>
  );
};

HomepageBanner.propTypes = {
  locale: PropTypes.string,
  getMsg: PropTypes.func,
};
HomepageBanner.defaultProps = {
  locale: 'en',
  getMsg: () => '',
};

export default withIntl(HomepageBanner);
