import React from 'react';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import PreProject from './PreProject';
import SeniorProject from './SeniorProject';
import CoopProgram from './CoopProgram';

const menus = [
  {
    id: 'pre-project',
    label: 'Pre-project',
  },
  {
    id: 'senior-project',
    label: 'Senior project',
  },
  {
    id: 'co-operative-program',
    label: 'Co-operative program',
  },
];

const SeniorBachelor = () => (
  <NestedMenuTabsLayout
    menus={menus}
    headingId={'bachelor-project'}
    defaultSelectedKeys={['pre-project']}
  >
    {([key]) => (
      <>
        {key === 'pre-project' && <PreProject />}
        {key === 'senior-project' && <SeniorProject />}
        {key === 'co-operative-program' && <CoopProgram />}
      </>
    )}
  </NestedMenuTabsLayout>
);

export default SeniorBachelor;
