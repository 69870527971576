/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import { css } from 'emotion';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import DownloadUI from 'components/atoms/DownloadUI';
import { useConsumer } from 'contexts/db';

const btnCss = css`
  margin-top: 12px;
  margin-right: 16px;
  margin-bottom: 8px;
`;

const list = [
  {
    label: 'จดหมายขอความอนุเคระห์ฝึกงาน',
    links: [
      {
        label: 'เวอร์ชั่นภาษาไทย',
        hrefId: 'internRequestTH',
      },
      {
        label: 'English version',
        hrefId: 'internRequestEN',
      },
    ],
  },
  {
    label: 'แบบตอบรับการฝึกงาน',
    links: [
      {
        label: 'เวอร์ชั่นภาษาไทย',
        hrefId: 'internAcceptanceTH',
      },
      {
        label: 'English version',
        hrefId: 'internAcceptanceEN',
      },
    ],
  },
  {
    label: 'จดหมายส่งตัวฝึกงาน',
    links: [
      {
        label: 'เวอร์ชั่นภาษาไทย',
        hrefId: 'internDeliverTH',
      },
      {
        label: 'English version',
        hrefId: 'internDeliverEN',
      },
    ],
  },
  {
    label: 'เอกสารประเมินผลการฝึกงาน',
    links: [
      {
        label: 'เวอร์ชั่นภาษาไทย',
        hrefId: 'internEvaluationTH',
      },
      {
        label: 'English version',
        hrefId: 'internEvaluationEN',
      },
    ],
  },
];

const InternshipFiles = () => {
  const dbAssets = useConsumer();
  return (
    <div>
      <Text.Title level={3}>เอกสารที่เกี่ยวข้องในการฝึกงาน</Text.Title>
      {list.map(({ label, links }) => (
        <React.Fragment key={label}>
          <Text.Paragraph mb={0} fontWeight={'bold'}>
            {label}
          </Text.Paragraph>
          <Box mb={24}>
            {links.map(props => (
              <DownloadUI
                key={props.label}
                className={btnCss}
                {...props}
                href={get(dbAssets, ['internship', props.hrefId])}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
      <br />
      <br />
      <br />
    </div>
  );
};

InternshipFiles.propTypes = {};
InternshipFiles.defaultProps = {};

export default InternshipFiles;
