import React from 'react';
import styled from '@emotion/styled';
import Text from 'components/atoms/Text';
import Container from 'components/layouts/Container';
import useIntl from 'hooks/useIntl';
import { breakpoints } from '../../theme';

const Wrapper = styled('div')`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${breakpoints.up('md')} {
    flex-direction: row-reverse;
    align-items: center;
    gap: 4rem;
    && .StyledParagraph {
      flex-basis: 360px;
      margin: 0;
      flex-shrink: 0;
    }
  }
`;

export default function Homepage80Anniversary() {
  const { getMsg } = useIntl();
  return (
    <>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <Text.Title
          className="news-title"
          level={2}
          fontSize={{ xs: 24, sm: 28, md: 32 }}
          mt="2rem"
          mb={0}
          fontWeight={800}
        >
          {getMsg('80anniversary')}
        </Text.Title>
      </Container>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <Wrapper>
          <Text.Body fontSize="1.25rem" mt="-1rem" mb={0}>
            {getMsg('80anniversaryDescription')}
          </Text.Body>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube-nocookie.com/embed/7XUAav9RaF8?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullscreen
          />
        </Wrapper>
      </Container>
    </>
  );
}
