import React from 'react';
import get from 'lodash/get';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';

const PreProject = () => {
  const dbAssets = useConsumer();
  return (
    <>
      <Text.Title level={3}>Pre-Project</Text.Title>
      <Text.Paragraph fontSize={16} mb={24} color={'text.secondary'}>
        ภาคการศึกษาต้น
      </Text.Paragraph>
      <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
        กำหนดการฉบับย่อ
      </Text.Paragraph>
      <MonthlyTimeline
        events={[
          {
            title: 'สิงหาคม',
            subtitle: 'ประชุมครั้งแรก (กำหนดการ-หลักการเขียนโครงงาน)',
            current: isInRange([15, 8], [17, 8]),
          },
          {
            title: 'ต้นกันยายน',
            subtitle:
              // eslint-disable-next-line max-len
              'นำส่ง รายชื่อนิสิตในกลุ่ม อาจารย์ที่ปรึกษาโครงงาน และร่างหัวข้อโครงงาน (2.5%)',
            links: [
              {
                label: 'แบบฟอร์มสมาชิกกลุ่ม',
                href: get(dbAssets, 'bachelorProject.preProjectMembersForm'),
              },
            ],
            current: isInRange([18, 8], [3, 9]),
          },
          {
            title: 'ตุลาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'ส่งแบบร่างบทคัดย่อ บทที่ 1 และบทที่ 2 ให้อาจารย์ที่ปรึกษาโครงงาน',
            list: ['บทที่ 1 บทนำ', 'บทที่ 2 ทฤษฎีที่เกี่ยวข้อง'],
            current: isInRange([4, 9], [12, 10]),
          },
          {
            title: 'ปลายตุลาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'ส่งบทคัดย่อ พร้อมชื่อหัวข้อโครงงาน** (2.5%) ที่ myCourseville ก่อนเวลา 15.00',
            links: [
              {
                label: 'แบบฟอร์มบทคัดย่อ',
                href: get(
                  dbAssets,
                  'bachelorProject.preProjectIntroAndProjectName',
                ),
              },
            ],
            current: isInRange([13, 10], [29, 10]),
          },
          {
            title: 'ต้นพฤษศจิกายน',
            subtitle: 'ประกาศ กำหนดการการสอบนำเสนอ บน myCourseville ',
            list: [
              'หมายเลขกลุ่ม กำหนดวัน กรรมการสอบ และห้องสอบ ',
              // eslint-disable-next-line max-len
              'นิสิตต้องจำหมายเลขกลุ่มของตนให้ดี และใช้เขียนบนหน้าปกรายงานทุกครั้ง ',
            ],
            current: isInRange([30, 10], [5, 11]),
          },
          {
            title: 'กลางพฤษศจิกายน',
            subtitle: 'ส่งแบบร่างรายงานนำเสนอโครงร่างหัวข้อให้อาจารย์ที่ปรึกษา',
            list: ['บทที่ 1, 2, 3'],
            current: isInRange([6, 11], [19, 11]),
          },
          {
            title: 'ต้นธันวาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'ส่งรายงานนำเสนอโครงร่างหัวข้อโครงงาน (2.5%) ที่ห้องธุรการภาควิชาฯก่อนเวลา 15.00 **   ',
            list: ['จำนวน 3 ชุด (อาจารย์ที่ปรึกษาและกรรมการ 2 ท่าน) '],
            links: [
              {
                label: 'รูปแบบรายงาน',
                href: get(dbAssets, 'bachelorProject.preProjectReportTemplate'),
              },
            ],
            current: isInRange([20, 11], [3, 12]),
          },
          {
            title: 'กลางธันวาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'สอบนำเสนอหัวข้อโครงงาน',
            list: [
              'นำเสนอ 15 นาที ถาม-ตอบ 10 นาที (กรุณารักษาเวลา)',
              'ใช้ PowerPoint Template ของคณะฯ',
              'เตรียมเอกสารประกอบการนำเสนอให้กรรมการทุกท่าน',
              'เตรียมแบบฟอร์มให้คะแนนพร้อมกรอกรายละเอียด',
            ],
            links: [
              {
                label: 'Powerpoint Template',
                href: get(
                  dbAssets,
                  'bachelorProject.preProjectPowerpointTemplate',
                ),
              },
              {
                label: 'แบบฟอร์มให้คะแนน',
                href: get(dbAssets, 'bachelorProject.preProjectAssessmentForm'),
              },
            ],
            current: isInRange([17, 12], [18, 12]),
          },
          {
            title: 'ปลายธันวาคม',
            subtitle: 'ส่งรายงานนำเสนอหัวข้อโครงงานฉบับสมบูรณ์  (2.5%)',
            current: isInRange([19, 12], [21, 12]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'Pre-project ฉบับเต็ม'}
        href={get(dbAssets, 'bachelorProject.preProjectDetail')}
      />
      <br />
      <br />
      <br />
    </>
  );
};

PreProject.propTypes = {};
PreProject.defaultProps = {};

export default PreProject;
