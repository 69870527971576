import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Box from 'components/atoms/Box';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';

const Root = styled('div')`
  i {
    font-size: 18px;
    min-width: 18px;
    text-align: center;
  }
  .fa-facebook {
    font-size: 24px;
  }
  .ant-typography {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ContactInfo = ({ menus, getData }) => (
  <Root>
    {menus.map(({ id, icon, type, value }, index) => {
      const text = getData ? getData(id) : value;
      const renderByType = () => {
        if (type === 'phone') {
          return <a href={`tel:${text}`}>{text}</a>;
        }
        if (type === 'email') {
          return (
            <a href={`mailto:${text}`} target={'_blank'} rel={'noopener'}>
              {text}
            </a>
          );
        }
        if (type === 'url') {
          return (
            <a href={text} target={'_blank'} rel={'noopener'}>
              {text}
            </a>
          );
        }
        return text;
      };
      return (
        <Box mt={index !== 0 ? 12 : 0} {...Box.alignCenter}>
          <Icon type={icon} />
          <Text.Body ml={16} mb={0}>
            {text ? renderByType() : '-'}
          </Text.Body>
        </Box>
      );
    })}
  </Root>
);

ContactInfo.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  getData: PropTypes.func,
};
ContactInfo.defaultProps = {
  menus: [],
  getData: undefined,
};

export default ContactInfo;
