import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const MenuItem = styled('div')`
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: default;
  font-weight: 500;
  &[href] {
    cursor: pointer;
    :hover {
      background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.08)`};
    }
  }

  ${({ disabled, theme }) => `
    color: ${disabled ? theme.text.divider : theme.text.primary};
  `}
  ${({ heading, theme }) => ({
    ...(heading && {
      color: theme.primary.dark,
      fontSize: 13,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      marginBottom: 4,
    }),
  })}
`;

MenuItem.propTypes = {
  as: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  heading: PropTypes.bool,
};
MenuItem.defaultProps = {
  as: 'div',
  disabled: false,
  heading: false,
};

export default MenuItem;
