import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Input } from 'antd';
import styled from '@emotion/styled';
import { breakpoints } from 'theme';

const SearchInput = styled(({ className, onSearch, placeholder, ...props }) => (
  <Input.Search
    className={cx('SearchInput', className)}
    placeholder={placeholder}
    onSearch={onSearch}
    enterButton
    {...props}
  />
))`
  .ant-input,
  .ant-input-search-button {
    ${breakpoints.up('xs')} {
      height: 36px;
    }

    ${breakpoints.up('md')} {
      height: 48px;
    }
  }

  .ant-input {
    border: none;
    box-shadow: none;
    font-size: 16px;
    ${breakpoints.up('md')} {
      padding-left: 24px;
    }

    :focus {
      box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.12);
    }
  }

  .ant-input-search-button {
    ${breakpoints.up('sm')} {
      padding-left: 20px;
      padding-right: 20px;
    }

    ${breakpoints.up('md')} {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};
SearchInput.defaultProps = {
  placeholder: 'Search...',
};

export default SearchInput;
