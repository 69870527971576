import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import logo from 'assets/logo80th.png';
import useScrollTrigger from 'hocs/useScrollTrigger';
import { breakpoints } from '../../theme';

const darkRed = '#AC171C';
const BookmarkWrapper = styled('div')`
  cursor: pointer;
  position: relative;
  z-index: 1;
  filter: drop-shadow(-1px 2px 4px rgba(0, 0, 0, 0.38));
`;
const height = {
  xs: 80,
  sm: 144,
  md: 184,
};
const slope = {
  xs: '64px',
  sm: '121px',
  md: '160px',
};
const padding = {
  xs: '8px 4px',
  sm: '12px 10px',
  md: '16px 12px',
};

const Bookmark = styled('div')`
  clip-path: polygon(0% 0%, 100% 0, 100% ${slope.xs}, 50% 100%, 0 ${slope.xs});
  background: ${darkRed};
  height: ${height.xs}px;
  display: flex;
  align-items: flex-end;
  transition: 0.5s;
  padding: ${padding.xs};
  padding-right: 2px;

  ${breakpoints.up('sm')} {
    clip-path: polygon(
      0% 0%,
      100% 0,
      100% ${slope.sm},
      50% 100%,
      0 ${slope.sm}
    );
    background: ${darkRed};
    height: ${height.sm}px;
    padding: ${padding.sm};
    padding-right: 4px;
  }

  ${breakpoints.up('md')} {
    clip-path: polygon(
      0% 0%,
      100% 0,
      100% ${slope.md},
      50% 100%,
      0 ${slope.md}
    );
    background: ${darkRed};
    height: ${height.md}px;
    padding: ${padding.md};
    padding-right: 6px;
  }

  ${({ shrank, up }) => ({
    ...(shrank && { transform: 'translateY(0)' }),
    ...(up && { transform: 'translateY(-100%)' }),
  })}

  ${breakpoints.up('sm')} {
    ${({ shrank, up }) => ({
      ...(shrank && { transform: 'translateY(-36px)' }),
      ...(up && { transform: 'translateY(-100%)' }),
    })}
  }

  ${breakpoints.up('md')} {
    ${({ shrank, up }) => ({
      ...(shrank && { transform: 'translateY(-36px)' }),
      ...(up && { transform: 'translateY(-100%)' }),
    })}
  }

  img {
    display: block;
    width: 60px;

    ${breakpoints.up('sm')} {
      width: 80px;
    }
  }
`;

const LogoBookmark = ({ history }) => {
  const {
    // trigger,
    scrolled,
  } = useScrollTrigger();
  return (
    <BookmarkWrapper
      className={'LogoBookmark-root'}
      onClick={() => {
        animateScroll.scrollToTop({
          duration: 600,
          smooth: true,
          delay: 0,
        });
        history.push('/');
      }}
    >
      <Bookmark
        shrank={scrolled}
        // up={trigger ? true : undefined}
      >
        <img alt={'logo'} src={logo} />
      </Bookmark>
    </BookmarkWrapper>
  );
};

LogoBookmark.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
LogoBookmark.defaultProps = {};

export default withRouter(LogoBookmark);
