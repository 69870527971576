/* eslint-disable max-len */
import { Table } from 'antd';
import Text from 'components/atoms/Text';
import Bulletin from 'components/molecules/Bulletin';
import { useConsumer } from 'contexts/db';
import { css } from 'emotion';
import useScrollToElement from 'hooks/useScrollToElement';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import DownloadUI from 'components/atoms/DownloadUI';
import ROUTES from '../../constants/routes';

const tableCss = css`
  .ant-table-tbody > tr:hover > td {
    background: none !important;
  }

  .ant-table-thead > tr {
    > th {
      font-weight: bold;
    }
  }

  tr[data-row-key='1'],
  tr[data-row-key='8'],
  tr[data-row-key='9'],
  tr[data-row-key='16'] {
    background: #fafafa;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const AdmissionBachelor = ({ scrollValue }) => {
  const dbAssets = useConsumer();
  useScrollToElement(scrollValue);
  return (
    <div>
      <Element name={'b-program'}>
        <Text.Heading level={3} plainFont={false} letterSpacing={1}>
          หลักสูตรวิศวกรรมศาสตรบัณฑิต <br /> สาขาวิชาวิศวกรรมอุตสาหการ
        </Text.Heading>
      </Element>
      <Text.SubHeading mb={24}>
        Bachelor of Engineering | B.Eng.(IE)
      </Text.SubHeading>
      <Bulletin
        data={get(dbAssets, 'admission.bachelor')}
        defaultLabel={'B.Eng.(IE) Bulletin'}
      />
      <DownloadUI
        label={get(dbAssets, 'admission.bachelor.approvedElectiveListLabel')}
        href={get(dbAssets, 'admission.bachelor.approvedElectiveList')}
        style={{ marginRight: 16, marginBottom: 16 }}
      />
      <Text.Topic>ชื่อหลักสูตร</Text.Topic>
      <Text.Body mb={12}>
        <b>ภาษาไทย : </b> หลักสูตรวิศวกรรมศาสตรบัณฑิต สาขาวิชาวิศวกรรมอุตสาหการ
      </Text.Body>
      <Text.Body mb={12}>
        <b>ภาษาอังกฤษ : </b> Bachelor of Engineering Program in Industrial
        Engineering
      </Text.Body>
      <Text.Topic>ชื่อปริญญา</Text.Topic>
      <Text.Body mb={12}>
        <b>ชื่อเต็มภาษาไทย : </b> วิศวกรรมศาสตรบัณฑิต (วิศวกรรมอุตสาหการ)
      </Text.Body>
      <Text.Body mb={12}>
        <b>ชื่อย่อภาษาไทย : </b> วศ.บ. (วิศวกรรมอุตสาหการ)
      </Text.Body>
      <Text.Body mb={12}>
        <b>ชื่อเต็มภาษาอังกฤษ : </b> Bachelor of Engineering (Industrial
        Engineering)
      </Text.Body>
      <Text.Body mb={12}>
        <b>ชื่อย่อภาษาอังกฤษ : </b> B.Eng. (Industrial Engineering)
      </Text.Body>
      <Text.Topic>ปรัชญาการศึกษา</Text.Topic>
      <Text.Body>
        ผลิตบัณฑิตที่เป็นที่ต้องการของประเทศ
        และสามารถตอบสนองต่อความต้องการที่หลากหลายของ
        สาขาวิชาชีพที่เกี่ยวข้องกับวิศวกรรม อุตสาหการ
        ที่มีการแตกแขนงไปสู่อุตสาหกรรมการผลิต การบริการและงานด้านวิศวกรรมการเงิน
      </Text.Body>
      <Text.Topic>วัตถุประสงค์ของหลักสูตร</Text.Topic>
      <Text.Body>
        <ol>
          <li>
            เพื่อให้บัณฑิตมีความรู้พื้นฐานทางวิทยาศาสตร์และวิศวกรรมศาสตร์เพียงพอแก่การประกอบวิชาชีพวิศวกรรมอุตสาหการที่หลากหลาย
            และการเรียนรู้เพิ่มเติมได้
          </li>
          <li>
            เพื่อให้บัณฑิตมีความรู้ในหลักการพื้นฐานของวิศวกรรมอุตสาหการเพียงพอแก่การประกอบวิชาชีพวิศวกรรมอุตสาหการ
            และการเรียนรู้เพิ่มเติมได้
          </li>
          <li>
            เพื่อให้บัณฑิตมีทักษะด้านการใช้เครื่องมือทางวิศวกรรมเพียงพอที่จะใช้แก้ปัญหาทางวิศวกรรมได้อย่างถูกต้องและมีประสิทธิภาพ
          </li>
          <li>
            เพื่อเปิดโอกาสให้นิสิตมีอิสระในการเลือกเรียนตามแนวทางที่ต้องการ
          </li>
          <li>
            เพื่อให้นิสิตสามารถวางแผนการศึกษาของตนเองให้ต่อเนื่องไปยังระดับบัณฑิตศึกษาได้
          </li>
        </ol>
      </Text.Body>
      <Text.Topic>ระบบการศึกษา</Text.Topic>
      <Text.Body>
        <b>ระบบการศึกษา</b> ระบบทวิภาค ภาคการศึกษาละไม่น้อยกว่า 15 สัปดาห์
      </Text.Body>
      <Text.Body>
        <b>การจัดการศึกษาภาคฤดูร้อน</b> มีภาคฤดูร้อน
      </Text.Body>
      <Text.Body>
        <b>การเทียบเคียงหน่วยกิตในระบบทวิภาค</b> -ไม่มี-
      </Text.Body>
      <Text.Body>
        <b>การลงทะเบียนเรียน</b> ระดับปริญญาตรี ภาคการศึกษาปกติ ไม่เกิน 22
        หน่วยกิต ภาคฤดูร้อน ไม่เกิน 7 หน่วยกิต
      </Text.Body>
      <Text.Body>
        <b>วัน-เวลาในการดำเนินการเรียนการสอน</b> ระบบทวิภาค
        <ul>
          <li>ภาคการศึกษาต้น : สิงหาคม - ธันวาคม</li>
          <li>ภาคการศึกษาปลาย : มกราคม - พฤษภาคม</li>
          <li>ภาคฤดูร้อน : มิถุนายน – กรกฎาคม</li>
        </ul>
      </Text.Body>
      <Text.Topic>โครงสร้างหลักสูตร</Text.Topic>
      <Text.Body>
        <b>จำนวนหน่วยกิต</b> รวมตลอดหลักสูตร 144 หน่วยกิต
      </Text.Body>
      <Table
        defaultExpandAllRows
        scroll={{ x: true }}
        className={tableCss}
        pagination={false}
        bordered
        columns={[
          { title: 'หมวด', dataIndex: 'subject' },
          { title: 'หน่วยกิต', dataIndex: 'credit' },
        ]}
        dataSource={[
          {
            key: '1',
            subject: '1)  หมวดวิชาศึกษาทั่วไป',
            credit: 30,
            children: [
              {
                key: '2',
                subject: 'กลุ่มวิชาสังคมศาสตร์ ',
                credit: 3,
              },
              {
                key: '3',
                subject: 'กลุ่มวิชามนุษยศาสตร์ ',
                credit: 3,
              },
              {
                key: '4',
                subject: 'กลุ่มวิชาสหศาสตร์ ',
                credit: 3,
              },
              {
                key: '5',
                subject: 'กลุ่มวิชาวิทยาศาสตร์และคณิตศาสตร์ ',
                credit: 3,
              },
              {
                key: '6',
                subject: 'กลุ่มรายวิชาศึกษาทั่วไปกลุ่มพิเศษ ',
                credit: 6,
              },
              {
                key: '7',
                subject: 'กลุ่มวิชาภาษาต่างประเทศ ',
                credit: 12,
              },
            ],
          },
          {
            key: '8',
            subject: '2)  หมวดวิชาพื้นฐานทางคณิตศาสตร์และวิทยาศาสตร์',
            credit: 21,
          },
          {
            key: '9',
            subject: '3)  หมวดวิชาเฉพาะ',
            credit: 87,
            children: [
              {
                key: '10',
                subject: 'แผนการศึกษาปกติ',
                credit: '',
                children: [
                  {
                    key: '11',
                    subject: 'รายวิชาบังคับ',
                    credit: 78,
                  },
                  {
                    key: '12',
                    subject: 'รายวิชาเลือก',
                    credit: 9,
                  },
                ],
              },
              {
                key: '13',
                subject: 'แผนการศึกษาสหกิจศึกษา',
                credit: '',
                children: [
                  {
                    key: '14',
                    subject: 'รายวิชาบังคับ',
                    credit: 84,
                  },
                  {
                    key: '15',
                    subject: 'รายวิชาเลือก',
                    credit: 3,
                  },
                ],
              },
            ],
          },
          {
            key: '16',
            subject: '4)  หมวดวิชาเลือกเสรี',
            credit: 6,
          },
        ]}
      />
      <br />
      <Element name={'b-how-to-apply'}>
        <Text.Topic>คุณสมบัติของผู้มีสิทธิเข้าศึกษา</Text.Topic>
      </Element>
      <Text.Body>
        เป็นผู้สำเร็จการศึกษาระดับมัธยมศึกษาตอนปลาย
        และเป็นไปตามระเบียบการคัดเลือกบุคคลเข้าศึกษาในสถาบันอุดมศึกษา
        สำนักงานคณะกรรมการการอุดมศึกษา และจุฬาลงกรณ์มหาวิทยาลัย
        และได้รับการคัดเลือกให้ศึกษาในหลักสูตรวิศวกรรมศาสตรบัณฑิต
        สาขาวิชาวิศวกรรมอุตสาหการ
        ตามหลักเกณฑ์การคัดเลือกสาขาวิชาของคณะวิศวกรรมศาสตร์
        จุฬาลงกรณ์มหาวิทยาลัย
      </Text.Body>
      <Text.Topic>วิธีการคัดเลือกผู้เข้าศึกษา</Text.Topic>
      <Text.Body>
        หลักสูตรระดับปริญญาตรี
        เป็นไปตามข้อบังคับว่าด้วยการรับนักเรียนเข้าศึกษาในจุฬาลงกรณ์มหาวิทยาลัยและประกาศของสมาคมอธิการบดีแห่งประเทศไทย
        (สอท.)
      </Text.Body>
      <Element name={'b-fees'}>
        <Text.Topic>ค่าใช้จ่ายตลอดหลักสูตร</Text.Topic>
      </Element>
      <Text.Body>ค่าเล่าเรียนภาคการศึกษาละ 21,000 บาท</Text.Body>
      <Text.Topic
        fontSize={20}
        fontWeight={'bold'}
        component={Link}
        to={ROUTES.scholarships.get('/bachelor')}
      >
        ดูข้อมูลทุนการศึกษา ป.ตรี
      </Text.Topic>
      <br />
      <br />
      <Text textStyle={'update'}>** อัพเดทข้อมูลล่าสุด ปี 2562 **</Text>
    </div>
  );
};

AdmissionBachelor.propTypes = {
  scrollValue: PropTypes.string.isRequired,
};
AdmissionBachelor.defaultProps = {};

export default AdmissionBachelor;
