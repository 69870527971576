/* eslint-disable max-len */
import React from 'react';
import cx from 'clsx';
import omit from 'lodash/omit';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import {
  style,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  textAlign,
  color,
  space,
  size,
  display,
  maxWidth,
  minWidth,
  width,
  zIndex,
  overflow,
  flex,
  textStyle,
} from 'styled-system';

const bgColor = style({
  prop: 'bgColor',
  cssProperty: 'backgroundColor',
  key: 'colors',
});

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
});
const textOverflow = style({
  prop: 'textOverflow',
  cssProperty: 'textOverflow',
});
const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
});
const textIndent = style({
  prop: 'textIndent',
  cssProperty: 'textIndent',
});
const wordBreak = style({
  prop: 'wordBreak',
  cssProperty: 'wordBreak',
});

const whitelist = [
  'lineHeight',
  'textAlign',
  'wordBreak',
  'textStyle',
  'textTransform',
  'block',
  'plainFont',
  'bgColor',
];

const Text = styled(({ className, text, component, ...props }) => {
  const Component = component || Typography.Text;
  return (
    <Component
      className={cx('StyledText', className)}
      {...omit(props, whitelist)}
    />
  );
})`
  ${({ block }) => (block ? { display: 'block' } : {})}
  &.StyledText {
    ${textStyle}
    ${space}
    ${fontWeight}
    ${fontFamily}
    ${fontStyle}
    ${fontSize}
    ${letterSpacing}
    ${lineHeight}
    ${color}
    ${bgColor}
    ${size}
    ${textAlign}
    ${display}
    ${wordBreak}
    ${whiteSpace}
    ${textOverflow}
    ${maxWidth}
    ${minWidth}
    ${width}
    ${zIndex}
    ${overflow}
    ${flex}
    ${textTransform} 
    ${({ css }) => ({
      ...css,
    })}
  }
`;

Text.overline = {
  textTransform: 'uppercase',
  letterSpacing: 0.5,
  color: 'text.disabled',
  fontSize: 12,
};
Text.displayName = 'Text';
Text.defaultProps = {
  display: 'inline-block',
};
Text.Title = styled(({ className, component, ...props }) => {
  const Component = component || Typography.Title;
  return (
    <Component
      className={cx('StyledTitle', className)}
      {...omit(props, whitelist)}
    />
  );
})`
  &.StyledTitle {
    font-family: Kanit, san-serif;
    ${({ plainFont }) => ({
      ...(plainFont && {
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", Sarabun, "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      }),
    })}
    ${space}
    ${fontWeight}
    ${fontFamily}
    ${fontStyle}
    ${fontSize}
    ${letterSpacing}
    ${lineHeight}
    ${color}
    ${bgColor}
    ${textAlign}
    ${maxWidth}
    ${whiteSpace}
    ${textTransform} 
    ${textStyle}
    ${({ css }) => ({
      ...css,
    })}
  }
`;
Text.Heading = Object.assign({}, Text.Title);
Text.Heading.defaultProps = {
  level: 3,
  mb: 8,
  plainFont: true,
  fontWeight: 'bold',
};
Text.Topic = Object.assign({}, Text.Title);
Text.Topic.defaultProps = {
  level: 4,
  letterSpacing: '0.5px',
};

Text.Paragraph = styled(({ className, component, ...props }) => {
  const Component = component || Typography.Paragraph;
  return (
    <Component
      className={cx('StyledParagraph', className)}
      {...omit(props, whitelist)}
    />
  );
})`
  &.StyledParagraph {
    ${space}
    ${fontWeight}
    ${fontFamily}
    ${fontStyle}
    ${fontSize}
    ${letterSpacing}
    ${lineHeight}
    ${color}
    ${bgColor}
    ${textAlign}
    ${whiteSpace}
    ${wordBreak}
    ${textTransform} 
    ${textStyle}
    ${textIndent}
    ${({ css }) => ({
      ...css,
    })}
  }
  
  ul li, ol li {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }
`;
Text.Body = Object.assign({}, Text.Paragraph);
Text.Body.defaultProps = {
  fontSize: 16,
  letterSpacing: '0.12px',
  lineHeight: 1.75,
  mb: 24,
};
Text.SubHeading = Object.assign({}, Text.Paragraph);
Text.SubHeading.defaultProps = {
  fontSize: 18,
  letterSpacing: '0.12px',
  lineHeight: '24px',
  mb: 32,
};

export default Text;
