import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import ROUTES from 'constants/routes';
import { LABS } from 'constants/header3';
import { useIntl } from 'contexts/intl';
import DRMIS from './DRMIS';
import CHASE from './CHASE';
import HFE from './HFE';
import AMPE from './AMPE';
import ROM from './ROM';

const StyledNested = styled(NestedMenuTabsLayout)`
  .ant-menu-item {
    min-height: 56px;
    text-overflow: unset;
    white-space: normal;
    line-height: 24px;
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    &.ant-menu-item {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }
`;

const getLabs = locale =>
  LABS.filter(({ hidden }) => !hidden)
    .map(item => ({
      ...item,
      label: item.name[locale],
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

const LabsRootPage = ({
  match: {
    params: { name },
  },
  history,
}) => {
  const { locale } = useIntl();
  return (
    <StyledNested
      menus={getLabs(locale)}
      headingId={'labs'}
      defaultSelectedKeys={[name]}
      onMenuClick={item => {
        const lab = LABS.find(({ id }) => id === item.key);
        history.replace(
          ROUTES.labs.get(`/${item.key}/${lab ? lab.name[locale] : ''}`),
        );
      }}
    >
      {([key]) => (
        <>
          {key === 'drmis' && <DRMIS />}
          {key === 'chase' && <CHASE />}
          {key === 'hfe' && <HFE />}
          {key === 'ampe' && <AMPE />}
          {key === 'rom' && <ROM />}
        </>
      )}
    </StyledNested>
  );
};

LabsRootPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
LabsRootPage.defaultProps = {};

export default LabsRootPage;
