import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import useIntl from 'hooks/useIntl';
import CustomCard from 'components/molecules/CustomCard';
import Actions from 'components/molecules/Actions';
import Text from '../atoms/Text';

const ResearcherCard = ({
  avatarUrl,
  advisor,
  advisorUrl,
  title,
  researchArea,
  contact,
  social,
  loading,
  CardProps,
}) => {
  const { getMsg } = useIntl();
  const mail = contact ? contact.email : '';
  const linkedin = social && social.length ? social[0].url : '';
  return (
    <CustomCard
      {...CardProps}
      image={avatarUrl}
      loading={loading}
      actions={
        loading
          ? null
          : [
              <Tooltip title={mail}>
                <Actions.Item
                  href={`mailto:${mail}`}
                  icon={'mail'}
                  label={mail ? 'email' : '-'}
                  disabled={!mail}
                  external
                />
              </Tooltip>,
              <Tooltip title={linkedin}>
                <Actions.Item
                  href={linkedin}
                  icon={'fab fa-linkedin'}
                  label={linkedin ? 'linkedin/profile' : '-'}
                  external
                  disabled={!linkedin}
                />
              </Tooltip>,
            ]
      }
    >
      <CustomCard.Meta title={title} />
      <br />
      <Text textStyle={'overline'}>{getMsg('researchArea')}</Text>
      <Text.Body mb={0}>{researchArea}</Text.Body>
      <br />
      <Text textStyle={'overline'}>{getMsg('advisor')}</Text>
      <Text.Body mb={0} component={Link} to={advisorUrl}>
        {advisor}
      </Text.Body>
    </CustomCard>
  );
};

ResearcherCard.propTypes = {
  advisor: PropTypes.string,
  advisorUrl: PropTypes.string,
  avatarUrl: PropTypes.string,
  title: PropTypes.string,
  researchArea: PropTypes.string,
  contact: PropTypes.shape({}),
  social: PropTypes.shape({}),
  loading: PropTypes.bool,
  CardProps: PropTypes.shape({}),
};
ResearcherCard.defaultProps = {
  advisor: '',
  advisorUrl: '',
  avatarUrl: '',
  title: '',
  researchArea: '',
  contact: {},
  social: {},
  loading: false,
  CardProps: undefined,
};

export default ResearcherCard;
