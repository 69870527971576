import createCollectionContext from 'hooks/createCollectionContext';
import FireApi from 'firebase/fireApi';
import toHOC from './toHOC';

export const {
  Provider,
  Consumer,
  useConsumer,
  useInitFetch,
  Context,
} = createCollectionContext({
  collectionName: 'staffs',
  itemName: 'staff',
  fetchingApi: FireApi.fetchStaffs,
});

export const StaffProvider = Provider;
export const StaffConsumer = Consumer;
export const withConsumer = toHOC(Consumer);
export default Context;
