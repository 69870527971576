import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import useIntl from 'hooks/useIntl';
import useScrollToElement from 'hooks/useScrollToElement';
import { useInitFetch } from 'contexts/lecturer';
import { Row, Col } from 'antd';
import Text from 'components/atoms/Text';
import RoleCard from 'components/molecules/RoleCard';
import Container from 'components/layouts/Container';
import Query from 'factories/query';
import { RANK } from 'constants/temporary';

const head = RANK.HEAD;
const deputyChiefs = RANK.CHIEFS;
const committees = RANK.COMMITTEES;
const OrganizationPage = ({ location }) => {
  const query = Query().toObject(location.search);
  useScrollToElement(query.role);
  const { lecturers, status } = useInitFetch();
  const { getMsg, locale } = useIntl();
  const renderCard = id => {
    const data = status.isRequest
      ? { id }
      : lecturers.find(({ id: uid }) => uid === id);
    return <RoleCard data={data} loading={status.isRequest} locale={locale} />;
  };
  return (
    <Container style={{ flex: 'auto' }}>
      <Element name={'head'}>
        <Text.Title level={2} letterSpacing={0.5}>
          {getMsg('headOfDepartment')}
        </Text.Title>
      </Element>
      <Row type={'flex'}>
        <Col xs={24} sm={12} md={10}>
          {renderCard(head)}
        </Col>
      </Row>
      <br />
      <br />
      <Element name={'deputy-chief'}>
        <Text.Title level={2} letterSpacing={0.5}>
          {getMsg('deputyChief')}
        </Text.Title>
      </Element>
      <Row type={'flex'} gutter={16}>
        {deputyChiefs.map(id => (
          <Col key={id} xs={24} sm={12} md={8}>
            {renderCard(id)}
          </Col>
        ))}
      </Row>
      <br />
      <br />
      <Element name={'committee'}>
        <Text.Title level={2} letterSpacing={0.5}>
          {getMsg('committee')}
        </Text.Title>
      </Element>
      <Row type={'flex'} gutter={16}>
        {committees.map(id => (
          <Col key={id} xs={24} sm={12} md={8}>
            {renderCard(id)}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

OrganizationPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
OrganizationPage.defaultProps = {};

export default withRouter(OrganizationPage);
