import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const DrmisEN = () => (
  <div>
    <Box mx={{ xs: -16, sm: -32 }} mb={24}>
      <img
        style={{ display: 'block', maxWidth: '100%' }}
        alt={'cover'}
        src={
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FDRMIS_onBlack.jpg?alt=media&token=c52de49d-c085-4a5a-a57b-d8cb8412453e'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      Disaster and Risk Management Information Systems Research Unit (DRMIS)
    </Text.Heading>
    <Text.Topic>Research area</Text.Topic>
    <Text.Body>
      <ul>
        <li>Disaster Management</li>
        <li>Risk Management</li>
        <li>Business Continuity Management</li>
        <li>Management Information Systems</li>
        <li>Big Data and Data Analytics</li>
      </ul>
    </Text.Body>

    <Text.Topic>Members</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get('/9RMKKUm8yie7AT8Ryiqd/Natt%20Leelawat')}
          >
            Asst.Prof Natt Leelawat D.Eng.
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/aN5GJGNaJBHFm0TEr4YM/Prasert%20Akkharaprathomphong',
            )}
          >
            Asst. Prof. Prasert Akkharaprathomphong
          </Link>
        </li>
        <li>
          Asst. Prof. Supattra Visessri, Ph.D. (Department of Water Resources
          Engineering)
        </li>
        <li>
          Asst. Prof. Chatpan Chintanapakdee, Ph.D. (Department of Civil
          Engineering)
        </li>
        <li>Jing Tang, D.Eng. (International School of Engineering)</li>
      </ul>
    </Text.Body>

    <Text.Topic>Location</Text.Topic>
    <Text.Body>Room 511, 5F, Engineering Building 4</Text.Body>
  </div>
);

DrmisEN.propTypes = {};
DrmisEN.defaultProps = {};

export default DrmisEN;
