import { useReducer } from 'react';

const INITIAL = 'INITIAL';
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

const setStatus = status => ({
  isInitial: status === INITIAL,
  isRequest: status === REQUEST,
  isSuccess: status === SUCCESS,
  isFailure: status === FAILURE,
});

const reducer = (state, action) => {
  switch (action.type) {
    case INITIAL:
      return {
        ...state,
        ...setStatus(INITIAL),
      };
    case REQUEST:
      return {
        ...state,
        ...setStatus(REQUEST),
      };
    case SUCCESS:
      return {
        ...state,
        ...setStatus(SUCCESS),
      };
    case FAILURE:
      return {
        ...setStatus(FAILURE),
        error: action.error,
      };
    default:
      return state;
  }
};

export default (initialValue, init) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      ...setStatus(INITIAL),
      error: null,
      ...initialValue,
    },
    init,
  );

  return {
    status: state,
    request: () => dispatch({ type: REQUEST }),
    resolve: () => dispatch({ type: SUCCESS }),
    reject: () => dispatch({ type: FAILURE }),
    reset: () => dispatch({ type: INITIAL }),
  };
};
