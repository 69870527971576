import React from 'react';
import { Row, Col } from 'antd';
import { css } from 'emotion';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import useIntl from 'hooks/useIntl';
import ImageGallery from '../../components/molecules/ImageGallery';

const linkCss = css`
  display: inline-flex;
  vertical-align: top;
  border-radius: 6px;
  background-color: #000000;
  width: 144px;
  min-height: 48px;
  :first-of-type {
    margin-right: 16px;
  }
`;
const imageCss = css`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 48px;
`;

const MRT = () => {
  const { getMsg } = useIntl();
  return (
    <Box pb={24}>
      <Text.Title level={3}>{getMsg('cuPopBus')}</Text.Title>
      <Text.Paragraph>{getMsg('cuPopBusDescription')}</Text.Paragraph>
      <Text.Paragraph>{getMsg('cuPopBusApp')}</Text.Paragraph>
      <a
        className={linkCss}
        href={
          // eslint-disable-next-line max-len
          'https://play.google.com/store/apps/details?id=com.iseinc.cupopbus'
        }
        target={'_blank'}
        rel={'noopener'}
      >
        <img
          src={
            // eslint-disable-next-line max-len
            'https://static1.squarespace.com/static/55e50bdfe4b0a8e8abf3e82c/t/5b57434ef950b7329f3f65f0/1532445581749/global-playstore.png'
          }
          alt={'google-play'}
          className={imageCss}
        />
      </a>
      <a
        className={linkCss}
        href={'itms-apps://apps.apple.com/th/app/cupopbus/id824843908'}
        rel={'noopener'}
      >
        <img
          src={
            // eslint-disable-next-line max-len
            'https://solyogaflorida.com/wp-content/uploads/2018/03/download-on-app-store.png'
          }
          alt={'app-store'}
          className={imageCss}
        />
      </a>
      <br />
      <br />
      <ImageGallery
        singlePhoto
        galleryMinHeight={300}
        galleryProps={{
          columns: 1,
        }}
        images={[
          // eslint-disable-next-line max-len
          'https://payload.cargocollective.com/1/18/577311/9310101/a2finalpop-01-02-02_1_800.jpg',
        ]}
      />
      <Text textStyle={'caption'}>{getMsg('clickToZoom')}</Text>
      <br />
      <br />
      <Row>
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} 1
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('line1Route')}</Text.Paragraph>
        </Col>
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} 2
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('line2Route')}</Text.Paragraph>
        </Col>
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} 4
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('line3Route')}</Text.Paragraph>
        </Col>
      </Row>
    </Box>
  );
};

MRT.propTypes = {};
MRT.defaultProps = {};

export default MRT;
