import React from 'react';
import isFunction from 'lodash/isFunction';
import cx from 'clsx';
import styled from '@emotion/styled';
import {
  space,
  size,
  textAlign,
  display,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  width,
  height,
  backgroundColor,
  background,
  borders,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  overflow,
  boxShadow,
  flex,
  justifyContent,
  alignItems,
  alignSelf,
  alignContent,
  flexWrap,
  flexDirection,
  flexBasis,
  justifyItems,
  style,
  opacity,
} from 'styled-system';

const bgColor = style({
  prop: 'bgColor',
  cssProperty: 'backgroundColor',
  key: 'colors',
});

const Box = styled(
  ({
    component: Component = 'div',
    href,
    target,
    rel,
    className,
    onClick,
    children,
    to,
    // eslint-disable-next-line no-shadow
    style,
  }) => (
    <Component
      className={cx('Box', className)}
      onClick={onClick}
      style={style}
      {...to && { to }}
      {...href && { href }}
      {...target && { target }}
      {...rel && { rel }}
    >
      {children}
    </Component>
  ),
)`
  ${({ theme, css }) => ({
    ...(isFunction(css) ? css(theme) : css),
  })}
  ${bgColor}
  ${space}
  ${size}
  ${textAlign}
  ${display}
  ${maxWidth}
  ${maxHeight}
  ${minWidth}
  ${minHeight}
  ${width}
  ${height}
  ${backgroundColor}
  ${background}
  ${borders}
  ${position}
  ${zIndex}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${overflow}
  ${boxShadow}
  ${flex}
  ${justifyContent}
  ${alignContent}
  ${alignItems}
  ${alignSelf}
  ${flexBasis}
  ${flexWrap}
  ${flexDirection}
  ${justifyItems}
  ${opacity}
`;

const justifyBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const justifyCenter = {
  display: 'flex',
  justifyContent: 'center',
};
const justifyCenterColumn = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};
const alignCenter = {
  display: 'flex',
  alignItems: 'center',
};
const alignCenterColumn = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};
const allCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const allCenterColumn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};
Box.justifyBetween = justifyBetween;
Box.justifyCenter = justifyCenter;
Box.justifyCenterColumn = justifyCenterColumn;
Box.alignCenter = alignCenter;
Box.alignCenterColumn = alignCenterColumn;
Box.allCenter = allCenter;
Box.allCenterColumn = allCenterColumn;
Box.alignCenter = alignCenter;
Box.displayName = 'Box';

export default Box;
