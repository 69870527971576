/* eslint-disable max-len */
import ROUTES from 'constants/routes';

const flatMenus = menus =>
  menus.reduce((result, item) => {
    const { name, subMenus, hidden, flatten } = item;
    if (hidden) return result;
    if (flatten || (!name && subMenus)) {
      return [...result, ...flatMenus(subMenus)];
    }
    if (subMenus) {
      return [...result, { ...item, subMenus: flatMenus(subMenus) }];
    }
    return [...result, item];
  }, []);

const ADMISSION = {
  id: 'admission',
  name: {
    en: 'Admission',
    th: 'การรับสมัคร',
  },
  heading: true,
  flatten: true,
  subMenus: [
    {
      id: 'admission-calendar',
      name: {
        en: 'Admission calendar',
        th: 'ปฏิทินการเข้าศึกษา',
      },
      href: ROUTES.prospectAC.get(''),
    },
    {
      id: 'programs',
      name: {
        en: 'Degree Programs',
        th: 'หลักสูตรที่เปิดรับ',
      },
      href: ROUTES.admission.get('/master/m-eng?topic=m-program'),
    },
    {
      id: 'how-to-apply',
      name: {
        en: 'How to apply',
        th: 'วิธีการสมัคร',
      },
      href: ROUTES.admission.get('/master/m-eng?topic=m-how-to-apply'),
    },
    {
      id: 'fees',
      name: {
        en: 'Tuition fee',
        th: 'ค่าใช้จ่าย',
      },
      href: ROUTES.admission.get('/master/m-eng?topic=m-fees'),
    },
  ],
};

const ACADEMIC_AFFAIRS = {
  id: 'academic-affairs',
  heading: true,
  name: {
    en: 'Academic affairs',
    th: 'งานวิชาการ',
  },
  subMenus: [
    {
      id: 'academic-calendar',
      name: {
        en: 'Academic calendar',
        th: 'ปฏิทินการศึกษา',
      },
      href: ROUTES.currentAC.get(),
    },
    // ----------------------- เพิ่มใน [นิสิตปัจจุบัน->งานวิชาการ->หลักสูตร(tag ที่เพิ่มเข้ามา) -----------------------//
    {
      id: 'programs',
      name: {
        en: 'Programs',
        th: 'หลักสูตร',
      },
      href: ROUTES.admission.get('/master/m-eng?topic=m-program'),
    },
    // -------------------------------------------------------------------------------------------------//
    {
      id: 'time-table',
      name: {
        en: 'Time Table',
        th: 'ตารางสอน',
      },
      href: ROUTES.schedule.get(),
    },
    {
      id: 'courses',
      name: {
        en: 'Curriculum & Courses',
        th: 'แผนการศึกษาและรายวิชา',
      },
      href: ROUTES.courses.get(),
    },
  ],
};

const PROJECTS_INTERNSHIP = {
  id: 'project-internship',
  heading: true,
  colProps: {
    lg: 6,
    xl: 5,
  },
  name: {
    en: 'Projects & Internship',
    th: 'โครงงานและการฝึกงาน',
  },
  subMenus: [
    {
      id: 'intern',
      name: {
        en: 'Internship (Bachelor)',
        th: 'ฝึกงาน (นิสิตปี 3)',
      },
      href: ROUTES.internship.get(),
    },
    {
      id: 'senior-project',
      name: {
        en: 'Senior Project & Co-op',
        th: 'โครงงานนิสิตปี 4',
      },
      href: ROUTES.seniorBachelor.get(),
    },
    {
      id: 'thesis',
      name: {
        en: 'Thesis & Dissertation',
        th: 'วิทยานิพนธ์',
      },
      href: ROUTES.thesis.get(),
    },
    {
      id: 'student-exchange-outbound',
      name: {
        en: 'Student Exchange Outbound',
        th: 'นิสิตแลกเปลี่ยนต่างประเทศ',
      },
      href:
        'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/documents%2FProcess%20for%20outbound%20exchange%20undergraduate%20studentNL.pdf?alt=media&token=99ed0145-8b81-48bc-8806-32b1e809582f',
      external: true,
    },
  ],
};

const INFORMATION_TECH = {
  id: 'information-tech',
  colProps: {
    lg: 6,
    xl: 5,
  },
  name: {
    en: 'Information Technology',
    th: 'เทคโนโลยีสารสนเทศ',
  },
  heading: true,
  subMenus: [
    {
      id: 'courseville',
      name: {
        en: 'MyCourseVille',
        th: 'MyCourseVille',
      },
      href: 'https://www.mycourseville.com/',
      external: true,
    },
    {
      id: 'cu-webmail',
      name: {
        en: 'CU Webmail',
        th: 'CU Webmail',
      },
      href:
        'https://login.microsoftonline.com/?whr=chula.ac.th&sso_reload=true',
      external: true,
    },
    {
      id: 'cu-cas',
      name: {
        en: 'CU CAS',
        th: 'CU CAS',
      },
      href: 'https://www.cas.chula.ac.th/cas/',
      external: true,
    },
    {
      id: 'ie-up',
      name: {
        en: 'IE UP',
        th: 'IE UP',
      },
      href: 'http://ie.eng.chula.ac.th/~ieup/',
      external: true,
    },
    {
      id: 'ie-up-schedule',
      name: {
        en: 'IE UP Schedule',
        th: 'ตารางการสอบโครงร่างฯและวิทยานิพนธ์',
      },
      href: 'http://ie.eng.chula.ac.th/ieup/calendar.php',
      external: true,
    },
    {
      id: 'pi-student',
      name: {
        en: 'PI Student',
        th: 'PI Student',
      },
      href: 'https://pi.eng.chula.ac.th/',
      external: true,
    },
  ],
};

const FORMS_DOWNLOAD = {
  id: 'forms-download',
  name: {
    en: 'Forms Download',
    th: 'ดาวน์โหลดเอกสาร',
  },
  heading: true,
  subMenus: [
    {
      id: 'academic-form',
      name: {
        en: 'General request form',
        th: 'เอกสารคำร้องทั่วไป',
      },
      href: ROUTES.forms.get('category=general'),
    },
    {
      id: 'senior-project-form',
      name: {
        en: 'Senior project form',
        th: 'เอกสารโครงงานนิสิตปี 4',
      },
      href: ROUTES.forms.get('category=bachelor&search=คะแนน,โปรเจค,project'),
    },
    {
      id: 'internship-form',
      name: {
        en: 'Internship form',
        th: 'เอกสารการฝึกงาน',
      },
      href: ROUTES.forms.get('category=bachelor&search=ฝึกงาน'),
    },
    {
      id: 'thesis-form',
      name: {
        en: 'Thesis form',
        th: 'เอกสารวิทยานิพนธ์',
      },
      href: ROUTES.forms.get('category=master&search=วิทยานิพนธ์'),
    },
  ],
};

const PEOPLE = {
  id: 'people',
  name: {
    en: 'People',
    th: 'บุคลากร',
  },
  href: '',
  subMenus: [
    {
      id: 'academic',
      heading: true,
      flatten: true,
      name: {
        en: 'Academic',
        th: 'บุคลากรวิชาการ',
      },
      href: ROUTES.lecturers.get(),
      subMenus: [
        {
          id: 'faculty-members',
          name: {
            en: 'Faculty members',
            th: 'อาจารย์',
          },
          href: ROUTES.lecturers.get(),
        },
        {
          id: 'researchers',
          name: {
            en: 'Researchers',
            th: 'นักวิจัย',
          },
          href: ROUTES.researchers.get(),
        },
      ],
    },
    {
      id: 'admin',
      heading: true,
      flatten: true,
      name: {
        en: 'Staffs',
        th: 'บุคลากรสนับสนุน',
      },
      href: ROUTES.staffs.get(),
      subMenus: [
        {
          id: 'admin-staffs',
          name: {
            en: 'Administrative Staffs',
            th: 'เจ้าหน้าที่ธุรการ',
          },
          href: ROUTES.staffs.get('dept=admin'),
        },
        {
          id: 'lab-staffs',
          name: {
            en: 'Laboratory Staffs',
            th: 'เจ้าหน้าที่ปฏิบัติการ',
          },
          href: ROUTES.staffs.get('dept=lab'),
        },
      ],
    },
    {
      id: 'others',
      name: {
        en: 'Recognizing our people',
        th: 'บุคลากรผู้สร้างชื่อเสียง',
      },
      colProps: {
        lg: 6,
        xl: 5,
      },
      heading: true,
      flatten: true,
      subMenus: [
        {
          id: 'alumni',
          name: {
            en: 'Eminent Alumni',
            th: 'ศิษย์เก่าดีเด่น',
          },
          hidden: true,
        },
        {
          id: 'winner',
          name: {
            en: 'Award winners',
            th: 'ผลงานและรางวัล',
          },
          hidden: true,
        },
      ],
    },
  ],
};

const NEWS = {
  id: 'news',
  name: {
    en: 'News',
    th: 'ข่าวสาร',
  },
  href: ROUTES.news.get(),
};

const CONTACT_US = {
  id: 'contact-us',
  heading: true,
  name: {
    en: 'Contact us',
    th: 'ติดต่อเรา',
  },
  flatten: true,
  subMenus: [
    {
      id: 'location',
      name: {
        en: 'Location',
        th: 'ตำแหน่งภาควิชา',
      },
      href: ROUTES.aboutUs.get('/location'),
    },
    {
      id: 'how to',
      name: {
        en: 'Directions',
        th: 'การเดินทาง',
      },
      href: ROUTES.aboutUs.get('/transportation/suvarnabhumi'),
    },
  ],
};

export const LABS = [
  {
    id: 'rom',
    name: {
      en: 'Resources and Operations Management (ROM)',
      th: 'หน่วยวิจัยการจัดการทรัพยากรและการดำเนินงาน (ROM)',
    },
    href: ROUTES.labs.get('/rom/Resources and Operations Management'),
  },
  {
    id: 'LogRC',
    name: {
      en: 'Logistics and Supply Chain Research Center (LogRC)',
      th: 'ศูนย์วิจัยโลจิสติกส์และห่วงโซ่อุปทาน (LogRC)',
    },
    hidden: true,
  },
  // {
  //   id: 'has',
  //   name: {
  //     en: 'Center for Health Systems Analytics (HAS)',
  //     th: 'ศูนย์การวิเคราะห์ระบบสุขภาพ (HAS)',
  //   },
  //   hidden: true,
  // },
  {
    id: 'chase',
    name: {
      en: 'Center of Human factors & System Engineering (CHASE)',
      th: 'ศูนย์วิศวกรรมระบบและปัจจัยมนุษย์ (CHASE)',
    },
    href: ROUTES.labs.get(
      '/chase/Center of Human factors and System Engineering',
    ),
  },
  {
    id: 'hfe',
    name: {
      en: 'Human factors & Ergonomics Laboratory (HFE)',
      th: 'ห้องปฏิบัติการการยศาสตร์และปัจจัยมนุษย์ (HFE)',
    },
    href: ROUTES.labs.get('/hfe/Human factors & Ergonomics Laboratory'),
  },
  {
    id: 'orc',
    name: {
      en: 'Operations Research Center (ORC)',
      th: 'ศูนย์การวิจัยการดำเนินงาน (ORC)',
    },
    hidden: true,
  },
  {
    id: 'drmis',
    name: {
      en: 'Disaster & Risk Management Info Systems Research Unit (DRMIS)',
      th:
        'หน่วยปฏิบัติการวิจัยระบบสารสนเทศการจัดการภัยพิบัติและความเสี่ยง (DRMIS)',
    },
    href: ROUTES.labs.get(
      '/drmis/Disaster and Risk Management Information Systems Research Unit',
    ),
  },
  {
    id: 'ampe',
    name: {
      en: 'Advanced Manufacturing & Precision Engineering R&D Center (AMPE)',
      th:
        'ศูนย์วิจัยและพัฒนาเทคโนโลยีการผลิตขั้นสูงและวิศวกรรมเที่ยงตรง (AMPE)',
    },
    href: ROUTES.labs.get(
      '/ampe/Advanced Manufacturing & Precision Engineering Research and Development Center',
    ),
  },
];

const PUBLICATION_1 = [
  {
    id: 'econ',
    name: {
      en: 'Economic Decision Analysis',
      th: 'การวิเคราะห์การตัดสินใจทางเศรษฐศาสตร์',
    },
    href: ROUTES.publication.get(
      '/svIUwb2Sr0KOVp6mdPf6/Economic Decision Analysis',
    ),
  },
  {
    id: 'human',
    name: {
      en: 'Human Factors & Ergonomics',
      th: 'การยศาสตร์และปัจจัยมนุษย์',
    },
    href: ROUTES.publication.get(
      '/5lqults0BtbONyxccoMf/Human Factors & Ergonomics',
    ),
  },
  {
    id: 'logistics',
    name: {
      en: 'Logistics & Supply Chain',
      th: 'โลจิสติกส์และห่วงโซ่อุปทาน',
    },
    href: ROUTES.publication.get(
      '/ULHIiovdAFzg0qmI45Tr/Logistics & Supply Chain',
    ),
  },
  {
    id: 'manufac',
    name: {
      en: 'Manufacturing Engineering',
      th: 'วิศวกรรมการผลิต',
    },
    href: ROUTES.publication.get(
      '/hye0V5onm1RRLGS9tccp/Manufacturing Engineering',
    ),
  },
  {
    id: 'mng-sci',
    name: {
      en: 'Management Science',
      th: 'วิทยาการจัดการ',
    },
    href: ROUTES.publication.get('/0MHUpAwNNeM0kprpUG9m/Management Science'),
  },
];

const PUBLICATION_2 = [
  {
    id: 'quality',
    name: {
      en: 'Quality Engineering',
      th: 'วิศวกรรมคุณภาพ',
    },
    href: ROUTES.publication.get('/JvGYL8d4dFcHKglYJ9kx/Quality Engineering'),
  },
  {
    id: 'statistics',
    name: {
      en: 'Data Analytics and Statistics',
      th: 'การวิเคราะห์ข้อมูลและสถิติ',
    },
    href: ROUTES.publication.get(
      '/fszXGGt5EQNp0DI6UBG4/Data Analytics and Statistics',
    ),
  },
  {
    id: 'info-system',
    name: {
      en: 'Information Systems',
      th: 'การจำลองและการหาค่าที่เหมาะที่สุด',
    },
    href: ROUTES.publication.get('/tqmxOt8oiR4ecWa9NGkL/Information Systems'),
  },
  {
    id: 'strategic-risk-mng',
    name: {
      en: 'Strategic & Risk Management',
      th: 'การบริหารเชิงกลยุทธ์และความเสี่ยง',
    },
    href: ROUTES.publication.get(
      '/XCMtD3ySFbDhob5QJyvH/Strategic & Risk Management',
    ),
  },
  {
    id: 'safety-eng',
    name: {
      en: 'Safety Engineering',
      th: 'วิศวกรรมความปลอดภัย',
    },
    href: ROUTES.publication.get('/8XSyHOBH5gZOj9XNWjE0/Safety Engineering'),
  },
];

const menus = [
  {
    id: 'prospect-student',
    name: {
      en: 'Prospect Student',
      th: 'ผู้สนใจเข้าศึกษา',
    },
    href: '',
    subMenus: [
      ADMISSION,
      {
        id: 'scholarship',
        heading: true,
        flatten: true,
        name: {
          en: 'Scholarship',
          th: 'ทุนการศึกษา',
        },
        subMenus: [
          {
            id: 'academic-scholarship',
            name: {
              en: 'Academic Scholarship',
              th: 'ทุนการศึกษาทั่วไป',
            },
            href: ROUTES.scholarships.get(),
          },
          {
            id: 'exchange-scholarship',
            name: {
              en: 'Scholarship for Exchange',
              th: 'ทุนแลกเปลี่ยน',
            },
            hidden: true,
          },
        ],
      },
      {
        id: 'student-life',
        heading: true,
        hidden: true,
        name: {
          en: 'Student life',
          th: 'ชีวิตในรั้วจุฬาฯ',
        },
        subMenus: [
          {
            id: 'campus-exp',
            name: {
              // campus exp from chula
              en: 'Campus experience',
              th: 'ประสบการณ์ในรั้วจุฬาฯ',
            },
          },
          {
            id: 'diversity',
            name: {
              en: 'Diversity, Equality & Inclusion',
              th: 'ความหลากหลายและภราดรภาพ',
            },
          },
          {
            id: 'living',
            name: {
              en: 'Living in Bangkok',
              th: 'การใช้ชีวิตในกรุงเทพฯ',
            },
          },
        ],
      },
      {
        id: 'tour-event',
        heading: true,
        hidden: true,
        name: {
          en: 'Tour & Event',
          th: 'สถานที่และกิจกรรม',
        },
        subMenus: [
          {
            id: 'campus-tour',
            name: {
              en: 'Campus tours',
              th: 'รอบรั้วจุฬาฯ',
            },
          },
          {
            id: 'campus-event',
            name: {
              en: 'On-campus events',
              th: 'กิจกรรมในจุฬาฯ',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'current-student',
    name: {
      en: 'Current Student',
      th: 'นิสิตปัจจุบัน',
    },
    href: '',
    subMenus: [
      ACADEMIC_AFFAIRS,
      PROJECTS_INTERNSHIP,
      // {
      //   id: 'international-affairs',
      //   heading: true,
      //   hidden: true,
      //   name: {
      //     en: 'International affairs',
      //     th: 'ทุนการศึกษา',
      //   },
      //   subMenus: [
      //     {
      //       id: 'inbound-exchange',
      //       name: {
      //         en: 'Inbound exchange',
      //         th: 'Inbound exchange',
      //       },
      //     },
      //     {
      //       id: 'outbound-exchange',
      //       name: {
      //         en: 'Outbound exchange',
      //         th: 'Outbound exchange',
      //       },
      //     },
      //     {
      //       id: 'scholarship',
      //       name: {
      //         en: 'Scholarship',
      //         th: 'ทุนการศึกษา',
      //       },
      //     },
      //   ],
      // },
      INFORMATION_TECH,
      // {
      //   id: 'regulation',
      //   heading: true,
      //   hidden: true,
      //   name: {
      //     en: 'Regulation',
      //     th: 'กฎระเบียบ',
      //   },
      //   subMenus: [
      //     {
      //       id: 'graduation',
      //       name: {
      //         en: 'Graduation',
      //         th: 'จบการศึกษา',
      //       },
      //     },
      //     {
      //       id: 'exam-policies',
      //       name: {
      //         en: 'Exam Policies',
      //         th: 'ระเบียบข้อบังคับในการสอบ',
      //       },
      //     },
      //     {
      //       id: 'dress-code',
      //       name: {
      //         en: 'Student dress code',
      //         th: 'Student dress code',
      //       },
      //     },
      //     {
      //       id: 'university-regulation',
      //       name: {
      //         en: 'University regulation',
      //         th: 'กฎระเบียบของมหาลัย',
      //       },
      //     },
      //     {
      //       id: 'gen-behavior-student',
      //       name: {
      //         en: 'Student general behavior',
      //         th: 'Student general behavior',
      //       },
      //     },
      //   ],
      // },
      FORMS_DOWNLOAD,
    ],
  },
  PEOPLE,
  {
    id: 'research',
    name: {
      en: 'Research',
      th: 'งานวิจัย',
    },
    subMenus: [
      {
        id: 'lab',
        name: {
          en: 'Research center & Labs',
          th: 'ศูนย์วิจัยและห้องปฏิบัติการ',
        },
        colProps: {
          lg: 10,
          xl: 9,
        },
        heading: true,
        sorted: true,
        subMenus: LABS,
      },
      {
        id: 'research-area',
        name: {
          en: 'Publication',
          th: 'ผลงานตีพิมพ์',
        },
        colProps: {
          lg: 6,
          xl: 5,
        },
        heading: true,
        borderless: true,
        subMenus: [
          ...PUBLICATION_1,
          ...PUBLICATION_2.map(item => ({
            ...item,
            desktopHidden: true,
          })),
        ],
      },
      {
        id: 'research-area2',
        name: {
          en: ' ',
          th: ' ',
        },
        colProps: {
          lg: 6,
          xl: 5,
        },
        heading: true,
        hidden: true,
        subMenus: PUBLICATION_2,
      },
    ],
  },
  NEWS,
  {
    id: 'about-us',
    name: {
      en: 'About us',
      th: 'เกี่ยวกับเรา',
    },
    href: ROUTES.aboutUs.get(),
    subMenus: [
      {
        id: 'about-ie',
        name: {
          en: 'About IE',
          th: 'เกี่ยวกับภาควิชา',
        },
        heading: true,
        subMenus: [
          {
            id: 'history',
            name: {
              en: 'Our History',
              th: 'ประวัติความเป็นมา',
            },
            href: ROUTES.aboutIE.get('/our-history'),
          },
          // {
          //   id: 'vision',
          //   name: {
          //     en: 'Vision & Message',
          //     th: 'วิสัยทัศน์และสารจากผู้บริหาร',
          //   },
          //   href: ROUTES.aboutIE.get('/vision-and-message'),
          // },
          {
            id: 'fact',
            name: {
              en: 'Facts & Figures',
              th: 'เหตุการณ์สำคัญ',
            },
            hidden: true,
          },
        ],
      },
      {
        id: 'organization',
        name: {
          en: 'Organization',
          th: 'โครงสร้างองค์กร',
        },
        heading: true,
        subMenus: [
          {
            id: 'head',
            name: {
              en: 'Head of department',
              th: 'หัวหน้าภาควิชา',
            },
            href: ROUTES.organization.get('role=head'),
          },
          {
            id: 'deputyChief',
            name: {
              en: 'Deputy chief of department',
              th: 'รองหัวหน้าภาควิชา',
            },
            href: ROUTES.organization.get('role=deputy-chief'),
          },
          {
            id: 'executive',
            name: {
              en: 'Executive committees',
              th: 'คณะกรรมการบริหารภาควิชา',
            },
            href: ROUTES.organization.get('role=committee'),
          },
          {
            id: 'headStaff',
            name: {
              en: 'Head of staff',
              th: 'หัวหน้าธุรการ',
            },
            href: ROUTES.staffs.get('dept=admin'),
          },
        ],
      },
      CONTACT_US,
    ],
  },
];

const collapsedMenu = flatMenus(menus);
const footerMenus = [
  ADMISSION,
  ACADEMIC_AFFAIRS,
  INFORMATION_TECH,
  FORMS_DOWNLOAD,
  PEOPLE,
  {
    ...NEWS,
    subMenus: [
      {
        id: 'announcement',
        name: {
          en: 'Announcements',
          th: 'ประกาศสำคัญ',
        },
        href: ROUTES.news.get('/announcement'),
      },
      {
        id: 'academic',
        name: {
          en: 'Academic news',
          th: 'ข่าววิชาการ',
        },
        href: ROUTES.news.get('/academic'),
      },
      {
        id: 'job',
        name: {
          en: 'Jobs',
          th: 'ประกาศงาน',
        },
        href: ROUTES.news.get('/job'),
      },
      {
        id: 'event',
        name: {
          en: 'Events',
          th: 'ข่าวกิจกรรม',
        },
        href: ROUTES.news.get('/event'),
      },
    ],
  },
];

export default {
  megaMenu: menus,
  collapsedMenu,
  footerMenus,
  contactUs: CONTACT_US,
};
