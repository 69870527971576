import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import cx from 'clsx';
import styled from '@emotion/styled';
import Box from 'components/atoms/Box';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import Hidden from 'components/responsive/Hidden';

const getDownloadProps = link => ({
  htmlType: 'button',
  href: link,
  target: '_blank',
  type: 'primary',
  shape: 'round',
  disabled: !link || isEmpty(link),
});

const FixedButton = styled(Button)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 99;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  .ant-typography {
    margin: 0 !important;
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    bottom: -16px;
    font-size: 11px;
    background: #ffffff;
    text-shadow: none;
    line-height: 1;
  }
`;

const DownloadUI = ({
  className,
  getMsg,
  label,
  link,
  icon,
  iconProps,
  boxProps,
}) => (
  <React.Fragment>
    <Hidden only={'xs'}>
      <Box
        className={cx('DownloadUI-root', className)}
        {...Box.alignCenterColumn}
        {...boxProps}
      >
        <Icon fontSize={52} {...iconProps} type={icon} />
        <Box mt={8} mb={16}>
          <Text size="large">{label}</Text>
        </Box>
        <Button {...getDownloadProps(link)}>{getMsg('download')}</Button>
      </Box>
    </Hidden>
    <Hidden smUp>
      <FixedButton
        className={cx('DownloadUI-root', className)}
        {...getDownloadProps(link)}
      >
        <Icon type="download" />
        <Text>{label}</Text>
      </FixedButton>
    </Hidden>
  </React.Fragment>
);

DownloadUI.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.string,
  boxProps: PropTypes.shape({}),
  iconProps: PropTypes.shape({}),
  getMsg: PropTypes.func,
};
DownloadUI.defaultProps = {
  className: '',
  icon: 'fal fa-file-alt',
  link: '',
  boxProps: {},
  iconProps: {},
  getMsg: () => '',
};

export default DownloadUI;
