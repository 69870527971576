import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FireApi from 'firebase/fireApi';
import { withConsumer } from 'contexts/post';
import useIntl from 'hooks/useIntl';
import { Skeleton, Divider, Card, Row, Col } from 'antd';
import Icon from 'components/atoms/Icon';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import DownloadUI from 'components/atoms/DownloadUI';
import ImageGallery from 'components/molecules/ImageGallery';
import RichTextStyle from 'components/molecules/RichTextEditor/RichTextStyle';
import BigPost from 'components/molecules/Post/BigPost';
import ROUTES from 'constants/routes';
import { imageService } from 'factories/Image';

const bigPostCss = css`
  margin-bottom: 16px;

  .SmallPost-root {
    min-height: 172px;
  }
`;

const PostDetail = ({ match: { params }, getOnePost, mergeOnePost }) => {
  const { getMsg, locale, displayText, formatDate } = useIntl();
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [data, setData] = useState(null);
  const [relatedLoading, setRelatedLoading] = useState(true);
  const preData = getOnePost(params.postId);
  useEffect(() => {
    const cachedPost = relatedPosts.find(({ id }) => id === params.postId);
    if (cachedPost) {
      setData(cachedPost);
      mergeOnePost(cachedPost);
    } else if (preData) {
      setData(preData);
      mergeOnePost(preData);
      setLoading(false);
    } else {
      FireApi.fetchPostDetail(params.postId)
        .then(response => {
          setData(response);
          mergeOnePost(response);
        })
        .then(() => setLoading(false));
    }
  }, params.postId);
  useEffect(() => {
    FireApi.fetchPosts({
      limit: 2,
      lastId: params.postId,
    })
      .then(setRelatedPosts)
      .then(() => setRelatedLoading(false));
  }, params.postId);
  const title = get(data, 'title');
  const content = get(data, 'content');
  const createdAt = get(data, 'createdAt');
  const tags = get(data, 'tags', []);
  const coverImage = get(data, 'coverImage', '');
  const images = get(data, 'images', []);
  const files = get(data, 'files', []);
  return (
    <Box
      mx={'auto'}
      width={'100%'}
      maxWidth={768}
      py={{ xs: 32, md: 48 }}
      px={{ xs: 16, md: 24 }}
      bgColor={'#ffffff'}
      boxShadow={'0 0 8px 0 rgba(0,0,0,0.08)'}
    >
      {loading ? (
        <>
          <Skeleton title={false} paragraph={{ rows: 1, width: 40 }} />
          <Divider style={{ margin: '12px 0' }} />
          <Skeleton active paragraph={false} />
          <Skeleton title={false} paragraph={{ rows: 1, width: 100 }} />
          <Box mt={24} />
          <Skeleton title={false} paragraph={{ rows: 4 }} />
          <Box mt={32} />
          <Skeleton title={false} active paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          {coverImage && (
            <Box
              height={{ xs: 160, sm: 240, md: 320 }}
              mx={{ xs: -16, sm: -32, md: -24 }}
              mt={{ xs: -32, md: -48 }}
              mb={{ xs: 16, md: 24 }}
              bgColor={'#e5e5e5'}
            >
              <img
                alt={'cover'}
                src={coverImage}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: '50% 40%',
                }}
              />
            </Box>
          )}
          <Box display={'flex'} mb={12}>
            {tags.map((tag, i) => (
              <React.Fragment key={tag}>
                <Text
                  textTransform={'uppercase'}
                  color={'primary.main'}
                  letterSpacing={1}
                  fontWeight={'bold'}
                >
                  {tag}
                </Text>
                {i !== tags.length - 1 && (
                  <Box width={'1px'} mx={16} bgColor={'grey.300'} />
                )}
              </React.Fragment>
            ))}
          </Box>
          <Text.Title level={2}>{displayText(title)}</Text.Title>
          <Box mb={24}>
            <Icon type={'calendar'} style={{ fontSize: 20 }} />
            <Text
              color={'text.secondary'}
              fontWeight={'bold'}
              fontSize={16}
              ml={8}
            >
              {formatDate(createdAt)}
            </Text>
          </Box>
          <RichTextStyle className={'front-end'}>
            <div dangerouslySetInnerHTML={{ __html: displayText(content) }} />
          </RichTextStyle>
          {files.length > 0 && (
            <>
              <Text.Topic>{getMsg('relatedFiles')}</Text.Topic>
              <Box mt={-16}>
                <Row gutter={16} type={'flex'}>
                  {imageService.mapImages(files).map(({ src, name }) => (
                    <Col key={src} style={{ marginTop: 16 }}>
                      <DownloadUI label={name} getMsg={getMsg} href={src} />
                    </Col>
                  ))}
                </Row>
              </Box>
            </>
          )}
          {images.length > 0 && (
            <>
              <Divider />
              <Text.Topic>{getMsg('relatedImages')}</Text.Topic>
              <ImageGallery images={images} />
            </>
          )}
        </>
      )}
      {relatedLoading && (
        <Row gutter={16}>
          {[0, 1].map(number => (
            <Col xs={24} sm={12} key={number}>
              <Card loading style={{ marginBottom: 16 }} />
            </Col>
          ))}
        </Row>
      )}
      {relatedPosts.length > 0 && (
        <>
          <Divider />
          <Text.Title level={4}>{getMsg('otherPosts')}</Text.Title>
          <Row gutter={16}>
            {relatedPosts.map(post => (
              <Col xs={24} sm={12} key={post.id}>
                <Link
                  to={ROUTES.postDetail.get(post.id, displayText(post.title))}
                >
                  <BigPost className={bigPostCss} locale={locale} {...post} />
                </Link>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Box>
  );
};

PostDetail.propTypes = {
  match: PropTypes.shape({}).isRequired,
  getOnePost: PropTypes.func.isRequired,
  mergeOnePost: PropTypes.func.isRequired,
};
PostDetail.defaultProps = {};

export default withConsumer(PostDetail);
