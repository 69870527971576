import { useEffect } from 'react';
import { animateScroll } from 'react-scroll';

const scrollConfig = {
  duration: 400,
  smooth: true,
  delay: 0,
};

export default (pathname, whitelist = []) => {
  useEffect(() => {
    if (!whitelist.some(path => pathname.includes(path))) {
      animateScroll.scrollToTop(scrollConfig);
    }
  }, [pathname]);
};
