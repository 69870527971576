import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useApi from 'hooks/useApi';
import { animateScroll } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { css } from 'emotion';
import FireApi from 'firebase/fireApi';
import { useConsumer } from 'contexts/form';
import { Affix, Button, List, Skeleton, Tabs, Input } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import TagList from 'components/molecules/TagList';
import Container from 'components/layouts/Container';
import Hidden from 'components/responsive/Hidden';
import useIntl from 'hooks/useIntl';
import { FORM_CATEGORIES, FORM_CATEGORY_BY_ID } from 'constants/temporary';
import Query from 'factories/query';
import { getIcon } from 'helpers/functions';

const array = Array(8)
  .fill(0)
  .map((_, i) => ({ key: i }));

const StyledList = styled(List)`
  .ant-list-item {
    padding: 24px 0;
  }
  .ant-list-item-action > li:first-child {
    padding: 0;
  }
`;

const inputStyle = css`
  input {
    border: none;
    background-color: #f0f0f2;
  }
  .ant-input-clear-icon {
    font-size: 18px;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 32px;
  margin-top: 6px;
  margin-right: 16px;
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      margin-top: 9px;
      font-size: 44px;
      margin-right: 20px;
    }
  `};
`;

const stringIncludes = (main, str) =>
  main.toLowerCase().includes(str.toLowerCase());

const FormsPage = ({ location, history }) => {
  const query = Query().toObject(location.search);
  const { forms, concatForms } = useConsumer();
  const [category, setCategory] = useState(query.category || 'all');
  const [formName, setFormName] = useState(query.search || '');
  const { api, status } = useApi(FireApi.fetchForms);
  const filteredForms = forms
    .filter(({ category: catId }) => {
      if (category === 'all') return true;
      return catId.includes(category);
    })
    .filter(({ title }) => {
      if (!formName) {
        return true;
      }
      const fullTitle = `${title.th}_${title.en}`;
      if (Array.isArray(formName)) {
        return formName.some(name => stringIncludes(fullTitle, name));
      }
      return stringIncludes(fullTitle, formName);
    });
  useEffect(() => {
    setCategory(query.category || 'all');
    setFormName(query.search || '');
  }, [location.search]);
  useEffect(() => {
    if (!filteredForms.length) {
      api({ categoryId: category }).then(result => {
        concatForms(result);
      });
    }
  }, [category]);
  const { getMsg, displayText } = useIntl();
  const exceed = 8 - filteredForms.length;
  const renderEmptyBox = () => <Box height={120 * (exceed < 0 ? 0 : exceed)} />;

  return (
    <Container contracted style={{ flex: 'auto' }}>
      <Affix offsetTop={0}>
        <Box bgColor={'#f7f7f7'} pb={8}>
          <Box mx={{ xs: -16, sm: 0 }} {...Box.justifyBetween}>
            <Tabs
              activeKey={category}
              onChange={key => {
                animateScroll.scrollToTop({
                  duration: 400,
                  smooth: true,
                  delay: 0,
                });
                setCategory(key);
                history.replace(`${location.pathname}?category=${key}`);
              }}
              style={{ width: '100%' }}
            >
              <Tabs.TabPane key={'all'} tab={getMsg('all')} />
              {FORM_CATEGORIES.map(({ label, id }) => (
                <Tabs.TabPane key={id} tab={displayText(label)} />
              ))}
            </Tabs>
          </Box>
          <Input.Search
            allowClear
            enterButton
            className={inputStyle}
            placeholder={'Search...'}
            value={Array.isArray(formName) ? formName.join(',') : formName}
            onChange={e => setFormName(e.target.value)}
          />
        </Box>
      </Affix>
      <Box pb={{ xs: 16, sm: 24 }}>
        <StyledList
          itemLayout="horizontal"
          dataSource={status.isRequest ? array : filteredForms}
          renderItem={item => {
            const commonProps = {
              href: get(item, 'url'),
              target: '_blank',
              rel: 'noopener',
              type: 'primary',
              icon: 'download',
            };
            return (
              <List.Item
                actions={
                  status.isRequest
                    ? null
                    : [
                        <>
                          <Hidden smUp>
                            <Button
                              {...commonProps}
                              shape={'circle'}
                              size={'large'}
                            />
                          </Hidden>
                          <Hidden smDown>
                            <Button {...commonProps} shape={'round'}>
                              {getMsg('download')}
                            </Button>
                          </Hidden>
                        </>,
                      ]
                }
              >
                <Skeleton
                  avatar
                  title={false}
                  loading={status.isRequest}
                  active
                >
                  <Box display={'flex'} flex={'1 1'}>
                    <StyledIcon
                      type={`fal fa-file-${getIcon(
                        get(item, ['title', 'th'], ''),
                        'alt',
                      )}`}
                    />
                    <Box flex={'auto'}>
                      <Text.Title
                        level={4}
                        mb={4}
                        fontSize={{ xs: 16, sm: 20 }}
                        letterSpacing={'0.5px'}
                      >
                        {displayText(item.title)}
                      </Text.Title>
                      <TagList
                        mapById={FORM_CATEGORY_BY_ID}
                        tags={item.category}
                      />
                    </Box>
                  </Box>
                </Skeleton>
              </List.Item>
            );
          }}
        />
        {renderEmptyBox()}
      </Box>
    </Container>
  );
};

FormsPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
FormsPage.defaultProps = {};

export default withRouter(FormsPage);
