// outlined icon
export {
  default as UploadOutline,
} from '@ant-design/icons/lib/outline/UploadOutline';

export {
  default as PlusOutline,
} from '@ant-design/icons/lib/outline/PlusOutline';

export {
  default as SearchOutline,
} from '@ant-design/icons/lib/outline/SearchOutline';

export {
  default as ScheduleOutline,
} from '@ant-design/icons/lib/outline/ScheduleOutline';

export {
  default as DeleteOutline,
} from '@ant-design/icons/lib/outline/DeleteOutline';

export {
  default as CloseOutline,
} from '@ant-design/icons/lib/outline/CloseOutline';

export {
  default as LeftOutline,
} from '@ant-design/icons/lib/outline/LeftOutline';

export {
  default as RightOutline,
} from '@ant-design/icons/lib/outline/RightOutline';

export {
  default as UserOutline,
} from '@ant-design/icons/lib/outline/UserOutline';

export {
  default as CalendarOutline,
} from '@ant-design/icons/lib/outline/CalendarOutline';

export {
  default as CopyOutline,
} from '@ant-design/icons/lib/outline/CopyOutline';

export {
  default as TeamOutline,
} from '@ant-design/icons/lib/outline/TeamOutline';

export {
  default as ReadOutline,
} from '@ant-design/icons/lib/outline/ReadOutline';

export {
  default as CaretDownOutline,
} from '@ant-design/icons/lib/outline/CaretDownOutline';

export {
  default as DashboardOutline,
} from '@ant-design/icons/lib/outline/DashboardOutline';

export {
  default as ShopOutline,
} from '@ant-design/icons/lib/outline/ShopOutline';

export {
  default as FileTextOutline,
} from '@ant-design/icons/lib/outline/FileTextOutline';

export {
  default as FileDoneOutline,
} from '@ant-design/icons/lib/outline/FileDoneOutline';

export {
  default as KeyOutline,
} from '@ant-design/icons/lib/outline/KeyOutline';

export {
  default as LockOutline,
} from '@ant-design/icons/lib/outline/LockOutline';

export {
  default as UpCircleOutline,
} from '@ant-design/icons/lib/outline/UpCircleOutline';

export {
  default as DisconnectOutline,
} from '@ant-design/icons/lib/outline/DisconnectOutline';

export {
  default as UnlockOutline,
} from '@ant-design/icons/lib/outline/UnlockOutline';

export {
  default as StopOutline,
} from '@ant-design/icons/lib/outline/StopOutline';

export {
  default as FilterOutline,
} from '@ant-design/icons/lib/outline/FilterOutline';

export {
  default as PhoneOutline,
} from '@ant-design/icons/lib/outline/PhoneOutline';

export {
  default as MailOutline,
} from '@ant-design/icons/lib/outline/MailOutline';

// filled icon
export { default as DeleteFill } from '@ant-design/icons/lib/fill/DeleteFill';

export { default as MailFill } from '@ant-design/icons/lib/fill/MailFill';

export { default as PhoneFill } from '@ant-design/icons/lib/fill/PhoneFill';

export {
  default as CloseCircleFill,
} from '@ant-design/icons/lib/fill/CloseCircleFill';

export {
  default as FacebookFill,
} from '@ant-design/icons/lib/fill/FacebookFill';

export {
  default as LinkedinFill,
} from '@ant-design/icons/lib/fill/LinkedinFill';

export {
  default as PaperClipOutline,
} from '@ant-design/icons/lib/outline/PaperClipOutline';

export {
  default as EyeOutline,
} from '@ant-design/icons/lib/outline/EyeOutline';

export {
  default as FileProtectOutline,
} from '@ant-design/icons/lib/outline/FileProtectOutline';

export {
  default as DownloadOutline,
} from '@ant-design/icons/lib/outline/DownloadOutline';

export {
  default as FileSyncOutline,
} from '@ant-design/icons/lib/outline/FileSyncOutline';

export {
  default as DownOutline,
} from '@ant-design/icons/lib/outline/DownOutline';

export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline';
export {
  default as FileImageOutline,
} from '@ant-design/icons/lib/outline/FileImageOutline';
export {
  default as MinusCircleOutline,
} from '@ant-design/icons/lib/outline/MinusCircleOutline';

export {
  default as CheckOutline,
} from '@ant-design/icons/lib/outline/CheckOutline';

export { default as CaretUpFill } from '@ant-design/icons/lib/fill/CaretUpFill';
export {
  default as CheckCircleFill,
} from '@ant-design/icons/lib/fill/CheckCircleFill';
export {
  default as InfoCircleFill,
} from '@ant-design/icons/lib/fill/InfoCircleFill';
export {
  default as ExclamationCircleFill,
} from '@ant-design/icons/lib/fill/ExclamationCircleFill';
export {
  default as CaretDownFill,
} from '@ant-design/icons/lib/fill/CaretDownFill';

export {
  default as LoadingOutline,
} from '@ant-design/icons/lib/outline/LoadingOutline';

export {
  default as ArrowRightOutline,
} from '@ant-design/icons/lib/outline/ArrowRightOutline';
