import React from 'react';
import cx from 'clsx';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { withIntl } from 'contexts/intl';

const invertedButton = css`
  border-radius: 40px;
  border-color: rgba(255, 255, 255, 0.38);
  color: rgba(255, 255, 255, 0.87);
  :hover,
  :focus {
    border-color: #fff;
    color: #fff;
  }
`;

const LangSwitcher = ({
  className,
  inverted,
  locale,
  switchLocale,
  buttonProps,
}) => (
  <Button
    ghost={inverted}
    className={cx(inverted && invertedButton, className)}
    {...buttonProps}
    onClick={() => switchLocale(locale === 'en' ? 'th' : 'en')}
  >
    {locale === 'en' ? '🇹🇭 ไทย' : '🇺🇸 English'}
  </Button>
);

LangSwitcher.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  locale: PropTypes.string.isRequired,
  switchLocale: PropTypes.func.isRequired,
  buttonProps: PropTypes.shape({}),
  inverted: PropTypes.bool,
  languages: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired }),
  ),
};
LangSwitcher.defaultProps = {
  className: '',
  style: {},
  buttonProps: {},
  inverted: false,
  languages: [],
};

export default withIntl(LangSwitcher);
