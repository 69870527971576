/* eslint-disable spaced-comment */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Affix, Row, Col } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Hidden from 'components/responsive/Hidden';
// import useScrollTrigger from 'hocs/useScrollTrigger';

const className = css`
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 16px;
  margin-bottom: 16px;
`;

// const Dummy = () => {
//   const { trigger, scrolled } = useScrollTrigger();
//   return (
//     <Box
//       css={{ transition: '0.3s' }}
//       mt={trigger ? -32 : 0}
//       pt={scrolled && !trigger ? 20 : 0}
//     />
//   );
// };

const Seperator = ({ children, heading }) => {
  const left = children()[0];
  const right = children()[1];
  const renderLeftContent = () => (
    <Box
      height={'100%'}
      py={{ xs: 16, sm: 48, md: 36 }}
      minHeight={460}
      position={'relative'}
    >
      {/*<Hidden mdDown>*/}
      {/*  <Dummy />*/}
      {/*</Hidden>*/}
      <Box maxWidth={256} ml={'auto'}>
        {heading && (
          <>
            <Text className={className}>{heading}</Text>
            <br />
          </>
        )}
        {left}
      </Box>
    </Box>
  );
  return (
    <Row type={'flex'}>
      <Col sm={7} lg={8} style={{ borderRight: '1px solid #e8e8e8' }}>
        <Hidden mdDown>
          <Affix offsetTop={48}>{renderLeftContent()}</Affix>
        </Hidden>
        <Hidden mdUp>
          <Affix offsetTop={0}>{renderLeftContent()}</Affix>
        </Hidden>
      </Col>
      <Col sm={17} lg={16}>
        <Box
          bgColor={'#ffffff'}
          height={'100%'}
          position={'relative'}
          zIndex={2}
        >
          {right}
        </Box>
        <Box
          position={'fixed'}
          mr={-999}
          pr={999}
          bgColor={'#ffffff'}
          height={'100%'}
          zIndex={1}
          top={0}
        />
      </Col>
    </Row>
  );
};

Seperator.propTypes = {
  children: PropTypes.func.isRequired,
  heading: PropTypes.string,
};
Seperator.defaultProps = {
  heading: '',
};

export default Seperator;
