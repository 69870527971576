import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Modal } from 'antd';
import CustomCard from 'components/molecules/CustomCard';
import Actions from 'components/molecules/Actions';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import TagList from 'components/molecules/TagList';
import RichTextStyle from 'components/molecules/RichTextEditor/RichTextStyle';
import useIntl from 'hooks/useIntl';
import { SCHOLARSHIP_CATEGORY_BY_ID } from 'constants/temporary';

const ScholarshipCard = ({
  logo,
  title,
  subtitle,
  sponsor,
  url,
  categories,
  detail,
  loading,
}) => {
  const { displayText, getMsg } = useIntl();
  const [open, setOpen] = useState(false);
  return (
    <>
      <CustomCard
        loading={loading}
        image={logo}
        iconPlaceholder={'fas fa-image'}
        title={sponsor}
        actions={[
          <Actions.Item
            icon={'far fa-search'}
            label={getMsg('readDetail')}
            onClick={() => setOpen(true)}
          />,
          <Tooltip title={url}>
            <Actions.Item
              href={url}
              icon={'far fa-globe'}
              label={url ? getMsg('sponsorLink') : '-'}
              external
              disabled={!url}
            />
          </Tooltip>,
        ]}
      >
        <CustomCard.Meta title={displayText(title)} />
        <Box mb={12} mt={16}>
          <TagList mapById={SCHOLARSHIP_CATEGORY_BY_ID} tags={categories} />
        </Box>
        <Text.Body mb={0} fontSize={14}>
          {displayText(subtitle)}
        </Text.Body>
      </CustomCard>
      <Modal
        visible={open}
        title={getMsg('scholarshipDetail')}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <RichTextStyle>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: detail }} />
        </RichTextStyle>
      </Modal>
    </>
  );
};

ScholarshipCard.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.shape({}),
  subtitle: PropTypes.shape({}),
  sponsor: PropTypes.string,
  url: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  detail: PropTypes.string,
  loading: PropTypes.bool,
};
ScholarshipCard.defaultProps = {
  logo: '',
  title: {},
  subtitle: {},
  sponsor: '',
  url: '',
  categories: [],
  detail: '',
  loading: false,
};

export default ScholarshipCard;
