import React, { useContext, useEffect } from 'react';
import upperFirst from 'lodash/upperFirst';
import useApi from './useApi';
import useCollection from './useCollection';

const createCollectionContext = (options = {}) => {
  const {
    additionalState,
    initialCollection = [],
    itemName = 'item',
    collectionName = 'collection',
    queryName = itemName,
    fetchingApi,
  } = options;
  const CollectionName = upperFirst(collectionName);
  const ItemName = upperFirst(itemName);
  const QueryName = upperFirst(queryName);
  const Context = React.createContext({
    collection: initialCollection,
    setCollection: () => {},
    ...additionalState,
  });

  // eslint-disable-next-line react/prop-types
  const Provider = ({ children }) => {
    const {
      collection,
      setCollection,
      concatCollection,
      mergeOneItem,
      deleteOneItem,
      getOneItem,
      getCollection,
      resourceQuery,
      setResourceQuery,
      concatResourceQuery,
      getResourceQuery,
    } = useCollection(initialCollection);
    return (
      <Context.Provider
        value={{
          collection,
          setCollection,
          concatCollection,
          mergeOneItem,
          deleteOneItem,
          getOneItem,
          getCollection,
          resourceQuery,
          setResourceQuery,
          concatResourceQuery,
          getResourceQuery,
          [collectionName]: collection,
          [`set${CollectionName}`]: setCollection,
          [`concat${CollectionName}`]: concatCollection,
          [`mergeOne${ItemName}`]: mergeOneItem,
          [`deleteOne${ItemName}`]: deleteOneItem,
          [`getOne${ItemName}`]: getOneItem,
          [`get${CollectionName}`]: getCollection,
          [`${queryName}Query`]: resourceQuery,
          [`set${QueryName}Query`]: setResourceQuery,
          [`concat${QueryName}Query`]: concatResourceQuery,
          [`get${QueryName}Query`]: getResourceQuery,
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  const useConsumer = () => useContext(Context);

  const useInitFetch = (customFetchingApi, forced = false) => {
    const { collection, setCollection } = useConsumer();
    const { api, status, reset } = useApi(fetchingApi || customFetchingApi);
    useEffect(() => {
      let ignore = false;
      if (forced || !collection || !collection.length) {
        api().then(response => {
          if (!ignore) {
            setCollection(response);
          }
        });
      }
      return () => {
        ignore = true;
      };
    }, []);
    return { collection, [collectionName]: collection, status, reset };
  };

  return {
    Context,
    Provider,
    Consumer: Context.Consumer,
    useConsumer,
    useInitFetch,
  };
};

export default createCollectionContext;
