import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const ChaseEN = () => (
  <div>
    <Box mx={{ xs: -16, sm: 0 }} mb={24}>
      <img
        style={{ display: 'block', maxWidth: '100%' }}
        alt={'cover'}
        src={
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FCHASE_4.jpg?alt=media&token=2bddda89-b0f1-4704-9135-018fe8407ab5'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      Center of Human Factor and System Engineering (CHASE)
    </Text.Heading>
    <Text.Topic>Research area</Text.Topic>
    <Text.Body>
      <ul>
        <li>Cognitive Human Factors</li>
        <li>System Engineering</li>
        <li>Usability Engineering</li>
        <li>Service Design & User Experience</li>
      </ul>
    </Text.Body>

    <Text.Topic>Members</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/K14thUQ7fYsIuhTOjaFs/Oran%20Kittithreerapronchai',
            )}
          >
            Assoc.Prof Oran Kittithreerapronchai PhD.
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/9maBFjohH9H11PeAHJhc/Arisara%20Jiamsanguanwong',
            )}
          >
            Assistant Professor Arisara Jiamsanguanwong, D.Eng
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>Location</Text.Topic>
    <Text.Body>Room 715, 7F, Engineering Building 4</Text.Body>
  </div>
);

ChaseEN.propTypes = {};
ChaseEN.defaultProps = {};

export default ChaseEN;
