import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Empty, Input } from 'antd';
import { useInitFetch } from 'contexts/lecturer';
import Box from 'components/atoms/Box';
import Timeline from 'components/molecules/Timeline';
import NestedMenuTabsLayout from 'components/layouts/NestedMenuTabsLayout';
import ListSkeleton from 'components/molecules/ListSkeleton';
import { useIntl } from 'contexts/intl';
import { CATEGORIES } from '../constants/temporary';
import ROUTES from '../constants/routes';

const getPublication = (lecturers, category, keyword = '') => {
  let result = [];
  lecturers.forEach(({ publication = [] }) => {
    result = [
      ...result,
      ...publication.filter(
        ({ year = '', title = '', subtitle = '', categories = [] }) => {
          if (!keyword) {
            return categories.includes(category);
          }
          return (
            categories.includes(category) &&
            // eslint-disable-next-line max-len
            `${year}${title.toLowerCase()}${subtitle.toLowerCase()}`.includes(
              keyword.toLowerCase(),
            )
          );
        },
      ),
    ];
  });
  return result.sort((a, b) => b.year - a.year);
};

const inputStyle = css`
  input {
    border: none;
    background-color: #f0f0f2;
  }
  .ant-input-clear-icon {
    font-size: 18px;
  }
`;

const PublicationPage = ({
  match: {
    params: { category },
  },
  history,
}) => {
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
    if (category) {
      setKeyword('');
    }
  }, [category]);
  const { mapLocale, getMsg } = useIntl();
  const { lecturers, status } = useInitFetch();
  const FILTERED_CATEGORIES = CATEGORIES.filter(item => !item.filterHidden);
  const NAMED_CATEGORIES = mapLocale(FILTERED_CATEGORIES, ['translation']);
  const publication = getPublication(lecturers, category, keyword);
  const boxProps = {
    px: 16,
    py: 16,
  };
  const isEmpty = !publication.length && !status.isRequest;
  const inputRef = useRef(null);
  return (
    <NestedMenuTabsLayout
      headingId={'publication'}
      defaultSelectedKeys={[category]}
      menus={NAMED_CATEGORIES.map(item => ({
        ...item,
        label: item.translation,
      }))}
      onMenuClick={item => {
        if (inputRef.current) {
          inputRef.current.input.state.value = '';
        }
        setKeyword('');
        const lab = NAMED_CATEGORIES.find(({ id }) => id === item.key);
        history.replace(
          ROUTES.publication.get(`/${item.key}/${lab ? lab.translation : ''}`),
        );
      }}
    >
      {() => (
        <Box height={'100%'}>
          <Box
            mt={-16}
            pt={16}
            bgColor={'#fff'}
            position={'sticky'}
            top={{ xs: 0, sm: 64 }}
            zIndex={10}
          >
            <Input.Search
              className={inputStyle}
              allowClear
              enterButton
              ref={inputRef}
              placeholder={getMsg('search')}
              onSearch={value => setKeyword(value)}
              onChange={e => {
                if (!e.target.value) {
                  setKeyword('');
                }
              }}
            />
          </Box>
          {status.isRequest && (
            <Box pt={16}>
              <ListSkeleton size={8} boxProps={boxProps} />
            </Box>
          )}
          {isEmpty && (
            <Box pt={40}>
              <Empty />
            </Box>
          )}
          {!status.isRequest && publication.length > 0 && (
            <Box pt={24}>
              <Timeline events={publication} />
            </Box>
          )}
        </Box>
      )}
    </NestedMenuTabsLayout>
  );
};

PublicationPage.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
PublicationPage.defaultProps = {};

export default PublicationPage;
