import { replaceUndefined } from 'helpers/functions';
import { USER_BY_TYPES } from 'constants/temporary';

const StaffFactory = () => {
  const schema = {
    avatarUrl: '',
    name: {
      en: '',
      th: '',
    },
    researchArea: '',
    advisorId: '',
    contact: {
      email: '',
    },
    social: [],
    type: USER_BY_TYPES.RESEARCHER,
  };

  const getName = ({ name }, locale = 'en') => name[locale];

  return {
    toApi: data => replaceUndefined(schema, data),
    getName,
  };
};

export const researcherService = StaffFactory();

export default StaffFactory;
