import React, { useState } from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { minWidth, space } from 'styled-system';
import Image from './Image';

const Wrapper = styled('div')`
  position: relative;
  text-align: center;
  ${minWidth}
  ${space}

  .shade-1 {
    width: 80px;
    height: 30px;
    z-index: 3;
    background: rgba(172, 23, 28, 0.38);
    transform: translateX(-50%) rotateX(40deg) translateY(8px) scale(0);
  }

  .shade-2 {
    width: 120px;
    height: 50px;
    z-index: 2;
    background: rgba(172, 23, 28, 0.16);
    transform: translateX(-50%) rotateX(45deg) translateY(24px) scale(0);
  }

  .shade-3 {
    width: 160px;
    height: 80px;
    z-index: 1;
    background: rgba(172, 23, 28, 0.08);
    transform: translateX(-50%) rotateX(50deg) translateY(51px) scale(0);
  }

  .shade-1,
  .shade-2,
  .shade-3 {
    transition: ${({ theme }) =>
      theme.transitions.create(['all'], { duration: 500 })};
    left: 50%;
    position: absolute;
    bottom: 0;
    border-radius: 50%;
  }

  &.-glowed,
  &:hover {
    .shade-1 {
      transform: translateX(-50%) rotateX(40deg) translateY(8px) scale(1);
    }
    .shade-2 {
      transform: translateX(-50%) rotateX(45deg) translateY(24px) scale(1);
    }
    .shade-3 {
      transform: translateX(-50%) rotateX(50deg) translateY(51px) scale(1);
    }
  }
`;

const Character = ({ image, children, glowed, ...props }) => {
  const [show, setShow] = useState(false);
  return (
    <Wrapper
      className={cx('Character-root', glowed && '-glowed')}
      onMouseOver={() => setShow(true)}
      onFocus={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      {...props}
    >
      <div className={'shade-1'} />
      <div className={'shade-2'} />
      <div className={'shade-3'} />
      <Image
        position={'relative'}
        zIndex={10}
        src={image}
        maxHeight={{ xs: 150 }}
      />
      {children({ show, setShow })}
    </Wrapper>
  );
};

Character.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.func,
  glowed: PropTypes.bool,
};
Character.defaultProps = {
  children: () => {},
  glowed: false,
};

export default Character;
