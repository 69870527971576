/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const RomTH = () => (
  <div>
    <Box mx={{ xs: -16, sm: -32 }} mb={24}>
      <img
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: 240,
          margin: 'auto',
        }}
        alt={'cover'}
        src={
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FROM.jpg?alt=media&token=c378da8d-d62b-4a9d-b3a4-2b079f41e2a6'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      หน่วยวิจัยการจัดการทรัพยากรและการดำเนินงาน
    </Text.Heading>
    <Text.Topic>งานวิจัยที่เกี่ยวข้อง</Text.Topic>
    <Text.Body>
      <ul>
        <li>การจัดการโซ่อุปทาน</li>
        <li>การจัดการด้านโลจิสติกส์</li>
        <li>การวางแผนทรัพยากรทางธุรกิจขององค์กรโดยรวม</li>
        <li>การออกแบบระบบและการดำเนินงาน</li>
        <li>การปรับปรุงกระบวนการทางธุรกิจ</li>
        <li>การวางแผนและควบคุมการผลิต</li>
        <li>การจัดการคลังสินค้า</li>
      </ul>
    </Text.Body>

    <Text.Topic>บุคลากร</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/jdZe6MhYUgo57TDzkBMV/Paveena%20Chaovalitwongse',
            )}
          >
            รศ. ดร.ปวีณา เชาวลิตวงศ์
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/edKtC4lJP8u3Rqvt8fXC/Naragain%20Phumchusri',
            )}
          >
            รศ. ดร.นระเกณฑ์ พุ่มชูศรี
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/54SBOSU5ssC7VSXdH3gY/Poom%20Luangjamekorn',
            )}
          >
            ผศ. ภูมิ เหลืองจามีกร
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/eqQyIsNixMu42Jiqv56r/Amonsiri%20Vilasdaechanont',
            )}
          >
            อ. ดร.อมรศิริ วิลาสเดชานนท์
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>สถานที่</Text.Topic>
    <Text.Body>ห้อง 716 ชั้น 7 อาคารเจริญวิศวกรรม (ตึก 4)</Text.Body>
  </div>
);

RomTH.propTypes = {};
RomTH.defaultProps = {};

export default RomTH;
