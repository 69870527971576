import * as firebase from 'firebase/app';
import difference from 'lodash/difference';
import { withLogger, withBodyLog } from 'factories/decorators';
import Course from 'factories/Course';
import { fireStoreService } from './fireConfig';
import { USER_BY_TYPES } from '../constants/temporary';

const assetCollection = fireStoreService.makeCollection('assets');
const courseCollection = fireStoreService.makeCollection('courses');
const userCollection = fireStoreService.makeCollection('users');
const scheduleCollection = fireStoreService.makeCollection('schedules');
const roomCollection = fireStoreService.makeCollection('rooms');
const postCollection = fireStoreService.makeCollection('posts');
const formCollection = fireStoreService.makeCollection('forms');
const scholarshipCollection = fireStoreService.makeCollection('scholarships');

// filter by
// 1. level (sophomore, junior, senior, master)
// 2. type (required, approvedElective)
// 3. categoryId (if categoryId is provided, dont attach lecturerId)
// 4. lecturerId (if lecturerId is provided, dont attach categoryId)
// 5. limit (amount of documents)
// 6. lastId (cursor document for next list)
const fetchCourses = (options = {}) => {
  const { level, type, categoryId, lecturerId, limit, lastId } = options;
  return courseCollection.find({
    queries: [
      ...Course().getLevelQuery(level),
      ['type', '==', type],
      ['categories', 'array-contains', categoryId],
      ['lecturers', 'array-contains', lecturerId],
    ],
    orderBy: [['code', 'asc']],
    limit,
    afterDocument: lastId,
  });
};

const fetchCourseDetail = courseCollection.findOne;

const createCourse = withBodyLog(
  data =>
    courseCollection.insertOne(data).then(result => {
      const { id: courseId, lecturers } = result;
      if (!lecturers.length) {
        return result;
      }
      return Promise.all(
        lecturers.map(lecturerId =>
          userCollection.updateOne({
            id: lecturerId,
            courses: firebase.firestore.FieldValue.arrayUnion(courseId),
          }),
        ),
      ).then(() => result);
    }),
  'new course',
);

const updateCourse = withBodyLog(
  (newData, prevData) =>
    courseCollection.updateOne(newData).then(result => {
      const { id: courseId } = result;
      const removedLecturers = difference(
        prevData.lecturers,
        newData.lecturers,
      );
      const addedLecturers = difference(newData.lecturers, prevData.lecturers);
      return Promise.all([
        ...removedLecturers.map(lecturerId =>
          userCollection.updateOne({
            id: lecturerId,
            courses: firebase.firestore.FieldValue.arrayRemove(courseId),
          }),
        ),
        ...addedLecturers.map(lecturerId =>
          userCollection.updateOne({
            id: lecturerId,
            courses: firebase.firestore.FieldValue.arrayUnion(courseId),
          }),
        ),
      ]).then(() => result);
    }),
  'updated course',
);

const deleteCourse = data =>
  courseCollection.deleteOne(data.id).then(() => {
    const { id: courseId, lecturers } = data;
    if (!lecturers) {
      return data;
    }
    return Promise.all(
      lecturers.map(lecturerId =>
        userCollection.updateOne({
          id: lecturerId,
          courses: firebase.firestore.FieldValue.arrayRemove(courseId),
        }),
      ),
    ).then(() => data);
  });

const fetchResearchers = () =>
  userCollection.find({
    queries: [['type', '==', USER_BY_TYPES.RESEARCHER]],
    orderBy: [['name', 'asc']],
  });

const fetchStaffs = () =>
  userCollection.find({
    queries: [['type', '==', USER_BY_TYPES.STAFF]],
    orderBy: [['name', 'asc']],
  });

// filter by
// 1. categoryId (if categoryId is provided, dont attach lecturerId)
// 2. limit (amount of documents)
// 3. lastId (cursor document for next list)
const fetchLecturers = (options = {}) => {
  const { categoryId, limit, lastId } = options;
  return userCollection.find({
    queries: [
      ['categories', 'array-contains', categoryId],
      ['type', '==', USER_BY_TYPES.LECTURER],
    ],
    orderBy: [['name', 'asc']],
    limit,
    afterDocument: lastId,
  });
};

const fetchLecturerDetail = userCollection.findOne;

const fetchUsers = (options = {}) => {
  const { categoryId, limit, lastId } = options;
  return userCollection.find({
    queries: [['categories', 'array-contains', categoryId]],
    orderBy: [['name', 'asc']],
    limit,
    afterDocument: lastId,
  });
};

const createUser = withBodyLog(
  data =>
    userCollection.insertOne(data).then(result => {
      const { id: userId, type, courses } = result;
      if (type !== USER_BY_TYPES.LECTURER || !courses) {
        return result;
      }
      return Promise.all(
        courses.map(courseId =>
          courseCollection.updateOne({
            id: courseId,
            lecturers: firebase.firestore.FieldValue.arrayUnion(userId),
          }),
        ),
      ).then(() => result);
    }),
  'new user',
);

const updateUser = withBodyLog(
  (newData, prevData) =>
    userCollection.updateOne(newData).then(result => {
      const { id: userId, type } = result;
      if (type !== USER_BY_TYPES.LECTURER) {
        return result;
      }
      const removedCourses = difference(prevData.courses, newData.courses);
      const addedCourses = difference(newData.courses, prevData.courses);
      return Promise.all([
        ...removedCourses.map(courseId =>
          courseCollection.updateOne({
            id: courseId,
            lecturers: firebase.firestore.FieldValue.arrayRemove(userId),
          }),
        ),
        ...addedCourses.map(courseId =>
          courseCollection.updateOne({
            id: courseId,
            lecturers: firebase.firestore.FieldValue.arrayUnion(userId),
          }),
        ),
      ]).then(() => result);
    }),
  'updated user',
);

const deleteUser = data =>
  userCollection.deleteOne(data.id).then(() => {
    const { id: userId, type, courses } = data;
    if (type !== USER_BY_TYPES.LECTURER || !courses) {
      return data;
    }
    return Promise.all(
      courses.map(courseId =>
        courseCollection.updateOne({
          id: courseId,
          lecturers: firebase.firestore.FieldValue.arrayRemove(userId),
        }),
      ),
    ).then(() => data);
  });

const fetchRooms = (options = {}) => {
  const { limit, lastId } = options;
  return roomCollection.find({
    orderBy: [['name', 'asc']],
    limit,
    afterDocument: lastId,
  });
};

const fetchPosts = (options = {}) => {
  const { limit, lastId, tagId, ...rest } = options;
  return postCollection.find({
    orderBy: [['createdAt', 'desc']],
    queries: [['tags', 'array-contains', tagId]],
    limit,
    afterDocument: lastId,
    ...rest,
  });
};

const fetchForms = (options = {}) => {
  const { limit, lastId, categoryId, ...rest } = options;
  return formCollection.find({
    orderBy: [['createdAt', 'desc']],
    ...(categoryId &&
      categoryId !== 'all' && {
        queries: [['category', 'array-contains', categoryId]],
      }),
    limit,
    afterDocument: lastId,
    ...rest,
  });
};

const fetchScholarships = (options = {}) => {
  const { limit, lastId, categoryId, ...rest } = options;
  return scholarshipCollection.find({
    orderBy: [['createdAt', 'desc']],
    ...(categoryId &&
      categoryId !== 'all' && {
        queries: [['categories', 'array-contains', categoryId]],
      }),
    limit,
    afterDocument: lastId,
    ...rest,
  });
};

const createPost = (...args) =>
  postCollection
    .insertOne(...args)
    .then(({ id }) => postCollection.findOne(id));
const updatePost = (...args) =>
  postCollection
    .updateOne(...args)
    .then(({ id }) => postCollection.findOne(id));

export default {
  // ASSETS
  getAssets: assetCollection.findOne,
  updateAsset: assetCollection.updateOne,

  // COURSE API
  fetchCourses,
  fetchCourseDetail,
  createCourse: withLogger(createCourse, 'Create Course'),
  updateCourse,
  deleteCourse,
  // ROOM API
  fetchRooms,
  createRoom: withBodyLog(roomCollection.insertOne, 'new room'),
  updateRoom: withBodyLog(roomCollection.updateOne, 'updated room'),
  deleteRoom: ({ id }) => roomCollection.deleteOne(id),

  // FORM API
  fetchForms,
  createForm: formCollection.insertOne,
  updateForm: formCollection.updateOne,
  deleteForm: ({ id }) => formCollection.deleteOne(id),

  // SCHOLARSHIP API
  fetchScholarships,
  createScholarship: withBodyLog(
    scholarshipCollection.insertOne,
    'new scholarship',
  ),
  updateScholarship: withBodyLog(
    scholarshipCollection.updateOne,
    'updated scholarship',
  ),
  deleteScholarship: ({ id }) => scholarshipCollection.deleteOne(id),

  // USER API
  fetchLecturerDetail: withLogger(fetchLecturerDetail, 'Lecturer D'),
  fetchLecturers: withLogger(fetchLecturers, 'Lecturers'),
  fetchUsers,
  fetchStaffs,
  fetchResearchers,
  fetchUserDetail: userCollection.findOne,
  createUser: withLogger(createUser, 'Create User'),
  updateUser,
  deleteUser,
  // SCHEDULE API
  fetchSchedules: scheduleCollection.find,
  fetchScheduleDetail: scheduleCollection.findOne,
  updateSchedule: withBodyLog(scheduleCollection.updateOne, 'updated schedule'),

  // POST API,
  fetchPosts,
  fetchPostDetail: postCollection.findOne,
  createPost: withBodyLog(createPost, 'new post'),
  updatePost: withBodyLog(updatePost, 'updated post'),
  deletePost: ({ id }) => postCollection.deleteOne(id),
};
