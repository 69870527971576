import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Affix, Col, Row, Tabs } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import TimeIndicator from './TimeIndicator';
import TimeTablePart from './TimeTablePart';

const StyledAffix = styled(Affix)`
  .ant-affix {
    z-index: 11;
  }
`;

const StyledTabs = styled(Tabs)`
  background: #ffffff;
  .ant-tabs-tab {
    margin: 0 !important;
  }
  .ant-tabs-nav-scroll {
    text-align: right;
  }
  .ant-tabs-bar .ant-tabs-top-bar {
    margin-bottom: 8px;
  }
`;

const StyledText = styled(Text)`
  position: sticky;
  top: 100px;
  margin-left: 4px;
  font-weight: bold;
  z-index: 1;
`;

const { TabPane } = Tabs;

const days = [
  { color: '#F6D26C', title: 'MON', day: 'monday' },
  { color: '#FFB6D2', title: 'TUE', day: 'tuesday' },
  { color: '#4CB57C', title: 'WED', day: 'wednesday' },
  { color: '#F37020', title: 'THU', day: 'thursday' },
  { color: '#6BBEFA', title: 'FRI', day: 'friday' },
];

const MiniTimeTable = ({ children, getMsg, getScheduleList, setItemProps }) => {
  const [part, setPart] = useState('morning');
  const px = { xs: 12, sm: 16 };
  return (
    <>
      <StyledAffix>
        <Box position="relative">
          <StyledTabs defaultActiveKey="morning" onChange={setPart}>
            <TabPane tab={getMsg('morning') || 'Morning'} key="morning" />
            <TabPane tab={getMsg('afternoon') || 'Afternoon'} key="evening" />
          </StyledTabs>
          {children}
        </Box>
        <Box px={px} pt={{ md: 8 }} bgColor={'#ffffff'}>
          {part === 'morning' && <TimeIndicator start={8} end={13} size={10} />}
          {part === 'evening' && (
            <TimeIndicator start={13} end={18} size={10} />
          )}
        </Box>
      </StyledAffix>
      {days.map((item, i) => (
        <Box
          position={'relative'}
          key={item.title}
          px={px}
          bgColor={i % 2 === 1 ? '#f9f9f9' : ''}
        >
          <StyledText>{item.title}</StyledText>
          {part === 'morning' && (
            <TimeTablePart
              color={item.color}
              setItemProps={_item => setItemProps(_item, part, item.day)}
              scheduleList={getScheduleList('morning', item.day)}
              start={8}
              end={13}
              size={10}
              rootProps={{
                mt: -22,
              }}
            />
          )}
          {part === 'evening' && (
            <TimeTablePart
              color={item.color}
              setItemProps={_item => setItemProps(_item, part, item.day)}
              scheduleList={getScheduleList('evening', item.day)}
              start={13}
              end={18}
              size={10}
              rootProps={{
                mt: -22,
              }}
            />
          )}
        </Box>
      ))}
      <Box position={'relative'} px={px} bgColor={'#f9f9f9'}>
        <StyledText>UNKNOWN</StyledText>
        <Row type="flex" align="middle">
          <Col xs={0} md={4} />
          <Col xs={24} md={20}>
            <Box px={{ xs: 0, sm: 24, md: 32 }}>
              <TimeTablePart
                start={8}
                end={18}
                size={10}
                scheduleList={getScheduleList('unknown')}
                setItemProps={setItemProps}
                hiddenEndLine
              />
            </Box>
          </Col>
        </Row>
      </Box>
    </>
  );
};

MiniTimeTable.propTypes = {
  children: PropTypes.node,
  getScheduleList: PropTypes.func,
  setItemProps: PropTypes.func,
  getMsg: PropTypes.func,
};
MiniTimeTable.defaultProps = {
  children: null,
  getScheduleList: () => {},
  setItemProps: () => {},
  getMsg: () => '',
};

export default MiniTimeTable;
