import firebase from 'firebase/app';
import FireStoreFactory from '../FireStore';

const FireFactory = ({ config, app }) => {
  let fireApp = app;
  if (!app) {
    fireApp = firebase.initializeApp(config);
  }

  const fireStore = FireStoreFactory(fireApp);

  return {
    fireStore,
  };
};

export default FireFactory;
