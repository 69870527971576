import { useEffect } from 'react';
import { scroller } from 'react-scroll';

export default (topic, options = {}) => {
  useEffect(() => {
    if (topic) {
      setTimeout(() => {
        scroller.scrollTo(topic, {
          duration: 700,
          smooth: true,
          offset: -80,
          delay: 0,
          ...options,
        });
      }, 200);
    }
  }, [topic]);
};
