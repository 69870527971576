import React from 'react';
import { Skeleton } from 'antd';
import Box from 'components/atoms/Box';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledListSkeleton = styled.div`
  .skeleton-wrapper:not(:first-of-type) {
    margin-top: 8px;
  }
  .ant-skeleton {
    .ant-skeleton-content {
      padding-left: 16px;
      .ant-skeleton-title {
        margin: 0;
      }
      .ant-skeleton-title + .ant-skeleton-paragraph {
        margin: 0;
        margin-top: 8px;
      }
    }
  }
`;

const ListSkeleton = ({ size, avatarShape, boxProps }) => (
  <StyledListSkeleton>
    {[...Array(size).keys()].map(val => (
      <Box
        key={val}
        borderRadius={4}
        bgColor={'#ffffff'}
        px={{ xs: 16, md: 32 }}
        py={{ xs: 16, md: 24 }}
        className="skeleton-wrapper"
        {...Box.alignCenter}
        {...boxProps}
      >
        <Skeleton
          loading
          active
          avatar={{ size: 'large', shape: avatarShape }}
          paragraph={{ rows: 1, width: 200 }}
        />
      </Box>
    ))}
  </StyledListSkeleton>
);

ListSkeleton.propTypes = {
  size: PropTypes.number,
  avatarShape: PropTypes.oneOf(['circle', 'square']),
  boxProps: PropTypes.shape({}),
};

ListSkeleton.defaultProps = {
  size: 1,
  avatarShape: 'square',
  boxProps: {},
};

export default ListSkeleton;
