/* eslint-disable no-console,max-len */
import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { ThemeProvider } from 'emotion-theming';
import { Switch, Route, Redirect } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { SizesProvider } from 'react-sizes';
import theme from 'theme';
import ROUTES from 'constants/routes';

// CONTEXTS
import { LecturerProvider } from 'contexts/lecturer';
import { CourseProvider } from 'contexts/course';
import { FormProvider } from 'contexts/form';
import { DBProvider } from 'contexts/db';
import IntlProvider from 'contexts/intl';

// HOOKS
import useScrollTopRouteChange from 'hooks/useScrollTopRouteChange';

// PAGES
import CoursesPage from 'pages/CoursesPage';
import CourseDetailPage from 'pages/CourseDetailPage';
import LecturersPage from 'pages/LecturersPage';
import LecturerDetail from 'pages/LecturerDetail';
import Homepage from 'pages/Homepage';
import SchedulePage from 'pages/SchedulePage';
import PostDetail from 'pages/PostDetail';
import FormsPage from 'pages/FormsPage';
import StaffsPage from 'pages/StaffPage';
import ScholarshipsPage from 'pages/ScholarshipsPage';
import ResearcherPage from 'pages/ResearcherPage';
import AboutUsPage from 'pages/AboutUsPage';
import NewsPage from 'pages/NewsPage';
import CurrentAC from 'pages/currentStudent';
import SeniorBachelor from 'pages/seniorBachelor';
import Internship from 'pages/internship';
import Admission from 'pages/admission';
import AdmissionCalendar from 'pages/calendar';
import ThesisPage from 'pages/ThesisPage';
import NotFoundPage from 'pages/NotFoundPage';
import LabsPage from 'pages/labs';
import PublicationPage from 'pages/PublicationPage';
import AboutIE from 'pages/aboutIE';
import OrganizationPage from 'pages/OrganizationPage';

// COMPONENTS
import GotoTop from 'components/responsive/GotoTop';
import Header from 'components/navigation/Header';
import Footer from 'components/navigation/Footer';
import './App.css';
import './grid.css';
import { PostProvider } from './contexts/post';
import { RoomProvider } from './contexts/room';
import { UserProvider } from './contexts/user';
import { StaffProvider } from './contexts/staff';
import { ResearcherProvider } from './contexts/researcher';
import { ScholarshipProvider } from './contexts/scholarship';

const LoadableAdmin = loadable(() => import('./pages/admin'), {
  fallback: () => <div>loading...</div>,
});
const LoadableLogin = loadable(() => import('./pages/LoginPage'), {
  fallback: () => <div>loading...</div>,
});

// Config can be created based on user-agent. See below
const config = { fallbackWidth: 360, fallbackHeight: 640 };

// eslint-disable-next-line react/prop-types
const Providers = ({ children }) => (
  <ThemeProvider theme={theme}>
    <IntlProvider>
      <DBProvider>
        <RoomProvider>
          <UserProvider>
            <CourseProvider>
              <PostProvider>
                <FormProvider>
                  <StaffProvider>
                    <ResearcherProvider>
                      <LecturerProvider>
                        <ScholarshipProvider>
                          <SizesProvider config={config}>
                            {children}
                          </SizesProvider>
                        </ScholarshipProvider>
                      </LecturerProvider>
                    </ResearcherProvider>
                  </StaffProvider>
                </FormProvider>
              </PostProvider>
            </CourseProvider>
          </UserProvider>
        </RoomProvider>
      </DBProvider>
    </IntlProvider>
  </ThemeProvider>
);

const ScrollTop = ({ location }) => {
  useScrollTopRouteChange(location.pathname, [
    '/prospect-student/admission',
    '/prospect-student/calendar/bachelor',
  ]);
  return null;
};

const CustomRedirect = () => {
  useEffect(() => {
    window.location.href = 'http://ie.eng.chula.ac.th/ienet2019';
  }, []);
  return null;
};

const App = () => {
  const [, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 200);
  }, []);
  return (
    <Providers>
      <Div100vh
        as="section"
        className={'ant-layout'}
        style={{ minHeight: '100rvh' }}
      >
        <Switch>
          <Redirect from={'/article/*'} to={ROUTES.news.main} />
          <Redirect
            from={['/mainpage/staff.php', '/mainpage/staff_detail.php']}
            to={ROUTES.lecturers.get()}
          />
          <Redirect
            from={[
              '/mainpage/program.php',
              '/acadamics/MasterofEngineering/*',
              '/acadamics/mie',
            ]}
            to={ROUTES.admission.root}
          />
          <Route path={'/ienet2019'} component={CustomRedirect} />
          <Route exact path={ROUTES.login.pattern} component={LoadableLogin} />
          <Route path={ROUTES.admin.pattern} component={LoadableAdmin} />
          <Route
            path={'*'}
            render={({ location }) => (
              <React.Fragment>
                <ScrollTop location={location} />
                <Header />
                <Switch>
                  <Route path={'/'} exact component={Homepage} />
                  <Route
                    path={ROUTES.organization.pattern}
                    exact
                    component={OrganizationPage}
                  />
                  <Redirect
                    from={ROUTES.aboutIE.root}
                    to={ROUTES.aboutIE.main}
                    exact
                  />
                  <Route path={ROUTES.aboutIE.pattern} component={AboutIE} />
                  <Route path={ROUTES.labs.pattern} component={LabsPage} />
                  <Route
                    path={ROUTES.publication.pattern}
                    component={PublicationPage}
                  />
                  <Route
                    path={ROUTES.courses.pattern}
                    exact
                    component={CoursesPage}
                  />
                  <Redirect
                    from={ROUTES.news.root}
                    to={ROUTES.news.main}
                    exact
                  />
                  <Route
                    path={ROUTES.news.pattern}
                    exact
                    component={NewsPage}
                  />
                  <Route
                    path={ROUTES.courseDetail.pattern}
                    component={CourseDetailPage}
                  />
                  <Route
                    path={ROUTES.lecturers.pattern}
                    exact
                    component={LecturersPage}
                  />
                  <Route
                    path={ROUTES.staffs.pattern}
                    exact
                    component={StaffsPage}
                  />
                  <Route
                    path={ROUTES.researchers.pattern}
                    exact
                    component={ResearcherPage}
                  />
                  <Redirect
                    from={ROUTES.scholarships.root}
                    to={ROUTES.scholarships.main}
                    exact
                  />
                  <Route
                    path={ROUTES.scholarships.pattern}
                    exact
                    component={ScholarshipsPage}
                  />
                  <Route
                    path={ROUTES.lecturerDetail.pattern}
                    component={LecturerDetail}
                  />
                  <Route
                    path={ROUTES.postDetail.pattern}
                    component={PostDetail}
                  />
                  <Route
                    path={ROUTES.forms.pattern}
                    exact
                    component={FormsPage}
                  />
                  <Redirect
                    from={ROUTES.aboutUs.root}
                    to={ROUTES.aboutUs.main}
                    exact
                  />
                  <Route
                    path={ROUTES.aboutUs.pattern}
                    exact
                    component={AboutUsPage}
                  />
                  <Route
                    path={ROUTES.schedule.pattern}
                    exact
                    component={SchedulePage}
                  />
                  <Route
                    path={ROUTES.currentAC.pattern}
                    exact
                    component={CurrentAC}
                  />
                  <Route
                    path={ROUTES.seniorBachelor.pattern}
                    exact
                    component={SeniorBachelor}
                  />
                  <Route
                    path={ROUTES.internship.pattern}
                    exact
                    component={Internship}
                  />
                  <Redirect
                    from={ROUTES.admission.root}
                    to={ROUTES.admission.main}
                    exact
                  />
                  <Route
                    path={ROUTES.admission.pattern}
                    exact
                    component={Admission}
                  />
                  <Redirect
                    from={ROUTES.prospectAC.root}
                    to={ROUTES.prospectAC.main}
                    exact
                  />
                  <Route
                    path={ROUTES.prospectAC.pattern}
                    exact
                    component={AdmissionCalendar}
                  />
                  <Route
                    path={ROUTES.thesis.pattern}
                    exact
                    component={ThesisPage}
                  />
                  <Route path={'*'} component={NotFoundPage} />
                </Switch>
                <Footer />
                <GotoTop />
              </React.Fragment>
            )}
          />
        </Switch>
      </Div100vh>
    </Providers>
  );
};

export default App;
