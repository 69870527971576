import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import matchesProperty from 'lodash/matchesProperty';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import FireApi from 'firebase/fireApi';
import compose from 'helpers/compose';
// CONTEXTS
import { withConsumer as withCourseConsumer } from 'contexts/course';
import { withConsumer as withLecturerConsumer } from 'contexts/lecturer';
import { withIntl } from 'contexts/intl';

// COMPONENTS
import { Row, Col, Skeleton, Tag, Table } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import Paper from 'components/atoms/Paper';
import ContentFields from 'components/molecules/ContentFields';
import ImageGallery from 'components/molecules/ImageGallery';
import DownloadUI from 'components/molecules/DownloadUI';
import UserToProfileList from 'components/molecules/UserToProfileList';
import Container from 'components/layouts/Container';

// CONSTANTS
import ROUTES from 'constants/routes';
import { CATEGORY_BY_ID } from 'constants/temporary';

const CoverImage = styled('div')`
  position: relative;
  margin: -16px -16px 0;
  height: 120px;
  z-index: 0;
  background: #881014;

  ${({ theme }) => `
    ${[theme.breakpoints.up('sm')]} {
      position: absolute;
      top: 64px;
      left: 0;
      right: 0;
      height: 384px;
      margin: 0px;
    }
    
    ${[theme.breakpoints.up('lg')]} {
      height: 424px;
    }
  `}

  ${({ theme, noImage }) => ({
    ...(noImage && {
      height: 32,
      [theme.breakpoints.up('sm')]: {
        height: 384,
      },
      [theme.breakpoints.up('md')]: {
        height: 424,
      },
    }),
  })}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ieOverlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      -1deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.2) 80%
    );
    ${({ theme }) => `
      ${[theme.breakpoints.up('sm')]} {
        display: block;
      }
    `}
  }
`;

const StyledTag = styled(Tag)`
  pointer-events: none;
  border-color: white;
  color: #ffffff;
  margin-top: 8px;
  background: rgba(255, 255, 255, 0.3);
`;

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: none;
  }
`;

const CourseDetailPage = ({
  match: {
    params: { courseId },
  },
  courses,
  getOneCourse,
  getLecturers,
  locale,
  getMsg,
}) => {
  const [course, setCourse] = useState({});
  const [tempCourses, setTempCourses] = useState(courses || []);
  const [courseLoading, setCourseLoading] = useState(false);
  const [lecturers, setLecturers] = useState([]);
  const [lecturersLoading, setLecturersLoading] = useState(false);
  // preload data from context;
  const preCourse = getOneCourse(courseId);
  // preload data from context;
  const preLecturers = getLecturers(course.lecturers);
  useEffect(() => {
    if (preCourse) {
      setCourse(preCourse);
    } else if (courseId) {
      setCourseLoading(true);
      FireApi.fetchCourseDetail(courseId)
        .then(response => {
          const { prerequisites = [] } = response;
          if (courses || !prerequisites.length) {
            return response;
          }
          return Promise.all(
            prerequisites.map(id => FireApi.fetchCourseDetail(id)),
          ).then(responses => {
            setTempCourses(responses);
            return response;
          });
        })
        .then(courseDetail => {
          setCourseLoading(false);
          setCourse(courseDetail);
        });
    }
  }, [courseId]);

  useEffect(() => {
    const fetchLecturers = async () => {
      if (!isEmpty(course.lecturers)) {
        setLecturersLoading(true);
        try {
          const lecturerDetails = await Promise.all(
            course.lecturers.map(lecturer =>
              FireApi.fetchLecturerDetail(lecturer),
            ),
          );
          setLecturers(lecturerDetails);
        } catch (_) {
          // TODO: show error
        } finally {
          setLecturersLoading(false);
        }
      }
    };
    if (preLecturers && preLecturers.length) {
      setLecturers(preLecturers);
    } else {
      fetchLecturers();
    }
  }, [course.lecturers]);
  return (
    <Container>
      <CoverImage noImage={!get(course, 'coverImage')}>
        {get(course, 'coverImage') && (
          <Image alt={'cover-image'} src={course.coverImage} />
        )}
        <div className={'ieOverlay'} />
      </CoverImage>
      <Box
        mb={{ xs: 16, sm: 36 }}
        mx={{ xs: -16, sm: 0 }}
        p={{ xs: 16, sm: 0 }}
        backgroundColor={{ xs: '#881014', sm: 'unset' }}
        position="relative"
      >
        {courseLoading ? (
          <Box mt={{ sm: 120 }} minHeight={153}>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 3, width: [150, 300, 200] }}
            />
          </Box>
        ) : (
          <Box mt={{ sm: 92 }}>
            <Text.Paragraph
              className={'CourseHeader-code'}
              fontSize={{ xs: 16, sm: 20 }}
              mb={'0.5em !important'}
              color={'grey.200'}
              fontWeight="bold"
            >
              {course.code}
            </Text.Paragraph>
            <Text.Title
              className={'CourseHeader-name'}
              mt={'0 !important'}
              fontWeight={800}
              letterSpacing={2}
              fontSize={{ xs: 32, sm: 40, md: 48 }}
              color={'primary.contrastText'}
            >
              {course.name}
            </Text.Title>
            {get(course, 'categories', []).map(categoryId => (
              <StyledTag key={categoryId}>
                {CATEGORY_BY_ID[categoryId].name}
              </StyledTag>
            ))}
          </Box>
        )}
      </Box>
      <Row>
        <Col md={18} xs={24}>
          <Box
            className="course-detail-wrapper"
            bgColor={'#ffffff'}
            mr={{ xs: 0, sm: 24 }}
            px={{ xs: 16, sm: 32 }}
            py={{ xs: 16, sm: 24 }}
            borderRadius={4}
            boxShadow={'0 0 8px 0 rgba(0,0,0,0.08)'}
          >
            <ContentFields
              loading={courseLoading}
              fields={[
                {
                  label: getMsg('courseTitle'),
                  value: course.name,
                  textProps: {
                    copyable: true,
                  },
                },
                {
                  label: getMsg('courseNumber'),
                  value: course.code,
                  textProps: {
                    copyable: true,
                  },
                },
                {
                  label: getMsg('courseType'),
                  value: startCase(course.type),
                },
                {
                  label: getMsg('courseCredits'),
                  value: course.credit,
                },
              ]}
            />
            <Row>
              <Col xs={8} sm={6}>
                <Text fontWeight={'bold'}>{getMsg('coursePrerequisites')}</Text>
              </Col>
              <Col xs={16} sm={18}>
                <Skeleton
                  active
                  loading={courseLoading}
                  paragraph={false}
                  title={{ style: { marginTop: 4 } }}
                >
                  {course.prerequisites && course.prerequisites.length
                    ? course.prerequisites.map(id => {
                        const item = tempCourses.find(
                          matchesProperty('id', id),
                        );
                        if (!item) return null;
                        return (
                          <Box mb={8} key={id}>
                            <Link to={ROUTES.courseDetail.get(id, item.name)}>
                              {item.code} - {item.name}
                            </Link>
                          </Box>
                        );
                      })
                    : '-'}
                </Skeleton>
              </Col>
            </Row>
            <Box my={32}>
              <Box mb={16}>
                <Text fontWeight="bold">{getMsg('courseDescription')}</Text>
              </Box>
              {courseLoading ? (
                <Skeleton active title={false} paragraph={{ rows: 3 }} />
              ) : (
                <Text>{get(course, ['description', locale])}</Text>
              )}
            </Box>
            {course.methods && course.methods.length > 0 && (
              <StyledTable
                pagination={false}
                columns={[
                  {
                    title: getMsg('courseMethod'),
                    dataIndex: 'label',
                    key: 'label',
                    width: '90%',
                  },
                  {
                    title: getMsg('courseHours'),
                    dataIndex: 'value',
                    key: 'value',
                    align: 'center',
                    width: '10%',
                  },
                ]}
                dataSource={get(course, 'methods', []).map((method, index) => ({
                  key: index,
                  label: get(method, ['label', locale]),
                  value: method.value,
                }))}
              />
            )}
            <Box mt={32}>
              <Box mb={16}>
                <Text fontWeight="bold">{getMsg('courseAtmosphere')}</Text>
              </Box>
              <ImageGallery images={get(course, ['images'], [])} />
            </Box>
          </Box>
        </Col>
        <Col md={6} xs={24}>
          <DownloadUI
            label={getMsg('courseSyllabus')}
            link={course.syllabusLink}
            getMsg={getMsg}
            boxProps={{
              bgColor: '#ffffff',
              px: { xs: 16, md: 32 },
              py: { xs: 16, md: 24 },
              borderRadius: 4,
              boxShadow: '0 0 8px 0 rgba(0,0,0,0.08)',
            }}
          />
          <Box mt={16}>
            <Box mb={16}>
              <Text fontWeight="bold">{getMsg('lecturers')}</Text>
            </Box>
            {lecturersLoading || courseLoading ? (
              <Paper>
                <Skeleton avatar active title={false} paragraph={false} />
                <Skeleton active />
              </Paper>
            ) : (
              <Paper p={{ xs: 16, sm: 16, md: 16 }}>
                <UserToProfileList
                  concise
                  users={lecturers}
                  to={ROUTES.lecturerDetail.get}
                  locale={locale}
                  getMsg={getMsg}
                  avatarProps={{
                    size: 48,
                  }}
                />
              </Paper>
            )}
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

CourseDetailPage.propTypes = {
  match: PropTypes.shape({}),
  locale: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  getOneCourse: PropTypes.func.isRequired,
  getLecturers: PropTypes.func.isRequired,
  getMsg: PropTypes.func.isRequired,
};
CourseDetailPage.defaultProps = {
  match: {},
  courses: null,
  locale: 'en',
};

export default compose(
  withCourseConsumer,
  withLecturerConsumer,
  withIntl,
)(CourseDetailPage);
