import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Container from 'components/layouts/Container';
import Hidden from 'components/responsive/Hidden';
import usePosts from 'hooks/usePosts';
import useIntl from 'hooks/useIntl';
import { breakpoints } from 'theme';
import BigPostSlider from './BigPostSlider';
import SmallPostSlider from './SmallPostSlider';

const NewsBox = styled(Box)`
  .news-title {
    letter-spacing: 1px;
    color: ${({ theme }) => theme.primary.main};
  }
  .news-title-dash {
    height: 4px;
    width: 24px;
    background: ${({ theme }) => theme.primary.main};
    ${breakpoints.up('sm')} {
      height: 6px;
      width: 32px;
    }
  }
  .news-button {
    color: ${({ theme }) => theme.primary.main};
    background: transparent;
    border-radius: 50px;
    width: auto;
    height: auto;
    font-weight: bold;
    padding: 8px 16px;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.primary.main};
    :hover {
      background: ${({ theme }) => theme.primary.main};
      color: ${({ theme }) => theme.white.text};
    }
    ${breakpoints.up('sm')} {
      padding: 8px 20px;
      font-size: 16px;
    }
  }
`;

const HomepagePosts = ({ history }) => {
  const { locale, getMsg, displayText } = useIntl();
  const { data: announcementData, status: announcementStatus } = usePosts(
    'announcement',
    8,
  );
  const { data: academicData, status: academicStatus } = usePosts(
    'academic',
    8,
  );
  const { data: jobData, status: jobStatus } = usePosts('job', 8);
  const { data: eventData, status: eventStatus } = usePosts('event', 8);
  const announcementSliderProps = {
    locale,
    loading: announcementStatus.isRequest,
    posts: announcementData || [],
  };
  const academicSliderProps = {
    locale,
    loading: academicStatus.isRequest,
    posts: academicData || [],
  };
  const jobSliderProps = {
    locale,
    loading: jobStatus.isRequest,
    posts: jobData || [],
  };
  const eventSliderProps = {
    locale,
    loading: eventStatus.isRequest,
    posts: eventData || [],
  };
  return (
    <>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <NewsBox my={24} {...Box.justifyBetween}>
          <Box>
            <Text.Title
              className="news-title"
              level={2}
              fontSize={{ xs: 24, sm: 28, md: 32 }}
              mb={'0.4em'}
              fontWeight={800}
            >
              {getMsg('announcement')}
            </Text.Title>
            <Box />
            <div className="news-title-dash" />
          </Box>
          <Button
            className="news-button"
            onClick={() => history.push('/news?content=announcement')}
          >
            {getMsg('seeAll')}
          </Button>
        </NewsBox>
        <Box mx={{ xs: -16 }}>
          <Hidden smUp>
            <SmallPostSlider
              displayText={displayText}
              pageSize={2}
              {...announcementSliderProps}
            />
          </Hidden>
          <Hidden smDown>
            <SmallPostSlider
              displayText={displayText}
              {...announcementSliderProps}
            />
          </Hidden>
        </Box>
      </Container>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <NewsBox my={24} {...Box.justifyBetween}>
          <Box>
            <Text.Title
              className="news-title"
              level={2}
              fontSize={{ xs: 24, sm: 28, md: 32 }}
              mb={'0.4em'}
              fontWeight={800}
            >
              {getMsg('academic')}
            </Text.Title>
            <Box />
            <div className="news-title-dash" />
          </Box>
          <Button
            className="news-button"
            onClick={() => history.push('/news?content=academic')}
          >
            {getMsg('seeAll')}
          </Button>
        </NewsBox>
        <Box mx={{ xs: -16 }}>
          <Hidden smUp>
            <BigPostSlider pageSize={1} {...academicSliderProps} />
          </Hidden>
          <Hidden smDown>
            <BigPostSlider {...academicSliderProps} />
          </Hidden>
        </Box>
      </Container>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <NewsBox my={24} {...Box.justifyBetween}>
          <Box>
            <Text.Title
              className="news-title"
              level={2}
              fontSize={{ xs: 24, sm: 28, md: 32 }}
              mb={'0.4em'}
              fontWeight={800}
            >
              {getMsg('event')}
            </Text.Title>
            <Box />
            <div className="news-title-dash" />
          </Box>
          <Button
            className="news-button"
            onClick={() => history.push('/news?content=event')}
          >
            {getMsg('seeAll')}
          </Button>
        </NewsBox>
        <Box mx={{ xs: -16 }}>
          <Hidden smUp>
            <BigPostSlider pageSize={1} {...eventSliderProps} />
          </Hidden>
          <Hidden smDown>
            <BigPostSlider {...eventSliderProps} />
          </Hidden>
        </Box>
      </Container>
      <Container py={{ xs: 16, sm: 16, md: 16 }}>
        <NewsBox my={24} {...Box.justifyBetween}>
          <Box>
            <Text.Title
              className="news-title"
              level={2}
              fontSize={{ xs: 24, sm: 28, md: 32 }}
              mb={'0.4em'}
              fontWeight={800}
            >
              {getMsg('job')}
            </Text.Title>
            <Box />
            <div className="news-title-dash" />
          </Box>
          <Button
            className="news-button"
            onClick={() => history.push('/news?content=job')}
          >
            {getMsg('seeAll')}
          </Button>
        </NewsBox>
        <Box mx={{ xs: -16 }}>
          <Hidden smUp>
            <SmallPostSlider
              displayText={displayText}
              pageSize={2}
              {...jobSliderProps}
            />
          </Hidden>
          <Hidden smDown>
            <SmallPostSlider displayText={displayText} {...jobSliderProps} />
          </Hidden>
        </Box>
      </Container>
    </>
  );
};

HomepagePosts.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
HomepagePosts.defaultProps = {};

export default withRouter(HomepagePosts);
