import { useState, useContext } from 'react';
import useApi from './useApi';

export default ({ api: fetchApi, Context, tag, pageSize }) => {
  const { concatResourceQuery, getResourceQuery } = useContext(Context);
  const [hasMore, setHasMore] = useState(true);
  const { api, status, reset } = useApi(fetchApi);

  const onInfiniteLoad = (...args) => {
    api(...args).then(response => {
      concatResourceQuery(tag)(response);
      if (response.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    });
  };

  const result = getResourceQuery(tag);

  return {
    result,
    onInfiniteLoad,
    hasMore: hasMore && !status.isRequest,
    setHasMore,
    status,
    reset,
  };
};
