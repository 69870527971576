import styled from '@emotion/styled';
import { Typography } from 'antd';

const template = `
  font-size: 16px;
  line-height: 1.75em;

  a * {
    color: #ac171c !important;
    :hover {
      text-decoration: underline;
    }
  }

  ul li {
    margin-bottom: 8px;

    * {
      margin: 0 !important;
    }
  }

  blockquote {
    font-size: 18px;
    margin: 24px -16px;
    padding: 24px 16px 24px 20px;
    font-style: italic;
    border-left: 8px solid #ac171c;
    line-height: 1.6;
    position: relative;
    background: #ac161c12;
  }

  blockquote * {
    margin: 0 !important;
  }

  blockquote span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    background-color: unset !important;
  }

  &.front-end {
    ${({ theme }) => `
      ${theme.breakpoints.up('md')} {
        blockquote {
          margin: 24px -24px;
          padding: 24px;
        }
      }
    `}
  }
`;

const RichTextStyle = styled(Typography)`
  ${template}
`;
RichTextStyle.Editor = styled('div')`
  ${template}
`;

export default RichTextStyle;
