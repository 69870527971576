import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Box from 'components/atoms/Box';
import { Card, Row, Col } from 'antd';
import Text from 'components/atoms/Text';

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .schedule-item:not(:first-child) {
    border-top: 1px solid rgb(232, 232, 232);
  }
`;

const ScheduleCard = ({ code, name, schedules, ...props }) => {
  const cardHeader = () => (
    <>
      <Text
        fontSize={{ xs: 12, sm: 14 }}
        mt={2}
        mr={8}
        color={'primary.main'}
        fontWeight={'bold'}
      >
        {code}
      </Text>
      <Text fontSize={{ xs: 12, sm: 14 }} fontWeight={'bold'}>
        {name}
      </Text>
    </>
  );

  const getTime = hourValue =>
    `${parseInt(hourValue, 10)}.${hourValue % 1 === 0.5 ? '30' : '00'}`;
  return (
    <StyledCard size="small" title={cardHeader()} {...props}>
      {schedules.map(({ id, lecturers, room, section, start, end, day }) => (
        <Box key={id} className="schedule-item" p={12}>
          <Row>
            <Col md={6} xs={12}>
              <Text.Paragraph mb={0} fontSize={10}>
                Day
              </Text.Paragraph>
              <Text.Paragraph
                fontSize={12}
                textTransform="capitalize"
                fontWeight="bold"
              >
                {day}
              </Text.Paragraph>
            </Col>
            <Col md={6} xs={12}>
              <Text.Paragraph mb={0} fontSize={10}>
                Time
              </Text.Paragraph>
              <Text.Paragraph fontSize={12} fontWeight="bold">
                {getTime(start)}-{getTime(end)}
              </Text.Paragraph>
            </Col>
            <Col md={6} xs={12}>
              <Text.Paragraph mb={0} fontSize={10}>
                Room
              </Text.Paragraph>
              <Text.Paragraph fontSize={12} fontWeight="bold">
                {room.name}
              </Text.Paragraph>
            </Col>
            <Col md={6} xs={12}>
              <Text.Paragraph mb={0} fontSize={10}>
                Section
              </Text.Paragraph>
              <Text.Paragraph fontSize={12} fontWeight="bold">
                {section || '-'}
              </Text.Paragraph>
            </Col>
            <Col md={24} xs={24}>
              <Text.Paragraph mb={0} fontSize={10}>
                Lecturers
              </Text.Paragraph>
              <div>
                {lecturers.map(lecturer => (
                  <Text.Paragraph
                    key={lecturer.id}
                    fontSize={12}
                    mb={0}
                    fontWeight="bold"
                  >
                    • {lecturer.name.en}
                  </Text.Paragraph>
                ))}
              </div>
            </Col>
          </Row>
        </Box>
      ))}
    </StyledCard>
  );
};

ScheduleCard.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ScheduleCard;
