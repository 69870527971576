import qs from 'qs';
import merge from 'lodash/merge';
import isString from 'lodash/isString';

function compose(...funcs) {
  if (!funcs) return val => val;
  if (funcs.length === 1) return funcs[0];
  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const formatValue = value => {
  if (!value) return [];
  if (isString(value)) return [value];
  return value;
};

const toObject = search =>
  qs.parse(search, {
    ignoreQueryPrefix: true,
    comma: true,
  });
const toString = query =>
  qs.stringify(query, {
    sort: alphabeticalSort,
    arrayFormat: 'comma',
  });

export default value => {
  // value can be a string or an object
  // safeValue is always an object
  let safeValue = isString(value) ? toObject(value) : value;

  return {
    get: () => safeValue,
    toObject,
    toString: (query = safeValue) => toString(query),
    attachQuery(newValue) {
      safeValue = merge(safeValue, newValue);
      return this;
    },
    detachQuery(key) {
      delete safeValue[key];
      return this;
    },
    valuesOf: key => formatValue(safeValue[key]),
    sortString: compose(
      result => `?${result}`,
      toString,
      toObject,
    ),
  };
};
