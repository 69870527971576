import React from 'react';
import { css } from 'emotion';
// import styled from '@emotion/styled';
import { Divider } from 'antd';
import Text from 'components/atoms/Text';

const dividerCss = css`
  .ant-divider-inner-text {
    color: #a5a5a5;
  }
`;

// const Blockquote = styled('div')`
//   font-size: calc(2vh + 0.8vw + 4px);
//   margin: 50px auto;
//   font-family: Kanit, Open Sans;
//   font-style: italic;
//   color: #000;
//   padding: 16px;
//   ${({ theme }) => `
//     ${theme.breakpoints.up('sm')} {
//       padding: 1.2em 24px 1.2em 24px;
//     }
//   `};
//   border-left: 8px solid #ac171c;
//   line-height: 1.6;
//   position: relative;
//   background: #fff8f8;
//
//   // :before {
//   //   font-family: Arial;
//   //   content: '\\201C';
//   //   color: #ac171c;
//   //   font-size: 4em;
//   //   position: absolute;
//   //   left: 10px;
//   //   top: -10px;
//   // }
//   //
//   // :after {
//   //   content: '';
//   // }
//
//   span {
//     display: block;
//     color: #333333;
//     font-style: normal;
//     font-weight: bold;
//     margin-top: 1em;
//   }
// `;

const Vision = () => (
  <div>
    <Divider className={dividerCss}>OUR VISION</Divider>
    <Text.Title level={2}>สาสน์จากหัวหน้าภาควิชา</Text.Title>
    <img
      alt={'history_01'}
      src={
        // eslint-disable-next-line max-len
        'https://ie-chula.s3-ap-southeast-1.amazonaws.com/public/users_new/paveena.jpg'
      }
      style={{ maxWidth: '100%' }}
    />
    <br />
    <br />
    <Text.Title level={2}>
      รศ.ดร.ปวีณา เชาวลิตวงศ์ <br />
      หัวหน้าภาควิชาวิศวกรรมอุตสาหการ
      <br />
      คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
      <br />
    </Text.Title>
    <br />
    <br />
    <Text.Body textIndent={'20px'}>
      วิศวกรรมอุตสาหการเป็นศาสตร๋ที่ถือกำเนิดขึ้นในยุคสมัยปฎิวัติอุตสาหกรรมที่มีความต้องการการผลิตในปริมาณที่มากขึ้น
      และต้องการต้นทุนที่ต่ำลง นอกจากการมีเครื่องจักรแล้วนั้น
      เรายังต้องการคนจำนวนมากเข้ามาช่วยในการผลิตด้วย
      ทำให้เกิดความสำคัญของการวางแผนต่างๆ ขึ้นมา ตัวอย่างเช่น การจัดสายการผลิต
      การจัดตารางงานของพนักงาน หรือการวางแผนการผลิต ซึ่งสิ่งต่างๆ
      เหล่านี้จะส่งผลต่อประสิทธิภาพและความสำเร็จของการผลิตนั้นๆ
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      ศาสตร์ของวิศวกรรมอุตสาหการก็ได้รับการพัฒนามาอย่างต่อเนื่อง
      จากจุดเริ่มต้นที่มีเพียงสายการผลิต
      ปัจจุบันความสามารถในการบริหารจัดการระบบของเราได้ถูกนำไปประยุกต์ใช้ในงานบริการต่างๆ
      ไม่ว่าจะเป็นงานบริการในด้านสาธารณสุข งานการเงินและการธนาคาร
      รวมทั้งงานด้านโลจิสติกส์ต่างๆ
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      ภาควิชาวิศวกรรมอุตสาหการได้ดำเนินการภายใต้คณะวิศวกรรมศาสตร์
      จุฬาลงกรณ์มหาวิทยาลัย มากว่า 70 ปี ความเป็นวิศวฯ จุฬาฯ
      ได้หยั่งรากลึกลงเป็นวัฒนธรรมองค์กรของภาควิชาวิศวกรรมอุตสาหการของเราในการมุ่งมั่น
      ทุ่มเทที่จะพัฒนานิสิตในรุ่นถัดไปเพื่อรับช่วงความสำเร็จที่สืบเนื่องต่อๆ
      กันมาจากรุ่นสู่รุ่น
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      เรามีคณาจารย์ที่มีศักยภาพสูง มีความเชี่ยวชาญในหลายสาขาวิชา
      และได้ดำเนินการพัฒนาภาควิชามาอย่างต่อเนื่อง
      เพื่อให้ยังคงความโดดเด่นในด้านวิชาการ
      หลักสูตรและระบบการเรียนการสอนเพื่อให้เท่าทันกับเทคโนโลยีและ
      ความต้องการทางด้านสังคมและการพัฒนาประเทศที่ได้เปลี่ยนแปลงไป
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      ด้วยความที่เราเป็นภาควิชาวิศวกรรมอุตสาหการแห่งแรกในประเทศไทย
      เราได้ทำหน้าที่ในการผลิตบัณฑิตวิศวกรรมอุตสาหการออกสู่สังคมไทยเป็นจำนวนมาก
      สิ่งที่ทำให้เราภูมิใจมากที่สุดนั้น คือ
      การได้เห็นบัณฑิตของเราประสบความสำเร็จในองค์กรต่างๆ
      บัณฑิตของเราได้เป็นผู้บริหารระดับสูงทั้งในภาครัฐและภาคเอกชน
      ไม่ว่าจะเป็นธุรกิจทางด้านการผลิต งานบริการ งานการเงิน งานการลงทุน
      และในภาครัฐ ที่อยู่ในระดับที่ต้องวางแผนนโยบายระดับประเทศ
      นอกจากนี้ยังมีบัณฑิตอีกหลายคนที่ประสบความสำเร็จในธุรกิจส่วนตัว
      เรารู้สึกยินดีและมีความภาคภูมิใจในบัณฑิตของเราอย่างมาก
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      เป็นที่ทราบกันดีว่าเรามาถึงยุคปฎิวัติดิจิทัลแล้ว
      ซึ่งเป็นผลมาจากการพัฒนาทางด้านเทคโนโลยีอย่างก้าวกระโดด
      การเปลี่ยนแปลงนี้ส่งผลกระทบให้เกิดการเปลี่ยนแปลงทางด้านสังคม เศษฐกิจ
      และสิ่งแวดล้อมอย่างรวดเร็ว
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      การเปลี่ยนแปลงดังกล่าวถือว่าเป็นความท้าทายให้แก่วิศวกรของเรา
      ความที่เป็นโจทย์ใหม่นั่นเอง จึงต้องการมองภาพแบบในหลายมิติ ในเชิงของ
      Multidisciplinary และ Interdisciplinary แต่สิ่งที่น่าเบาใจสำหรับเรา
      คือศาสตร์ของเรา หรือตรรกะของเราหรือ Core
      ทางด้านความคิดของวิศวกรรมอุตสาหการ อันได้แก่ ความสามารถในการวิเคราะห์
      และความสามารถในการคิดอย่างเป็นระบบก็ยังคงทันสมัยอยู่
      หากแต่ตัวเทคโนโลยีที่ใช้ในการสนับสนุนการตัดสินใจ
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      สำหรับโจทย์ใหม่ที่เราต้องเจอ ก็จะต้องเปลี่ยนแปลงไปอย่างแน่นอน
      จึงทำให้วิศวกรรมอุตสาหการตั้งแต่ยุคนี้เป็นต้นไป จำเป็นต้องเรียนรู้
      เข้าใจและประยุกต์เทคโนโลยีต่างๆ
      กลับเข้ามาใช้ในการทำงานของตนเองได้อย่างมีประสิทธิภาพ
    </Text.Body>

    <Text.Body textIndent={'20px'}>
      เราเองในฐานะผู้พัฒนานิสิตได้ตระหนักถึงประเด็นเหล่านั้นดี
      เราจึงได้มีการปรับปรุงและพัฒนาตนเองมาอย่างต่อเนื่อง
      ไม่ว่าจะเป็นในด้านเนื้อหาหลักสูตร ระบบวิธีการเรียนการสอน Facility ต่างๆ
      สนับสนุนการเรียนการสอน เพื่อต้องการให้เกิดความมั่นใจได้ว่า
      เราสามารถฟูมฟักนิสิตของเรา ทั้งด้านความรู้ทางวิชาการ
      ความคิดจิตวิญญาณเพื่อให้เขาพร้อมออกสู่สังคม
      และประสบความสำเร็จดังเช่นบัณฑิตรุ่นก่อนๆ มา
    </Text.Body>
  </div>
);

Vision.propTypes = {};
Vision.defaultProps = {};

export default Vision;
