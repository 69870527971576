import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from 'components/atoms/Icon';
import { getIcon } from 'helpers/functions';

const Root = styled('a')`
  display: inline-flex;
  min-height: 40px;
  border-radius: 2px;
  transition: 0.12s;
  background: ${({ theme }) => theme.primary.main};
  box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3),
    0 2px 4px 0 rgba(45, 35, 66, 0.4),
    inset 0 -2px 0 0 ${({ theme }) => theme.primary.main};

  .DownloadUI-left {
    width: 40px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: ${({ theme }) => `1px solid ${theme.primary.main}`};
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .DownloadUI-right {
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
  }

  .DownloadUI-download {
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
  }

  .DownloadUI-label {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }

  :focus,
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 11px 16px -3px rgba(45, 35, 66, 0.3),
      0 4px 5px 0 rgba(45, 35, 66, 0.4),
      inset 0 -2px 0 0 ${({ theme }) => theme.primary.main};
  }
  :active {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.38);
    transform: translateY(0px);
  }

  &.-disabled {
    pointer-events: none;
    background: #e5e5e5;
    box-shadow: none;
    i {
      color: #a5a5a5;
    }
    .DownloadUI-left {
      border-color: #d5d5d5;
    }

    .DownloadUI-download {
      color: #a5a5a5;
    }

    .DownloadUI-label {
      color: #a5a5a5;
    }
  }
`;

const DownloadUI = ({ className, label, getMsg, href, ...props }) => (
  <Root
    className={cx('DownloadUI-root', className, !href && '-disabled')}
    target={'_blank'}
    rel={'noopener'}
    href={href}
    {...props}
  >
    <div className={'DownloadUI-left'}>
      <Icon
        type={`fal fa-file-${getIcon(label)}`}
        fontSize={24}
        color={'primary.main'}
      />
    </div>
    <div className={'DownloadUI-right'}>
      <span className={'DownloadUI-download'}>{getMsg('download')}</span>
      <span className={'DownloadUI-label'}>{label}</span>
    </div>
  </Root>
);

DownloadUI.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  getMsg: PropTypes.func,
  href: PropTypes.string,
};
DownloadUI.defaultProps = {
  href: '',
  className: '',
  getMsg: () => 'Download',
};

export default DownloadUI;
