import React from 'react';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import { isInRange } from 'helpers/dateAndTime';

const DoctorateBeginCalendar = () => (
  <div>
    <Text.Title level={3}>Ph.D.(IE) Admission Calendar</Text.Title>
    <Text.Body>
      กำหนดการรับสมัครคัดเลือกนิสิตเข้าศึกษาในระดับดุษฎีบัณฑิตศึกษา{' '}
      <b>ประจำภาคการศึกษาต้น</b>
    </Text.Body>
    <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
      กำหนดการฉบับย่อ
    </Text.Paragraph>
    <MonthlyTimeline
      events={[
        {
          title: 'กุมภาพันธ์ - เมษายน',
          subtitle: 'เปิดรับสมัครนิสิตภาคการศึกษาต้น',
          current: isInRange([1, 2], [31, 4]),
        },
        {
          title: 'พฤษภาคม',
          subtitle: '',
          list: [
            'ประกาศรายชื่อผู้มีสิทธิ์เข้ารับการทดสอบ ',
            // eslint-disable-next-line max-len
            'รายละเอียด วัน เวลา สถานที่สอบ ของวิชาที่เปิดรับสมัคร จากเว็บไซต์',
          ],
          links: [
            {
              label: 'www.grad.chula.ac.th',
              href: 'https://www.grad.chula.ac.th',
            },
          ],
          current: isInRange([1, 5], [20, 5]),
        },
        {
          title: 'กำหนดวันทดสอบข้อเขียนและสัมภาษณ์',
          current: isInRange([21, 5], [31, 5]),
        },
        {
          title: 'วันสุดท้ายของการส่งรายชื่อผู้ผ่านการสอบคัดเลือก',
          subtitle: '(หากไม่มีคะแนนภาษาอังกฤษไม่รับเข้าศึกษา)',
          current: isInRange([1, 6], [31, 6]),
        },
        {
          title: 'กรกฎาคม',
          subtitle: 'ประกาศเลขประจำตัวนิสิตใหม่ ทางอินเตอร์เน็ต',
          current: isInRange([1, 7], [20, 7]),
        },
        {
          title: 'ปลายกรกฎาคม',
          subtitle: 'ดาวน์โหลดเอกสารลงทะเบียนและลงทะเบียนแรกเข้า',
          current: isInRange([21, 7], [31, 7]),
        },
        {
          title: 'สิงหาคม',
          subtitle: 'วันเปิดภาคเรียน',
          current: isInRange([1, 8], [31, 8]),
        },
      ]}
    />
    <br />
    <br />
    <br />
  </div>
);

DoctorateBeginCalendar.propTypes = {};
DoctorateBeginCalendar.defaultProps = {};

export default DoctorateBeginCalendar;
