import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Tag } from 'antd';
import get from 'lodash/get';
import useIntl from 'hooks/useIntl';

const tagCss = css`
  margin-bottom: 4px;
`;

const TagList = ({ tags, mapById, getPath }) => {
  if (!tags) return null;
  const { locale } = useIntl();
  if (typeof tags === 'string') {
    return (
      <Tag className={tagCss}>{get(mapById, [tags, ...getPath(locale)])}</Tag>
    );
  }
  return tags.map(label => (
    <Tag key={label} className={tagCss}>
      {get(mapById, [label, ...getPath(locale)])}
    </Tag>
  ));
};

TagList.propTypes = {
  mapById: PropTypes.shape({}).isRequired,
  tags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  getPath: PropTypes.func,
};
TagList.defaultProps = {
  tags: [],
  localeDisabled: false,
  getPath: locale => ['label', locale],
};

export default TagList;
