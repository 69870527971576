import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import { animateScroll } from 'react-scroll';
import { Row, Col, Button, Affix } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import Hidden from 'components/responsive/Hidden';
import { CategoryFilter } from 'components/molecules/Filter';
import { withConsumer } from 'contexts/lecturer';
import { withIntl } from 'contexts/intl';
import FireApi from 'firebase/fireApi';
import LecturerFactory from 'factories/User/Lecturer';

import Query from 'factories/query';

// CONSTANTS
import { CATEGORIES } from 'constants/temporary';
import SearchInput from 'components/atoms/SearchInput';
import Drawer from 'components/molecules/Drawer';
import ProfessorCard from 'components/molecules/ProfessorCard';
import Container from 'components/layouts/Container';

const loadingArray = Array(6)
  .fill(0)
  .map((i, index) => ({ id: i + index }));

const LecturersPage = ({
  location: { search, pathname },
  history,
  lecturers,
  setLecturers,
  // intl
  getMsg,
  locale,
}) => {
  const query = Query().toObject(search);
  const { keyword, category } = Query(search).get();
  const [tempKeyword, setTempKeyword] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    if (!lecturers || !lecturers.length) {
      setLoading(true);
      FireApi.fetchLecturers()
        .then(setLecturers)
        .then(() => setLoading(false));
    }
  }, []);
  const getLecturers = () => {
    if (!lecturers) return [];
    return lecturers
      .filter(({ categories }) => {
        if (!category) return true;
        return every(isArray(category) ? category : [category], c =>
          categories.includes(c),
        );
      })
      .filter(({ name }) => {
        if (!keyword) return true;
        return (
          (name.en || '').toLowerCase().includes(keyword.toLowerCase()) ||
          (name.th || '').toLowerCase().includes(keyword.toLowerCase())
        );
      })
      .filter(({ hidden }) => !hidden);
  };
  const handleSearch = val => {
    if (val) {
      animateScroll.scrollToTop({
        duration: 0,
        delay: 0,
      });
      history.replace(`${pathname}?keyword=${val}`);
      setTempKeyword('');
    }
  };
  const people = getLecturers();
  const displayedLecturers = React.useMemo(
    () => LecturerFactory().orderByRank(people, locale),
    [people, locale],
  );
  const renderContent = () => (
    <ProfessorCard.Group
      professors={loading ? loadingArray : displayedLecturers}
      loading={loading}
    />
  );
  return (
    <Container>
      <Hidden smDown>
        <Row type={'flex'} justify={'space-between'}>
          <Col sm={8}>
            <Affix offsetTop={24}>
              <Box px={8} pb={16} {...Box.justifyBetween}>
                <Text textStyle={'categoryHeading'}>{getMsg('lecturers')}</Text>
                <Box>
                  {!isEmpty(query) && (
                    <Box pr={{ sm: 24, md: 36 }} display={'inline-block'}>
                      <Button
                        type="primary"
                        shape="round"
                        size={'small'}
                        onClick={() => history.replace(pathname)}
                        htmlType={'button'}
                      >
                        {query.keyword
                          ? getMsg('clearSearch')
                          : getMsg('clearFilter')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                pr={{
                  sm: 16,
                  md: 36,
                }}
              >
                <CategoryFilter
                  title={getMsg('courseCategory').toUpperCase()}
                  menus={CATEGORIES}
                  amountShowed
                />
              </Box>
            </Affix>
          </Col>
          <Col sm={16}>
            <Affix offsetTop={0}>
              <Box p={16} mx={-16} bgColor={'grey.100'}>
                <SearchInput
                  placeholder={getMsg('searchLecturerPlaceholder')}
                  value={tempKeyword}
                  onChange={e => setTempKeyword(e.target.value)}
                  onSearch={handleSearch}
                />
              </Box>
            </Affix>
            {renderContent()}
          </Col>
        </Row>
      </Hidden>
      <Hidden smUp>
        <Affix offsetTop={0} onChange={affixed => setIsSticky(affixed)}>
          <Box
            bgColor={'grey.100'}
            px={16}
            mx={-16}
            pb={16}
            boxShadow={isSticky ? '0 0 10px -2px rgba(0,0,0,0.4)' : ''}
          >
            <Box pb={8} {...Box.justifyBetween}>
              <Text textStyle={'categoryHeading'}>{getMsg('lecturers')}</Text>
              {!isEmpty(query) && (
                <Box display={'inline-block'}>
                  <Button
                    type="primary"
                    shape="round"
                    size={'small'}
                    onClick={() => history.replace(pathname)}
                    htmlType={'button'}
                  >
                    {query.keyword
                      ? getMsg('clearSearch')
                      : getMsg('clearFilter')}
                  </Button>
                </Box>
              )}
              <Button
                className={'simple'}
                size={'large'}
                type="primary"
                ghost
                icon="filter"
                onClick={() => setOpen(true)}
                htmlType={'button'}
              >
                {getMsg('filter')}
              </Button>
            </Box>
            <SearchInput
              placeholder={getMsg('searchLecturerPlaceholder')}
              value={tempKeyword}
              onChange={e => setTempKeyword(e.target.value)}
              onSearch={handleSearch}
            />
          </Box>
        </Affix>
        {renderContent()}
        <Drawer
          title={getMsg('filter')}
          placement={'right'}
          onClose={() => setOpen(false)}
          visible={open}
        >
          <Box px={8}>
            <Box textAlign={'right'} mr={8}>
              {!isEmpty(query) && (
                <Button
                  type="primary"
                  shape="round"
                  size={'small'}
                  onClick={() => {
                    setOpen(false);
                    history.replace(pathname);
                  }}
                  htmlType={'button'}
                >
                  {getMsg('clearFilter')}
                </Button>
              )}
            </Box>
            <CategoryFilter
              title={getMsg('courseCategory').toUpperCase()}
              menus={CATEGORIES}
              amountShowed
            />
          </Box>
        </Drawer>
      </Hidden>
    </Container>
  );
};

LecturersPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  lecturers: PropTypes.arrayOf(PropTypes.shape({})),
  setLecturers: PropTypes.func.isRequired,
  getMsg: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  mapLocale: PropTypes.func.isRequired,
};
LecturersPage.defaultProps = {
  lecturers: null,
};

export default withIntl(withConsumer(LecturersPage));
