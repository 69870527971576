import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import styled from '@emotion/styled';
import { Timeline as AntTimeline } from 'antd';
import Text from 'components/atoms/Text';

const StyledTimeline = styled(AntTimeline)`
  &.ant-timeline {
    margin-left: 48px;
    ${({ theme }) => `
      ${[theme.breakpoints.up('sm')]} {
        margin-left: 52px;
      }
    `}
  }
  .ant-timeline-item-head {
    background-color: ${({ theme }) => theme.primary.main};
    width: 8px;
    height: 8px;
  }
  .ant-timeline-item-tail {
    border-color: ${({ theme }) => theme.primary.main};
    border-width: 1px;
    top: 0;
    left: 4px;
  }
  .ant-timeline-item-content {
    ${({ theme }) => `
      ${[theme.breakpoints.up('sm')]} {
        margin-left: 24px;
      }
    `}
  }
  .TimelineItem-year {
    position: absolute;
    top: 2px;
    left: -64px;
    font-size: 12px;
  }
`;

const { Item } = AntTimeline;

const Timeline = ({ events }) => (
  <StyledTimeline>
    {orderBy(events, ['year'], ['desc']).map(({ title, subtitle, year }) => (
      <Item key={title}>
        <Text.Title level={4} fontSize={16} mb={4}>
          {title}
        </Text.Title>
        {subtitle && <Text.Paragraph>{subtitle}</Text.Paragraph>}
        {year && (
          <Text.Paragraph
            className={'TimelineItem-year'}
            color={'primary.main'}
          >
            {year}
          </Text.Paragraph>
        )}
      </Item>
    ))}
  </StyledTimeline>
);

Timeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
};
Timeline.defaultProps = {
  events: [],
};

export default Timeline;
