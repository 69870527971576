import React from 'react';
import { Col, Row } from 'antd';
import Text from 'components/atoms/Text';
import TimeIndicator from './TimeIndicator';

const TimeTableHeader = () => (
  <Row type="flex">
    <Col xs={0} md={2}>
      <Text.Paragraph
        textAlign={'right'}
        color={'grey.400'}
        fontWeight={'bold'}
        mr={32}
      >
        Time
      </Text.Paragraph>
    </Col>
    <Col xs={24} md={22}>
      <Row>
        <Col span={12}>
          <TimeIndicator start={8} end={13} size={10} hiddenEndTime />
        </Col>
        <Col span={12}>
          <TimeIndicator start={13} end={18} size={10} />
        </Col>
      </Row>
    </Col>
  </Row>
);

TimeTableHeader.propTypes = {};
TimeTableHeader.defaultProps = {};

export default TimeTableHeader;
