import React from 'react';
import { useIntl } from 'contexts/intl';
import ContactInfo from 'components/molecules/ContactInfo';
import AmpeEN from './AmpeEN';
import AmpeTH from './AmpeTH';

export default () => {
  const { locale } = useIntl();
  return (
    <>
      {locale === 'en' && <AmpeEN />}
      {locale === 'th' && <AmpeTH />}
      <ContactInfo
        menus={[
          { id: 'phone', icon: 'phone', type: 'phone' },
          {
            id: 'email',
            icon: 'mail',
            type: 'email',
            value: '-',
          },
          {
            id: 'website',
            icon: 'fal fa-browser',
            type: 'url',
          },
          {
            id: 'facebook',
            icon: 'fab fa-facebook',
            type: 'url',
            value: '-',
          },
        ]}
      />
    </>
  );
};
