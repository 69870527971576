import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { Row, Col } from 'antd';
import Text from 'components/atoms/Text';
import useIntl from 'hooks/useIntl';

const lat = 13.7359843;
const lng = 100.5338229;

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={16} defaultCenter={{ lat, lng }}>
      {props.isMarkerShown && <Marker position={{ lat, lng }} />}
    </GoogleMap>
  )),
);

Map.propTypes = {
  isMarkerShown: PropTypes.bool,
  googleMapURL: PropTypes.string,
  loadingElement: PropTypes.element,
  containerElement: PropTypes.element,
  mapElement: PropTypes.element,
};
Map.defaultProps = {
  isMarkerShown: true,
  googleMapURL:
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAP_KEY
    }&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `400px` }} />,
  mapElement: <div style={{ height: `100%` }} />,
};

const UList = styled('ul')({
  paddingLeft: 16,
  '& > li': {
    marginBottom: 8,
  },
});

const Location = () => {
  const { getMsg } = useIntl();
  return (
    <div>
      <Map />
      <br />
      <Text.Title level={4}>{getMsg('address')}</Text.Title>
      <Text.Paragraph>{getMsg('ieAddress')}</Text.Paragraph>
      <br />
      <Row>
        <Col xs={24} sm={12}>
          <Text.Title level={4}>{getMsg('email')}</Text.Title>
          <Text.Paragraph
            component={'a'}
            href={'mailto:ie.info@eng.chula.ac.th'}
            target={'_blank'}
            rel={'noopener'}
          >
            ie.info@eng.chula.ac.th
          </Text.Paragraph>
          <br />
          <br />
        </Col>
        <Col xs={24} sm={12}>
          <Text.Title level={4}>{getMsg('tel')}</Text.Title>
          <UList>
            <li>
              <Text.Paragraph
                component={'a'}
                href={'tel:+6622186814-6'}
                target={'_blank'}
                rel={'noopener'}
              >
                0-2218-6814-6
              </Text.Paragraph>
            </li>
            <li>
              <Text.Paragraph
                component={'a'}
                href={'tel:+66912107165'}
                target={'_blank'}
                rel={'noopener'}
              >
                091-210-7165
              </Text.Paragraph>
            </li>
          </UList>
          <br />
          <br />
        </Col>
        <Col xs={24} sm={12}>
          <Text.Title level={4}>{getMsg('fax')}</Text.Title>
          <Text.Paragraph>0-2251-3969, 0-2218-6813</Text.Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default Location;
