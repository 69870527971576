/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { Element } from 'react-scroll';
import { Col, Row, Table, Divider } from 'antd';
import Text from 'components/atoms/Text';
import useScrollToElement from 'hooks/useScrollToElement';
import DownloadUI from 'components/atoms/DownloadUI';
import Bulletin from 'components/molecules/Bulletin';
import { useConsumer } from 'contexts/db';
import ROUTES from 'constants/routes';

const tableCss = css`
  .ant-table-tbody > tr:hover > td {
    background: none !important;
  }
`;

const AdmissionDoctorate = ({ scrollValue }) => {
  const dbAssets = useConsumer();
  useScrollToElement(scrollValue);
  return (
    <div>
      <Element name={'d-program'}>
        <Text.Heading plainFont={false} letterSpacing={1}>
          หลักสูตรวิศวกรรมศาสตรดุษฎีบัณฑิต <br /> สาขาวิชาวิศวกรรมอุตสาหการ
        </Text.Heading>
      </Element>
      <Text.SubHeading mb={24}>
        Doctor of Philosophy | Ph.D.(IE)
      </Text.SubHeading>

      <Bulletin
        data={get(dbAssets, 'admission.phd')}
        defaultLabel={'Ph.D.(IE) Bulletin'}
      />

      <Text.Topic>ปรัชญาของหลักสูตร</Text.Topic>
      <Text.Body>
        ผลิตดุษฎีบัณฑิตที่เป็นผู้นำในด้านการวิจัยในสาขาวิศวกรรมอุตสาหการ
        อีกทั้งเป็นผู้ที่มีความสามารถในการถ่ายทอดองค์ความรู้ใหม่ที่เป็นประโยชน์ต่อส่วนรวมแก่ผู้อื่นได้
      </Text.Body>
      <Text.Topic>ความสำคัญของหลักสูตร</Text.Topic>
      <Text.Body>
        หลักสูตรวิศวกรรมศาสตรดุษฎีบัณฑิต สาขาวิชาวิศวกรรมอุตสาหการ
        มีแผนการพัฒนาคุณภาพหลักสูตร และคุณภาพบัณฑิตในระดับนานาชาติ
        ด้วยการกำหนดคุณลักษณะของบัณฑิตที่พึงประสงค์และผลการเรียนรู้ในรายวิชาต่างๆให้สอดคล้องกับความต้องการคุณภาพมาตรฐานความสามารถของวิศวกรรมศาสตรดุษฎีบัณฑิตในสาขาวิชาวิศวกรรมอุตสาหการ
        และปรับปรุงกระบวนการเรียนการสอน ที่มุ่งเน้นการศึกษา วิจัยอย่างเป็นระบบ
        พัฒนานิสิตให้มีทักษะความสามารถในด้านการสืบค้นปัญหา วิเคราะห์ปัญหา
        และประยุกต์ความรู้พื้นฐานในการแก้ปัญหาทางวิศวกรรมที่มีความซับซ้อน
        มีความสามารถในการสื่อสาร และการนำเสนอการแก้ไขปัญหาทางวิศวกรรม
        ด้วยภาษาไทยและภาษาอังกฤษ อย่างมีประสิทธิผล มีคุณธรรม จริยธรรม
        ทำงานได้ด้วยตนเอง
        และร่วมงานกับทีมงานที่มีความหลากหลายทางวัฒนธรรมได้อย่างดีเป็นที่ยอมรับในภาคอุตสาหกรรม
        อีกทั้ง
        พัฒนาให้ดุษฎีบัณฑิตสามารถผลิตงานวิจัยหรือสร้างองค์ความรู้ใหม่ทางด้านวิศวกรรมอุตสาหการ
        และถ่ายทอดองค์ความรู้ใหม่ที่เป็นประโยชน์ต่อส่วนรวมแก่ผู้อื่นได้
        เพื่อพัฒนาความเป็นผู้เชี่ยวชาญในการพัฒนาเศรษฐกิจของประเทศและนานาชาติ
      </Text.Body>
      <Text.Topic>วัตถุประสงค์ของหลักสูตร</Text.Topic>
      <Text.Body mb={12}>
        <b>วัตถุประสงค์ของหลักสูตรปรับปรุง (พ.ศ.2561)</b>
      </Text.Body>
      <Text.Body>
        <ol>
          <li>
            เพื่อผลิตดุษฎีบัณฑิตให้มีความรู้ความสามารถในด้านการสืบค้นปัญหา
            วิเคราะห์ปัญหา
            และประยุกต์ความรู้พื้นฐานในการแก้ปัญหาทางวิศวกรรมที่มีความซับซ้อนได้
          </li>
          <li>
            เพื่อสร้างความต่อเนื่องในการผลิตงานวิจัย สร้างองค์ความรู้ใหม่
            และเผยแพร่องค์ความรู้ทางด้านวิศวกรรมอุตสาหการ
            และสนับสนุนให้เกิดความร่วมมือและการแลกเปลี่ยนความรู้และประสบการณ์ในระดับประเทศและระดับนานาชาติ
          </li>
        </ol>
      </Text.Body>
      <Text.Topic>การดำเนินการหลักสูตร</Text.Topic>
      <Text.Body mb={12}>
        <b>วัน-เวลาในการดำเนินการเรียนการสอน (ระบบทวิภาค)</b>
      </Text.Body>
      <Text.Body>
        <ul>
          <li>ภาคการศึกษาต้น : สิงหาคม - ธันวาคม</li>
          <li>ภาคการศึกษาปลาย : มกราคม - พฤษภาคม</li>
          <li>ภาคฤดูร้อน : มิถุนายน - กรกฎาคม</li>
        </ul>
      </Text.Body>
      <Text.Topic>คุณสมบัติของผู้เข้าศึกษา</Text.Topic>
      <Text.Body mb={12}>
        สำเร็จการศึกษาปริญญาวิศวกรรมศาสตรมหาบัณฑิต สาขาวิชาวิศวกรรมอุตสาหการ
        หรือเทียบเท่า และมีคะแนนเฉลี่ยอย่างน้อย 3.50 ตามระดับคะแนน 4 แต้ม
        หรือเทียบเท่า โดยคุณภาพของวิทยานิพนธ์ระดับมหาบัณฑิตอยู่ในเกณฑ์ดี
        หรือดีมาก หรือเทียบเท่า
      </Text.Body>
      <Text.Body>
        คุณสมบัติอื่นๆ เป็นไปตามประกาศซึ่งบัณฑิตวิทยาลัยจะประกาศให้ทราบเป็นปีๆ
        ไป หรือคณะกรรมการบริหารหลักสูตรพิจารณาแล้ว เห็นสมควรให้มีสิทธิสมัครได้
      </Text.Body>
      <Text.Topic>หลักสูตร</Text.Topic>
      <Text.Body>
        จำนวนหน่วยกิตรวมตลอดหลักสูตร 48 หน่วยกิต ระยะเวลาการศึกษา 3 ปี
      </Text.Body>
      <Text.Topic>โครงสร้างหลักสูตร</Text.Topic>
      <Table
        defaultExpandAllRows
        scroll={{ x: true }}
        className={tableCss}
        pagination={false}
        bordered
        columns={[
          { title: 'หมวด', dataIndex: 'subject' },
          { title: 'หน่วยกิต', dataIndex: 'credit' },
        ]}
        dataSource={[
          {
            key: '1',
            subject: 'แบบ 2.1',
            credit: 48,
            children: [
              {
                key: '2',
                subject: 'จำนวนหน่วยกิตรายวิชาเรียน',
                credit: 12,
              },
              {
                key: '3',
                subject: 'จำนวนหน่วยกิตวิทยานิพนธ์',
                credit: 36,
              },
            ],
          },
        ]}
      />
      <br />
      <Text.Topic>คุณสมบัติของผู้มีสิทธิ์สมัคร</Text.Topic>
      <Text.Body>
        <ol>
          <li>
            ได้คะแนนการทดสอบความรู้ความสามารถทางภาษาอังกฤษ (CU-TEP ตั้งแต่ 45
            หรือ TOEFL ตั้งแต่ 450 หรือ IELTS ตั้งแต่ 4.0)
          </li>
          <li>
            สำเร็จการศึกษาปริญญาวิศวกรรมศาสตรมหาบัณฑิต
            สาขาวิศวกรรมอุตสาหการหรือเทียบเท่า หรือ ปริญญาวิทยาศาสตรมหาบัณฑิต
            ในสาขาวิชาที่มีความเกี่ยวข้องกับวิศวกรรมอุตสาหการ และมีคะแนนเฉลี่ย
            อย่างน้อย 3.50 โดยคุณภาพของวิทยานิพนธ์ระดับมหาบัณฑิตอยู่ในเกณฑ์ดี
            หรือดีมาก
          </li>
          <li>
            คณะกรรมการบริหารหลักสูตรฯ
            พิจารณาแล้วเห็นสมควรให้มีสิทธิ์สมัครเข้าศึกษาได้
          </li>
        </ol>
      </Text.Body>
      <Element name={'d-how-to-apply'}>
        <Text.Topic>วิธีการสมัคร</Text.Topic>
      </Element>
      <Text.Body mb={12}>
        <ol>
          <li>
            ผู้สมัครสามารถเข้าไปดูรายละเอียดที่ www.grad.chula.ac.th เลือกเมนู
            “การเข้าศึกษา” เลือก “ขั้นตอนการสมัครเข้าศึกษา”
            อ่านรายละเอียดขั้นตอนการสมัครเข้าศึกษาก่อนแล้ว
            จึงดำเนินการตามที่บัณฑิตวิทยาลัยกำหนด
          </li>
          <li>
            ผู้สมัครกรอกข้อมูลเรียบร้อยแล้ว{' '}
            <b>พิมพ์ใบสมัครพร้อมเอกสารประกอบการรับสมัคร นำส่งทางไปรษณีย์</b>{' '}
            ถึงบัณฑิตวิทยาลัย (วันที่ ที่ทำการไปรษณีย์ต้นทางประทับตราต้องไม่เกิน
            1 วันหลังวันสุดท้ายของการรับสมัครทางไปรษณีย์)
          </li>
        </ol>
      </Text.Body>
      <Text.Body mb={12}>
        <b>(เพิ่มเติม)</b>{' '}
        ผู้สมัครต้องกรอกใบสมัครของภาควิชาวิศวกรรมอุตสาหการพร้อมหลักฐานต่าง ๆ
        รวมทั้งจดหมาย รับรองจากอาจารย์ที่ปรึกษา หรือผู้บังคับบัญชาจำนวน 2 ฉบับ
        ซึ่งขอแบบฟอร์มได้ที่ห้องธุรการภาควิชาวิศวกรรม อุตสาหการ
        อาคารเจริญวิศวกรรม (ตึก 4) ชั้น 4 คณะวิศวกรรมศาสตร์
        <b>ก่อน</b>การสมัครผู้สมัครต้องมีแนวคิดในการทำวิทยานิพนธ์
        และมีอาจารย์ที่ตนต้องการจะทำวิจัยวิทยานิพนธ์ด้วย
        เพื่อหารือเรื่องหัวข้อวิทยานิพนธ์เบื้องต้นสำหรับนำไปเขียนประกอบการสมัคร
      </Text.Body>
      <Text.Body>
        <b>กำหนดการเริ่มเข้าศึกษา</b> ดูในประกาศรับเข้าศึกษาของคณะ
        สำหรับผู้ที่ศึกษาอยู่ในภาคการศึกษาสุดท้าย
        จะต้องแสดงหลักฐานสำเร็จการศึกษาในวันลงทะเบียนแรกเข้า
        ซึ่งการรับเข้าศึกษาจะมีผลเมื่อผู้สมัครสำเร็จการศึกษาแล้ว
        โดยได้แต้มเฉลี่ยตามคุณสมบัติที่กำหนด
      </Text.Body>
      <Element name={'d-fees'}>
        <Text.Topic>รายละเอียดค่าใช้จ่าย</Text.Topic>
      </Element>
      <Row type={'flex'}>
        <Col xs={17} md={14}>
          <Text.Body ml={16} mb={0}>
            ค่ารับสมัครเข้าศึกษา
          </Text.Body>
        </Col>
        <Col>
          <Text.Body mb={0}>500 บาท</Text.Body>
        </Col>
        <Col xs={17} md={14}>
          <Text.Body ml={16} mb={0}>
            ค่าเล่าเรียนภาคการศึกษาละ
          </Text.Body>
        </Col>
        <Col>
          <Text.Body mb={0}>33,500 บาท</Text.Body>
        </Col>
      </Row>
      <br />
      <Text.Body>
        หากผู้สมัครมีข้อสงสัยเกี่ยวกับคุณสมบัติของผู้มีสิทธิ์สมัคร
        ติดต่อได้ที่เจ้าหน้าที่ภาควิชาฯ โทร. 0-2218-6814-7
        ในระหว่างวันเวลาราชการ วันจันทร์-ศุกร์ เวลา 08.00 - 16.00 น.
        และวันเสาร์-อาทิตย์ เวลา 09.00-15.30 น. อาคารเจริญวิศวกรรม (ตึก 4) ชั้น
        4 ห้องบริหารงานทั่วไป ภาควิชาวิศวกรรมอุตสาหการ คณะวิศวกรรมศาสตร์
        จุฬาลงกรณ์มหาวิทยาลัย หรือที่ Website :{' '}
        <a
          href="https://www.grad.chula.ac.th"
          target={'_blank'}
          rel={'noopener'}
        >
          www.grad.chula.ac.th
        </a>
      </Text.Body>
      <Text.Topic>ช่วงเวลาเปิดรับสมัคร</Text.Topic>
      <Text.Body>
        <ul>
          <li>
            เปิดรับสมัคร รอบที่ 1 ช่วงเดือน <b>กุมภาพันธ์</b> ถึง <b>มีนาคม</b>
          </li>
          <li>
            เปิดรับสมัคร รอบที่ 2 ช่วงเดือน <b>เมษายน</b> ถึง <b>พฤษภาคม</b>
          </li>
        </ul>
        ที่{' '}
        <a
          href="https://www.grad.chula.ac.th"
          target={'_blank'}
          rel={'noopener'}
        >
          www.grad.chula.ac.th
        </a>{' '}
        (ดูที่เมนู- การเข้าศึกษา)
      </Text.Body>
      <Text.Body fontWeight={'bold'}>
        ผู้ที่ไม่มีผลสอบภาษาอังกฤษ (CU-TEP) ดูรายละเอียดได้จากประกาศ เรื่อง
        การทดสอบความรู้ความสามารถทางภาษาอังกฤษ และสมัครได้ที่{' '}
        <a href="http://www.atc.chula.ac.th" target={'_blank'} rel={'noopener'}>
          www.atc.chula.ac.th
        </a>{' '}
        (ส่งผลการทดสอบภาษาอังกฤษ (CU-TEP) ที่ภาควิชาวิศวกรรมอุตสาหการ เดือน
        พฤษภาคม ก่อนเข้าสัมภาษณ์ หากไม่มีคะแนนภาษาอังกฤษไม่รับเข้าศึกษา)
      </Text.Body>
      <Text.Topic>ประกาศรายชื่อผู้มีสิทธิ์สอบ</Text.Topic>
      <Text.Body>
        กลางเดือนพฤษภาคม ที่ บัณฑิตวิทยาลัย หรือ{' '}
        <a
          href="https://www.grad.chula.ac.th"
          target={'_blank'}
          rel={'noopener'}
        >
          www.grad.chula.ac.th
        </a>{' '}
        และที่เว็บไซต์ภาควิชา
      </Text.Body>
      <Text.Topic>ประกาศผลการสอบคัดเลือก</Text.Topic>
      <Text.Body>
        ต้นเดือนมิถุนายน ที่ บัณฑิตวิทยาลัย หรือ{' '}
        <a
          href="https://www.grad.chula.ac.th"
          target={'_blank'}
          rel={'noopener'}
        >
          www.grad.chula.ac.th
        </a>{' '}
        และที่เว็บไซต์ภาควิชา
      </Text.Body>
      <Text.Topic
        fontSize={20}
        fontWeight={'bold'}
        component={Link}
        to={ROUTES.scholarships.get('/phd')}
      >
        ดูข้อมูลทุนการศึกษา ป.เอก
      </Text.Topic>
      <br />
      <br />
      <Text textStyle={'update'}>** อัพเดทข้อมูลล่าสุด ปี 2562 **</Text>
      <Divider />
      <DownloadUI
        label={'รายละเอียด ป.เอก (ภาคต้น) (ปกติ)'}
        href={get(dbAssets, 'admission.phd.detailGeneral')}
      />
      <br />
      <br />
      <DownloadUI
        label={'รายละเอียด ป.เอก (ภาคต้น) (วิธีพิเศษ)'}
        href={get(dbAssets, 'admission.phd.detailSpecial')}
      />
      <br />
      <br />
    </div>
  );
};

AdmissionDoctorate.propTypes = {
  scrollValue: PropTypes.string.isRequired,
};
AdmissionDoctorate.defaultProps = {};

export default AdmissionDoctorate;
