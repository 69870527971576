import React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import cx from 'clsx';
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import {
  opacity,
  fontSize,
  lineHeight,
  space,
  size,
  display,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  width,
  height,
  borders,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  overflow,
  boxShadow,
  flex,
  alignSelf,
  alignContent,
} from 'styled-system';

const Image = styled(({ className, src, alt, avatar, ...props }) => {
  const Component = avatar ? Avatar : 'img';
  return (
    <Component
      alt={alt}
      src={src}
      className={cx('Image', className)}
      {...omit(props, [
        'maxHeight',
        'lineHeight',
        'alignSelf',
        'circular',
        'zIndex',
      ])}
    />
  );
})`
  ${({ theme, css, circular }) => ({
    ...(isFunction(css) ? css(theme) : css),
    ...(circular && {
      borderRadius: 100,
    }),
  })}
  &.ant-avatar.ant-avatar-icon {
    ${fontSize}
    ${lineHeight}
    > img {
      object-fit: cover;
    }
  };
  ${opacity}
  ${space}
  ${size}
  ${display}
  ${maxWidth}
  ${maxHeight}
  ${minWidth}
  ${minHeight}
  ${width}
  ${height}
  ${borders}
  ${position}
  ${zIndex}
  ${top}
  ${right}
  ${bottom}
  ${left}
  ${overflow}
  ${boxShadow}
  ${flex}
  ${alignContent}
  ${alignSelf}
`;

Image.createAntAvatar = sizes => ({
  size: sizes,
  fontSize: mapValues(sizes, o => o / 2),
  lineHeight: mapValues(sizes, o => `${o}px`),
});
Image.displayName = 'Image';
Image.propTypes = {
  circular: PropTypes.bool,
};
Image.defaultProps = {
  circular: false,
};

export default Image;
