import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'components/atoms/Text';
import Box from 'components/atoms/Box';
import ROUTES from 'constants/routes';

const ChaseTH = () => (
  <div>
    <Box mx={{ xs: -16 }} mb={24}>
      <img
        style={{ display: 'block', maxWidth: '100%' }}
        alt={'cover'}
        src={
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/prod-ie-chula.appspot.com/o/public%2Flabs%2FCHASE_4.jpg?alt=media&token=2bddda89-b0f1-4704-9135-018fe8407ab5'
        }
      />
    </Box>
    <Text.Heading level={3} plainFont={false} letterSpacing={1}>
      {/* eslint-disable-next-line max-len */}
      ศูนย์วิศวกรรมระบบและปัจจัยมนุษย์ (CHASE)
    </Text.Heading>
    <Text.Topic>งานวิจัยที่เกี่ยวข้อง</Text.Topic>
    <Text.Body>
      <ul>
        <li>ปัจจัยมนุษย์การรู้คิด</li>
        <li>วิศวกรรมระบบ</li>
        <li>วิศวกรรมความสามารถการใช้งาน</li>
        <li>การออกแบบงานบริการ และประสบการณ์ผู้ใช้</li>
      </ul>
    </Text.Body>

    <Text.Topic>บุคลากร</Text.Topic>
    <Text.Body>
      <ul>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/K14thUQ7fYsIuhTOjaFs/Oran%20Kittithreerapronchai',
            )}
          >
            รศ. ดร. โอฬาร กิตติธีรพรชัย
          </Link>
        </li>
        <li>
          <Link
            to={ROUTES.lecturers.get(
              '/9maBFjohH9H11PeAHJhc/Arisara%20Jiamsanguanwong',
            )}
          >
            ผศ. ดร. อริศรา เจียมสงวนวงศ์
          </Link>
        </li>
      </ul>
    </Text.Body>

    <Text.Topic>สถานที่</Text.Topic>
    <Text.Body>ห้อง 715 ชั้น 7 อาคารเจริญวิศวกรรม (ตึก 4)</Text.Body>
  </div>
);

ChaseTH.propTypes = {};
ChaseTH.defaultProps = {};

export default ChaseTH;
