import React from 'react';
import { Row, Col, Divider } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import ImageGallery from 'components/molecules/ImageGallery';
import useIntl from 'hooks/useIntl';

const DonMuang = () => {
  const { getMsg } = useIntl();
  return (
    <Box pb={24}>
      <Text.Title level={3}>{getMsg('arriveDonMaung')}</Text.Title>
      <Text.Title level={4}>{getMsg('publicTransport')}</Text.Title>
      <ImageGallery
        singlePhoto
        galleryMinHeight={250}
        galleryProps={{
          columns: 1,
        }}
        images={[
          // eslint-disable-next-line max-len
          'https://s.isanook.com/tr/0/ud/281/1407549/1407549-20180207044913-ccee36f.jpg',
        ]}
      />
      <Text textStyle={'caption'}>{getMsg('clickToZoom')}</Text>
      <br />
      <br />
      <Row>
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} A1
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('lineA1Step1')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA1Step2')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA1Step3')}</Text.Paragraph>
        </Col>
        <Divider />
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} A2
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step1')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step2')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step3')}</Text.Paragraph>
        </Col>
        <Divider />
        <Col xs={24} sm={3}>
          <Text.Paragraph fontWeight={'bold'} fontSize={16}>
            {getMsg('busLine')} 29
          </Text.Paragraph>
        </Col>
        <Col xs={24} sm={21}>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step1')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step2')}</Text.Paragraph>
          <Text.Paragraph fontSize={16}>{getMsg('lineA2Step3')}</Text.Paragraph>
        </Col>
      </Row>
      <br />
      <Text.Title level={4}>{getMsg('taxi')}</Text.Title>
      <Text.Paragraph fontSize={16}>{getMsg('taxiDirection')}</Text.Paragraph>
    </Box>
  );
};

DonMuang.propTypes = {};
DonMuang.defaultProps = {};

export default DonMuang;
