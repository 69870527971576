import React from 'react';
import get from 'lodash/get';
import useIntl from 'hooks/useIntl';
import { useInitFetch } from 'contexts/researcher';
import { useInitFetch as useLecturerInitFetch } from 'contexts/lecturer';
import { Row, Col } from 'antd';
import Text from 'components/atoms/Text';
import ResearcherCard from 'components/molecules/ResearcherCard';
import Container from 'components/layouts/Container';
import { lecturerService } from 'factories/User/Lecturer';
import ROUTES from 'constants/routes';

const loadingArray = Array(6)
  .fill(0)
  .map((i, index) => ({ id: i + index }));

const ResearcherPage = () => {
  const { researchers, status } = useInitFetch();
  const { lecturers, status: lecturerStatus } = useLecturerInitFetch(
    null,
    true,
  );
  const { getMsg, locale, displayText } = useIntl();
  const isLoading = status.isRequest;
  const getAdvisor = lecturer =>
    lecturerStatus.isRequest ? 'loading...' : lecturerService.getName(lecturer);
  const renderCard = item => {
    const title = get(item, ['name', locale]);
    const lecturer = lecturers.find(({ id }) => id === item.advisorId);
    return (
      <Col key={item.id} xs={24} md={12} lg={8} style={{ display: 'flex' }}>
        <ResearcherCard
          avatarUrl={item.avatarUrl}
          loading={isLoading}
          title={title}
          contact={item.contact}
          social={item.social}
          researchArea={item.researchArea}
          advisor={getAdvisor(lecturer)}
          advisorUrl={
            lecturer
              ? ROUTES.lecturerDetail.get(
                  lecturer.id,
                  displayText(lecturer.name),
                )
              : ''
          }
        />
      </Col>
    );
  };
  return (
    <Container style={{ flex: 'auto' }}>
      <Text.Title level={2} letterSpacing={0.5}>
        {getMsg('researchers')}
      </Text.Title>
      <Row gutter={16} type={'flex'}>
        {(isLoading ? loadingArray : researchers).map(renderCard)}
      </Row>
    </Container>
  );
};

ResearcherPage.propTypes = {};
ResearcherPage.defaultProps = {};

export default ResearcherPage;
