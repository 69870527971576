import isUndefined from 'lodash/isUndefined';
import range from 'lodash/range';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isBefore from 'date-fns/is_before';
import format from 'date-fns/format';
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en';

const LOCALES = {
  en,
  th,
};

const MONTHS = [
  {
    en: 'January',
    th: 'มกราคม',
  },
  {
    en: 'February',
    th: 'กุมภาพันธ์',
  },
  {
    en: 'March',
    th: 'มีนาคม',
  },
  {
    en: 'April',
    th: 'เมษายน',
  },
  {
    en: 'May',
    th: 'พฤษภาคม',
  },
  {
    en: 'June',
    th: 'มิถุนายน',
  },
  {
    en: 'July',
    th: 'กรกฎาคม',
  },
  {
    en: 'August',
    th: 'สิงหาคม',
  },
  {
    en: 'September',
    th: 'กันยายน',
  },
  {
    en: 'October',
    th: 'ตุลาคม',
  },
  {
    en: 'November',
    th: 'พฤษจิกายน',
  },
  {
    en: 'December',
    th: 'ธันวาคม',
  },
];

export const isInRange = (start = [], end = [], dateObj = new Date()) => {
  const month = dateObj.getMonth() + 1; // months from 1-12
  const day = dateObj.getDate();

  const current = [day, month];
  const startDay = start[0];
  const startMonth = start[1];
  const endDay = end[0];
  const endMonth = end[1];
  if (
    isUndefined(startDay) ||
    isUndefined(startMonth) ||
    isUndefined(endDay) ||
    isUndefined(endMonth)
  ) {
    throw new Error('isInRange error! incorrect params');
  }
  if (current[1] > startMonth && current[1] < endMonth) {
    return true;
  }
  if (current[1] === startMonth && current[1] === endMonth) {
    return current[0] >= startDay && current[0] <= endDay;
  }
  if (current[1] === startMonth) {
    return current[0] >= startDay;
  }
  if (current[1] === endMonth) {
    return current[0] <= endDay;
  }
  return false;
};

export const sortCreatedAt = items =>
  items.sort((a, b) => {
    if (isBefore(a.createdAt.toDate(), b.createdAt.toDate())) {
      return 1;
    }
    return -1;
  });

const getYear = (enYear, locale) => {
  if (locale === 'th') return enYear + 543;
  return enYear;
};

export const sortYearly = (items, locale = 'en') => {
  const minItem = minBy(items, ({ createdAt }) => new Date(createdAt.toDate()));
  const maxItem = maxBy(items, ({ createdAt }) => new Date(createdAt.toDate()));
  if (!minItem || !maxItem) return items;
  const minDate = new Date(minItem.createdAt.toDate());
  const maxDate = new Date(maxItem.createdAt.toDate());
  const minYear = minDate.getUTCFullYear();
  const maxYear = maxDate.getUTCFullYear();
  return range(minYear, maxYear + 1)
    .reverse()
    .reduce((result, year) => {
      const yearItems = items.filter(({ createdAt }) => {
        const dateObj = new Date(createdAt.toDate());
        return dateObj.getUTCFullYear() === year;
      });
      if (!yearItems.length) {
        // no this month data, then return previous result
        return result;
      }
      return [...result, { name: getYear(year, locale), items: yearItems }];
    }, []);
};

export const sortMonthly = (items, locale = 'en') =>
  range(12)
    .reverse()
    .reduce((result, month) => {
      const monthItems = items.filter(({ createdAt }) => {
        const dateObj = new Date(createdAt.toDate());
        return dateObj.getUTCMonth() === month;
      });
      if (!monthItems.length) {
        // no this month data, then return previous result
        return result;
      }
      return [
        ...result,
        { name: MONTHS[month][locale], items: sortCreatedAt(monthItems) },
      ];
    }, []);

export const formatDate = (createdAt, locale = 'en', pattern = 'D MMM YYYY') =>
  createdAt && typeof createdAt.toDate === 'function'
    ? format(createdAt.toDate(), pattern, {
        locale: LOCALES[locale],
      })
    : '';

export const parsedCreatedAt = (items, locale = 'en', pattern = 'D MMM YYYY') =>
  items.map(({ createdAt, ...rest }) => ({
    ...rest,
    createdAt: formatDate(createdAt, locale, pattern),
  }));

export default {
  isInRange,
  sortMonthly,
  parsedCreatedAt,
};
