import useStatus from './useStatus';

const useApi = api => {
  const { status, request, resolve, reject, reset } = useStatus();
  return {
    api: (...args) => {
      request();
      return api(...args)
        .then((...result) => {
          resolve();
          return Promise.resolve(...result);
        })
        .catch(error => {
          reject(error);
          throw error;
        });
    },
    status,
    reset,
  };
};

export default useApi;
