import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Box from 'components/atoms/Box';
import Drawer from 'components/molecules/Drawer';
import MENUS from 'constants/header3';
import LogoBookmark from 'components/atoms/LogoBookmark';
import LangSwitcher from 'components/atoms/LangSwitcher';
import { useIntl } from 'contexts/intl';

const { SubMenu } = Menu;

const darkRed = '#881014';
const StyledHeader = styled(Layout.Header)`
  background: ${darkRed};
  display: flex;
  width: 100%;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.87);
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  line-height: normal;
  justify-content: space-between;
  z-index: 10;

  ${({ theme }) => `
    ${[theme.breakpoints.up('sm')]} {
      height: 64px;
    }
  `}

  .MiniHeader-bars {
    padding: 8px;
    font-size: 20px;
    color: #ffffff;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu-title {
    white-space: normal;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto;
    min-height: 40px;
    line-height: 1.45;
  }
`;

const MiniHeader = () => {
  const { locale, getMsg } = useIntl();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  // eslint-disable-next-line react/prop-types
  const renderMenu = ({ name, href, external }) => {
    if (external) {
      return (
        <a
          className={'menu-item'}
          href={href}
          target={'_blank'}
          rel={'noopener'}
          onClick={onClose}
        >
          {name[locale]}
        </a>
      );
    }
    if (href) {
      return (
        <Link className={'menu-item'} to={href} onClick={onClose}>
          {name[locale]}
        </Link>
      );
    }
    return <span className={'menu-item'}>{name[locale]}</span>;
  };
  return (
    <React.Fragment>
      <StyledHeader>
        <LogoBookmark />
        <Box {...Box.alignCenter}>
          <LangSwitcher
            inverted
            buttonProps={{
              style: { marginRight: 16 },
            }}
          />
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <a
            role={'button'}
            onClick={() => setOpen(true)}
            className={'MiniHeader-bars'}
          >
            <i className={'far fa-bars'} />
          </a>
        </Box>
      </StyledHeader>
      <Drawer
        title={getMsg('welcomeToIE')}
        placement={'left'}
        onClose={onClose}
        visible={open}
      >
        <StyledMenu mode="inline" inlineIndent={16}>
          {MENUS.collapsedMenu.map(menu =>
            menu.subMenus && menu.subMenus.length ? (
              <SubMenu
                className={'MiniHeader-menuLevel1'}
                key={menu.name[locale]}
                title={renderMenu(menu)}
              >
                {menu.subMenus.map(subMenu =>
                  subMenu.subMenus ? (
                    <SubMenu
                      key={`${menu.name[locale]}-${subMenu.name[locale]}`}
                      title={renderMenu(subMenu)}
                    >
                      {subMenu.subMenus.map(subMenu2 => (
                        <Menu.Item
                          key={`${menu.name[locale]}-${subMenu.name[locale]}-${
                            subMenu2.name[locale]
                          }`}
                        >
                          {renderMenu(subMenu2)}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={`${menu.name[locale]}-${subMenu.name[locale]}`}
                      onClick={onClose}
                    >
                      {renderMenu(subMenu)}
                    </Menu.Item>
                  ),
                )}
              </SubMenu>
            ) : (
              <Menu.Item
                key={menu.name[locale]}
                className={'MiniHeader-menuLevel1'}
                onClick={onClose}
              >
                {renderMenu(menu)}
              </Menu.Item>
            ),
          )}
        </StyledMenu>
      </Drawer>
    </React.Fragment>
  );
};

MiniHeader.propTypes = {
  locale: PropTypes.string,
};
MiniHeader.defaultProps = {
  locale: 'en',
};

export default MiniHeader;
