import omit from 'lodash/omit';
import { withLimit, withCursor, withOrderBy, withQueries } from './decorators';

const composeRef = (...fns) => ref =>
  fns.reduce((prev, curr) => prev(curr(ref)));

const CollectionFactory = collectionRef => {
  const find = (options = {}) => {
    const { queries = [], orderBy, limit, cursor, afterDocument } = options;
    let currentRef = composeRef(withOrderBy(orderBy), withQueries(queries))(
      collectionRef,
    );
    let promise;
    if (afterDocument) {
      promise = collectionRef
        .doc(afterDocument)
        .get()
        .then(doc => {
          currentRef = composeRef(
            withLimit(limit),
            withCursor('startAfter', doc),
          )(currentRef);
          return currentRef.get();
        });
    } else {
      currentRef = composeRef(withLimit(limit), withCursor(cursor))(currentRef);
      promise = currentRef.get();
    }
    return promise.then(snapshot => {
      const result = [];
      snapshot.forEach(document => {
        result.push({ ...document.data(), id: document.id });
      });
      return result;
    });
  };

  const findOne = id =>
    collectionRef
      .doc(id)
      .get()
      .then(doc => ({
        ...doc.data(),
        id: doc.id,
      }));

  const updateOne = ({ id, ...data }) => {
    const newData = {
      ...omit(data, ['createdAt', 'updatedAt']),
      updatedAt: new Date(),
    };
    return collectionRef
      .doc(id)
      .update(newData)
      .then(() => ({ id, ...newData }));
  };

  const updateMany = mapping => items =>
    Promise.all(
      items.map((item, index) =>
        updateOne({ id: item.id, ...mapping(item, index) }),
      ),
    );

  const insertOne = ({ id, ...data }) => {
    const attachedData = {
      ...data,
      createdAt: new Date(),
    };
    let promise = collectionRef.add(attachedData);
    if (id) {
      promise = collectionRef.doc(id).set(attachedData);
    }
    return promise.then(ref => ({
      ...attachedData,
      id: id || ref.id,
    }));
  };

  const deleteOne = id =>
    collectionRef
      .doc(id)
      .delete()
      .then(() => id);
  return {
    find,
    findOne,
    updateOne,
    updateMany,
    insertOne,
    deleteOne,
  };
};

export default CollectionFactory;
