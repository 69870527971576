import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styled from '@emotion/styled';

const Div = styled('a')`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  cursor: pointer;
  background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.2)`};
  transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);

  &:hover {
    transform: scale(1.2);
    background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.6)`};
  }

  &.-active {
    pointer-events: none;
    transform: none;
    background: ${({ theme }) => `rgba(${theme.primary.rgb}, 0.87)`};
  }
`;

const Group = styled('div')`
  text-align: center;

  .Dot-root:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const Dot = ({ className, active, ...props }) => (
  <Div {...props} className={cx('Dot-root', active && '-active', className)} />
);

Dot.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
};
Dot.defaultProps = {
  className: '',
  active: false,
};
// eslint-disable-next-line react/prop-types
Dot.Group = ({ className, ...props }) => (
  <Group className={cx('DotGroup-root', className)} {...props} />
);

export default Dot;
