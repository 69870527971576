import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import DownloadUI from 'components/atoms/DownloadUI';
import useScrollToElement from 'hooks/useScrollToElement';
import Query from 'factories/query';
import { isInRange } from 'helpers/dateAndTime';
import { useConsumer } from 'contexts/db';

const BachelorCalendar = ({ location }) => {
  const dbAssets = useConsumer();
  const query = Query().toObject(location.search);
  useScrollToElement(query.topic);
  return (
    <div>
      <Text.Title level={3}>ปฏิทินการเข้าศึกษาระดับปริญญาตรี</Text.Title>
      <Text.Body mb={32}>
        การรับสมัครด้วยระบบ TCAS (Thai University Center Admission System) คือ
        ระบบการคัดเลือกบุคคลเข้าศึกษาต่อในระดับ มหาวิทยาลัย
        เริ่มใช้ครั้งแรกในปีการศึกษา 2561
        ตามประกาศของที่ประชุมอธิการบดีแห่งประเทศไทย (ทปอ.) ประกอบด้วยการ
        คัดเลือก 5 รอบ ได้แก่
      </Text.Body>

      <Element name={'round-1'}>
        <Text.Topic>
          รอบที่ 1 การคัดเลือกด้วยแฟ้มสะสมผลงาน (Portfolio)
        </Text.Topic>
      </Element>
      <Text.Body mb={16}>
        การคัดเลือกรอบที่ 1 พิจารณาจากผลงานในแฟ้มสะสมผลงาน (Portfolio)
        โดยไม่มีการสอบข้อเขียน
      </Text.Body>
      <br />
      <MonthlyTimeline
        events={[
          {
            title: 'ภายในพฤศจิกายน',
            subtitle:
              'สถาบันอุดมศึกษา กําหนดวิธีการ เกณฑ์ และคุณสมบัติของผู้สมัคร',
            current: isInRange([1, 11], [31, 11]),
          },
          {
            title: 'ธันวาคม',
            subtitle:
              // eslint-disable-next-line max-len
              'ผู้สมัครยื่น Portfolio ให้สถาบันอุดมศึกษาดําเนินการคัดเลือกผู้สมัคร',
            current: isInRange([1, 12], [31, 12]),
          },
          {
            title: 'มกราคม',
            subtitle: 'การสอบสัมภาษณ์',
            current: isInRange([1, 1], [24, 1]),
          },
          {
            title: 'ภายในมกราคม',
            subtitle: '',
            list: [
              'สถาบันอุดมศึกษาส่งรายชื่อผู้ผ่านการคัดเลือกเข้าระบบ TCAS',
              'ผู้สมัครยืนยันสิทธิ์เข้าศึกษาในระบบ TCAS',
            ],
            current: isInRange([25, 1], [31, 1]),
          },
          {
            title: 'ภายในกุมภาพันธ์',
            subtitle:
              // eslint-disable-next-line max-len
              'ทปอ. ส่งรายชื่อผู้ยืนยันสิทธิ์ให้สถาบันอุดมศึกษา เพื่อประกาศรายชื่อผู้มีสิทธิ์เข้าศึกษาต่อไป',
            current: isInRange([1, 2], [28, 2]),
          },
        ]}
      />
      <Element name={'round-2'}>
        <Text.Topic>รอบที่ 2 การรับแบบโควตา (สอบข้อเขียน)</Text.Topic>
      </Element>
      <Text.Body mb={16}>
        การคัดเลือกรอบที่ 2 เป็นการรับแบบโควตาสําหรับนักเรียนที่อยู่ในพื้นที่
        หรือ รอบเขตการศึกษาที่ทางมหาวิทยาลัยกําหนด
      </Text.Body>
      <br />
      <MonthlyTimeline
        events={[
          {
            title: 'ภายในมกราคม',
            subtitle:
              // eslint-disable-next-line max-len
              'สถาบันอุดมศึกษาประกาศเกณฑ์การสอบ และประกาศการรับสมัครก่อนเวลาที่มีการสอบได้ อาจ มีการใช้ข้อสอบกลาง',
            current: isInRange([1, 1], [31, 1]),
          },
          {
            title: 'กุมภาพันธ์',
            subtitle:
              // eslint-disable-next-line max-len
              'การรับสมัคร',
            current: isInRange([1, 2], [28, 2]),
          },
          {
            title: 'มีนาคม',
            subtitle: 'การสอบวิชาเฉพาะที่จุฬาฯ จัดสอบ',
            current: isInRange([1, 3], [31, 3]),
          },
          {
            title: 'ภายในเมษายน',
            subtitle: '',
            list: [
              'สถาบันอุดมศึกษาส่งรายชื่อผู้ผ่านการคัดเลือกให้เข้าระบบ TCAS',
              'ผู้สมัครยืนยันสิทธิ์เข้าศึกษาในระบบ TCAS',
              // eslint-disable-next-line max-len
              'ทปอ. ส่งรายชื่อผู้ยืนยันสิทธิ์ให้สถาบันอุดมศึกษา เพื่อประกาศรายชื่อผู้มีสิทธิ์เข้าศึกษาต่อไป',
            ],
            current: isInRange([1, 4], [30, 4]),
          },
        ]}
      />
      <Element name={'round-3'}>
        <Text.Topic>รอบที่ 3 การรับตรงร่วมกัน</Text.Topic>
      </Element>
      <Text.Body mb={16}>
        การคัดเลือกรอบที่ 3 เป็นการคัดเลือกนักเรียนที่อยู่ในโครงการ กสพท.
        (กลุ่มสถาบันแพทยศาสตร์แห่งประเทศไทย), โครงการอื่นๆ
        ตามที่มหาวิทยาลัยกําหนด
      </Text.Body>
      <br />
      <MonthlyTimeline
        events={[
          {
            title: 'ภายในมกราคม',
            subtitle:
              // eslint-disable-next-line max-len
              'สถาบันอุดมศึกษาจัดส่งรายละเอียดเกณฑ์การรับสมัครให้ ทปอ.',
            current: isInRange([1, 1], [31, 1]),
          },
          {
            title: 'เมษายน',
            subtitle:
              // eslint-disable-next-line max-len
              'สมัครผ่านระบบ TCAS และชําระเงินค่าสมัครตามจํานวนสาขาวิชาที่เลือก (สูงสุดได้ 6 สาขาวิชา)',
            current: isInRange([1, 4], [30, 4]),
          },
          {
            title: 'พฤษภาคม',
            subtitle: '',
            list: [
              'ทปอ. ประกาศรายชื่อผู้มีสิทธิ์สอบสัมภาษณ์',
              'การสอบสัมภาษณ์',
            ],
            current: isInRange([1, 5], [20, 5]),
          },
          {
            title: 'ภายในพฤษภาคม',
            subtitle: '',
            list: [
              'สถาบันอุดมศึกษาส่งรายชื่อผู้ผ่านการคัดเลือกเข้าระบบ TCAS',
              'ทปอ. ประกาศรายชื่อผู้ยืนยันสิทธิ์เข้าศึกษาในรอบที่ 3',
            ],
            current: isInRange([21, 5], [31, 5]),
          },
        ]}
      />
      <Element name={'round-4'}>
        <Text.Topic>รอบที่ 4 การรับแบบ Admission</Text.Topic>
      </Element>
      <br />
      <MonthlyTimeline
        events={[
          {
            title: 'ภายในเดือนเมษายน',
            subtitle:
              // eslint-disable-next-line max-len
              'สถาบันอุดมศึกษาจัดส่งรายละเอียดเกณฑ์การรับสมัครให้ ทปอ.',
            current: isInRange([1, 4], [30, 4]),
          },
          {
            title: 'พฤษภาคม',
            subtitle: '',
            list: [
              'ทปอ. รับสมัคร Admission ผ่านระบบ TCAS ที่เว็บไซต์ของ ทปอ.',
              'ทปอ. ประกาศรายชื่อผู้มีสิทธิ์สอบสัมภาษณ์',
            ],
            current: isInRange([1, 5], [31, 5]),
          },
          {
            title: 'มิถุนายน',
            subtitle: '',
            list: [
              'ผู้สมัครสอบสัมภาษณ์และตรวจร่างกาย',
              'สถาบันอุดมศึกษาส่งรายชื่อผู้ผ่านการคัดเลือกจากการสอบสัมภาษณ์',
            ],
            current: isInRange([1, 6], [20, 6]),
          },
          {
            title: 'ภายในมิถุนายน',
            subtitle: 'ทปอ. ประกาศรายชื่อผู้ยืนยันสิทธิ์เข้าศึกษาในรอบที่ 4',
            current: isInRange([21, 6], [30, 6]),
          },
        ]}
      />
      <Element name={'round-5'}>
        <Text.Topic>รอบที่ 5 การรับตรงแบบอิสระ</Text.Topic>
      </Element>
      <br />
      <MonthlyTimeline
        events={[
          {
            title: 'ภายในเดือนเมษายน',
            subtitle:
              // eslint-disable-next-line max-len
              'สถาบันอุดมศึกษาประกาศเกณฑ์การรับสมัครและดําเนินการคัดเลือก',
            current: isInRange([1, 4], [30, 4]),
          },
          {
            title: 'พฤษภาคม',
            subtitle: '',
            list: ['การรับสมัคร', 'การสอบสัมภาษณ์'],
            current: isInRange([1, 5], [31, 5]),
          },
          {
            title: 'มิถุนายน',
            subtitle: '',
            list: [
              'สถาบันอุดมศึกษาส่งรายชื่อผู้ที่ผ่านการคัดเลือกเข้าระบบ TCAS',
              'ผู้สมัครยืนยันสิทธิ์เข้าศึกษาในระบบ TCAS',
              'สถาบันอุดมศึกษาประกาศรายชื่อผู้ยืนยันสิทธิ์เข้าศึกษาในรอบที่ 5',
            ],
            current: isInRange([1, 6], [30, 6]),
          },
        ]}
      />
      <Text>
        *กำหนดการข้างต้นอาจมีความคลาดเคลื่อนเล็กน้อย หากต้องการวันที่ตามปฏิทิน
        โปรดดาวน์โหลดฉบับเต็ม
      </Text>
      <br />
      <br />
      <DownloadUI
        label={'ปฏิทินฉบับเต็ม'}
        href={get(dbAssets, 'calendar.bachelorAdmission')}
      />
      <br />
      <br />
      <br />
    </div>
  );
};

BachelorCalendar.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
BachelorCalendar.defaultProps = {};

export default withRouter(BachelorCalendar);
