import React, { useEffect, useState, useContext } from 'react';
import get from 'lodash/get';
import useApi from 'hooks/useApi';
import FireApi from 'firebase/fireApi';

const initialState = {
  course: {
    type: {},
    level: {},
    category: {},
  },
  admission: {
    master: {},
  },
  internship: {},
  bachelorProject: {},
  calendar: {},
  thesis: {},
};
const Context = React.createContext(initialState);

// eslint-disable-next-line react/prop-types
export const DBProvider = ({ children }) => {
  const [db, setDb] = useState(initialState);
  const { api, status } = useApi(FireApi.getAssets);
  useEffect(() => {
    let ignore = false;
    api('public').then(data => {
      if (!ignore) {
        setDb(data);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <Context.Provider value={{ ...db, getPublicAssetStatus: status }}>
      {children}
    </Context.Provider>
  );
};
export const mapCount = (items, db, key) =>
  items.map(item => ({
    ...item,
    name: `${item.name} (${get(db, ['course', key, item.id], '...')})`,
  }));
export const useConsumer = () => useContext(Context);
export default Context;
