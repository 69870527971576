import createCollectionContext from 'hooks/createCollectionContext';
import FireApi from 'firebase/fireApi';
import toHOC from './toHOC';

export const {
  Provider,
  Consumer,
  useConsumer,
  useInitFetch,
  Context,
} = createCollectionContext({
  collectionName: 'lecturers',
  itemName: 'lecturer',
  fetchingApi: FireApi.fetchLecturers,
});

export const LecturerProvider = Provider;
export const LecturerConsumer = Consumer;
export const withConsumer = toHOC(Consumer);
export default Context;
