import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Text from 'components/atoms/Text';

const HeaderSchedule = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .TimeIndicator-label {
    transform: translateX(-50%);
  }
`;

const LastTimeLabel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 0;
  .TimeIndicator-label {
    transform: translateX(50%);
  }
  .TimeIndicator-dot {
    transform: translateX(50%);
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  height: ${({ size }) => size || 8}px;
  width: ${({ size }) => size || 8}px;
  margin-top: 8px;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.grey[300]};
`;

const TimeIndicator = ({ start, end, size, hiddenEndTime }) => (
  <Row
    className={'TimeIndicator-root'}
    prefixCls={size === 10 ? 'iecu-row' : undefined}
    type="flex"
    align="bottom"
    style={{ position: 'relative' }}
  >
    {Array((end - start) * 2)
      .fill(1)
      .map((_, index) => (
        <HeaderSchedule
          // eslint-disable-next-line react/no-array-index-key
          key={`schedule-header-${index}`}
          prefixCls={size === 10 ? 'iecu-col' : undefined}
          span={size / ((end - start) * 2)}
        >
          {index % 2 === 0 && (
            <Text className={'TimeIndicator-label'}>{start + index / 2}</Text>
          )}
          {<Dot size={index % 2 === 0 ? 10 : 6} />}
        </HeaderSchedule>
      ))}
    {!hiddenEndTime && (
      <LastTimeLabel>
        <Text className={'TimeIndicator-label'}>{end}</Text>
        <Dot className={'TimeIndicator-dot'} size={10} />
      </LastTimeLabel>
    )}
  </Row>
);

TimeIndicator.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  size: PropTypes.oneOf([10, 24]),
  hiddenEndTime: PropTypes.bool,
};
TimeIndicator.defaultProps = {
  size: 24,
  hiddenEndTime: false,
};

export default TimeIndicator;
