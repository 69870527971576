import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { space } from 'styled-system';
import SwipeableViews from 'react-swipeable-views';
import bengIcon from 'assets/beng-icon.png';
import mengIcon from 'assets/meng-icon.png';
import phdIcon from 'assets/phd-icon.png';
import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Hidden from 'components/responsive/Hidden';
import Container from 'components/layouts/Container';
import { breakpoints } from 'theme';
import { withIntl } from 'contexts/intl';
import ROUTES from 'constants/routes';

const BG = styled('div')`
  position: absolute;
  left: 0;
  z-index: 1;
  background: rgb(204, 27, 33);
  background: linear-gradient(
    153deg,
    rgba(204, 27, 33, 1) 0%,
    rgba(66, 9, 11, 1) 100%
  );
  ${breakpoints.up('sm')} {
    min-width: 400px;
    min-height: 400px;
    top: 108px;
    left: -120px;
    -webkit-clip-path: polygon(0 0, 0% 56%, 22% 100%, 100% 68%, 80% 8%);
    clip-path: polygon(0 0, 0% 56%, 22% 100%, 100% 68%, 80% 8%);
  }
  ${breakpoints.up('lg')} {
    min-width: 600px;
    top: 100px;
    left: -275px;
    -webkit-clip-path: polygon(0 0, 10% 0, 0 78%, 41% 100%, 100% 68%, 80% 11%);
    clip-path: polygon(0 0, 10% 0, 0 78%, 41% 100%, 100% 68%, 80% 11%);
  }
`;

const BackgroundText = styled(Text)`
  opacity: 1;
  pointer-events: none;
  position: absolute;
  font-weight: bold;
  font-size: 100px;
  color: ${({ theme }) => theme.grey[200]};
  &.-top {
    top: 0%;
  }
  &.-bottom {
    bottom: 0%;
  }
`;

const StyledImage = styled(Image)`
  width: 50px;
  margin-bottom: 16px;
`;

const TitleText = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.white.text};
`;

const DescriptionText = styled(Text)`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.white.secondary};
`;

const ProgramBox = styled(Link)`
  position: relative;
  z-index: 1;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  background-color: ${({ theme }) => theme.primary.main};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  ${space};
`;

const DegreeProgramSlider = styled(SwipeableViews)`
  padding: 0 32px;
  margin: 32px 0;
  width: 100%;
  .program-box {
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    background-color: ${({ theme }) => theme.primary.main};
    display: flex;
    padding: 16px;
    height: 100%;
  }
`;

const DegreeProgramMobile = styled(Box)`
  text-align: center;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.primary.main},
    ${({ theme }) => theme.primary.dark}
  );
`;

const bachelorLink = ROUTES.admission.get('/bachelor?topic=b-program');
const masterLink = ROUTES.admission.get('/master/m-eng?topic=m-program');
const phdLink = ROUTES.admission.get('/doctorate?topic=d-program');

const HomepagePrograms = ({ getMsg }) => {
  const scrollAnimate = () => {
    const topBgText = document.getElementById('top-bg-text');
    if (topBgText) {
      const offset = topBgText.getBoundingClientRect().top;
      topBgText.style.left = `${offset / 2 + 200}px`;
    }
    const bottomBgText = document.getElementById('bottom-bg-text');
    if (bottomBgText) {
      const offset = topBgText.getBoundingClientRect().top;
      bottomBgText.style.right = `${offset / 2}px`;
    }
  };
  useEffect(() => {
    document.addEventListener('scroll', scrollAnimate);
  }, []);
  const renderTopic = () => (
    <>
      <Text.Title
        fontWeight="bold"
        mb={20}
        maxWidth={172}
        fontSize={30}
        color={'white.text'}
        letterSpacing={1}
      >
        {getMsg('programs')}
      </Text.Title>
      <Text.Paragraph color={'white.text'}>
        {getMsg('programDescription')}
      </Text.Paragraph>
    </>
  );
  return (
    <Container id={'parallax'} p={{ xs: '0!important' }} mt={40}>
      <Hidden smDown>
        <Box position={'relative'} pt={80} pb={144} px={{ sm: 24, md: 0 }}>
          <BG />
          <Row gutter={32}>
            <Col span={6}>
              <Box mt={100} position={'relative'} zIndex={2}>
                {renderTopic()}
              </Box>
            </Col>
            <Col span={6}>
              <ProgramBox mt={180} to={bachelorLink}>
                <StyledImage src={bengIcon} />
                <TitleText>{getMsg('bachelorDegree')}</TitleText>
                <TitleText mb={20}>{getMsg('bachelorDegreeShort')}</TitleText>
                <DescriptionText>{getMsg('generalProgram')}</DescriptionText>
                <DescriptionText>{getMsg('coopProgram')}</DescriptionText>
              </ProgramBox>
            </Col>
            <Col span={6}>
              <ProgramBox mt={120} to={masterLink}>
                <StyledImage src={mengIcon} />
                <TitleText>{getMsg('masterDegree')}</TitleText>
                <TitleText mb={20}>{getMsg('masterDegreeShort')}</TitleText>
                <DescriptionText>{getMsg('regularProgram')}</DescriptionText>
                <DescriptionText>{getMsg('executiveProgram')}</DescriptionText>
              </ProgramBox>
            </Col>
            <Col span={6}>
              <ProgramBox mt={40} to={phdLink}>
                <StyledImage src={phdIcon} />
                <TitleText>{getMsg('doctorDegree')}</TitleText>
                <TitleText mb={20}>{getMsg('doctorDegreeShort')}</TitleText>
              </ProgramBox>
            </Col>
          </Row>
          <Box
            position={'absolute'}
            top={0}
            left={0}
            bottom={0}
            right={0}
            overflow={'hidden'}
          >
            <BackgroundText className={'-top'} id="top-bg-text">
              EFFICIENT
            </BackgroundText>
            <BackgroundText className={'-bottom'} id="bottom-bg-text">
              INNOVATIVE
            </BackgroundText>
          </Box>
        </Box>
        <Box height={160} />
      </Hidden>
      <Hidden smUp>
        <DegreeProgramMobile py={32} {...Box.allCenterColumn}>
          <Box px={16} {...Box.allCenterColumn}>
            {renderTopic()}
          </Box>
          <DegreeProgramSlider slideStyle={{ padding: '0 8px' }}>
            <Box
              component={Link}
              to={bachelorLink}
              className="program-box"
              {...Box.alignCenterColumn}
            >
              <StyledImage src={bengIcon} />
              <TitleText>{getMsg('bachelorDegree')}</TitleText>
              <TitleText mb={20}>{getMsg('bachelorDegreeShort')}</TitleText>
              <DescriptionText>{getMsg('generalProgram')}</DescriptionText>
              <DescriptionText>{getMsg('coopProgram')}</DescriptionText>
            </Box>
            <Box
              component={Link}
              to={masterLink}
              className="program-box"
              {...Box.alignCenterColumn}
            >
              <StyledImage src={mengIcon} />
              <TitleText>{getMsg('masterDegree')}</TitleText>
              <TitleText mb={20}>{getMsg('masterDegreeShort')}</TitleText>
              <DescriptionText>{getMsg('regularProgram')}</DescriptionText>
              <DescriptionText>{getMsg('executiveProgram')}</DescriptionText>
            </Box>
            <Box
              component={Link}
              to={phdLink}
              className="program-box"
              {...Box.alignCenterColumn}
            >
              <StyledImage src={phdIcon} />
              <TitleText>{getMsg('doctorDegree')}</TitleText>
              <TitleText mb={20}>{getMsg('doctorDegreeShort')}</TitleText>
            </Box>
          </DegreeProgramSlider>
        </DegreeProgramMobile>
      </Hidden>
    </Container>
  );
};

HomepagePrograms.propTypes = {
  getMsg: PropTypes.func,
};
HomepagePrograms.defaultProps = {
  getMsg: () => '',
};

export default withIntl(HomepagePrograms);
