import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Col, Row, Affix } from 'antd';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Text';
import TimeTableHeader from './TimeTableHeader';
import TimeTableDay from './TimeTableDay';
import TimeTablePart from './TimeTablePart';

const StyledAffix = styled(Affix)`
  .ant-affix {
    z-index: 11;
  }
`;

const days = [
  { color: '#F6D26C', title: 'MON', day: 'monday' },
  { color: '#FFB6D2', title: 'TUE', day: 'tuesday' },
  { color: '#4CB57C', title: 'WED', day: 'wednesday' },
  { color: '#F37020', title: 'THU', day: 'thursday' },
  { color: '#6BBEFA', title: 'FRI', day: 'friday' },
];

const FullTimeTable = ({ children, getScheduleList, setItemProps }) => (
  <>
    <StyledAffix offsetTop={64}>
      {children}
      <Box px={16} pt={{ md: 8 }} bgColor={'#ffffff'}>
        <TimeTableHeader />
      </Box>
    </StyledAffix>
    {days.map((item, i) => (
      <Box key={item.title} px={16} bgColor={i % 2 === 1 ? '#f9f9f9' : ''}>
        <TimeTableDay
          {...item}
          getScheduleList={part => getScheduleList(part, item.day)}
          setItemProps={(_item, part) => setItemProps(_item, part, item.day)}
        />
      </Box>
    ))}
    <Box px={16} bgColor={'#f9f9f9'}>
      <Row type="flex" align="middle">
        <Col xs={0} md={2}>
          <Text fontWeight={900}>UNKNOWN</Text>
        </Col>
        <Col xs={24} md={22}>
          <Box px={{ xs: 0, sm: 24, md: 32 }}>
            <TimeTablePart
              start={8}
              end={18}
              size={10}
              scheduleList={getScheduleList('unknown')}
              setItemProps={setItemProps}
              hiddenEndLine
            />
          </Box>
        </Col>
      </Row>
    </Box>
  </>
);

FullTimeTable.propTypes = {
  getScheduleList: PropTypes.func,
  setItemProps: PropTypes.func,
  children: PropTypes.node,
};
FullTimeTable.defaultProps = {
  getScheduleList: () => {},
  setItemProps: () => {},
  children: null,
};

export default FullTimeTable;
