import React from 'react';
import Text from 'components/atoms/Text';
import MonthlyTimeline from 'components/molecules/MonthlyTimeline';
import { isInRange } from 'helpers/dateAndTime';

const MasterEndCalendar = () => (
  <div>
    <Text.Title level={3}>Ph.D.(IE) Admission Calendar</Text.Title>
    <Text.Body>
      กำหนดการรับสมัครคัดเลือกนิสิตเข้าศึกษาในระดับดุษฎีบัณฑิตศึกษา{' '}
      <b>ประจำภาคการศึกษาปลาย</b>
    </Text.Body>
    <Text.Paragraph fontSize={16} color={'text.primary'} fontWeight={'bold'}>
      กำหนดการฉบับย่อ
    </Text.Paragraph>
    <MonthlyTimeline
      events={[
        {
          title: 'กันยายน - พฤศจิกายน',
          subtitle: 'เปิดรับสมัครนิสิตภาคการศึกษาต้น',
          current: isInRange([1, 9], [1, 11]),
        },
        {
          title: 'พฤศจิกายน',
          subtitle: '',
          list: [
            'ประกาศรายชื่อผู้มีสิทธิ์เข้ารับการทดสอบ ',
            // eslint-disable-next-line max-len
            'รายละเอียด วัน เวลา สถานที่สอบ ของวิชาที่เปิดรับสมัคร จากเว็บไซต์',
          ],
          links: [
            {
              label: 'www.grad.chula.ac.th',
              href: 'https://www.grad.chula.ac.th',
            },
          ],
          current: isInRange([2, 11], [10, 11]),
        },
        {
          title: 'กำหนดวันทดสอบข้อเขียนและสัมภาษณ์',
          current: isInRange([11, 11], [20, 11]),
        },
        {
          title: 'วันสุดท้ายของการส่งรายชื่อผู้ผ่านการสอบคัดเลือก',
          subtitle: '(หากไม่มีคะแนนภาษาอังกฤษไม่รับเข้าศึกษา)',
          current: isInRange([21, 11], [25, 11]),
        },
        {
          title: 'ธันวาคม',
          subtitle: 'ประกาศเลขประจำตัวนิสิตใหม่ ทางอินเตอร์เน็ต',
          current: isInRange([1, 12], [20, 12]),
        },
        {
          title: 'ปลายธันวาคม',
          subtitle: 'ดาวน์โหลดเอกสารลงทะเบียนและลงทะเบียนแรกเข้า',
          current: isInRange([21, 12], [31, 12]),
        },
        {
          title: 'มกราคม',
          subtitle: 'วันเปิดภาคเรียน',
          current: isInRange([1, 1], [31, 1]),
        },
      ]}
    />
    <br />
    <br />
    <br />
  </div>
);

MasterEndCalendar.propTypes = {};
MasterEndCalendar.defaultProps = {};

export default MasterEndCalendar;
