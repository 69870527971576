import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/molecules/Filter';
import { withConsumer } from 'contexts/lecturer';
import FireApi from 'firebase/fireApi';
import withFilterQuery from 'hocs/withFilterQuery';
import compose from 'helpers/compose';
import { withIntl } from 'contexts/intl';

const LecturerFilter = ({
  getMsg,
  mapLocale,
  lecturers,
  setLecturers,
  ...props
}) => {
  useEffect(() => {
    if (!lecturers || !lecturers.length) {
      FireApi.fetchLecturers().then(setLecturers);
    }
  }, []);
  return (
    <Filter
      title={getMsg('lecturer').toUpperCase()}
      amountShowed
      {...props}
      loading={!lecturers}
      menus={lecturers}
      getMenus={menus =>
        mapLocale(menus, ['name']).sort((a, b) => (a.name < b.name ? -1 : 1))
      }
    />
  );
};

LecturerFilter.propTypes = {
  lecturers: PropTypes.arrayOf(PropTypes.shape({})),
  setLecturers: PropTypes.func,
  mapLocale: PropTypes.func.isRequired,
  getMsg: PropTypes.func.isRequired,
};
LecturerFilter.defaultProps = {
  lecturers: null,
  setLecturers: () => {},
};

export default compose(
  withFilterQuery({
    key: 'lecturer',
    mapProps: ({ queryValue, attachQuery, detachQuery }) => ({
      selectedItems: queryValue,
      onClick: ({ key }) => attachQuery(key),
      onRemove: detachQuery,
    }),
    attachQuery(query) {
      return query.detachQuery('category');
    },
  }),
  withConsumer,
  withIntl,
)(LecturerFilter);
