import React from 'react';
import PropTypes from 'prop-types';
import { Drawer as AntDrawer } from 'antd';
import styled from '@emotion/styled';

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-content-wrapper {
    ${({ theme }) => `
      ${theme.breakpoints.only('sm')} {
        min-width: 300px;
      }
    `}
  }
  .ant-drawer-body {
    padding: 8px 0 24px 0;

    .ant-menu {
      border-right: none;

      .MiniHeader-menuLevel1 {
        margin-bottom: 4px;
        .ant-menu-submenu-title {
          .menu-item {
            color: ${({ theme }) => theme.text.primary};
          }
        }
        > .menu-item {
          color: ${({ theme }) => theme.text.primary};
        }
      }
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      right: 20px;
    }
  }
`;

const Drawer = ({ visible, onClose, ...props }) => (
  <StyledDrawer onClose={onClose} visible={visible} {...props} />
);

Drawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
Drawer.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default Drawer;
