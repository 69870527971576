import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import Arrow from 'components/atoms/Arrow';
import Box from 'components/atoms/Box';
import Hidden from 'components/responsive/Hidden';
import styled from '@emotion/styled';
import Dot from 'components/atoms/Dot';
import { SmallPost } from 'components/molecules/Post';
import ROUTES from 'constants/routes';

const Root = styled(Box)`
  margin-top: -16px 0;

  .Arrow-root {
    background: none !important;
    :after {
      background: none;
    }
  }
  .Arrow-right {
    margin-left: 24px;
  }
  .DotGroup-root {
    flex: 1;
    margin: 24px 0px;
  }
  .post-col {
    cursor: pointer;
  }
  .SmallPost-root {
    height: 272px;
    > .SmallPost-root {
      height: initial;
    }
  }
`;

const SmallPostSlider = ({ pageSize, locale, displayText, loading, posts }) => {
  const [page, setPage] = useState(0);
  const pageAmount = Math.ceil(posts.length / pageSize);

  const onChangePage = i => {
    setPage(i);
  };

  const renderPost = index => {
    if (loading) {
      return (
        <Col key={`col-${index}`} className="post-col" span={24 / pageSize}>
          <SmallPost createdAt={null} title={''} tags={[]} loading />
        </Col>
      );
    }
    const data = posts[index];
    if (data) {
      return (
        <Col key={`col-${index}`} className="post-col" span={24 / pageSize}>
          <Link
            to={ROUTES.postDetail.get(data.id, displayText(data.title))}
            style={{ display: 'block' }}
          >
            <SmallPost locale={locale} {...data} tags={[]} hasGradient dark />
          </Link>
        </Col>
      );
    }
    return null;
  };

  const renderSlide = pageIndex => (
    <Row key={`pageIndex-${pageIndex}`} type={'flex'} gutter={16}>
      {new Array(pageSize)
        .fill(1)
        .map((_, index) => renderPost(pageIndex * pageSize + index))}
    </Row>
  );

  return (
    <Root position={'relative'}>
      <SwipeableViews
        resistance
        springConfig={{
          duration: '0.6s',
          easeFunction: '',
          delay: '0s',
        }}
        style={{ paddingLeft: 8, paddingRight: 32 }}
        slideStyle={{ padding: '16px 8px' }}
        index={page}
        onChangeIndex={onChangePage}
        onSwitching={i => {
          setPage(i);
        }}
      >
        {loading
          ? new Array(Math.floor(2))
              .fill(1)
              .map((_, index) => renderSlide(index))
          : new Array(pageAmount).fill(1).map((_, index) => renderSlide(index))}
      </SwipeableViews>
      <Box {...Box.alignCenter}>
        {pageAmount > 1 && (
          <Dot.Group>
            {new Array(pageAmount).fill(1).map((_, index) => (
              <Dot
                // eslint-disable-next-line react/no-array-index-key
                key={`dot-${index}`}
                active={page === index}
                onClick={() => onChangePage(index)}
              />
            ))}
          </Dot.Group>
        )}
        <Hidden mdDown>
          <Box ml={'auto'}>
            <Arrow.Left
              disabled={page === 0}
              onClick={() => onChangePage(page - 1)}
            />
            <Arrow.Right
              disabled={page === pageAmount - 1}
              onClick={() => onChangePage(page + 1)}
            />
          </Box>
        </Hidden>
      </Box>
    </Root>
  );
};

SmallPostSlider.propTypes = {
  pageSize: PropTypes.number,
  locale: PropTypes.string,
  displayText: PropTypes.func,
  loading: PropTypes.bool,
  posts: PropTypes.arrayOf(PropTypes.shape({})),
};

SmallPostSlider.defaultProps = {
  pageSize: 4,
  locale: 'en',
  displayText: () => '',
  loading: false,
  posts: [],
};

export default SmallPostSlider;
