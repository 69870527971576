import some from 'lodash/some';
import every from 'lodash/every';
import property from 'lodash/property';
import { useState } from 'react';
import { useInitFetch as useRoomInitFetch } from 'contexts/room';
import { useInitFetch as useUserInitFetch } from 'contexts/user';
import { useInitFetch as useCourseInitFetch } from 'contexts/course';
import { SEMESTER } from 'constants/temporary';
import FireApi from 'firebase/fireApi';
import useInitFetch from './useInitFetch';

export default initialSemester => {
  const [semester, setSemester] = useState(initialSemester || SEMESTER.FIRST);
  const [searchKey, setSearchKey] = useState('');
  const { rooms, status: roomStatus } = useRoomInitFetch(null, true);
  const { users, status: userStatus } = useUserInitFetch(null, true);
  const { courses, status: courseStatus } = useCourseInitFetch(null, true);
  const { data: schedule, setData: setSchedule, status } = useInitFetch(
    () => FireApi.fetchScheduleDetail(semester),
    {},
    [semester],
  );

  const statuses = [roomStatus, userStatus, courseStatus, status];
  return {
    status: {
      isRequest: some(statuses, property('isRequest')),
      isSuccess: every(statuses, property('isSuccess')),
      isFailure: some(statuses, property('isFailure')),
      error: some(statuses, property('error')),
    },
    searchKey,
    setSearchKey,
    semester,
    setSemester,
    rooms,
    users,
    courses,
    schedule,
    setSchedule,
  };
};
