import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styled from '@emotion/styled';
import { Button, Tooltip } from 'antd';
import Icon from 'components/atoms/Icon';

const StyledButton = styled(Button)`
  &.Social-root {
    width: 48px;
    height: 48px;
    line-height: 59px;
    box-shadow: none;
    border: none;
  }
`;

const Social = ({ className, type, url, iconProps, buttonProps }) => {
  if (!url) return null;
  const ICON_TYPE = {
    facebook: 'fab fa-facebook',
    linkedin: 'fab fa-linkedin',
    researchgate: 'fab fa-researchgate',
  };
  return (
    <Tooltip title={type} placement={'bottom'}>
      <StyledButton
        className={cx('Social-root', className)}
        htmlType={'button'}
        shape={'circle'}
        href={url}
        target={'_blank'}
        {...buttonProps}
      >
        <Icon fontSize={32} {...iconProps} type={ICON_TYPE[type]} />
      </StyledButton>
    </Tooltip>
  );
};

Social.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  iconProps: PropTypes.shape({}),
  buttonProps: PropTypes.shape({}),
};
Social.defaultProps = {
  className: '',
  iconProps: {},
  buttonProps: {},
};

const Group = styled('div')`
  .ant-btn:not(:first-of-type) {
    margin-left: 8px;
  }
`;
Social.Group = ({ data, ...props }) => (
  <Group>
    {data.map(({ id, url }) => (
      <Social key={id} type={id} url={url} {...props} />
    ))}
  </Group>
);
Social.Group.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Social;
