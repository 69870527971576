import React from 'react';
import styled from '@emotion/styled';
import { Col, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import Box from 'components/atoms/Box';
import Container from 'components/layouts/Container';
import Paper from 'components/atoms/Paper';

const InfoSkeleton = styled(Skeleton)`
  .ant-skeleton-header {
    ${({ theme }) => `
      ${[theme.breakpoints.up('sm')]} {
        padding-right: 24px;
      }
      ${[theme.breakpoints.up('md')]} {
        padding-right: 36px;
      }
      .ant-skeleton-avatar-lg {
        ${[theme.breakpoints.up('sm')]} {
          width: 72px;
          height: 72px;
        }
        ${[theme.breakpoints.up('md')]} {
          width: 100px;
          height: 100px;
        }
      }
    `}
  }
`;

const LecturerDetailLayout = ({
  loading,
  renderInfo,
  renderContact,
  renderProfile,
}) => (
  <Container
    px={{
      xs: 0,
      sm: 24,
      md: 0,
    }}
  >
    <Row gutter={{ sm: 24 }}>
      <Col span={24}>
        <Paper pad={'none'}>
          {loading ? (
            <Box p={{ xs: 16, sm: 24, md: 36 }}>
              <InfoSkeleton avatar active paragraph={{ rows: 1 }} />
            </Box>
          ) : (
            renderInfo()
          )}
        </Paper>
      </Col>
      <Col xs={24} sm={15}>
        <Paper
          mt={{
            xs: 16,
            sm: 24,
          }}
          pad={'none'}
        >
          {loading ? (
            <Box p={{ xs: 16, sm: 24, md: 36 }}>
              <Skeleton active />
              <Box mt={24}>
                <Skeleton active />
              </Box>
            </Box>
          ) : (
            renderProfile()
          )}
        </Paper>
      </Col>
      <Col xs={0} sm={9}>
        <Paper mt={24}>
          {loading ? (
            <Skeleton active paragraph={{ rows: 4 }} />
          ) : (
            renderContact()
          )}
        </Paper>
      </Col>
    </Row>
  </Container>
);

LecturerDetailLayout.propTypes = {
  loading: PropTypes.bool,
  renderInfo: PropTypes.func.isRequired,
  renderContact: PropTypes.func.isRequired,
  renderProfile: PropTypes.func.isRequired,
};
LecturerDetailLayout.defaultProps = {
  loading: false,
};

export default LecturerDetailLayout;
