import React, { useState } from 'react';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { Link, withRouter } from 'react-router-dom';
import { Button, Layout, Row, Col, Tooltip } from 'antd';
import Box from 'components/atoms/Box';
import Icon from 'components/atoms/Icon';
import LogoBookmark from 'components/atoms/LogoBookmark';
import MenuItem from 'components/atoms/MenuItem';
import useScrollTrigger from 'hocs/useScrollTrigger';
import MENUS from 'constants/header3';
import LangSwitcher from 'components/atoms/LangSwitcher';
import useIntl from 'hooks/useIntl';

const sortByName = (items, locale) =>
  items.sort((a, b) => (a.name[locale] < b.name[locale] ? -1 : 1));

const darkRed = '#881014';
const StyledHeader = styled(Layout.Header)`
  &.ant-layout-header {
    background: ${darkRed};
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.4s;
    box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.87);

    ${({ shrank, up }) => ({
      ...(shrank && { height: '64px' }),
      ...(up && { transform: 'translateY(-120%)' }),
    })}
  }
`;

const Menu = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: flex-end;

  > span.menu-item {
    cursor: default;
  }

  > .menu-item {
    padding: 0 32px;
    height: 64%;
    line-height: initial;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
    transition: 0.3s;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;

    &:after {
      content: '';
      height: 2px;
      margin: 0 32px;
      background: #ffffff;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }

    &:hover,
    &.-active {
      opacity: 1;
      &:after {
        opacity: 1;
      }
    }
  }
`;

const MegaMenu = styled('div')`
  height: ${({ showed }) => (showed ? '320px' : 0)};
  opacity: ${({ showed }) => (showed ? 1 : 0)};
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.38);
  position: fixed;
  left: 0;
  right: 0;
  top: ${({ shrank }) => (shrank ? 64 : 84)}px;
  transition: 0.3s;
  line-height: initial;

  .SubMenu-header {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    color: #a0a0a0;
    min-height: 36px;
    display: flex;
    padding: 0 16px;
  }

  .SubMenu-item {
    min-height: 36px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 0 8px;
    padding: 0 8px;
  }

  .SubMenu-item:hover {
    background: rgba(172, 22, 27, 0.06);
  }
`;

const hideButtonCss = css`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const NewHeader = ({ location }) => {
  const { locale } = useIntl();
  const {
    // trigger,
    scrolled,
  } = useScrollTrigger();
  const [showed, setShowed] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [subMenus, setSubMenus] = useState([]);
  const onLeaveMenu = () => {
    setShowed(false);
    setActiveMenu('');
  };
  const onHoverMenu = (menu, toggled = false) => {
    if (toggled && activeMenu === menu.id) {
      return onLeaveMenu();
    }
    setActiveMenu(menu.id);
    setSubMenus(menu.subMenus || []);
    return setShowed(true);
  };
  const onClick = () => {
    setShowed(false);
    setActiveMenu('');
  };
  const renderParentMenu = menu => {
    const similarProps = {
      key: menu.id || menu.name[locale],
      className: cx('menu-item', activeMenu === menu.id && '-active'),
      onMouseOver: () => onHoverMenu(menu),
      onFocus: () => onHoverMenu(menu),
      onTouchStart: () => onHoverMenu(menu, true),
    };
    return menu.href ? (
      <Link to={menu.href} {...similarProps}>
        {menu.name[locale]}
      </Link>
    ) : (
      <span {...similarProps}>{menu.name[locale]}</span>
    );
  };
  const renderChildMenu = menu => {
    if (!menu.name) return null;
    const getProps = () => {
      if (menu.external) {
        return {
          as: 'a',
          href: menu.href,
          target: '_blank',
          rel: 'noopener',
          onClick,
        };
      }
      if (menu.href) {
        return {
          as: Link,
          to: menu.href,
          onClick,
        };
      }
      return {};
    };
    return (
      <Tooltip
        key={menu.id || menu.name[locale]}
        title={!!menu.href || menu.heading ? '' : 'Coming soon!'}
        placement={'bottomRight'}
      >
        <MenuItem {...getProps()} disabled={!menu.href} heading={menu.heading}>
          {menu.name[locale]}
        </MenuItem>
      </Tooltip>
    );
  };
  return (
    <>
      <StyledHeader
        shrank={scrolled}
        // up={trigger || undefined}
      >
        <LogoBookmark />
        <Menu onMouseLeave={onLeaveMenu}>
          {MENUS.megaMenu.map(menu => renderParentMenu(menu))}{' '}
          <LangSwitcher buttonProps={{ style: { marginLeft: 16 } }} inverted />
          <MegaMenu shrank={scrolled} showed={showed && subMenus.length}>
            <Box px={50} height={'100%'}>
              <Row
                type={'flex'}
                justify={'center'}
                style={{ height: '100%' }}
                gutter={16}
              >
                <Col md={0} lg={1} />
                {subMenus
                  .filter(({ desktopHidden }) => !desktopHidden)
                  .map((menu, i) => {
                    const { sorted } = menu;
                    const getSubMenus = () => {
                      if (!subMenus || !Array.isArray(subMenus)) {
                        return null;
                      }
                      const [hiddenMenus, activeMenus] = partition(
                        menu.subMenus,
                        {
                          hidden: true,
                        },
                      );
                      let result = sorted
                        ? [
                            ...sortByName(activeMenus, locale),
                            ...sortByName(hiddenMenus, locale),
                          ]
                        : [...activeMenus, ...hiddenMenus];
                      result = result.filter(
                        ({ desktopHidden }) => !desktopHidden,
                      );
                      return result.map(subMenu => renderChildMenu(subMenu));
                    };
                    return (
                      <Col
                        md={6}
                        lg={5}
                        xl={4}
                        {...menu.colProps}
                        key={menu.id}
                        style={{
                          ...(!menu.borderless && {
                            borderRight:
                              i === subMenus.length - 1
                                ? 'none'
                                : '1px solid rgba(0,0,0,0.08)',
                          }),
                        }}
                      >
                        <Box py={16}>
                          {renderChildMenu(menu)}
                          {getSubMenus()}
                        </Box>
                      </Col>
                    );
                  })}
              </Row>
            </Box>
            <Button
              className={hideButtonCss}
              shape="circle"
              type={'primary'}
              onClick={onLeaveMenu}
            >
              <Icon type={'fas fa-chevron-up'} />
            </Button>
          </MegaMenu>
        </Menu>
      </StyledHeader>
      <Box
        height={84}
        flex={'0 0 auto'}
        bgColor={
          location.pathname === '/' || location.pathname.includes('/courses')
            ? darkRed
            : '#f7f7f7'
        }
      />
    </>
  );
};

NewHeader.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
NewHeader.defaultProps = {};

export default withRouter(NewHeader);
