/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import { Tag, Skeleton } from 'antd';
import cx from 'clsx';
import { withRouter, Link } from 'react-router-dom';
import { withConsumer } from 'contexts/lecturer';
import Box from 'components/atoms/Box';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import Hidden from 'components/responsive/Hidden';
import { CATEGORY_BY_ID, COURSE_TYPE_BY_ID } from 'constants/temporary';
import ROUTES from 'constants/routes';

const getLecturerName = (lecturers, id, lang = 'en') => {
  if (!lecturers) return '';
  const lecturer = lecturers.find(({ id: _id }) => _id === id);
  return get(lecturer, ['name', lang]);
};

const getCourseType = (course, lang = 'en') =>
  get(COURSE_TYPE_BY_ID, [course.type, 'name', lang]);

const CourseItem = withConsumer(
  withRouter(
    ({ className, course, lecturers: all, lecturerHidden, locale, getMsg }) => {
      const firstCategoryId = get(course, 'categories[0]');
      const categoryIcon = get(CATEGORY_BY_ID, [firstCategoryId, 'icon']);
      return course ? (
        <Box
          className={cx('CourseItem', className)}
          component={Link}
          to={ROUTES.courseDetail.get(course.id, course.name)}
          display={'flex'}
          borderRadius={4}
          bgColor={'#ffffff'}
          px={{
            xs: 16,
            md: 32,
          }}
          py={{
            xs: 16,
            md: 24,
          }}
          css={({ transitions }) => ({
            transition: transitions.create(),
            cursor: 'pointer',
            ':hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 12px 0 rgba(0,0,0,0.12)',
            },
            '& em': {
              fontStyle: 'normal',
              backgroundColor: '#ffff8b',
            },
          })}
        >
          <Image
            avatar
            shape="square"
            icon="read"
            size={48}
            mt={4}
            alt={'course-avatar'}
            src={categoryIcon}
            // src={course.coverImage}
          />
          <Box
            minWidth={0}
            ml={{
              xs: 16,
              md: 32,
            }}
            flex={1}
          >
            <Text.Title
              plainFont
              textStyle={'cardHeading'}
              fontSize={'16px !important'}
              mb={{
                xs: 2,
                sm: 8,
              }}
            >
              <Text component={'span'}>{course.code}</Text>{' '}
              <Text fontWeight={200} component={'span'}>
                —
              </Text>{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: get(
                    course,
                    '_highlightResult.name.value',
                    startCase(lowerCase(course.name)),
                  ),
                }}
              />
            </Text.Title>
            <Hidden smDown>
              {course.categories.map(categoryId => (
                <Tag key={categoryId}>{CATEGORY_BY_ID[categoryId].name}</Tag>
              ))}
              <Box height={8} />
              {!lecturerHidden &&
                (all && all.length ? (
                  course.lecturers.map(id => (
                    <Text.Paragraph
                      key={id}
                      fontSize={14}
                      mb={0}
                      color={'text.secondary'}
                    >
                      • {getLecturerName(all, id, locale)}
                    </Text.Paragraph>
                  ))
                ) : (
                  <Skeleton
                    active
                    loading
                    title={{ width: '60%' }}
                    paragraph={null}
                  />
                ))}
            </Hidden>
            <Hidden smUp>
              <Box {...Box.alignCenter} mb={4}>
                <Text color={'primary.main'}>
                  {course.credit} {getMsg('courseCredits')}
                </Text>
                <Text mx={4} color={'grey.500'}>
                  •
                </Text>
                <Text {...Text.overline} fontSize={14}>
                  {getCourseType(course, locale)}
                </Text>
              </Box>
              {course.categories.map(categoryId => (
                <Tag key={categoryId}>{CATEGORY_BY_ID[categoryId].name}</Tag>
              ))}
              <Box height={4} />
              {!lecturerHidden && (
                <Text color={'text.secondary'} fontSize={14}>
                  {course.lecturers.map(id => (
                    <Text.Paragraph key={id} mb={0}>
                      • {getLecturerName(all, id, locale)}
                    </Text.Paragraph>
                  ))}
                </Text>
              )}
            </Hidden>
          </Box>
          <Hidden smDown>
            <Box>
              <Text.Paragraph
                color={'primary.main'}
                fontSize={16}
                mb={{
                  sm: 4,
                  md: 8,
                }}
                textAlign={'right'}
              >
                {course.credit} {getMsg('courseCredits')}
              </Text.Paragraph>
              <Text.Paragraph {...Text.overline} textAlign={'right'}>
                {getCourseType(course, locale)}
              </Text.Paragraph>
            </Box>
          </Hidden>
        </Box>
      ) : null;
    },
  ),
);

CourseItem.propTypes = {
  course: PropTypes.shape({}),
  lecturerHidden: PropTypes.bool,
  locale: PropTypes.string,
  getMsg: PropTypes.func,
};
CourseItem.defaultProps = {
  course: undefined,
  lecturerHidden: false,
  locale: 'en',
  getMsg: () => '',
};

const Group = ({ courses, ...props }) => (
  <Box css={{ '& .CourseItem:not(:first-of-type)': { marginTop: 8 } }}>
    {courses.map(course => (
      <CourseItem key={course.id} course={course} {...props} />
    ))}
  </Box>
);
Group.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  lecturerHidden: PropTypes.bool,
};
Group.defaultProps = {
  courses: [],
  lecturerHidden: false,
};

CourseItem.Group = Group;
export default CourseItem;
